import gql from 'graphql-tag';

export const CREATE_PANEL_MUTATION = gql`
  mutation createPanel($panelInput: PanelCreate!) {
    createPanel(panelInput: $panelInput) {
      id
      name
      description
      questTestCode
      frequencyPerYear
      modeOfAcquisition
      questPrice
      sex
      biomarkers {
        id
        name
      }
    }
  }
`;

export const UPDATE_PANEL_MUTATION = gql`
  mutation updatePanel($id: UUID!, $panelInput: PanelUpdate!) {
    updatePanel(id: $id, panelInput: $panelInput) {
      id
      name
      description
      questTestCode
      frequencyPerYear
      modeOfAcquisition
      questPrice
      sex
      biomarkers {
        id
        name
      }
    }
  }
`;

export const DELETE_PANEL_MUTATION = gql`
  mutation deletePanel($id: UUID!) {
    deletePanel(id: $id)
  }
`;
