import gql from 'graphql-tag';

export const CREATE_PATIENT_MUTATION = gql`
  mutation createPatient($patientInput: PatientCreate!) {
    createPatient(patientInput: $patientInput) {
      id
      patientIdentifier
      patientFirebaseId
      fname
      lname
      preferredName
      biologicalSex
      dob
      ssn
      pronouns
      ethnicOrigin
      shirtSize
      createdAt
      updatedAt
      motivational {
        id
        patientId
        tenYearHealthGoals
        purposeForJoiningFunction
        howDidTheyHearAboutFunction
        commentsBeforeBooking
        createdAt
        updatedAt
      }
      covid {
        id
        patientId
        hadCovid
        covidDate
        covidSeverity
        hasCovidResidualSymptoms
        covidResidualSymptoms
        vaccinatedForCovid
        covidVaccineName
        covidVaccineDateLastDosage
        createdAt
        updatedAt
      }
      femaleReproductiveHealth {
        id
        patientId
        currentlyOnBirthControl
        birthControlType
        timeOnBirthControl
        dateOfLastPeriod
        currentlyPregnant
        tryingToConceive
        currentMenstrualStatus
        priorPregnancies
        priorPregnancy {
          id
          femaleReproductiveHealthId
          count
          numberOfChildren
          numberOfMiscarriages
          numberOfAbortions
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      medicalHx {
        id
        patientId
        hasChronicCondition
        chronicConditions
        chronicConditionDiagnosisDates
        takingMedications
        currentMedications
        hadSurgeries
        surgeries
        beenHospitalized
        hospitalizations
        hadIssuesWithBloodDraw
        bloodDrawIssues
        createdAt
        updatedAt
      }
      patientBehaviorInfo {
        id
        patientId
        diet
        physicallyActive
        smoker
        smokingFrequency
        alcohol
        alcoholFrequency
        createdAt
        updatedAt
      }
      patientContactInfo {
        id
        patientId
        email
        phoneNumber
        streetAddress
        city
        state
        zip
        createdAt
        updatedAt
      }
    }
  }
`;

export const UPDATE_PATIENT_MUTATION = gql`
  mutation updatePatient($id: UUID!, $patientInput: PatientUpdate!) {
    updatePatient(id: $id, patientInput: $patientInput) {
      id
      patientIdentifier
      patientFirebaseId
      fname
      lname
      preferredName
      biologicalSex
      dob
      ssn
      pronouns
      ethnicOrigin
      shirtSize
      createdAt
      updatedAt
      motivational {
        id
        patientId
        tenYearHealthGoals
        purposeForJoiningFunction
        howDidTheyHearAboutFunction
        commentsBeforeBooking
        createdAt
        updatedAt
      }
      covid {
        id
        patientId
        hadCovid
        covidDate
        covidSeverity
        hasCovidResidualSymptoms
        covidResidualSymptoms
        vaccinatedForCovid
        covidVaccineName
        covidVaccineDateLastDosage
        createdAt
        updatedAt
      }
      femaleReproductiveHealth {
        id
        patientId
        currentlyOnBirthControl
        birthControlType
        timeOnBirthControl
        dateOfLastPeriod
        currentlyPregnant
        tryingToConceive
        currentMenstrualStatus
        priorPregnancies
        priorPregnancy {
          id
          femaleReproductiveHealthId
          count
          numberOfChildren
          numberOfMiscarriages
          numberOfAbortions
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      medicalHx {
        id
        patientId
        hasChronicCondition
        chronicConditions
        chronicConditionDiagnosisDates
        takingMedications
        currentMedications
        hadSurgeries
        surgeries
        beenHospitalized
        hospitalizations
        hadIssuesWithBloodDraw
        bloodDrawIssues
        createdAt
        updatedAt
      }
      patientBehaviorInfo {
        id
        patientId
        diet
        physicallyActive
        smoker
        smokingFrequency
        alcohol
        alcoholFrequency
        createdAt
        updatedAt
      }
      patientContactInfo {
        id
        patientId
        email
        phoneNumber
        streetAddress
        city
        state
        zip
        createdAt
        updatedAt
      }
    }
  }
`;

export const DELETE_PATIENT_MUTATION = gql`
  mutation deletePatient($id: UUID!) {
    deletePatient(id: $id)
  }
`;

/* Patient internal objects */

export const UPDATE_PATIENT_CONTACT_INFO_MUTATION = gql`
  mutation updatePatientContactInfo(
    $id: UUID!
    $patientContactInfoInput: PatientContactInfoUpdate!
  ) {
    updatePatientContactInfo(id: $id, patientContactInfoInput: $patientContactInfoInput) {
      id
      patientId
      email
      phoneNumber
      streetAddress
      city
      state
      zip
      createdAt
      updatedAt
    }
  }
`;

export const CREATE_PATIENT_CONTACT_INFO_MUTATION = gql`
  mutation createPatientContactInfo($patientContactInfoInput: PatientContactInfoCreate!) {
    createPatientContactInfo(patientContactInfoInput: $patientContactInfoInput) {
      id
      patientId
      email
      phoneNumber
      streetAddress
      city
      state
      zip
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_PATIENT_BEHAVIOR_INFO_MUTATION = gql`
  mutation updatePatientBehaviorInfo(
    $id: UUID!
    $patientBehaviorInfoInput: PatientBehaviorInfoUpdate!
  ) {
    updatePatientBehaviorInfo(id: $id, patientBehaviorInfoInput: $patientBehaviorInfoInput) {
      id
      patientId
      diet
      physicallyActive
      smoker
      smokingFrequency
      alcohol
      alcoholFrequency
      createdAt
      updatedAt
    }
  }
`;

export const CREATE_PATIENT_BEHAVIOR_INFO_MUTATION = gql`
  mutation createPatientBehaviorInfo($patientBehaviorInfoInput: PatientBehaviorInfoCreate!) {
    createPatientBehaviorInfo(patientBehaviorInfoInput: $patientBehaviorInfoInput) {
      id
      patientId
      diet
      physicallyActive
      smoker
      smokingFrequency
      alcohol
      alcoholFrequency
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_PATIENT_MOTIVATIONAL_MUTATION = gql`
  mutation updateMotivational($id: UUID!, $motivationalInput: MotivationalUpdate!) {
    updateMotivational(id: $id, motivationalInput: $motivationalInput) {
      id
      patientId
      tenYearHealthGoals
      purposeForJoiningFunction
      howDidTheyHearAboutFunction
      commentsBeforeBooking
      createdAt
      updatedAt
    }
  }
`;

export const CREATE_PATIENT_MOTIVATIONAL_MUTATION = gql`
  mutation createMotivational($motivationalInput: MotivationalCreate!) {
    createMotivational(motivationalInput: $motivationalInput) {
      id
      patientId
      tenYearHealthGoals
      purposeForJoiningFunction
      howDidTheyHearAboutFunction
      commentsBeforeBooking
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_PATIENT_MEDICAL_HX_MUTATION = gql`
  mutation updateMedicalHx($id: UUID!, $medicalHxInput: MedicalHxUpdate!) {
    updateMedicalHx(id: $id, medicalHxInput: $medicalHxInput) {
      id
      patientId
      hasChronicCondition
      chronicConditions
      chronicConditionDiagnosisDates
      takingMedications
      currentMedications
      hadSurgeries
      surgeries
      beenHospitalized
      hospitalizations
      hadIssuesWithBloodDraw
      bloodDrawIssues
      createdAt
      updatedAt
    }
  }
`;

export const CREATE_PATIENT_MEDICAL_HX_MUTATION = gql`
  mutation createMedicalHx($medicalHxInput: MedicalHxCreate!) {
    createMedicalHx(medicalHxInput: $medicalHxInput) {
      id
      patientId
      hasChronicCondition
      chronicConditions
      chronicConditionDiagnosisDates
      takingMedications
      currentMedications
      hadSurgeries
      surgeries
      beenHospitalized
      hospitalizations
      hadIssuesWithBloodDraw
      bloodDrawIssues
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_PATIENT_FEMALE_REPRODUCTIVE_HEALTH_MUTATION = gql`
  mutation updateFemaleReproductiveHealth(
    $femaleReproductiveHealthInput: FemaleReproductiveHealthUpdate!
    $id: UUID!
  ) {
    updateFemaleReproductiveHealth(
      femaleReproductiveHealthInput: $femaleReproductiveHealthInput
      id: $id
    ) {
      id
      patientId
      currentlyOnBirthControl
      birthControlType
      timeOnBirthControl
      dateOfLastPeriod
      currentlyPregnant
      tryingToConceive
      currentMenstrualStatus
      priorPregnancies
      priorPregnancy {
        id
        femaleReproductiveHealthId
        count
        numberOfChildren
        numberOfMiscarriages
        numberOfAbortions
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;

export const CREATE_PATIENT_PRIOR_PREGNANCY = gql`
  mutation createPriorPregnancy($priorPregnancyInput: PriorPregnancyCreate!) {
    createPriorPregnancy(priorPregnancyInput: $priorPregnancyInput) {
      id
      femaleReproductiveHealthId
      count
      numberOfChildren
      numberOfMiscarriages
      numberOfAbortions
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_PATIENT_PRIOR_PREGNANCY = gql`
  mutation updatePriorPregnancy($id: UUID!, $priorPregnancyInput: PriorPregnancyUpdate!) {
    updatePriorPregnancy(id: $id, priorPregnancyInput: $priorPregnancyInput) {
      id
      femaleReproductiveHealthId
      count
      numberOfChildren
      numberOfMiscarriages
      numberOfAbortions
      createdAt
      updatedAt
    }
  }
`;

export const CREATE_PATIENT_FEMALE_REPRODUCTIVE_HEALTH_MUTATION = gql`
  mutation createFemaleReproductiveHealth(
    $femaleReproductiveHealthInput: FemaleReproductiveHealthCreate!
  ) {
    createFemaleReproductiveHealth(femaleReproductiveHealthInput: $femaleReproductiveHealthInput) {
      id
      patientId
      currentlyOnBirthControl
      birthControlType
      timeOnBirthControl
      dateOfLastPeriod
      currentlyPregnant
      tryingToConceive
      currentMenstrualStatus
      priorPregnancies
      priorPregnancy {
        id
        femaleReproductiveHealthId
        count
        numberOfChildren
        numberOfMiscarriages
        numberOfAbortions
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;

export const CREATE_PATIENT_COVID_MUTATION = gql`
  mutation createCovid($covidInput: CovidCreate!) {
    createCovid(covidInput: $covidInput) {
      id
      hadCovid
      covidDate
      covidSeverity
      hasCovidResidualSymptoms
      covidResidualSymptoms
      vaccinatedForCovid
      covidVaccineName
      covidVaccineDateLastDosage
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_PATIENT_COVID_MUTATION = gql`
  mutation updateCovid($covidInput: CovidUpdate!, $id: UUID!) {
    updateCovid(covidInput: $covidInput, id: $id) {
      id
      hadCovid
      covidDate
      covidSeverity
      hasCovidResidualSymptoms
      covidResidualSymptoms
      vaccinatedForCovid
      covidVaccineName
      covidVaccineDateLastDosage
      createdAt
      updatedAt
    }
  }
`;
