import { IBiomarkerResult } from '@/interfaces/biomarkerResults';
import { IPackage } from '@/interfaces/packages';
import { IUser } from '@/interfaces/users';
import { IVisit } from '@/interfaces/visits';
import tz from 'moment-timezone';
import { IPatient } from '@/interfaces/patients';
import { IBiomarker } from '@/interfaces/biomarkers';
import { IRecommendation } from '@/interfaces/recommendations';
import { IRequisition } from '@/interfaces/requisitions';
import { ISexSpecifics } from '@/interfaces/sexSpecifics';

/**
 * returns a subArray of packages ids from packages array
 */
export const getPackagesIdsFromPackages = (packages: IPackage []) => {
    const packagesIds: string[] = [];
    packages.forEach((p: IPackage) => {
    if (p && p.id) {
        packagesIds.push(p.id);
    }
    });
    return packagesIds;
};

/**
 * Gets a list of biomarkerResults ids from biomarkerResults array
 */
export const getBiomarkerResultsIdsFromBiomarkerResults = (biomarkerResults: IBiomarkerResult []) => {
    const biomarkerResultsIds: string[] = [];
    biomarkerResults.forEach((b: IBiomarkerResult) => {
    if (b && b.id) {
        biomarkerResultsIds.push(b.id);
    }
    });
    return biomarkerResultsIds;
};

/**
 * reduces the list of biomarkers to a simple list of biomarker ids
 */
export const getBiomarkerIdsFromBiomarkers = (biomarkers: IBiomarker[]) => {
    const biomarkerIds: string [] = [];
    if (biomarkers && biomarkers.length > 0) {
        biomarkers.forEach((biomarker: IBiomarker) => {
        biomarkerIds.push(biomarker.id || '');
        });
    }
    return biomarkerIds;
};

/**
 * reduces a list of Recommenations to a simple list of Recommendation ids
 */
export const getRecommendationIdsFromRecommendations = (recommendations: IRecommendation[]) => {
    const recommendationIds: string [] = [];
    if (recommendations && recommendations.length > 0) {
        recommendations.forEach((recommendation: IRecommendation) => {
        recommendationIds.push(recommendation.id || '');
        });
    }
    return recommendationIds;
};

/**
 * Rerturns a subArray of visits ids from visits array
 */
export const getVisitsIdsFromVisits = (visits: IVisit []) => {
    if (visits && visits.length > 0) {
        const visitsIds: string[] = [];
        visits.forEach((visit: IVisit ) => {
        if (visit && visit.id) {
            visitsIds.push(visit.id);
        }
        });
        return visitsIds;
    } else {
        return [];
    }
};

export const getPatientNameOrEmail = (patient: IPatient) => {
    if (patient) {
        if (patient.fname && patient?.lname) {
            return patient.fname + ' ' + patient.lname;
        } else if (patient.fname && !patient.lname) {
            return patient.fname;
        } else if (!patient.fname && patient.lname) {
            return patient.lname;
        } else if (patient.patientContactInfo
            && Object.keys(patient.patientContactInfo).length > 0) {
            return patient.patientContactInfo.email || 'N/A';
        } else {
            return 'N/A';
        }
    }
    return '';
};

/**
 * Gets a phyisican name from an user object
 */
export const getPhysicianName = (physician: IUser) => {
    if (physician) {
        if (physician.fname && physician?.lname) {
            return physician.fname + ' ' + physician.lname;
        } else if (physician.fname && !physician.lname) {
            return physician.fname;
        } else if (!physician.fname && physician.lname) {
            return physician.lname;
        } else {
            return 'N/A';
        }
    }
    return '';
};

export const getCurrentTimezone = () => {
    return tz.tz.guess();
};

/**
 * Converts utc time to local time am/pm format
 */
export const convertUtcToLocalTime = (date: string | Date, timezone: string) => {
    if (date) {
        return tz.utc(date).tz(timezone).format('YYYY-MM-DD HH:mm');
    } else {
        return '';
    }
};

/**
 * Converts local time to utc time format (YYYY-MM-DD HH:mm z)
 */
export const convertLocalTimeToUtc = (date: string | Date, timezone: string) => {
    if (date) {
        const momentDateTimeObject = tz.tz(date, timezone);
        const utcDateTime = momentDateTimeObject.utc().format();
        return utcDateTime;
    } else {
        return '';
    }
};


/**
 * Gets a formated dateTime tz string from the UTC dateTime string and a timezone string
 * @param date: string -> server time with format YYYY-MM-DD HH:mm
 * @param timezone: string -> timezone string
 * @returns - formatted dateTime tz string
 */
export const getFormattedDateTimeFromUtc = (date: string, timezone) => {
        const dateTime = tz.utc(date).tz(timezone).format('YYYY-MM-DD HH:mm z');
        return dateTime;
};

/*
Util method to reduce and extract ids from the selected objects
*/
export const extractIds = (list, key = 'id') => {
    let returningList = [];
    if (list && list.length > 0) {
        returningList = list.reduce((acc, item) => {
            if (item && item[key]) {
                acc.push(item[key]);
            }
            return acc;
        }, []);
    }
    return returningList;
};

/**
 * Check if the biomarker is critical for the patient sex
 * @param biomarker - the biomarker object to be checked
 * @param patientSex - the patient sex
 * @returns - true if the biomarker is critical for the patient sex
 */
export const checkIfBiomarkerIsCritical = (biomarker: IBiomarker, patientSex: string) => {
    if (biomarker && patientSex) {
        const sexSpecificForPatinet = getBiomarkerSexDetailsForPatientBiologicalSex(biomarker, patientSex);

        if (sexSpecificForPatinet && sexSpecificForPatinet.critical
                && Object.keys(sexSpecificForPatinet.critical).length > 0) {
            return sexSpecificForPatinet.critical.isPriority1 || sexSpecificForPatinet.critical.isPriority2;
        }
    }

    return false;
};


/**
 * gets the current patient biological sex
 * or returns "unknown" if not found
 */
export const getPatientBiologicalSexFromRequisition = (requisition: IRequisition): string => {
  if (requisition && requisition.patient && (requisition.patient as IPatient).biologicalSex) {
    return (requisition.patient as IPatient).biologicalSex as string || 'unknown';
  }

  return 'unknown';
};

/**
 * gets the sexDetails from biomarker
 * based on the patient biological sex.
 * If a matching sexDetails is not found it returns undefined
 */
export const getBiomarkerSexDetailsForPatientBiologicalSex = (item: IBiomarker, patientBiologicalSex: string) => {

  let patientSexSpecificInformation: ISexSpecifics | undefined; // returning variable

  if (item && item.sexDetails && item.sexDetails.length > 0) {
    /*
      Check the sexSpecific information that the biomarker contains and is compatible with
      the patient biological sex.
      the code will only take in count the first occurency of the sexSpecific information
      if for example the patient is male and there is multiple "male" sexSpecific information
      the code will only take the first one. Also if there is no sexSpecific information for the
      patient sex the code will take the first "all" sexSpecific information
    */
    const compatibleSexSpecifics = item.sexDetails.filter((sexSpecific) => {
      if (sexSpecific.sex === patientBiologicalSex || sexSpecific.sex === 'All') {
        return true;
      }
      return false;
    });

    // now select the first compatible sexSpecific information by sex
    if (compatibleSexSpecifics && compatibleSexSpecifics.length > 0) {
      if (compatibleSexSpecifics.length === 1) {
        // in this case, just take the first one
        patientSexSpecificInformation = compatibleSexSpecifics[0];
      } else {
        const biomarkerSexSpecificForPatientSex = compatibleSexSpecifics.find((sexSpecific) => {
          return sexSpecific === patientBiologicalSex;
        });
        // if there is a finding, assign it to the final variable
        if (biomarkerSexSpecificForPatientSex) {
          patientSexSpecificInformation = biomarkerSexSpecificForPatientSex;
        } else {
          // if there is no finding, just take the first one, it should be for "All"
          patientSexSpecificInformation = compatibleSexSpecifics[0];
        }
      }
    }
  }
  return patientSexSpecificInformation;
};
