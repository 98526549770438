import { ActionContext } from 'vuex';

import * as mutations from '@/store/crud/mutations';

import { CrudState } from '@/store/crud/state';

import { State } from '@/store/state';
import { commitAddNotification } from '@/store/main/mutations';
import { dispatchCheckApiError } from '@/store/main/actions';

import { graphQlApi } from '@/graphql/graphqlApi';
import { ICreateRecommendation, IRecommendation, IUpdateRecommendation } from '@/interfaces/recommendations';
import { crudModules } from '@/constants/globalConstants';


type MainContext = ActionContext<CrudState, State>;


/**
 * Returns cached data
 * or false if it is expired
 */
const getCachedData = async (context: MainContext, needsDataUpgrade: boolean) => {

    const cachedData = context.getters.recommendations;
    const dispatchAction = 'recommendationsDataExpired';

    if (cachedData && cachedData.length > 0) {
        const dataExpired = await context.dispatch(dispatchAction);
        // check data expiration
        if (dataExpired) {
            // re run this action without cached data
            return false; // data is expired so we need to fetch new data
        } else {
            if (needsDataUpgrade) {
                if (context.getters.needsDataUpgrade(crudModules.RECOMMENDATIONS)) {
                    return false;
                }
            }
            return cachedData;
        }
    } else {
        return false;
    }
};

/**
 * Actions sub module for Exercises actions
 */
export default {
    /**
     * Gets all recommendations with minimal data to be used in lists and dropdowns
     * @param context - Vuex Action context
     * @returns - Promise with recommendations or false if error
     */
     async actionGetRecommendationsForList(context: MainContext) {
        try {
            // check cached data
            const cachedData = await getCachedData(context, false);
            if (cachedData) {
                return cachedData;
            }

            const response = await graphQlApi.getRecommendationsForList();
            if (response) {
                mutations.commitSetRecommendations(context, response.data.recommendations);
                mutations.setModuleNeedsDataUpgrade(context, {
                    module: crudModules.RECOMMENDATIONS,
                    needsDataUpgrade: true,
                });
                mutations.setDataUpdated(context, crudModules.RECOMMENDATIONS);
                return Promise.resolve(response.data.recommendations);
            } else {
                throw new Error('Failed while fetching recommendations');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Error while fetching recommendations', color: 'error' });
            return Promise.resolve(false);
        }
    },
    /**
     * Gets all recommendations with minimal data to be used in lists and dropdowns
     * @param context - Vuex Action context
     * @returns - Promise with recommendations or false if error
     */
    async actionGetRecommendationsForTable(context: MainContext) {
    try {
        // check cached data
        const cachedData = await getCachedData(context, true);
        if (cachedData) {
            return cachedData;
        }

        const response = await graphQlApi.getRecommendationsForTable();
        if (response) {
            mutations.commitSetRecommendations(context, response.data.recommendations);
            mutations.setModuleNeedsDataUpgrade(context, {
                module: crudModules.RECOMMENDATIONS,
                needsDataUpgrade: true,
            });
            mutations.setDataUpdated(context, crudModules.RECOMMENDATIONS);
            return Promise.resolve(response.data.recommendations);
        } else {
            throw new Error('Failed while fetching recommendations');
        }
    } catch (error) {
        await dispatchCheckApiError(context, error);
        commitAddNotification(context, { content: 'Error while fetching recommendations', color: 'error' });
        return Promise.resolve(false);
    }
    },
    /**
     * Gets all recommendations with all available data
     * @param context - Vuex Action context
     * @returns - Promise with recommendations or false if error
     */
    async actionGetRecommendations(context: MainContext) {
        try {
            // check cached data
            const cachedData = await getCachedData(context, true);
            if (cachedData) {
                return cachedData;
            }

            const response = await graphQlApi.getAllRecommendations();
            if (response) {
                mutations.commitSetRecommendations(context, response.data.recommendations);
                mutations.setModuleNeedsDataUpgrade(context, {
                    module: crudModules.RECOMMENDATIONS,
                    needsDataUpgrade: false,
                });
                mutations.setDataUpdated(context, crudModules.RECOMMENDATIONS);
                return Promise.resolve(response.data.recommendations);
            } else {
                throw new Error('Failed while fetching recommendations');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Error while fetching recommendations', color: 'error' });
            return Promise.resolve(false);
        }
    },
    /**
     * Gets a single recommendation from the DB
     * @param context - Vuex Action context
     * @param payload - Recommendation to get
     * @returns - Promise with recommendation or false if error
     */
    async actionGetRecommendationById(context: MainContext, id: string) {
        try {
            const response = await graphQlApi.getRecommendationById(id);
            if (response) {
                mutations.commitSetRecommendation(context, response.data.recommendationById);
                return Promise.resolve(response.data.recommendationById);
            } else {
                throw new Error('Failed while fetching recommendation');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Error while fetching recommendation', color: 'error' });
            return Promise.resolve(false);
        }
    },
    /**
     * updates a single recommendation in the DB
     * @param context - Vuex Action context
     * @param payload - recommendation to add
     * @returns - Promise with added recommendation or false if error
     */
    async actionUpdateRecommendation(context: MainContext, payload: { recommendationId: string,
        recommendations: IUpdateRecommendation }) {
        const loadingNotification = { content: 'saving', color: 'info' };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                graphQlApi.updateRecommendation(payload.recommendationId, payload.recommendations),
                await new Promise<void>((resolve) => setTimeout(() => resolve(), 500)),
            ]))[0];
            if (response) {
                commitAddNotification(context, { content: 'recommendations successfully updated', color: 'success' });
                mutations.commitSetRecommendation(context, response.data.updateRecommendation);
                return Promise.resolve(response.data.updateRecommendation);
            } else {
                throw new Error('Failed while updating recommendations');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Failed while updating recommendations', color: 'error' });
            return Promise.resolve(false);
        }
    },
    /**
     * Creates a new recommendations in the DB
     * @param context - Vuex Action context
     * @param payload - recommendations to add
     * @returns - Promise with added recommendations or false if error
     */
    async actionCreateRecommendation(context: MainContext, payload: ICreateRecommendation) {
        const loadingNotification = { content: 'saving', color: 'info' };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                graphQlApi.createRecommendation(payload),
                await new Promise<void>((resolve) => setTimeout(() => resolve(), 500)),
            ]))[0];
            if (response) {
                commitAddNotification(context, { content: 'recommendations successfully created', color: 'success' });
                mutations.commitSetRecommendation(context, response.data.createRecommendation);
                return Promise.resolve(response.data.createRecommendation);
            } else {
                throw new Error('Failed while creating recommendations');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Failed while creating recommendations', color: 'error' });
            return Promise.resolve(false);
        }
    },
    /**
     * deletes a single recommendations from the DB
     * @param context - Vuex Action context
     * @param payload - recommendations to add
     * @returns - Promise with the removed recommendations or false if error
     */
    async actionDeleteRecommendation(context: MainContext, payload: { recommendationId: string }) {
        const loadingNotification = { content: 'saving', color: 'info' };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                graphQlApi.deleteRecommendation(payload.recommendationId),
                await new Promise<void>((resolve) => setTimeout(() => resolve(), 500)),
            ]))[0];
            if (response) {
                commitAddNotification(context, { content: 'recommendations successfully deleted', color: 'success' });
                mutations.commitDeleteRecommendation(context, {id: payload.recommendationId} as IRecommendation);
                return Promise.resolve(response.data.deleteRecommendation);
            } else {
                throw new Error('Failed while deleting recommendations');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Failed while removing recommendations', color: 'error' });
            return Promise.resolve(false);
        }
    },
};

