import gql from 'graphql-tag';

export const GET_PHYSICIAN_NOTES_QUERY = gql`
query physicianNotes($skip: Int, $limit: Int, $requisitionIds: [UUID!]) {
  physicianNotes(skip: $skip, limit: $limit, requisitionIds: $requisitionIds){
    id
    content
    category {
      id
      categoryName
    }
    user {
      id
    }
    createdAt
    updatedAt
  }
}
`;

export const GET_PHYSICIAN_NOTE_BY_ID_QUERY = gql`
  query physicianNoteById($id: String!) {
    physicianNoteById(id: $id){
      id
      content
      category {
        id
        categoryName
      }
      user {
        id
      }
      createdAt
      updatedAt
    }
  }
`;
