import { ActionContext } from 'vuex';

import * as mutations from '@/store/crud/mutations';

import { CrudState } from '@/store/crud/state';

import { State } from '@/store/state';
import { commitAddNotification } from '@/store/main/mutations';
import { dispatchCheckApiError } from '@/store/main/actions';

import { graphQlApi } from '@/graphql/graphqlApi';
import { ICreateFood, IFood, IUpdateFood } from '@/interfaces/foods';
import { crudModules } from '@/constants/globalConstants';


type MainContext = ActionContext<CrudState, State>;


/**
 * Returns cached data
 * or false if it is expired
 */
const getCachedData = async (context: MainContext, needsDataUpgrade: boolean) => {

    const cachedData = context.getters.foods;
    const dispatchAction = 'foodsDataExpired';

    if (cachedData && cachedData.length > 0) {
        const dataExpired = await context.dispatch(dispatchAction);
        // check data expiration
        if (dataExpired) {
            // re run this action without cached data
            return false; // data is expired so we need to fetch new data
        } else {
            if (needsDataUpgrade) {
                if (context.getters.needsDataUpgrade(crudModules.FOODS)) {
                    return false;
                }
            }
            return cachedData;
        }
    } else {
        return false;
    }
};

/**
 * Actions sub module for Exercises actions
 */
export default {
    /**
     * Gets all exercises with all available data
     * @param context - Vuex Action context
     * @returns - Promise with added exercise or false if error
     */
         async actionGetFoodsForList(context: MainContext) {
        try {
            // check cached data
            const cachedData = await getCachedData(context, false);
            if (cachedData) {
                return cachedData;
            }

            const response = await graphQlApi.getAllFoodsForList();
            if (response) {
                mutations.commitSetFoods(context, response.data.foods);
                mutations.setModuleNeedsDataUpgrade(context, {
                    module: crudModules.FOODS,
                    needsDataUpgrade: true,
                });
                mutations.setDataUpdated(context, crudModules.FOODS);
                return Promise.resolve(response.data.foods);
            } else {
                throw new Error('Failed while fetching foods');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Error while fetching foods', color: 'error' });
            return Promise.resolve(false);
        }
    },
    /**
     * Gets all exercises with all available data
     * @param context - Vuex Action context
     * @returns - Promise with added exercise or false if error
     */
    async actionGetFoods(context: MainContext) {
        try {
            // check cached data
            const cachedData = await getCachedData(context, true);
            if (cachedData) {
                return cachedData;
            }

            const response = await graphQlApi.getAllFoods();
            if (response) {
                mutations.commitSetFoods(context, response.data.foods);
                mutations.setModuleNeedsDataUpgrade(context, {
                    module: crudModules.FOODS,
                    needsDataUpgrade: false,
                });
                mutations.setDataUpdated(context, crudModules.FOODS);
                return Promise.resolve(response.data.foods);
            } else {
                throw new Error('Failed while fetching foods');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Error while fetching foods', color: 'error' });
            return Promise.resolve(false);
        }
    },
    /**
     * Gets a single food from the database
     * @param context - Vuex Action context
     * @param payload - Food to get
     * @returns - Promise with food or false if error
     */
    async actionGetFoodById(context: MainContext, id: string) {
        try {
            const response = await graphQlApi.getFoodById(id);
            if (response) {
                mutations.commitSetFood(context, response.data.foodById);
                return Promise.resolve(response.data.foodById);
            } else {
                throw new Error('Failed while fetching food');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Error while fetching food', color: 'error' });
            return Promise.resolve(false);
        }
    },
    /**
     * Updates a single food in the DB
     * @param context - Vuex Action context
     * @param payload - Food to add
     * @returns - Promise with added food or false if error
     */
    async actionUpdateFood(context: MainContext, payload: { foodId: string, food: IUpdateFood }) {
        const loadingNotification = { content: 'saving', color: 'info' };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                graphQlApi.updateFood(payload.foodId, payload.food),
                await new Promise<void>((resolve) => setTimeout(() => resolve(), 500)),
            ]))[0];
            if (response) {
                commitAddNotification(context, { content: 'Food successfully updated', color: 'success' });
                mutations.commitSetFood(context, response.data.updateFood);
                return Promise.resolve(response.data.updateFood);
            } else {
                throw new Error('Failed while updating food');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Failed while updating food', color: 'error' });
            return Promise.resolve(false);
        }
    },
    /**
     * Creates a new food in the DB
     * @param context - Vuex Action context
     * @param payload - Food to add
     * @returns - Promise with added food or false if error
     */
    async actionCreateFood(context: MainContext, payload: ICreateFood) {
        const loadingNotification = { content: 'saving', color: 'info' };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                graphQlApi.createFood(payload),
                await new Promise<void>((resolve) => setTimeout(() => resolve(), 500)),
            ]))[0];
            if (response) {
                commitAddNotification(context, { content: 'Food successfully created', color: 'success' });
                mutations.commitSetFood(context, response.data.createFood);
                return Promise.resolve(response.data.createFood);
            } else {
                throw new Error('Failed while creating food');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Failed while creating food', color: 'error' });
            return Promise.resolve(false);
        }
    },
    /**
     * Deletes a single food from the DB
     * @param context - Vuex Action context
     * @param payload - Food to remove
     * @returns - Promise with removed food or false if error
     */
    async actionDeleteFood(context: MainContext, payload: { foodId: string }) {
        const loadingNotification = { content: 'saving', color: 'info' };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                graphQlApi.deleteFood(payload.foodId),
                await new Promise<void>((resolve) => setTimeout(() => resolve(), 500)),
            ]))[0];
            if (response) {
                commitAddNotification(context, { content: 'Food successfully deleted', color: 'success' });
                mutations.commitDeleteFood(context, {id: payload.foodId} as IFood);
                return Promise.resolve(response.data.deleteFood);
            } else {
                throw new Error('Failed while deleting food');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Failed while removing food', color: 'error' });
            return Promise.resolve(false);
        }
    },
};
