import gql from 'graphql-tag';

export const CREATE_BIOMARKER_RESULT_MUTATION = gql`
  mutation createBiomarkerResults($biomarkerResultInput: BiomarkerResultCreate!) {
    createBiomarkerResults(biomarkerResultInput: $biomarkerResultInput) {
      id
      biomarker {
        id
        name
      }
      biomarkerName
      orderingPhysician {
        id
        email
        isPhysician
        fname
        lname
      }
      dateOfService
      collectionSite
      testResult
      measurementUnits
      testResultOutOfRange
      questReferenceRange
    }
  }
`;

export const UPDATE_BIOMARKER_RESULT_MUTATION = gql`
  mutation updateBiomarkerResults($biomarkerResultInput: BiomarkerResultUpdate!, $id: UUID!) {
    updateBiomarkerResults(biomarkerResultInput: $biomarkerResultInput, id: $id) {
      id
      biomarker {
        id
        name
      }
      biomarkerName
      orderingPhysician {
        id
        email
        isPhysician
        fname
        lname
      }
      dateOfService
      collectionSite
      testResult
      measurementUnits
      testResultOutOfRange
      questReferenceRange
    }
  }
`;

export const DELETE_BIOMARKER_RESULT_MUTATION = gql`
  mutation deleteBiomarkerResults($id: UUID!) {
    deleteBiomarkerResults(id: $id)
  }
`;
