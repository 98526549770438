import {ActionContext} from 'vuex';
import {CrudState} from '@/store/crud/state';
import {State} from '@/store/state';

import * as mutations from '@/store/crud/mutations';
import {commitAddNotification} from '@/store/main/mutations';
import {dispatchCheckApiError} from '@/store/main/actions';

import {
  IDocumentRequest,
  IDocumentUpload,
  IListDirectoriesRequest,
  ITableRequisitionDocuments,
} from '@/interfaces/documents';
import {api} from '@/api';
import {
  dispatchGetDocumentsFromRequisition,
  dispatchGetPatientsForList,
  dispatchGetRequisitionsForList,
} from '../actions';
import {readOnePatientById} from '../getters';
import {getPatientNameOrEmail} from '@/utils/common';
import {IRequisition} from '@/interfaces/requisitions';
import {IVisit} from '@/interfaces/visits';
import {getLocalToken} from '@/utils';
import {ITimeTrackerCreate} from '@/interfaces/timeTracker';
import {graphQlApi} from '@/graphql/graphqlApi';

type MainContext = ActionContext<CrudState, State>;

/**
 * Actions sub module for user actions
 */
export default {
  /**
   * Documents actions
   */

  /**
   * Gets a single file from the documents service
   * @param context - Vuex Action context
   * @param payload - request payload
   * @returns - Promise with file or false if error
   */
  async actionGetFile(context: MainContext, payload: {request: IDocumentRequest}) {
    const loadingNotification = {content: 'saving', color: 'info'};
    try {
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          api.getDocument(getLocalToken() || '', payload.request),
          await new Promise<void>((resolve) => setTimeout(() => resolve(), 500)),
        ])
      )[0];
      if (response) {
        return Promise.resolve(response.data);
      } else {
        throw new Error('Failed while getting document');
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
      commitAddNotification(context, {content: 'Failed while getting document', color: 'error'});
      return Promise.resolve(false);
    }
  },
  /**
   * Gets all documents from the existing requisitions
   * This is temporal until the documents service has a better way
   * to handle the directories and files
   * @param context - Vuex Action context
   * @returns - Promise with documents or false if error
   * @deprecated - This function is low performance and should not be used
   */
  async actionGetAllDocuments(context: MainContext) {
    try {
      // FIXME - this is temporal until we have a better solution
      // get requisitions first
      await dispatchGetRequisitionsForList(context);
      await dispatchGetPatientsForList(context);

      const requisitions = context.getters.requisitions as IRequisition[];

      // creates a map of patientIds and requisitionsIds
      const patientRequisitionMap: IListDirectoriesRequest[] = []; // map of patientId to requisitionId
      for (const requisition of requisitions) {
        if (requisition.patientId && requisition.patientId.length > 0) {
          if (requisition.visits && requisition.visits.length > 0) {
            for (const visit of requisition.visits) {
              patientRequisitionMap.push({
                patient_id: requisition.patientId,
                requisition_id: requisition.id || '',
                list_only_pdf: true,
                visit_id: (visit as IVisit).id || '',
              });
            }
          }
        }
      }
      const existingDocuments: ITableRequisitionDocuments[] = [];
      const documentRequestPromises: any[] = [];

      // with the patientIds matched with the requisitionIds we loop over the resultings array
      // to request to the service the documents for each patientId/requisitionId pair
      for (const requestPayload of patientRequisitionMap) {
        documentRequestPromises.push({
          promise: dispatchGetDocumentsFromRequisition(context, {
            data: requestPayload,
            yieldMessages: true,
          }),
          payload: requestPayload,
        });
      }

      for (const promise of documentRequestPromises) {
        try {
          const document = await promise.promise;

          if (document && document.length > 0) {
            // if there are documents for the patient/requisition
            // pair, we add them to the existingDocuments array
            // we also check if there is a file on this directory, for that, for the moment we just
            // check if it ends with .pdf
            document.forEach((doc) => {
              if (doc.endsWith('.pdf')) {
                // get the patient Name from the patientId
                let patientName = '';
                const patientProfile = readOnePatientById(context)(promise.payload.patient_id);
                if (patientProfile && Object.keys(patientProfile).length > 0) {
                  patientName = getPatientNameOrEmail(patientProfile);
                }

                existingDocuments.push({
                  documentName: doc.split('/').pop(), // get the last part of the path,
                  requisitionId: promise.payload.requisition_id,
                  visitId: promise.payload.visit_id,
                  patientName,
                  patientId: promise.payload.patient_id,
                });
              }
            });
          }
        } catch (error) {
          // FIXME - important log here
          continue; // ignore the error and continue
        }
      }

      if (existingDocuments.length > 0) {
        mutations.commitSetDocuments(context, existingDocuments);
        return Promise.resolve(existingDocuments);
      }
    } catch (error) {
      commitAddNotification(context, {content: 'Failed while getting documents', color: 'error'});
      return Promise.resolve(false);
    }
  },
  /**
   * Get All the documents for a given visit.
   * To achieve this, a request to the pdf service is made with the patientId as base route.
   * Then all the documents are filtered by the visitId.
   * @param context - Vuex Action context
   * @param payload - request payload { visitId: string, patientId: string }
   */
  async actionGetVisitDocuments(
    context: MainContext,
    payload: {yieldMessages: boolean; visitId: string; patientId},
  ) {
    const {visitId, patientId, yieldMessages} = payload;
    const loadingNotification = {content: 'saving', color: 'info'};
    try {
      if (!yieldMessages) {
        commitAddNotification(context, loadingNotification);
      }
      const response = (
        await Promise.all([
          api.getDocumentsFromVisit(getLocalToken() || '', {
            patient_id: patientId,
            list_only_pdf: true,
            visit_id: '',
            requisition_id: '',
          }),
          await new Promise<void>((resolve) => setTimeout(() => resolve(), 500)),
        ])
      )[0];
      if (response && response.data) {
        const documents = response.data.filter((doc: string) => doc.includes(visitId));
        return Promise.resolve(documents);
      } else {
        throw new Error('Failed while getting documents');
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
      commitAddNotification(context, {content: 'Failed while getting documents', color: 'error'});
      return Promise.resolve(false);
    }
  },
  /**
   * Gets a list of directories or documents from the documents service
   * @param context - Vuex Action context
   * @param payload - request payload { data: IDocumentRequest, yieldMessages: boolean }
   * @returns - Promise with list of directories or documents or false if error
   */
  async actionGetDocumentsFromRequisition(context: MainContext, payload) {
    const {data, yieldMessages} = payload;
    const loadingNotification = {content: 'saving', color: 'info'};
    try {
      if (!yieldMessages) {
        commitAddNotification(context, loadingNotification);
      }
      const response = (
        await Promise.all([
          api.getDocumentsFromRequisition(getLocalToken() || '', data),
          await new Promise<void>((resolve) => setTimeout(() => resolve(), 500)),
        ])
      )[0];
      if (response) {
        return Promise.resolve(response.data);
      } else {
        throw new Error('Failed while getting documents');
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
      if (!yieldMessages) {
        commitAddNotification(context, {content: 'Failed while getting documents', color: 'error'});
      }
      return Promise.resolve(false);
    }
  },
  /**
   * Deletes a document from the service
   * @param context - Vuex Action context
   * @param payload - request payload { data: ITableRequisitionDocuments }
   * @returns - Promise with true if success or false if error
   */
  async actionDeleteDocument(context: MainContext, payload: IDocumentRequest) {
    const loadingNotification = {content: 'saving', color: 'info'};
    try {
      commitAddNotification(context, loadingNotification);
      const deletePayload: IDocumentRequest = {
        ...payload,
      };
      const response = (
        await Promise.all([
          api.deleteDocument(getLocalToken() || '', deletePayload),
          await new Promise<void>((resolve) => setTimeout(() => resolve(), 500)),
        ])
      )[0];
      if (response) {
        mutations.commitDeleteDocument(context, payload);
        return Promise.resolve(response);
      } else {
        throw new Error('Failed while deleting document');
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
      commitAddNotification(context, {content: 'Failed while deleting document', color: 'error'});
      return Promise.resolve(false);
    }
  },
  /**
   * Uploads a document to the documents service
   * @param context - Vuex Action context
   * @param payload - request payload { data: IDocumentRequest, yieldMessages: boolean }
   * @returns - Promise with true if success or false if error
   */
  async actionUploadDocument(context: MainContext, payload: IDocumentUpload) {
    const loadingNotification = {content: 'saving', color: 'info'};
    await dispatchGetPatientsForList(context); // for patient names

    try {
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          api.uploadDocument(getLocalToken() || '', payload),
          await new Promise<void>((resolve) => setTimeout(() => resolve(), 500)),
        ])
      )[0];
      if (response) {
        commitAddNotification(context, {content: 'document uploaded', color: 'success'});
        // get the patient Name from the patientId
        let patientName = '';
        const patientProfile = readOnePatientById(context)(payload.patient_id);
        if (patientProfile && Object.keys(patientProfile).length > 0) {
          patientName = getPatientNameOrEmail(patientProfile);
        }
        mutations.commitSetDocument(context, {
          patientId: payload.patient_id,
          requisitionId: payload.requisition_id,
          patientName,
          visitId: payload.visit_id,
          fileName: payload.pdf_file_name.name,
        } as ITableRequisitionDocuments);

        return Promise.resolve(response);
      } else {
        throw new Error('Failed while uploading document');
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
      commitAddNotification(context, {content: 'Failed while uploading document', color: 'error'});
      return Promise.resolve({ data: { url: '', status_code: error.status_code }});
    }
  },

  async actionDownloadDocument(context: MainContext, payload: ITableRequisitionDocuments) {
    const loadingNotification = {content: 'Downloading..', color: 'info'};
    try {
      commitAddNotification(context, loadingNotification);
      const downloadPayload: IDocumentRequest = {
        patient_id: payload.patientId,
        visit_id: payload.visitId,
        requisition_id: payload.requisitionId,
        pdf_file_name: payload.documentName!,
      };
      const response = (
        await Promise.all([
          api.getDocument(getLocalToken() || '', downloadPayload),
          await new Promise<void>((resolve) => setTimeout(() => resolve(), 500)),
        ])
      )[0];
      if (response) {
        return Promise.resolve(response);
      } else {
        throw new Error('Failed while downloading document');
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
      commitAddNotification(context, {
        content: 'Failed while downloading document',
        color: 'error',
      });
      return Promise.resolve(false);
    }
  },

  async actionGetTimezoneForLocation(context: MainContext, payload: {city: string; state: string}) {
    try {
      const response = await api.getTimezoneForAppointment(getLocalToken() ?? '', payload);
      if (response) {
        return Promise.resolve(response.data);
      } else {
        throw new Error('Failed while getting timezone');
      }
    } catch (error) {
      commitAddNotification(context, {content: 'Failed while Getting timezone', color: 'error'});
      return Promise.resolve(false);
    }
  },

  async actionGetTimezonesBatchAction(
    context: MainContext,
    requestDictionary: {[key: string]: Array<{city: string; state: string}>},
  ) {
    try {
      const response = await api.getTimezonesBatch(getLocalToken() ?? '', requestDictionary);
      if (response) {
        return Promise.resolve(response.data);
      } else {
        throw new Error('Failed while getting timezones');
      }
    } catch (error) {
      commitAddNotification(context, {content: 'Failed while Getting timezones', color: 'error'});
      return Promise.resolve(false);
    }
  },

  async actionGetPatientsTimeline(context: MainContext) {
    try {
      const response = await api.getPatientsTimeline(getLocalToken() ?? '');
      if (response) {
        return Promise.resolve(response.data);
      } else {
        throw new Error('Failed while getting Patients timeline');
      }
    } catch (error) {
      commitAddNotification(context, {
        content: 'Failed while getting Patients timeline',
        color: 'error',
      });
      return Promise.resolve(false);
    }
  },
  /**
   * Gets a single file from the documents service
   * @param context - Vuex Action context
   * @param payload - request payload
   * @returns - Promise with file or false if error
   */
  async actionCreateReviewingTime(context: MainContext, payload: ITimeTrackerCreate) {
    try {
      const response = (await Promise.all([
        graphQlApi.createReviewingTime(payload),
        await new Promise<void>((resolve) => setTimeout(() => resolve(), 500)),
      ]))[0];
      if (response) {
        return Promise.resolve(response.data.createReviewingTime);
      } else {
        throw new Error('Failed creating the time review tracking');
      }
    } catch (error) {
      return Promise.resolve(false);
    }
  },
};
