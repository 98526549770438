import { ActionContext } from 'vuex';

import * as mutations from '@/store/crud/mutations';

import { CrudState } from '@/store/crud/state';

import { State } from '@/store/state';
import { commitAddNotification } from '@/store/main/mutations';
import { dispatchCheckApiError } from '@/store/main/actions';



import { graphQlApi } from '@/graphql/graphqlApi';
import {ICreateVisit, IUpdateVisit, IVisit, IVisitDocument} from '@/interfaces/visits';
import { crudModules } from '@/constants/globalConstants';



type MainContext = ActionContext<CrudState, State>;


/**
 * Returns visits cached data
 * or false if it is expired
 * If the view or components needs the full data, there is a flag
 * "needsDataUpgrade" to check if the existing cache is list or full data.
 * If it is list, then the data is not valid and needs to be upgraded.
 */
const getVisitsCachedData = async (context: MainContext, needsDataUpgrade = false) => {
    if (context.getters.visits && context.getters.visits.length > 0) {
        const dataExpired = await context.dispatch('visitsDataExpired');
        // check data expiration
        if (dataExpired) {
            // re run this action without cached data
            return false; // data is expired so we need to fetch new data
        } else {
            if (needsDataUpgrade) {
                if (context.getters.needsDataUpgrade(crudModules.VISITS)) {
                    return false;
                }
            }
            return context.getters.visits;
        }
    } else {
        return false;
    }
};

/**
 * Actions sub module for visits visits
 */
export default {
    /**
     * Gets all the visits with all the available data from the database
     * @param context - MainContext
     * @returns - Promise<IVisit[]> - list of visits or false if error
     */
    async actionGetVisits(context: MainContext) {
        try {
            // check cached data
            const visitCachedData = await getVisitsCachedData(context, true);
            if (visitCachedData) {
                return visitCachedData;
            }
            const response = await graphQlApi.getAllVisits();
            if (response) {
                mutations.commitSetVisits(context, response.data.visits);
                mutations.setModuleNeedsDataUpgrade(context, {
                    module: crudModules.VISITS,
                    needsDataUpgrade: false,
                });
                mutations.setDataUpdated(context, crudModules.VISITS);
                return Promise.resolve(response.data.visits);
            } else {
                throw new Error('failed while fetching visits');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Error while fetching visits', color: 'error' });
            return Promise.resolve(false);
        }
    },
    /**
     * Gets all patients doing a query just with the information
     * used in the admin Patients view
     * @param context
     * @returns - Promise<IPatient[]> - list of patients or false if error
     */
    async actionGetVisitsForList(context: MainContext) {
        try {
            // check cached data
            const visitCachedData = await getVisitsCachedData(context);
            if (visitCachedData) {
                return visitCachedData;
            }
            const response = await graphQlApi.getVisitsForTableView();
            if (response) {
                mutations.commitSetVisits(context, response.data.visits);
                mutations.setModuleNeedsDataUpgrade(context, {
                    module: crudModules.VISITS,
                    needsDataUpgrade: true,
                });
                mutations.setDataUpdated(context, crudModules.VISITS);
                return Promise.resolve(response.data.visits);
            } else {
                throw new Error('failed while fetching visits');
            }
        } catch (error) {
            commitAddNotification(context, { content: 'Failed while fetching visits', color: 'error' });
            await dispatchCheckApiError(context, error);
            return Promise.resolve(false);
        }
    },
    async actionGetVisitById(context: MainContext, id: string) {
        try {
            const response = await graphQlApi.getVisitById(id);
            if (response) {
                mutations.commitSetVisit(context, response.data.visitById);
                return Promise.resolve(response.data.visitById);
            } else {
                throw new Error('failed while fetching visit');
            }
        } catch (error) {
            commitAddNotification(context, { content: 'Failed while fetching visit', color: 'error' });
            await dispatchCheckApiError(context, error);
            return Promise.resolve(false);
        }
    },
    async actionGetVisitByPatientEmail(context: MainContext, email: string) {
        try {
            const response = await graphQlApi.getVisitByPatientEmail(email);
            if (response) {
                mutations.commitSetVisit(context, response.data.visitByPatientEmail);
                return Promise.resolve(response.data.visitByEmail);
            } else {
                throw new Error('failed while fetching visit');
            }
        } catch (error) {
            commitAddNotification(context, { content: 'Failed while fetching visit', color: 'error' });
            await dispatchCheckApiError(context, error);
            return Promise.resolve(false);
        }
    },
    async actionGetVisitByPatientId(context: MainContext, id: string) {
        try {
            const response = await graphQlApi.getVisitByPatientId(id);
            if (response) {
                mutations.commitSetVisit(context, response.data.visitByPatientId);
                return Promise.resolve(response.data.visitByPatientId);
            } else {
                throw new Error('failed while fetching visit');
            }
        } catch (error) {
            commitAddNotification(context, { content: 'Failed while fetching visit', color: 'error' });
            await dispatchCheckApiError(context, error);
            return Promise.resolve(false);
        }
    },
    /**
     * Updates a visit in the Database
     * @param context - MainContext
     * @param payload - IUpdateVisit
     * @returns - Promise<IVisit> - updated visit or false if error
     */
    async actionUpdateVisit(context: MainContext, payload: { visitId: string, visit: IUpdateVisit }) {
        const loadingNotification = { content: 'saving', color: 'info' };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                graphQlApi.updateVisit(payload.visitId, payload.visit),
                await new Promise<void>((resolve) => setTimeout(() => resolve(), 500)),
            ]))[0];
            if (response) {
                mutations.commitSetVisit(context, response.data.updateVisit);
                return Promise.resolve(response.data.updateVisit);
            } else {
                throw new Error('failed while updating visit');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            return Promise.resolve(false);
        }
    },
    /**
     * creates a new visit
     * @param context - MainContext
     * @param payload - { visit: ICreateVisit }
     * @returns - Promise<IVisit> - created visit or false if error
     */
    async actionCreateVisit(context: MainContext, payload: ICreateVisit) {
        const loadingNotification = { content: 'Creating Visit...', color: 'info' };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                graphQlApi.createVisit(payload),
                await new Promise<void>((resolve) => setTimeout(() => resolve(), 500)),
            ]))[0];
            if (response) {
                commitAddNotification(context, { content: 'Visit successfully created', color: 'success' });
                mutations.commitSetVisit(context, response.data.createVisit);
                return Promise.resolve(response.data.createVisit);
            } else {
                throw new Error('failed while creating visit');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Error while creating visit', color: 'error' });
            return Promise.resolve(false);
        }
    },
    /**
     * Deletes a visit from the DB
     * @param context  - MainContext
     * @param payload  - { visitId: string }
     * @returns - Promise<boolean> - true if success or false if error
     */
    async actionDeleteVisit(context: MainContext, payload: { visitId: string }) {
        const loadingNotification = { content: 'Deleting Visit...', color: 'info' };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                graphQlApi.deleteVisit(payload.visitId),
                await new Promise<void>((resolve) => setTimeout(() => resolve(), 500)),
            ]))[0];
            if (response) {
                mutations.commitDeleteVisit(context, {id: payload.visitId} as IVisit);
                return Promise.resolve(true);
            } else {
                throw new Error('failed while deleting visit');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            return Promise.resolve(false);
        }
    },
    /**
     * creates a new visit document
     * @param context - MainContext
     * @param payload - { payload: IVisitDocument }
     * @returns - Promise<IVisitDocument> - created visit or false if error
     */
    async actionCreateVisitDocument(context: MainContext, payload: IVisitDocument) {
        const loadingNotification = { content: 'Creating document visit...', color: 'info' };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                graphQlApi.createVisitDocument(payload),
                await new Promise<void>((resolve) => setTimeout(() => resolve(), 500)),
            ]))[0];
            if (response) {
                commitAddNotification(context, { content: 'Visit document successfully created', color: 'success' });
                return Promise.resolve(response.data.createVisitDocument);
            } else {
                throw new Error('failed while creating visit');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Error while creating visit', color: 'error' });
            return Promise.resolve(false);
        }
    },
    /**
     * Soft delete a visit document
     * @param context  - MainContext
     * @param payload  - { visitDocumentId: string }
     * @returns - Promise<IVisitDocument> - true if success or false if error
     */
    async actionDeleteVisitDocument(context: MainContext, payload: { visitDocumentId: string }) {
        const loadingNotification = { content: 'Deleting Visit document...', color: 'info' };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                graphQlApi.deleteVisitDocument(payload.visitDocumentId),
                await new Promise<void>((resolve) => setTimeout(() => resolve(), 500)),
            ]))[0];
            if (response) {
                return Promise.resolve(true);
            } else {
                throw new Error('failed while deleting visit document');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            return Promise.resolve(false);
        }
    },
    /**
     * Change visibility of visit document
     * @param context  - MainContext
     * @param payload  - { visitDocumentId: string }
     * @returns - Promise<IVisitDocument> - true if success or false if error
     */
    async actionShowVisitDocument(context: MainContext, payload: { visitDocumentId: string }) {
        const loadingNotification = { content: 'Showing Visit document...', color: 'info' };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                graphQlApi.showVisitDocument(payload.visitDocumentId),
                await new Promise<void>((resolve) => setTimeout(() => resolve(), 500)),
            ]))[0];
            if (response) {
                return Promise.resolve(true);
            } else {
                throw new Error('failed while showing visit document');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            return Promise.resolve(false);
        }
    },
    /**
     * Change visibility of visit document
     * @param context  - MainContext
     * @param payload  - { visitDocumentId: string }
     * @returns - Promise<IVisitDocument> - true if success or false if error
     */
    async actionHideVisitDocument(context: MainContext, payload: { visitDocumentId: string }) {
        const loadingNotification = { content: 'Hiding Visit document...', color: 'info' };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                graphQlApi.hideVisitDocument(payload.visitDocumentId),
                await new Promise<void>((resolve) => setTimeout(() => resolve(), 500)),
            ]))[0];
            if (response) {
                return Promise.resolve(true);
            } else {
                throw new Error('failed while hiding visit document');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            return Promise.resolve(false);
        }
    },
};
