
import { CrudState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';
import { IUserProfile } from '@/interfaces/users';
import { IPatient } from '@/interfaces/patients';
import { IVisit } from '@/interfaces/visits';
import { IPackage } from '@/interfaces/packages';
import { IPanel } from '@/interfaces/panels';
import { IBiomarker } from '@/interfaces/biomarkers';
import { IBiomarkerResult } from '@/interfaces/biomarkerResults';
import { ISupplement } from '@/interfaces/supplements';
import { IFood } from '@/interfaces/foods';
import { IExercise } from '@/interfaces/exercises';
import { IRequisition } from '@/interfaces/requisitions';
import { getDefaultState } from '.';
import { IDocument, IDocumentRequest, IRequisitionDocument, IRequisitionDocuments, ITableRequisitionDocuments } from '@/interfaces/documents';
import { IRecommendation } from '@/interfaces/recommendations';
import { ICategory } from '@/interfaces/categories';
import { ISymptom } from '@/interfaces/symptoms';
import { ICause } from '@/interfaces/causes';
import { ISelfCare } from '@/interfaces/selfCare';
import { INote } from '@/interfaces/notes';
import { IAccessCode } from '@/interfaces/accessCode';

export const mutations = {
    setUsers(state: CrudState, payload: IUserProfile[]) {
        state.users = payload;
    },
    setUser(state: CrudState, payload: IUserProfile) {
        const users = state.users.filter((user: IUserProfile) => user.id !== payload.id);
        users.push(payload);
        state.users = users;
    },
    deleteUser(state: CrudState, payload: IUserProfile) {
        const users = state.users.filter((user: IUserProfile) => user.id !== payload.id);
        state.users = users;
    },
    setPatients(state: CrudState, payload: IPatient[]) {
        state.patients = payload;
    },
    setPatient(state: CrudState, payload: IPatient) {
        const patients = state.patients.filter((patient: IPatient) => patient.id !== payload.id);
        patients.push(payload);
        state.patients = patients;
    },
    deletePatient(state: CrudState, payload: IPatient) {
        const patients = state.patients.filter((patient: IPatient) => patient.id !== payload.id);
        state.patients = patients;
    },
    setRequisitions(state: CrudState, payload: IRequisition[]) {
        state.requisitions = payload;
    },
    setRequisition(state: CrudState, payload: IRequisition) {
        const requisitions = state.requisitions.filter((requisition: IRequisition) => requisition.id !== payload.id);
        requisitions.push(payload);
        state.requisitions = requisitions;
    },
    deleteRequisition(state: CrudState, payload: IRequisition) {
        const requisitions = state.requisitions.filter((requisition: IRequisition) => requisition.id !== payload.id);
        state.requisitions = requisitions;
    },
    setVisits(state: CrudState, payload: IVisit[]) {
        state.visits = payload;
    },
    setVisit(state: CrudState, payload: IVisit) {
        const visits = state.visits.filter((visit: IVisit) => visit.id !== payload.id);
        visits.push(payload);
        state.visits = visits;
    },
    deleteVisit(state: CrudState, payload: IVisit) {
        const visits = state.visits.filter((visit: IVisit) => visit.id !== payload.id);
        state.visits = visits;
    },
    setPackages(state: CrudState, payload: IPackage[]) {
        state.packages = payload;
    },
    setPackage(state: CrudState, payload: IPackage) {
        const packages = state.packages.filter((packageItem: IPackage) =>
        packageItem.id !== payload.id);

        packages.push(payload);
        state.packages = packages;
    },
    deletePackage(state: CrudState, payload: IPackage) {
        const packages = state.packages.filter((packageItem: IPackage) =>
        packageItem.id !== payload.id);
        state.packages = packages;
    },
    setPanels(state: CrudState, payload: IPanel[]) {
        state.panels = payload;
    },
    setPanel(state: CrudState, payload: IPanel) {
        const panels = state.panels.filter((panel: IPanel) => panel.id !== payload.id);
        panels.push(payload);
        state.panels = panels;
    },
    deletePanel(state: CrudState, payload: IPanel) {
        const panels = state.panels.filter((panel: IPanel) => panel.id !== payload.id);
        state.panels = panels;
    },
    setBiomarkers(state: CrudState, payload: IBiomarker[]) {
        state.biomarkers = payload;
    },
    setBiomarker(state: CrudState, payload: IBiomarker) {
        const biomarkers = state.biomarkers.filter((biomarker: IBiomarker) =>
        biomarker.id !== payload.id);

        biomarkers.push(payload);
        state.biomarkers = biomarkers;
    },
    deleteBiomarker(state: CrudState, payload: IBiomarker) {
        const biomarkers = state.biomarkers.filter((biomarker: IBiomarker) =>
        biomarker.id !== payload.id);
        state.biomarkers = biomarkers;
    },
    setBiomarkerResults(state: CrudState, payload: IBiomarkerResult[]) {
        state.biomarkerResults = payload;
    },
    setBiomarkerResult(state: CrudState, payload: IBiomarkerResult) {
        const biomarkerResults = state.biomarkerResults.filter((biomarkerResult: IBiomarkerResult) =>
        biomarkerResult.id !== payload.id);

        biomarkerResults.push(payload);
        state.biomarkerResults = biomarkerResults;
    },
    deleteBiomarkerResult(state: CrudState, payload: IBiomarkerResult) {
        const biomarkerResults = state.biomarkerResults.filter((biomarkerResult: IBiomarkerResult) =>
        biomarkerResult.id !== payload.id);
        state.biomarkerResults = biomarkerResults;
    },
    setSupplements(state: CrudState, payload: ISupplement[]) {
        state.supplements = payload;
    },
    setSupplement(state: CrudState, payload: ISupplement) {
        const supplements = state.supplements.filter((supplement: ISupplement) =>
        supplement.id !== payload.id);

        supplements.push(payload);
        state.supplements = supplements;
    },
    deleteSupplement(state: CrudState, payload: ISupplement) {
        const supplements = state.supplements.filter((supplement: ISupplement) =>
        supplement.id !== payload.id);
        state.supplements = supplements;
    },
    setFoods(state: CrudState, payload: IFood[]) {
        state.foods = payload;
    },
    setFood(state: CrudState, payload: IFood) {
        const foods = state.foods.filter((food: IFood) => food.id !== payload.id);
        foods.push(payload);
        state.foods = foods;
    },
    deleteFood(state: CrudState, payload: IFood) {
        const foods = state.foods.filter((food: IFood) => food.id !== payload.id);
        state.foods = foods;
    },
    setExercises(state: CrudState, payload: IExercise[]) {
        state.exercises = payload;
    },
    setExercise(state: CrudState, payload: IExercise) {
        const exercises = state.exercises.filter((exercise: IExercise) =>
        exercise.id !== payload.id);
        exercises.push(payload);
        state.exercises = exercises;
    },
    setRecommendations(state: CrudState, payload: IRecommendation[]) {
        state.recommendations = payload;
    },
    setRecommendation(state: CrudState, payload: IRecommendation) {
        const recommendations = state.recommendations.filter((recommendation: IRecommendation) =>
        recommendation.id !== payload.id);
        recommendations.push(payload);
        state.recommendations = recommendations;
    },
    deleteRecommendation(state: CrudState, payload: IRecommendation) {
        const recommendations = state.recommendations.filter((recommendation: IRecommendation) =>
        recommendation.id !== payload.id);
        state.recommendations = recommendations;
    },
    deleteExercise(state: CrudState, payload: IExercise) {
        const exercises = state.exercises.filter((exercise: IExercise) =>
        exercise.id !== payload.id);
        state.exercises = exercises;
    },
    setCategories(state: CrudState, payload: ICategory[]) {
        state.categories = payload;
    },
    setCategory(state: CrudState, payload: ICategory) {
        const categories = state.categories.filter((category: ICategory) =>
        category.id !== payload.id);
        categories.push(payload);
        state.categories = categories;
    },
    deleteCategory(state: CrudState, payload: ICategory) {
        const categories = state.categories.filter((category: ICategory) =>
        category.id !== payload.id);
        state.categories = categories;
    },
    setSymptoms(state: CrudState, payload: ISymptom[]) {
        state.symptoms = payload;
    },
    setSymptom(state: CrudState, payload: ISymptom) {
        const symptoms = state.symptoms.filter((symptom: ISymptom) =>
        symptom.id !== payload.id);
        symptoms.push(payload);
        state.symptoms = symptoms;
    },
    deleteSymptom(state: CrudState, payload: ISymptom) {
        const symptoms = state.symptoms.filter((symptom: ISymptom) =>
        symptom.id !== payload.id);
        state.symptoms = symptoms;
    },
    setCause(state: CrudState, payload: ICause) {
        const causes = state.causes.filter((cause: ICause) => cause.id !== payload.id);
        causes.push(payload);
        state.causes = causes;
    },
    setCauses(state: CrudState, payload: ICause[]) {
        state.causes = payload;
    },
    deleteCause(state: CrudState, payload: ICause) {
        const causes = state.causes.filter((cause: ICause) => cause.id !== payload.id);
        state.causes = causes;
    },
    setSelfCares(state: CrudState, payload: ISelfCare[]) {
        state.selfCares = payload;
    },
    setSelfCare(state: CrudState, payload: ISelfCare) {
        state.selfCares = state.selfCares.filter((selfCare: ISelfCare) =>
        selfCare.id !== payload.id);
        state.selfCares.push(payload);
    },
    deleteSelfCare(state: CrudState, payload: ISelfCare) {
        state.selfCares = state.selfCares.filter((selfCare: ISelfCare) =>
        selfCare.id !== payload.id);
    },
    setDocuments(state: CrudState, payload: ITableRequisitionDocuments[]) {
        state.requisitionDocuments = payload;
    },
    setDocument(state: CrudState, payload: ITableRequisitionDocuments) {
        const documents = state.requisitionDocuments.filter((document: ITableRequisitionDocuments) => {
            return document.patientId === payload.patientId
            && document.requisitionId === payload.requisitionId
            && document.visitId === payload.visitId
            ? document.documentName !== payload.documentName : true;
        });
        documents.push(payload);
        state.requisitionDocuments = documents;
    },
    deleteDocument(state: CrudState, payload: IDocumentRequest) {
        const documents = state.requisitionDocuments.filter((document: ITableRequisitionDocuments) => {
            return document.patientId === payload.patient_id
            && document.requisitionId === payload.requisition_id
            && document.visitId === payload.visit_id
            ? document.documentName !== payload.pdf_file_name : true;
        });
        state.requisitionDocuments = documents;
    },
    /* Data expiration and cache strategy */
    setDataExpired(state: CrudState, payload) {
        const index =
            state.dataExpired.findIndex((item: any) => item.name === payload.module);
        if (index && index !== -1) {
            state.dataExpired[index] = payload;
        }
    },
    forceModuleDataExpired(state: CrudState, payload) {
        const index =
            state.dataExpired.findIndex((item: any) => item.name === payload.module);
        if (index && index !== -1) {
            state.dataExpired[index].expired = true;
            state.dataExpired[index].dateTime = state.dataExpired[index].timeUntilExpired;
        }
    },
    /* set that the data in the store is updated */
    setDataUpdated(state: CrudState, payload) {
        const dateExpiredIndex  =
            state.dataExpired.findIndex((item: any) => item.name === payload);
        if (dateExpiredIndex !== -1) {
            state.dataExpired[dateExpiredIndex].expired = false;
            state.dataExpired[dateExpiredIndex].dateTime = new Date();
        }
    },
    resetCrudState(state: CrudState) {
        Object.assign(state, getDefaultState());
    },
    setModuleNeedsDataUpgrade(state: CrudState, payload) {
        state.needsDataUpgrade[payload.module] = payload.needsDataUpgrade;
    },
    setPhysicianNotes(state: CrudState, payload: INote[]) {
        state.physicianNotes = payload;
    },
    setPhysicianNote(state: CrudState, payload: INote) {
        const notes = state.physicianNotes.filter((note: INote) =>
        note.id !== payload.id);
        notes.push(payload);
        state.physicianNotes = notes;
    },
    deletePhysicianNote(state: CrudState, id: string) {
        const notes = state.physicianNotes.filter((note: INote) =>
        note.id !== id);
        state.physicianNotes = notes;
    },
    setAccessCodesResults(state: CrudState, payload: IAccessCode[]) {
      state.accessCodes = payload;
    },
};

const { commit } = getStoreAccessors<CrudState, State>('');

export const commitSetUser = commit(mutations.setUser);
export const commitSetUsers = commit(mutations.setUsers);
export const commitDeleteUser = commit(mutations.deleteUser);
export const commitSetPatient = commit(mutations.setPatient);
export const commitSetPatients = commit(mutations.setPatients);
export const commitDeletePatient = commit(mutations.deletePatient);
export const commitSetVisit = commit(mutations.setVisit);
export const commitSetVisits = commit(mutations.setVisits);
export const commitDeleteVisit = commit(mutations.deleteVisit);
export const commitSetRequisition = commit(mutations.setRequisition);
export const commitSetRequisitions = commit(mutations.setRequisitions);
export const commitDeleteRequisition = commit(mutations.deleteRequisition);
export const commitSetPackage = commit(mutations.setPackage);
export const commitSetPackages = commit(mutations.setPackages);
export const commitDeletePackage = commit(mutations.deletePackage);
export const commitSetPanel = commit(mutations.setPanel);
export const commitSetPanels = commit(mutations.setPanels);
export const commitDeletePanel = commit(mutations.deletePanel);
export const commitSetBiomarker = commit(mutations.setBiomarker);
export const commitSetBiomarkers = commit(mutations.setBiomarkers);
export const commitDeleteBiomarker = commit(mutations.deleteBiomarker);
export const commitSetBiomarkerResults = commit(mutations.setBiomarkerResults);
export const commitSetBiomarkerResult = commit(mutations.setBiomarkerResult);
export const commitDeleteBiomarkerResult = commit(mutations.deleteBiomarkerResult);
export const commitSetSupplement = commit(mutations.setSupplement);
export const commitSetSupplements = commit(mutations.setSupplements);
export const commitDeleteSupplement = commit(mutations.deleteSupplement);
export const commitSetFood = commit(mutations.setFood);
export const commitSetFoods = commit(mutations.setFoods);
export const commitDeleteFood = commit(mutations.deleteFood);
export const commitSetExercise = commit(mutations.setExercise);
export const commitSetExercises = commit(mutations.setExercises);
export const commitDeleteExercise = commit(mutations.deleteExercise);
export const commitSetRecommendation = commit(mutations.setRecommendation);
export const commitSetRecommendations = commit(mutations.setRecommendations);
export const commitDeleteRecommendation = commit(mutations.deleteRecommendation);
export const commitSetCategory = commit(mutations.setCategory);
export const commitSetCategories = commit(mutations.setCategories);
export const commitDeleteCategory = commit(mutations.deleteCategory);
export const commitSetSymptom = commit(mutations.setSymptom);
export const commitSetSymptoms = commit(mutations.setSymptoms);
export const commitDeleteSymptom = commit(mutations.deleteSymptom);
export const commitSetCause = commit(mutations.setCause);
export const commitSetCauses = commit(mutations.setCauses);
export const commitDeleteCause = commit(mutations.deleteCause);
export const commitSetSelfCares = commit(mutations.setSelfCares);
export const commitSetSelfCare = commit(mutations.setSelfCare);
export const commitDeleteSelfCare = commit(mutations.deleteSelfCare);


export const commitSetDocuments = commit(mutations.setDocuments);
export const commitSetDocument = commit(mutations.setDocument);
export const commitDeleteDocument = commit(mutations.deleteDocument);

export const setDataExpired = commit(mutations.setDataExpired);
export const setDataUpdated = commit(mutations.setDataUpdated);
export const setModuleNeedsDataUpgrade = commit(mutations.setModuleNeedsDataUpgrade);
export const commitSetPhysicianNotes = commit(mutations.setPhysicianNotes);
export const commitSetPhysicianNote = commit(mutations.setPhysicianNote);
export const commitDeletePhysicianNote = commit(mutations.deletePhysicianNote);
export const commitSetAccessCodes = commit(mutations.setAccessCodesResults);




