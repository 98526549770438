import {ActionContext} from 'vuex';
import {CrudState} from '@/store/crud/state';
import {State} from '@/store/state';
import {commitAddNotification} from '@/store/main/mutations';

import {graphQlApi} from '@/graphql/graphqlApi';
import {ICriticalResultCreate, ICriticalResultUpdate} from '@/interfaces/criticals';

type MainContext = ActionContext<CrudState, State>;

/**
 * Actions sub module for critical-result actions
 */
export default {
  /**
   * Updates a single Critical result in database
   * @param context - MainContext
   * @param payload - ICriticalResultUpdate, the Critical result comment to update
   * @returns - returns a Promise with the updated Critical result comment or false if error
   */
  async actionUpdateCriticalResult(
    context: MainContext,
    payload: ICriticalResultUpdate,
  ) {
    const {id, ...restPayload} = payload;
    try {
      // @ts-ignore
      const {data, errors} = await graphQlApi.updateCriticalResult(id, {...restPayload});
      if (errors) {
        throw new Error();
      }
      if (data) {
        return data;
      }
      commitAddNotification(context, {
        content: 'Can`t update a critical result',
        color: 'error',
      });
      return Promise.reject();
    } catch (e) {
      commitAddNotification(context, {
        content: 'Failed while updating a critical result',
        color: 'error',
      });
      return Promise.reject();
    }
  },
  /**
   * Creates a single Critical result in database
   * @param context - MainContext
   * @param payload - ICriticalResultCreate - the critical result to be created
   * @returns - returns a Promise with the created Critical result comment or false if error
   */
  async actionCreateCriticalResult(context: MainContext, payload: ICriticalResultCreate) {
    try {
      // @ts-ignore
      const {data, errors} = await graphQlApi.createCriticalResult({...payload});
      if (errors) {
        throw new Error();
      }
      if (data && data.createCriticalResult) {
        return data.createCriticalResult;
      }
      commitAddNotification(context, {
        content: 'Can`t create a critical result',
        color: 'error',
      });
      return Promise.reject();
    } catch (e) {
      commitAddNotification(context, {
        content: 'Failed while creating a critical',
        color: 'error',
      });
      return Promise.reject();
    }
  },
};
