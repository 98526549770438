import gql from 'graphql-tag';

export const GET_ALL_USERS_QUERY = gql `
query users($skip: Int, $limit: Int) {
    users(skip: $skip, limit: $limit){
        id
        healthGorillaId
        email
        phoneNumber
        isActive
        isSuperuser
        isPhysician
        fname
        lname
        npi
        licenseState
        isNursePracticioner
        isReviewer
        isCreator
        requisition{
            id
        }
    }
}
`;

export const GET_USER_BY_ID_QUERY = gql `
query userById($id: UUID!) {
    userById(id: $id){
        id
        healthGorillaId
        email
        phoneNumber
        isActive
        isSuperuser
        isPhysician
        isCreator
        fname
        lname
        npi
        licenseState
        isNursePracticioner
        isReviewer
        requisition{
            id
        }
    }
}
`;


export const GET_USER_BY_EMAIL_QUERY = gql `
query userByEmail($email: String!) {
    userByEmail(email: $email){
        id
        email
        phoneNumber
        isActive
        isSuperuser
        isCreator
        isPhysician
        fname
        lname
        npi
        licenseState
        isNursePracticioner
        isReviewer
    }
}
`;

export const GET_USER_BY_USER_GROUP_QUERY = gql `
query userByUserGroup($userGroup: String!) {
    userByUserGroup(userGroup: $userGroup){
        id
        email
        phoneNumber
        isActive
        isSuperuser
        isPhysician
        isCreator
        fname
        lname
        npi
        licenseState
        isNursePracticioner
        isReviewer
    }
}
`;

export const GET_USERS_FOR_TABLE_VIEW_QUERY = gql `
query users($skip: Int, $limit: Int) {
    users(skip: $skip, limit: $limit){
        id
        healthGorillaId
        email
        isActive
        isSuperuser
        isPhysician
        isNursePracticioner
        isCreator
        isReviewer
        fname
        lname
    }
}
`;

export const GET_USERS_FOR_LIST_QUERY = gql `
query users($skip: Int, $limit: Int) {
    users(skip: $skip, limit: $limit){
        id
        email
        isActive
        isSuperuser
        isPhysician
        isCreator
        isNursePracticioner
        fname
        lname
    }
}
`;

export const GET_USER_ME_QUERY = gql `
{
    userMe{
        id
        healthGorillaId
        email
        phoneNumber
        isActive
        isSuperuser
        isPhysician
        isCreator
        fname
        lname
        npi
        licenseState
        isNursePracticioner
        isReviewer
    }
}
`;
