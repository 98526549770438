
import gql from 'graphql-tag';

export const CREATE_PHYSICIAN_NOTE_MUTATION = gql `
mutation createPhysicianNote($physicianNoteInput: PhysicianNoteCreate!) {
  createPhysicianNote(physicianNoteInput: $physicianNoteInput) {
    id
    content
    category {
      id
      categoryName
    }
    createdAt
    updatedAt
  }
}
`;

export const UPDATE_PHYSICIAN_NOTE_MUTATION = gql `
  mutation updatePhysicianNote($id: UUID!, $physicianNoteInput: PhysicianNoteUpdate!) {
    updatePhysicianNote(id: $id, physicianNoteInput: $physicianNoteInput) {
      id
      content
      category {
        id
        categoryName
      }
      createdAt
      updatedAt
    }
  }
`;

export const DELETE_PHYSICIAN_NOTE_MUTATION = gql `
  mutation deletePhysicianNote($id: UUID!) {
    deletePhysicianNote(id: $id)
  }
`;
