import '@babel/polyfill';
// Import Component hooks before component definitions
import './component-hooks';
import Vue from 'vue';
import './plugins/vee-validate';
import App from './App.vue';
import router from './router';
import store from '@/store';
import './registerServiceWorker';
import vuetify from './plugins/vuetify';
import './plugins/apollo';
import { apolloProvider } from './plugins/apollo';
import './plugins/vueToast';
import './plugins/event-bus';

const app = new Vue({
  router,
  store,
  vuetify,
  apolloProvider,
  render: (h) => h(App),
}).$mount('#app');
