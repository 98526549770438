import gql from 'graphql-tag';


export const GET_SUPPLEMENTS_FOR_LIST_QUERY = gql`
query supplements($skip: Int, $limit: Int) {
    supplements(skip: $skip, limit: $limit){
        id
        name
    }
}
`;

export const GET_ALL_SUPPLEMENTS_QUERY = gql`
query supplements($skip: Int, $limit: Int) {
    supplements(skip: $skip, limit: $limit){
        id
        name
        supplementGroup
        description
    }
}
`;

export const GET_SUPPLEMENTS_BY_ID_QUERY = gql`
query supplementById($id: UUID!) {
    supplementById(id: $id){
        id
        name
        supplementGroup
        description
    }
}
`;

export const GET_SUPPLEMENTS_BY_NAME_QUERY = gql`
query supplementByName($name: String!) {
    supplementByName(name: $name){
        id
        name
        supplementGroup
        description
    }
}
`;

export const GET_SUPPLEMENTS_BY_GROUP_QUERY = gql`
query supplementBySupplementGroup($supplementGroup: String!) {
    supplementBySupplementGroup(supplementGroup: $supplementGroup){
        id
        name
        supplementGroup
        description
    }
}
`;
