import { ActionContext } from 'vuex';

import * as mutations from '@/store/crud/mutations';

import { CrudState } from '@/store/crud/state';

import { State } from '@/store/state';
import { commitAddNotification } from '@/store/main/mutations';
import { dispatchCheckApiError } from '@/store/main/actions';

import { graphQlApi } from '@/graphql/graphqlApi';
import { ICovidInfo, IFemaleReproductiveHealth, IMotivationalInfo, IPatient, IPatientBehaviorInfo,
    IPatientContactInfo, IPatientCreate, IPatientMedicalHx, IPatientUpdate, IPriorPregnancies } from '@/interfaces/patients';
import { crudModules } from '@/constants/globalConstants';


type MainContext = ActionContext<CrudState, State>;

/**
 * Returns patients cached data
 * or false if it is expired
 * If the view or components needs the full data, there is a flag
 * "needsDataUpgrade" to check if the existing cache is list or full data.
 * If it is list, then the data is not valid and needs to be upgraded.
 */
const getPatientsCachedData = async (context: MainContext, needsDataUpgrade = false) => {
    if (context.getters.patients && context.getters.patients.length > 0) {
        const dataExpired = await context.dispatch('patientsDataExpired');
        // check data expiration
        if (dataExpired) {
            // re run this action without cached data
            return false; // data is expired so we need to fetch new data
        } else {
            if (needsDataUpgrade) {
                if (context.getters.needsDataUpgrade(crudModules.PATIENTS)) {
                    return false;
                }
            }
            return context.getters.patients;
        }
    } else {
        return false;
    }
};

/**
 * Actions sub module for patients actions
 */
export default {
    /**
     * gets all the patients from the database with all information
     * @param context - vuex action context
     * @returns - Promise<IPatient[]> - list of patients or false if error
     */
    async actionGetPatients(context: MainContext) {
        try {
            // check cached data
            const cachedUsers = await getPatientsCachedData(context, true);
            if (cachedUsers) {
                return cachedUsers;
            }
            const response = await graphQlApi.getAllPatients();
            if (response) {
                mutations.commitSetPatients(context, response.data.patients);
                mutations.setModuleNeedsDataUpgrade(context, {
                    module: crudModules.PATIENTS,
                    needsDataUpgrade: false,
                });
                mutations.setDataUpdated(context, crudModules.PATIENTS);
                return Promise.resolve(response.data.patients);
            } else {
                throw new Error('Error while getting patients');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Failed while fetching patients', color: 'error' });
            return Promise.resolve(false);
        }
    },
    /**
     * gets a list of patients with reduced information
     * @returns - Promise<IPatient[]> - list of patients or false if error
     */
    async actionGetPatientsForList(context: MainContext) {
        try {
            const cachedUsers = await getPatientsCachedData(context);
            if (cachedUsers) {
                return cachedUsers;
            }
            const response = await graphQlApi.getAllPatientsForList();
            if (response) {
                mutations.commitSetPatients(context, response.data.patients);
                mutations.setModuleNeedsDataUpgrade(context, {
                    module: crudModules.PATIENTS,
                    needsDataUpgrade: true,
                });
                mutations.setDataUpdated(context, crudModules.PATIENTS);
                return Promise.resolve(response.data.patients);
            } else {
                throw new Error('Failed while getting patients');
            }
        } catch (error) {
            commitAddNotification(context, { content: 'Failed while fetching Patients', color: 'error' });
            await dispatchCheckApiError(context, error);
            return Promise.resolve(false);
        }
    },
    /**
     * gets a list of patients with reduced information for a table
     * @returns - Promise<IPatient[]> - list of patients or false if error
     */
    async actionGetPatientsForTable(context: MainContext) {
        try {
            const cachedUsers = await getPatientsCachedData(context);
            if (cachedUsers) {
                return cachedUsers;
            }
            const response = await graphQlApi.getPatientsForTableView();
            if (response) {
                mutations.commitSetPatients(context, response.data.patients);
                mutations.setModuleNeedsDataUpgrade(context, {
                    module: crudModules.PATIENTS,
                    needsDataUpgrade: true,
                });
                mutations.setDataUpdated(context, crudModules.PATIENTS);
                return Promise.resolve(response.data.patients);
            } else {
                throw new Error('Failed while getting patients');
            }
        } catch (error) {
            commitAddNotification(context, {
                content: 'Failed while fetching Patients for table view',
                color: 'error',
            });
            await dispatchCheckApiError(context, error);
            return Promise.resolve(false);
        }
    },
    /**
     * gets a single patient with all information from the database
     * @param context - vuex action context
     * @param id - id of the patient to get
     * @returns - Promise<IPatient> - patient or false if error
     */
    async actionGetPatientById(context: MainContext, id: string) {
        try {
            const response = await graphQlApi.getPatientById(id);
            if (response) {
                mutations.commitSetPatient(context, response.data.patientById);
                return Promise.resolve(response.data.patientById);
            } else {
                throw new Error('Failed while getting patient');
            }
        } catch (error) {
            commitAddNotification(context, { content: 'Failed while fetching Patient', color: 'error' });
            await dispatchCheckApiError(context, error);
            return Promise.resolve(false);
        }
    },
    async actionGetPatientByEmail(context: MainContext, email: string) {
        try {
            const response = await graphQlApi.getPatientByEmail(email);
            if (response) {
                mutations.commitSetPatient(context, response.data.patientByEmail);
                return Promise.resolve(response.data.patientByEmail);
            } else {
                throw new Error('Failed while getting patient');
            }
        } catch (error) {
            commitAddNotification(context, { content: 'Failed while fetching Patient', color: 'error' });
            await dispatchCheckApiError(context, error);
            return Promise.resolve(false);
        }
    },
    async actionGetPatientByFirebaseId(context: MainContext, firebaseId: string) {
        try {
            const response = await graphQlApi.getPatientByFirebaseId(firebaseId);
            if (response) {
                return Promise.resolve(response.data.patientByPatientFirebaseId);
            } else {
                throw new Error('Failed while getting patient');
            }
        } catch (error) {
            commitAddNotification(context, { content: 'Failed while fetching Patient', color: 'error' });
            await dispatchCheckApiError(context, error);
            return Promise.resolve(false);
        }
    },
    async actionGetPatientByPatientIdentifier(context: MainContext, patientIdentifier: string) {
        try {
            const response = await graphQlApi.getPatientByPatientIdentifier(patientIdentifier);
            if (response) {
                return Promise.resolve(response.data.patientByPatientIdentifier);
            } else {
                throw new Error('Failed while getting patient');
            }
        } catch (error) {
            commitAddNotification(context, { content: 'Failed while fetching Patient', color: 'error' });
            await dispatchCheckApiError(context, error);
            return Promise.resolve(false);
        }
    },
    async actionUpdatePatient(context: MainContext, payload: { patientId: string, patient: IPatientUpdate }) {
        const loadingNotification = { content: 'saving', color: 'info' };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                graphQlApi.updatePatient(payload.patientId, payload.patient),
                await new Promise<void>((resolve) => setTimeout(() => resolve(), 500)),
            ]))[0];
            if (response) {
                commitAddNotification(context, { content: 'Patient successfully updated', color: 'success' });
                mutations.commitSetPatient(context, response.data.updatePatient);
                return Promise.resolve(response.data.updatePatient);
            } else {
                throw new Error('Error while updating patient');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Error while updating patient', color: 'error' });
            return Promise.resolve(false);
        }
    },
    async actionCreatePatient(context: MainContext, payload: IPatientCreate) {
        const loadingNotification = { content: 'Creating Patient...', color: 'info' };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                graphQlApi.createPatient(payload),
                await new Promise<void>((resolve) => setTimeout(() => resolve(), 500)),
            ]))[0];
            if (response) {
                commitAddNotification(context, { content: 'Patient successfully created', color: 'success' });
                mutations.commitSetPatient(context, response.data.createPatient);
                return Promise.resolve(response.data.createPatient);
            } else {
                throw new Error('Error while creating patient');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Error while creating patient', color: 'error' });
            return Promise.resolve(false);
        }
    },
    /**
     * Deletes a patient from the database
     * @param context - vuex action context
     * @param payload - patient to delete
     * @returns - Promise<boolean> - true if successful, false if error
     */
    async actionDeletePatient(context: MainContext, payload: { patientId: string }) {
        const loadingNotification = { content: 'Deleting Patient...', color: 'info' };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                graphQlApi.deletePatient(payload.patientId),
                await new Promise<void>((resolve) => setTimeout(() => resolve(), 500)),
            ]))[0];
            if (response) {
                commitAddNotification(context, { content: 'Patient successfully deleted', color: 'success' });
                mutations.commitDeletePatient(context, {id: payload.patientId } as IPatient);
                return Promise.resolve(response.data.deletePatient);
            } else {
                throw new Error('Error while deleting patient');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Error while deleting patient', color: 'error' });
            return Promise.resolve(false);
        }
    },
    /* Patient internal info */
    async actionCreatePatientCovidInfo(context: MainContext, id: ICovidInfo) {
        const loadingNotification = { content: 'Creating Covid Info...', color: 'info' };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                graphQlApi.createPatientCovid(id),
                await new Promise<void>((resolve) => setTimeout(() => resolve(), 500)),
            ]))[0];
            if (response) {
                commitAddNotification(context, { content: 'Patient Covid information succesfully created', color: 'success' });
                return Promise.resolve(response.data.createCovid);
            } else {
                throw new Error('Error while creating covid info');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, {
                content: 'Error while creating patient covid information', color: 'error' });
            return Promise.resolve(false);
        }
    },
    async actionUpdatePatientCovidInfo(context: MainContext, payload: { id: string, covidInfo: ICovidInfo }) {
        const loadingNotification = { content: 'saving', color: 'info' };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                graphQlApi.updatePatientCovid(payload.id, payload.covidInfo),
                await new Promise<void>((resolve) => setTimeout(() => resolve(), 500)),
            ]))[0];
            if (response) {
                commitAddNotification(context, { content: 'Patient Covid information succesfully updated', color: 'success' });
                return Promise.resolve(response.data.updateCovid);
            } else {
                throw new Error('Error while updating covid info');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, {
                content: 'Error while updating patient covid information', color: 'error' });
            return Promise.resolve(false);
        }
    },
    async actionCreateMedicalHx(context: MainContext, payload: IPatientMedicalHx) {
        const loadingNotification = { content: 'Creating medical Hx...', color: 'info' };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                graphQlApi.createPatientMedicalHx(payload),
                await new Promise<void>((resolve) => setTimeout(() => resolve(), 500)),
            ]))[0];
            if (response) {
                commitAddNotification(context, { content: 'Patient medical history succesfully created', color: 'success' });
                return Promise.resolve(response.data.createMedicalHx);
            } else {
                throw new Error('Error while creating medical history');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, {
                content: 'Error while creating patient medical history', color: 'error' });
            return Promise.resolve(false);
        }
    },
    async actionUpdateMedicalHx(context: MainContext, payload: { id: string, medicalHx: IPatientMedicalHx }) {
        const loadingNotification = { content: 'saving', color: 'info' };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                graphQlApi.updatePatientMedicalHx(payload.id, payload.medicalHx),
                await new Promise<void>((resolve) => setTimeout(() => resolve(), 500)),
            ]))[0];
            if (response) {
                commitAddNotification(context, { content: 'Patient medical history succesfully updated', color: 'success' });
                return Promise.resolve(response.data.updateMedicalHx);
            } else {
                throw new Error('Error while updating medical history');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, {
                content: 'Error while updating patient medical history', color: 'error' });
            return Promise.resolve(false);
        }
    },
    async actionCreatePatientMotivational(context: MainContext, payload: IMotivationalInfo) {
        const loadingNotification = { content: 'Creating Motivational...', color: 'info' };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                graphQlApi.createPatientMotionalHx(payload),
                await new Promise<void>((resolve) => setTimeout(() => resolve(), 500)),
            ]))[0];
            if (response) {
                commitAddNotification(context, { content: 'Patient motivational information succesfully created', color: 'success' });
                return Promise.resolve(response.data.createMotivational);
            } else {
                throw new Error('Error while creating motivational info');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, {
                content: 'Error while creating patient motivational information', color: 'error' });
            return Promise.resolve(false);
        }
    },
    async actionUpdatePatientMotivational(
        context: MainContext, payload: { id: string, motivationalInfo: IMotivationalInfo }) {
        const loadingNotification = { content: 'saving', color: 'info' };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                graphQlApi.updatePatientMotionalHx(payload.id, payload.motivationalInfo),
                await new Promise<void>((resolve) => setTimeout(() => resolve(), 500)),
            ]))[0];
            if (response) {
                commitAddNotification(context, { content: 'Patient motivational information succesfully updated', color: 'success' });
                return Promise.resolve(response.data.updateMotivational);
            } else {
                throw new Error('Error while updating motivational info');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, {
                content: 'Error while updating patient motivational information', color: 'error' });
            return Promise.resolve(false);
        }
    },
    async actionCreatePatientBehaviorInfo(context: MainContext, payload: IPatientBehaviorInfo) {
        const loadingNotification = { content: 'Creating Patient Behavior Info...', color: 'info' };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                graphQlApi.createPatientBehaviorInfo(payload),
                await new Promise<void>((resolve) => setTimeout(() => resolve(), 500)),
            ]))[0];
            if (response) {
                commitAddNotification(context, { content: 'Patient behavior information succesfully created', color: 'success' });
                return Promise.resolve(response.data.createPatientBehaviorInfo);
            } else {
                throw new Error('Error while creating behavior info');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, {
                content: 'Error while creating patient behavior information', color: 'error' });
            return Promise.resolve(false);
        }
    },
    async actionUpdatePatientBehaviorInfo(
        context: MainContext, payload: { id: string, behaviorInfo: IPatientBehaviorInfo }) {
        const loadingNotification = { content: 'saving', color: 'info' };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                graphQlApi.updatePatientBehaviorInfo(payload.id, payload.behaviorInfo),
                await new Promise<void>((resolve) => setTimeout(() => resolve(), 500)),
            ]))[0];
            if (response) {
                commitAddNotification(context, { content: 'Patient behavior information succesfully updated', color: 'success' });
                return Promise.resolve(response.data.updatePatientBehaviorInfo);
            } else {
                throw new Error('Error while updating behavior info');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, {
                content: 'Error while updating patient behavior information', color: 'error' });
            return Promise.resolve(false);
        }
    },
    async actionCreatePatientContactInfo(context: MainContext, payload: IPatientContactInfo) {
        const loadingNotification = { content: 'Creating Patient Contact Info...', color: 'info' };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                graphQlApi.createPatientContactInfo(payload),
                await new Promise<void>((resolve) => setTimeout(() => resolve(), 500)),
            ]))[0];
            if (response) {
                commitAddNotification(context, { content: 'Patient contact information succesfully created', color: 'success' });
                return Promise.resolve(response.data.createPatientContactInfo);
            } else {
                throw new Error('Error while creating contact info');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, {
                content: 'Error while creating patient contact information', color: 'error' });
            return Promise.resolve(false);
        }
    },
    async actionUpdatePatientContactInfo(
        context: MainContext, payload: { id: string, contactInfo: IPatientContactInfo }) {
        const loadingNotification = { content: 'saving', color: 'info' };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                graphQlApi.updatePatientContactInfo(payload.id, payload.contactInfo),
                await new Promise<void>((resolve) => setTimeout(() => resolve(), 500)),
            ]))[0];
            if (response) {
                commitAddNotification(context, { content: 'Patient contact information succesfully updated', color: 'success' });
                return Promise.resolve(response.data.updatePatientContactInfo);
            } else {
                throw new Error('Error while updating contact info');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, {
                content: 'Error while updating patient contact information', color: 'error' });
            return Promise.resolve(false);
        }
    },
    async actionCreatePatientFemaleInfo(context: MainContext, payload: IFemaleReproductiveHealth) {
        const loadingNotification = { content: 'Creating Patient Female Info...', color: 'info' };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                graphQlApi.createPatientFemaleInfo(payload),
                await new Promise<void>((resolve) => setTimeout(() => resolve(), 500)),
            ]))[0];
            if (response) {
                commitAddNotification(context,
                    { content: 'Patient reproductive health information succesfully created', color: 'success' });
                return Promise.resolve(response.data.createFemaleReproductiveHealth);
            } else {
                throw new Error('Error while creating reproductive health info');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, {
                content: 'Error while creating patient reproductive health information', color: 'error' });
            return Promise.resolve(false);
        }
    },
    async actionUpdatePatientFemaleInfo(
        context: MainContext, payload: { id: string, femaleInfo: IFemaleReproductiveHealth }) {
        const loadingNotification = { content: 'saving', color: 'info' };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                graphQlApi.updatePatientFemaleInfo(payload.id, payload.femaleInfo),
                await new Promise<void>((resolve) => setTimeout(() => resolve(), 500)),
            ]))[0];
            if (response) {
                commitAddNotification(context,
                    { content: 'Patient reproductive health information succesfully updated', color: 'success' });
                return Promise.resolve(response.data.updateFemaleReproductiveHealth);
            } else {
                throw new Error('Error while updating reproductive health info');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, {
                content: 'Error while updating patient reproductive health information', color: 'error' });
            return Promise.resolve(false);
        }
    },
    async actionCreatePriorPregnancies(context: MainContext, payload: IPriorPregnancies) {
        const loadingNotification = { content: 'Creating Prior Pregnancies Info...', color: 'info' };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                graphQlApi.createPriorPregnancies(payload),
                await new Promise<void>((resolve) => setTimeout(() => resolve(), 500)),
            ]))[0];
            if (response) {
                commitAddNotification(context, { content: 'Prior pregnancies succesfully created', color: 'success' });
                return Promise.resolve(response.data.createPriorPregnancy);
            } else {
                throw new Error('Error while creating prior pregnancies');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, {
                content: 'Error while creating patient prior pregnancies', color: 'error' });
            return Promise.resolve(false);
        }
    },
    async actionUpdatePriorPregnancies(
        context: MainContext, payload: { id: string, priorPregnancies: IPriorPregnancies }) {
        const loadingNotification = { content: 'saving', color: 'info' };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                graphQlApi.updatePriorPregnancies(payload.id, payload.priorPregnancies),
                await new Promise<void>((resolve) => setTimeout(() => resolve(), 500)),
            ]))[0];
            if (response) {
                commitAddNotification(context, { content: 'Prior pregnancies succesfully updated', color: 'success' });
                return Promise.resolve(response.data.updatePriorPregnancy);
            } else {
                throw new Error('Error while updating prior pregnancies');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, {
                content: 'Error while updating patient prior pregnancies', color: 'error' });
            return Promise.resolve(false);
        }
    },
};
