import gql from 'graphql-tag';

export const GET_CATEGORIES_FOR_LIST_QUERY = gql`
query categories($skip: Int, $limit: Int) {
    categories(skip: $skip, limit: $limit){
        id
        categoryName
    }
}
`;

export const GET_ALL_CATEGORIES_QUERY = gql`
query categories($skip: Int, $limit: Int) {
    categories(skip: $skip, limit: $limit){
        id
        categoryName
        description
        biomarkers{
            id
            name
            questBiomarkerCode
        }
    }
}
`;

export const GET_CATEGORY_BY_ID_QUERY = gql`
query categoryById($id: UUID!) {
    categoryById(id: $id){
        id
        categoryName
        description
        biomarkers{
            id
            name
            questBiomarkerCode
        }
    }
}
`;
