import { mutations } from './mutations';
import { getters } from './getters';
import {
  actions,
} from './actions';
import { CrudState } from './state';
import { crudModules } from '@/constants/globalConstants';
import { INote } from '@/interfaces/notes';


export const getDefaultState = () => {
  return {
    users: [],
    patients: [],
    visits: [],
    packages: [],
    panels: [],
    biomarkers: [],
    requisitions: [],
    biomarkerResults: [],
    supplements: [],
    requisitionDocuments: [],
    foods: [],
    recommendations: [],
    exercises: [],
    categories: [],
    symptoms: [],
    selfCares: [],
    causes: [],
    needsDataUpgrade: { // when the data is lazy loaded, this will be used to identify which data needs to be upgraded
      [crudModules.USERS]: false,
      [crudModules.PATIENTS]: false,
      [crudModules.VISITS]: false,
      [crudModules.PACKAGES]: false,
      [crudModules.PANELS]: false,
      [crudModules.BIOMARKERS]: false,
      [crudModules.REQUISITIONS]: false,
      [crudModules.BIOMARKER_RESULTS]: false,
      [crudModules.SUPPLEMENTS]: false,
      [crudModules.FOODS]: false,
      [crudModules.EXERCISES]: false,
      [crudModules.RECOMMENDATIONS]: false,
      [crudModules.CATEGORIES]: false,
      [crudModules.SYMPTOMS]: false,
      [crudModules.SELF_CARE]: false,
      [crudModules.CAUSES]: false,
    },
    dataExpired: [
      {
        name: crudModules.USERS,
        expired: true,
        dateTime: new Date(),
        timeUntilExpired: 30,
      },
      {
          name: crudModules.PATIENTS,
          expired: true,
          dateTime: new Date(),
          timeUntilExpired: 30,
      },
      {
          name: crudModules.VISITS,
          expired: true,
          dateTime: new Date(),
          timeUntilExpired: 30,
      },
      {
          name: crudModules.PACKAGES,
          expired: true,
          dateTime: new Date(),
          timeUntilExpired: 30,
      },
      {
          name: crudModules.REQUISITIONS,
          expired: true,
          dateTime: new Date(),
          timeUntilExpired: 0,
      },
      {
          name: crudModules.PANELS,
          expired: true,
          dateTime: new Date(),
          timeUntilExpired: 30,
      },
      {
          name: crudModules.BIOMARKERS,
          expired: true,
          dateTime: new Date(),
          timeUntilExpired: 30,
      },
      {
          name: crudModules.SUPPLEMENTS,
          expired: true,
          dateTime: new Date(),
          timeUntilExpired: 30,
      },
      {
          name: crudModules.BIOMARKER_RESULTS,
          expired: true,
          dateTime: new Date(),
          timeUntilExpired: 30,
      },
      {
          name: crudModules.FOODS,
          expired: true,
          dateTime: new Date(),
          timeUntilExpired: 30,
      },
      {
          name: crudModules.EXERCISES,
          expired: true,
          dateTime: new Date(),
          timeUntilExpired: 30,
      },
      {
          name: crudModules.RECOMMENDATIONS,
          expired: true,
          dateTime: new Date(),
          timeUntilExpired: 30,
      },
      {
          name: crudModules.CATEGORIES,
          expired: true,
          dateTime: new Date(),
          timeUntilExpired: 30,
      },
      {
          name: crudModules.SYMPTOMS,
          expired: true,
          dateTime: new Date(),
          timeUntilExpired: 30,
      },
      {
          name: crudModules.SELF_CARE,
          expired: true,
          dateTime: new Date(),
          timeUntilExpired: 30,
      },
      {
          name: crudModules.CAUSES,
          expired: true,
          dateTime: new Date(),
          timeUntilExpired: 30,
      },
    ],
    physicianNotes: [],
    accessCodes: [],
  } as CrudState;
};

export const crudModule = {
  state: getDefaultState(),
  mutations,
  actions,
  getters,
};
