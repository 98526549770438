import {ActionContext} from 'vuex';

import * as mutations from '@/store/crud/mutations';
import {CrudState} from '@/store/crud/state';
import {State} from '@/store/state';
import {commitAddNotification} from '@/store/main/mutations';
import {dispatchCheckApiError} from '@/store/main/actions';

import {graphQlApi} from '@/graphql/graphqlApi';
import {ICreateRequisition, IRequisition, IUpdateRequisition} from '@/interfaces/requisitions';
import {crudModules} from '@/constants/globalConstants';

type MainContext = ActionContext<CrudState, State>;

/**
 * Returns requisitions cached data
 * or false if it is expired
 * If the view or components needs the full data, there is a flag
 * "needsDataUpgrade" to check if the existing cache is list or full data.
 * If it is list, then the data is not valid and needs to be upgraded.
 */
const getRequisitionsCachedData = async (context: MainContext, needsDataUpgrade = false) => {
  if (context.getters.requisitions && context.getters.requisitions.length > 0) {
    const dataExpired = await context.dispatch('requisitionsDataExpired');
    // check data expiration
    if (dataExpired) {
      // re run this action without cached data
      return false; // data is expired so we need to fetch new data
    } else {
      if (needsDataUpgrade) {
        if (context.getters.needsDataUpgrade(crudModules.REQUISITIONS)) {
          return false;
        }
      }
      return context.getters.requisitions;
    }
  } else {
    return false;
  }
};

/**
 * Actions sub module for biomarkers actions
 */
export default {
  async actionGetRequisitions(context: MainContext) {
    try {
      // check cached data
      const requisitionsCachedData = await getRequisitionsCachedData(context, true);
      if (requisitionsCachedData) {
        return requisitionsCachedData;
      }
      const response = await graphQlApi.getAllRequisitions();
      if (response) {
        mutations.commitSetRequisitions(context, response.data.requisitions);
        mutations.setModuleNeedsDataUpgrade(context, {
          module: crudModules.REQUISITIONS,
          needsDataUpgrade: false,
        });
        mutations.setDataUpdated(context, crudModules.REQUISITIONS);
        return Promise.resolve(response.data.requisitions);
      } else {
        throw new Error('failed while getting requisitions');
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
      commitAddNotification(context, {
        content: 'Error while fetching requisitions',
        color: 'error',
      });
      return Promise.resolve(false);
    }
  },
  async actionGetRequisitionsForList(context: MainContext) {
    try {
      // check cached data
      const requisitionsCachedData = await getRequisitionsCachedData(context);
      if (requisitionsCachedData) {
        return requisitionsCachedData;
      }
      const response = await graphQlApi.getRequisitionsForTableView();
      if (response) {
        mutations.commitSetRequisitions(context, response.data.requisitions);
        mutations.setModuleNeedsDataUpgrade(context, {
          module: crudModules.REQUISITIONS,
          needsDataUpgrade: true,
        });
        mutations.setDataUpdated(context, crudModules.REQUISITIONS);
        return Promise.resolve(response.data.requisitions);
      } else {
        throw new Error('failed while getting requisitions');
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
      commitAddNotification(context, {
        content: 'Error while fetching requisitions',
        color: 'error',
      });
      return Promise.resolve(false);
    }
  },
  /**
   * Gets all requisitions that are not yet confirmed
   * This is used by physicians users to confirm the requisitions.
   * The filter happens on server side with the token of the user
   */
  async actionGetRequisitionsByState(context: MainContext) {
    try {
      const requisitionsCachedData = await getRequisitionsCachedData(context);
      if (requisitionsCachedData) {
        return requisitionsCachedData;
      }
      const response = await graphQlApi.getRequisitionsByState();
      if (response) {
        mutations.commitSetRequisitions(context, response.data.requisitionByState);
        mutations.setDataUpdated(context, crudModules.REQUISITIONS);
        return Promise.resolve(response.data.requisitionByState);
      } else {
        throw new Error('failed while getting requisitions');
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
      commitAddNotification(context, {
        content: 'Error while fetching requisitions',
        color: 'error',
      });
      return Promise.resolve(false);
    }
  },
  /**
   * Gets all the requisitions that have lab results.
   * This is used by Physicians and nurse users to see the requisitions with lab results.
   */
  async actionGetRequisitionResults(context: MainContext) {
    try {
      const requisitionsCachedData = await getRequisitionsCachedData(context);
      if (requisitionsCachedData) {
        return requisitionsCachedData;
      }
      const response = await graphQlApi.getRequisitionResults();
      if (response) {
        return Promise.resolve(response.data.requisitionByStateIsConfirmed);
      } else {
        throw new Error('failed while getting requisition results');
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
      commitAddNotification(context, {
        content: 'Error while fetching requisition results',
        color: 'error',
      });
      return Promise.resolve(false);
    }
  },
  /**
   * Gets a requisition that have lab result by id.
   * This is used by Physicians and nurse users to see the requisitions with lab results.
   */
  async actionGetRequisitionResultById(context: MainContext, id: string) {
    try {
      const response = await graphQlApi.getRequisitionResultById(id);
      if (response) {
        return Promise.resolve(response.data.requisitionByStateIsConfirmedById);
      } else {
        throw new Error('failed while getting requisition result by id');
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
      commitAddNotification(context, {
        content: 'Error while fetching requisition result by id',
        color: 'error',
      });
      return Promise.resolve(false);
    }
  },
  /**
   * Requisitions for reviewer role
   */
  async actionGetRequisitionsForReviewer(context: MainContext) {
    try {
      const requisitionsCachedData = await getRequisitionsCachedData(context);
      if (requisitionsCachedData) {
        return requisitionsCachedData;
      }
      const response = await graphQlApi.getRequisitionsForReviewer();
      if (response) {
        mutations.commitSetRequisitions(context, response.data.requisitionReviewer);
        mutations.setDataUpdated(context, crudModules.REQUISITIONS);
        return Promise.resolve(response.data.requisitions);
      } else {
        throw new Error('failed while getting requisitions');
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
      commitAddNotification(context, {
        content: 'Error while fetching requisitions',
        color: 'error',
      });
      return Promise.resolve(false);
    }
  },
  async actionGetRequisitionForReviewerById(context: MainContext, id: string) {
    try {
      const response = await graphQlApi.getRequisitionsForReviewerById(id);
      if (response && response.data && response.data.requisitionReviewer) {
        mutations.commitSetRequisitions(context, response.data.requisitionReviewer);
        return Promise.resolve(response.data.requisitionReviewer);
      } else {
        throw new Error('failed while getting requisition');
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
      commitAddNotification(context, {content: 'Error while fetching requisition', color: 'error'});
      return Promise.resolve(false);
    }
  },
  async actionGetRequisitionById(context: MainContext, id: string) {
    try {
      const response = await graphQlApi.getRequisitionById(id);
      if (response) {
        mutations.commitSetRequisition(context, response.data.requisitionById);
        return Promise.resolve(response.data.requisitionById);
      } else {
        throw new Error('failed while getting requisition');
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
      commitAddNotification(context, {content: 'Error while fetching requisition', color: 'error'});
      return Promise.resolve(false);
    }
  },
  async actionCreateRequisition(context: MainContext, requisition: ICreateRequisition) {
    const loadingNotification = {content: 'Creating Requisition...', color: 'info'};
    try {
      commitAddNotification(context, loadingNotification);
      const response = await graphQlApi.createRequisition(requisition);
      if (response && response.data) {
        mutations.commitSetRequisition(context, response.data.createRequisition);
        return Promise.resolve(response.data.createRequisition);
      } else {
        throw new Error('failed while creating requisition');
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
      return Promise.resolve(false);
    }
  },
  async actionUpdateRequisition(
    context: MainContext,
    payload: {
      requisitionId: string;
      requisition: IUpdateRequisition;
    },
  ) {
    const loadingNotification = {content: 'Updating Requisition...', color: 'info'};
    try {
      commitAddNotification(context, loadingNotification);
      const response = await graphQlApi.updateRequisition(
        payload.requisitionId,
        payload.requisition,
      );
      if (response && response.data) {
        mutations.commitSetRequisition(context, response.data.updateRequisition);
        return Promise.resolve(response.data.updateRequisition);
      } else {
        throw new Error('failed while updating requisition');
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
      return Promise.resolve(false);
    }
  },
  async actionUpdateRequisitionNurse(
    context: MainContext,
    payload: {
      requisitionId: string;
      requisition: IUpdateRequisition;
    },
  ) {
    try {
      const response = await graphQlApi.updateRequisitionNurse(
        payload.requisitionId,
        payload.requisition,
      );
      if (response) {
        mutations.commitSetRequisition(context, response.data.updateRequisitionNurse);
        return Promise.resolve(response.data.updateRequisitionNurse);
      } else {
        throw new Error('failed while updating requisition');
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
      commitAddNotification(context, {content: 'Error while updating requisition', color: 'error'});
      return Promise.resolve(false);
    }
  },
  async actionDeleteRequisition(context: MainContext, requisitionId: string) {
    try {
      const response = await graphQlApi.deleteRequisition(requisitionId);
      if (response) {
        commitAddNotification(context, {
          content: 'Requisition successfully deleted',
          color: 'success',
        });
        mutations.commitDeleteRequisition(context, {id: requisitionId} as IRequisition);
        return Promise.resolve(true);
      } else {
        throw new Error('failed while deleting requisition');
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
      commitAddNotification(context, {content: 'Error while deleting requisition', color: 'error'});
      return Promise.resolve(false);
    }
  },
};
