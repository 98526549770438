import gql from 'graphql-tag';

export const GET_ALL_PACKAGES_QUERY = gql`
query packages($skip: Int, $limit: Int) {
    packages(skip: $skip, limit: $limit){
        id
        name
        version
        description
        panels{
            id
            name
            biomarkers{
                id
                name
                questBiomarkerCode
            }
        }
    }
}
`;

export const GET_ALL_PACKAGES_FOR_TABLE_VIEW_QUERY = gql`
query packages($skip: Int, $limit: Int) {
    packages(skip: $skip, limit: $limit){
        id
        name
        description
        panels{
            name
        }
    }
}
`;

export const GET_ALL_PACKAGES_FOR_LIST_QUERY = gql`
query packages($skip: Int, $limit: Int) {
    packages(skip: $skip, limit: $limit){
        id
        name
    }
}
`;

export const GET_PACKAGE_BY_ID_QUERY = gql`
query packageById($id: UUID!) {
    packageById(id: $id){
        id
        name
        version
        description
        panels{
            id
            name
        }
    }
}
`;
