import gql from 'graphql-tag';

export const UPDATE_CRITICAL_RESULT_MUTATION = gql`
    mutation updateCriticalResult($id: UUID!, $criticalResultInput: CriticalResultUpdate!) {
        updateCriticalResult(id: $id, criticalResultInput: $criticalResultInput){
            level
            criticalReviewed
        }
    }
`;

export const CREATE_CRITICAL_RESULT_MUTATION = gql`
    mutation createCriticalResult($criticalResultInput: CriticalResultCreate!) {
        createCriticalResult(criticalResultInput: $criticalResultInput){
            id
            level
            criticalReviewed
            dateTimeCriticalReviewed
            visitId
        }
    }
`;


