import Vue from 'vue';
import IdleVue from 'idle-vue'
import Vuex, { StoreOptions } from 'vuex';

import { mainModule } from './main';
import { State } from './state';
import { crudModule } from './crud';

Vue.use(Vuex);


const storeOptions: StoreOptions<State> = {
  modules: {
    main: mainModule,
    crud: crudModule,
  },
};



export const store = new Vuex.Store<State>(storeOptions);

Vue.use(IdleVue, { store, idleTime: 10000 });


export default store;
