import { ActionContext } from 'vuex';

import { CrudState } from '@/store/crud/state';

import { State } from '@/store/state';
import { crudModules } from '@/constants/globalConstants';
import { IDataExpiration } from '@/interfaces/dataExpiration';
import { setDataExpired } from '../mutations';

type MainContext = ActionContext<CrudState, State>;

/**
 * Actions sub module for user actions
 */
export default {
  /* data expirations */
  async usersDataExpired(context: MainContext) {
    const expiration = context.state.dataExpired.find(
      (dataExpired) => dataExpired.name === crudModules.USERS,
    ) as IDataExpiration;
    return await checkExpiration(expiration, context);
  },
  async patientsDataExpired(context: MainContext) {
    const expiration = context.state.dataExpired.find(
      (dataExpired) => dataExpired.name === crudModules.PATIENTS,
    ) as IDataExpiration;
    return await checkExpiration(expiration, context);
  },
  async visitsDataExpired(context: MainContext) {
    const expiration = context.state.dataExpired.find(
      (dataExpired) => dataExpired.name === crudModules.VISITS,
    ) as IDataExpiration;
    return await checkExpiration(expiration, context);
  },
  async requisitionsDataExpired(context: MainContext) {
    const expiration = context.state.dataExpired.find(
      (dataExpired) => dataExpired.name === crudModules.REQUISITIONS,
    ) as IDataExpiration;
    return await checkExpiration(expiration, context);
  },
  async packagesDataExpired(context: MainContext) {
    const expiration = context.state.dataExpired.find(
      (dataExpired) => dataExpired.name === crudModules.PACKAGES,
    ) as IDataExpiration;
    return await checkExpiration(expiration, context);
  },
  async panelsDataExpired(context: MainContext) {
    const expiration = context.state.dataExpired.find(
      (dataExpired) => dataExpired.name === crudModules.PANELS,
    ) as IDataExpiration;
    return await checkExpiration(expiration, context);
  },
  async biomarkersDataExpired(context: MainContext) {
    const expiration = context.state.dataExpired.find(
      (dataExpired) => dataExpired.name === crudModules.BIOMARKERS,
    ) as IDataExpiration;
    return await checkExpiration(expiration, context);
  },
  async biomarkerResultsDataExpired(context: MainContext) {
    const expiration = context.state.dataExpired.find(
      (dataExpired) => dataExpired.name === crudModules.BIOMARKER_RESULTS,
    ) as IDataExpiration;
    return await checkExpiration(expiration, context);
  },
  async foodsDataExpired(context: MainContext) {
    const expiration = context.state.dataExpired.find(
      (dataExpired) => dataExpired.name === crudModules.FOODS,
    ) as IDataExpiration;
    return await checkExpiration(expiration, context);
  },
  async supplementsDataExpired(context: MainContext) {
    const expiration = context.state.dataExpired.find(
      (dataExpired) => dataExpired.name === crudModules.SUPPLEMENTS,
    ) as IDataExpiration;
    return await checkExpiration(expiration, context);
  },
  async exercisesDataExpired(context: MainContext) {
    const expiration = context.state.dataExpired.find(
      (dataExpired) => dataExpired.name === crudModules.EXERCISES,
    ) as IDataExpiration;
    return await checkExpiration(expiration, context);
  },
  async recommendationsDataExpired(context: MainContext) {
    const expiration = context.state.dataExpired.find(
      (dataExpired) => dataExpired.name === crudModules.RECOMMENDATIONS,
    ) as IDataExpiration;
    return await checkExpiration(expiration, context);
  },
  async causesDataExpired(context: MainContext) {
    const expiration = context.state.dataExpired.find(
      (dataExpired) => dataExpired.name === crudModules.CAUSES,
    ) as IDataExpiration;
    return await checkExpiration(expiration, context);
  },
  async symptomsDataExpired(context: MainContext) {
    const expiration = context.state.dataExpired.find(
      (dataExpired) => dataExpired.name === crudModules.SYMPTOMS,
    ) as IDataExpiration;
    return await checkExpiration(expiration, context);
  },
  async categoriesDataExpired(context: MainContext) {
    const expiration = context.state.dataExpired.find(
      (dataExpired) => dataExpired.name === crudModules.CATEGORIES,
    ) as IDataExpiration;
    return await checkExpiration(expiration, context);
  },
  async SelfCaresDataExpired(context: MainContext) {
    const expiration = context.state.dataExpired.find(
      (dataExpired) => dataExpired.name === crudModules.SELF_CARE,
    ) as IDataExpiration;
    return await checkExpiration(expiration, context);
  },
  /**
   * expires a cached data manually by
   * setting the expiration time to 0
   */
  async expireDataByModuleName(context: MainContext, moduleName: string) {
    context.commit('forceModuleDataExpired', {
      module: moduleName,
    });
  },
};

const checkExpiration = async (expiration: IDataExpiration, context: MainContext, forceExpiration: boolean = false) => {
  // if flaged as expired or forced expiration we dont need to calculate
  if (expiration) {
    if (expiration.expired) {
      return Promise.resolve(true);
    }
    // calculate if it expired by time
    const expired = await calculateDateExpiration(expiration.dateTime, expiration.timeUntilExpired) || forceExpiration;
    if (expired) {
      setDataExpired(context, expiration);
      return true;
    }
  }
  return false;
};

const calculateDateExpiration = (expirationTime: Date, timeLimit) => {
  const currentTime = new Date().getTime();
  const dataTime = expirationTime.getTime();
  const timeDiff: number = Math.floor((currentTime - dataTime) / 1000 / 60);
  if (timeDiff >= timeLimit) {
    return Promise.resolve(true);
  }
  return Promise.resolve(false);
};
