export const requisitionEditTypes = {
    EDIT_FROM_VISIT: 'EDIT_FROM_VISIT',
    EDIT_FROM_REQUISITION: 'EDIT_FROM_REQUISITION',
    CREATION: 'CREATION',
};

export const crudModules = {
    USERS: 'users',
    PATIENTS: 'patients',
    VISITS: 'visits',
    PACKAGES: 'packages',
    PANELS: 'panels',
    BIOMARKERS: 'biomarkers',
    REQUISITIONS: 'requisitions',
    BIOMARKER_RESULTS: 'biomarkerResults',
    SUPPLEMENTS: 'supplements',
    FOODS: 'foods',
    EXERCISES: 'exercises',
    RECOMMENDATIONS: 'recommendations',
    CATEGORIES: 'categories',
    CAUSES: 'causes',
    SYMPTOMS: 'symptoms',
    SELF_CARE: 'selfCare',
};
