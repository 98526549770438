
import gql from 'graphql-tag';

export const CREATE_TOKEN_MUTATION = gql `
mutation createToken($email: String!, $password: String!) {
    createToken(email: $email, password: $password){
        accessToken
        tokenType
    }
}
`;

export const CREATE_REVIEWING_TIME_MUTATION = gql `
    mutation createReviewingTime($reviewingTimeInput: ReviewingTimeCreate!) {
        createReviewingTime(reviewingTimeInput: $reviewingTimeInput)
    }
`;
