import Vue from 'vue';
import Router from 'vue-router';

import RouterComponent from './components/RouterComponent.vue';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: () => import(/* webpackChunkName: "start" */ './views/main/Start.vue'),
      children: [
        {
          path: 'login',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "login" */ './views/Login.vue'),
        },
        {
          path: 'validate-code/:user_id',
          name: 'validate-code',
          props: true,
          component: () => import('./views/ValidateCode.vue'),
        },
        {
          path: 'recover-password',
          component: () =>
            import(/* webpackChunkName: "recover-password" */ './views/PasswordRecovery.vue'),
        },
        {
          path: 'reset-password',
          component: () =>
            import(/* webpackChunkName: "reset-password" */ './views/ResetPassword.vue'),
        },
        {
          path: 'main',
          component: () => import(/* webpackChunkName: "main" */ './views/main/Main.vue'),
          children: [
            /*
            * Dashboard removed from app
            {
              path: 'dashboard',
              component: () => import(/* webpackChunkName: "main-dashboard" */ /*'./views/main/Dashboard.vue'),
          },
          */
            {
              path: 'profile',
              component: RouterComponent,
              redirect: 'profile/view',
              children: [
                {
                  path: 'view',
                  component: () =>
                    import(
                      /* webpackChunkName: "main-profile" */ './views/main/profile/UserProfile.vue' // tslint:disable-line
                    ),
                },
                {
                  path: 'edit',
                  component: () =>
                    import(
                      /* webpackChunkName: "main-profile-edit" */ './views/main/profile/UserProfileEdit.vue' // tslint:disable-line
                    ),
                },
                {
                  path: 'password',
                  component: () =>
                    import(
                      /* webpackChunkName: "main-profile-password" */ './views/main/profile/UserProfileEditPassword.vue' // tslint:disable-line
                    ),
                },
              ],
            },
            {
              path: 'physician',
              component: () =>
                import(
                  /* webpackChunkName: "main-physician" */ './views/main/physician/Physician.vue' // tslint:disable-line
                ),
              redirect: 'physician/requisitions',
              children: [
                {
                  path: 'requisitions/',
                  name: 'main-physician-requisitions',
                  component: () =>
                    import(
                      /* webpackChunkName: "main-physician-requisitions" */ './views/main/physician/LabRequisition.vue' // tslint:disable-line
                    ),
                },
                {
                  path: 'results/',
                  name: 'main-physician-results',
                  component: () =>
                    import(
                      /* webpackChunkName: "main-physician-results" */ './views/main/physician/TestResults.vue' // tslint:disable-line
                    ),
                  children: [
                    {
                      path: ':requisitionId',
                      name: 'physician-results-step',
                      component: () =>
                        import(
                          /* webpackChunkName: "physician-results-step" */ './views/main/physician/Results.vue' // tslint:disable-line
                        ),
                      children: [
                        {
                          path: 'review',
                          name: 'physician-review-step',
                          component: () =>
                            import(
                              /* webpackChunkName: "physician-review-step" */ './views/main/physician/Review.vue' // tslint:disable-line
                            ),
                          children: [
                            {
                              path: 'confirmation',
                              name: 'physician-confirmation-step',
                              component: () =>
                                import(
                                  /* webpackChunkName: "physician-confirmation-step" */ './views/main/physician/Confirmation.vue' // tslint:disable-line
                                ),
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              path: 'reviewer',
              component: () =>
                import(
                  /* webpackChunkName: "main-reviewer" */ './views/main/reviewer/Reviewer.vue' // tslint:disable-line
                ),
              redirect: 'reviewer/results',
              children: [
                {
                  path: 'results/',
                  name: 'reviewer-results',
                  component: () =>
                    import(
                      /* webpackChunkName: "main-reviewer-results" */ './views/main/reviewer/ReviewerResults.vue' // tslint:disable-line
                    ),
                },
                {
                  path: 'result/:requisitionId',
                  name: 'reviewer-review-result',
                  props: true,
                  component: () =>
                    import(
                      /* webpackChunkName: "main-reviewer-review-result" */ './views/main/reviewer/ReviewerTestResult.vue' // tslint:disable-line
                    ),
                },
              ],
            },
            {
              path: 'admin',
              component: () =>
                import(/* webpackChunkName: "main-admin" */ './views/main/admin/Admin.vue'),
              redirect: 'admin/',
              children: [
                {
                  path: 'users',
                  redirect: 'users/all',
                },
                {
                  path: 'users/all',
                  component: () =>
                    import(
                      /* webpackChunkName: "main-admin-users" */ './views/main/admin/AdminUsers.vue' // tslint:disable-line
                    ),
                },
                {
                  path: 'users/edit/:id',
                  name: 'main-admin-users-edit',
                  props: true,
                  component: () =>
                    import(
                      /* webpackChunkName: "main-admin-users-edit" */ './views/main/admin/EditUser.vue' // tslint:disable-line
                    ),
                },
                {
                  path: 'users/create',
                  name: 'main-admin-users-create',
                  component: () =>
                    import(
                      /* webpackChunkName: "main-admin-users-create" */ './views/main/admin/CreateUser.vue' // tslint:disable-line
                    ),
                },
                {
                  path: 'patients',
                  redirect: 'patients/all',
                },
                {
                  path: 'patients/all',
                  component: () => import('./views/main/admin/patients/AdminPatients.vue'),
                },
                {
                  path: 'patient/:id',
                  name: 'main-admin-patients-edit',
                  props: true,
                  component: () => import('./views/main/admin/patients/Patient.vue'),
                },
                {
                  path: 'patients/create',
                  name: 'main-admin-patients-create',
                  component: () => import('./views/main/admin/patients/Patient.vue'),
                },
                {
                  path: 'patients/customer-service/:patientId',
                  name: 'main-admin-patients-customer-service',
                  props: true,
                  component: () =>
                    import('./views/main/admin/patients/PatientCustomerServiceView.vue'),
                },
                {
                  path: 'visits',
                  redirect: 'visits/all',
                },
                {
                  path: 'visits/all',
                  component: () => import('./views/main/admin/visits/AdminVisits.vue'),
                },
                {
                  path: 'visits/edit/:visitId',
                  name: 'main-admin-visits-edit',
                  props: true,
                  component: () => import('./views/main/admin/visits/AdminVisit.vue'),
                },
                {
                  path: 'visits/create/',
                  props: true,
                  name: 'main-admin-visits-create-by-requisition',
                  component: () => import('./views/main/admin/visits/AdminVisit.vue'),
                },
                /* Requisitions */
                {
                  path: 'requisitions',
                  redirect: 'requisitions/all',
                },
                {
                  path: 'requisitions/all',
                  component: () => import('./views/main/admin/requisitions/AdminRequisitions.vue'),
                },
                {
                  path: 'requisitions/edit/:requisitionId',
                  name: 'main-admin-requisitions-edit',
                  props: true,
                  component: () => import('./views/main/admin/requisitions/AdminRequisition.vue'),
                },
                {
                  path: 'requisitions/create',
                  props: true,
                  name: 'main-admin-requisitions-create',
                  component: () => import('./views/main/admin/requisitions/AdminRequisition.vue'),
                },
                {
                  path: 'visits/create/:requisitionPatientId',
                  props: true,
                  name: 'main-admin-requisition-create-id',
                  component: () => import('./views/main/admin/requisitions/AdminRequisition.vue'),
                },
                /* Biomarker results */
                {
                  path: 'biomarkerresults',
                  redirect: 'biomarkerresults/all',
                },
                {
                  path: 'biomarkerresults/all',
                  component: () =>
                    import('./views/main/admin/biomarker-results/AdminBiomarkerResults.vue'),
                },
                {
                  path: 'biomarkerresults/edit/:biomarkerResultId',
                  name: 'main-admin-biomarkerresults-edit',
                  props: true,
                  component: () =>
                    import('./views/main/admin/biomarker-results/EditBiomarkerResult.vue'),
                },
                /* Reports */
                {
                  path: 'reports',
                  redirect: 'reports/timeline',
                },
                {
                  path: 'reports/timeline',
                  component: () => import('./views/main/admin/reports/PatientTimeline.vue'),
                },
                {
                  path: 'access-codes',
                  name: 'main-admin-accesscode-list',
                  component: () => import('./views/main/admin/access-codes/AccessCodesList.vue'),
                  children: [
                    {
                      path: 'add',
                      name: 'main-admin-accesscode-create',
                      component: () => import('./views/main/admin/access-codes/CreateCode.vue'),
                    },
                    {
                      path: ':id',
                      name: 'main-admin-accesscode-edit',
                      component: () => import('./views/main/admin/access-codes/CreateCode.vue'),
                    },
                  ],
                },
              ],
            },
            {
              path: 'content',
              component: () =>
                import(/* webpackChunkName: "main-content" */ './views/main/content/Content.vue'),
              redirect: 'content/recommendations',
              children: [
                /* Packages */
                {
                  path: 'packages',
                  redirect: 'packages/all',
                },
                {
                  path: 'packages/all',
                  component: () => import('./views/main/content/packages/Packages.vue'),
                },
                {
                  path: 'packages/create',
                  props: true,
                  name: 'main-content-packages-create',
                  component: () => import('./views/main/content/packages/CreatePackage.vue'),
                },
                {
                  path: 'packages/edit/:packageId',
                  name: 'main-content-packages-edit',
                  props: true,
                  component: () => import('./views/main/content/packages/EditPackage.vue'),
                },
                /* Panels */
                {
                  path: 'panels',
                  redirect: 'panels/all',
                },
                {
                  path: 'panels/all',
                  component: () => import('./views/main/content/panels/Panels.vue'),
                },
                {
                  path: 'panels/create',
                  props: true,
                  name: 'main-content-panels-create',
                  component: () => import('./views/main/content/panels/CreatePanel.vue'),
                },
                {
                  path: 'panels/edit/:panelId',
                  name: 'main-content-panels-edit',
                  props: true,
                  component: () => import('./views/main/content/panels/EditPanel.vue'),
                },
                /* Biomarkers */
                {
                  path: 'biomarkers',
                  redirect: 'biomarkers/all',
                },
                {
                  path: 'biomarkers/all',
                  component: () => import('./views/main/content/biomarkers/Biomarkers.vue'),
                },
                {
                  path: 'biomarkers/create',
                  props: true,
                  name: 'main-content-biomarkers-create',
                  component: () => import('./views/main/content/biomarkers/Biomarker.vue'),
                },
                {
                  path: 'biomarkers/:biomarkerId',
                  name: 'main-content-biomarkers-edit',
                  props: true,
                  component: () => import('./views/main/content/biomarkers/Biomarker.vue'),
                },
                /* Supplements */
                {
                  path: 'supplements',
                  redirect: 'supplements/all',
                },
                {
                  path: 'supplements/all',
                  component: () => import('./views/main/content/supplements/Supplements.vue'),
                },
                {
                  path: 'supplements/create',
                  props: true,
                  name: 'main-content-supplements-create',
                  component: () => import('./views/main/content/supplements/Supplement.vue'),
                },
                {
                  path: 'supplements/edit/:supplementId',
                  name: 'main-content-supplements-edit',
                  props: true,
                  component: () => import('./views/main/content/supplements/Supplement.vue'),
                },
                /* foods */
                {
                  path: 'foods',
                  redirect: 'foods/all',
                },
                {
                  path: 'foods/all',
                  component: () => import('./views/main/content/foods/Foods.vue'),
                },
                {
                  path: 'foods/create',
                  props: true,
                  name: 'main-content-foods-create',
                  component: () => import('./views/main/content/foods/Food.vue'),
                },
                {
                  path: 'foods/edit/:foodId',
                  name: 'main-content-foods-edit',
                  props: true,
                  component: () => import('./views/main/content/foods/Food.vue'),
                },
                /* recommendations */
                {
                  path: 'recommendations',
                  redirect: 'recommendations/all',
                },
                {
                  path: 'recommendations/all',
                  component: () =>
                    import('./views/main/content/recommendations/Recommendations.vue'),
                },
                {
                  path: 'recommendations/create',
                  name: 'main-content-recommendations-create',
                  component: () =>
                    import('./views/main/content/recommendations/Recommendation.vue'),
                },
                {
                  path: 'recommendations/edit/:recommendationId',
                  name: 'main-content-recommendations-edit',
                  props: true,
                  component: () =>
                    import('./views/main/content/recommendations/Recommendation.vue'),
                },
                /* exercises */
                {
                  path: 'exercises',
                  redirect: 'exercises/all',
                },
                {
                  path: 'exercises/all',
                  component: () => import('./views/main/content/exercises/Exercises.vue'),
                },
                {
                  path: 'exercises/create',
                  props: true,
                  name: 'main-content-exercises-create',
                  component: () => import('./views/main/content/exercises/Exercise.vue'),
                },
                {
                  path: 'exercises/edit/:exerciseId',
                  name: 'main-content-exercises-edit',
                  props: true,
                  component: () => import('./views/main/content/exercises/Exercise.vue'),
                },
                /* categories */
                {
                  path: 'categories',
                  redirect: 'categories/all',
                },
                {
                  path: 'categories/all',
                  component: () => import('./views/main/content/categories/Categories.vue'),
                },
                {
                  path: 'categories/create',
                  props: true,
                  name: 'main-content-categories-create',
                  component: () => import('./views/main/content/categories/Category.vue'),
                },
                {
                  path: 'categories/edit/:categoryId',
                  name: 'main-content-categories-edit',
                  props: true,
                  component: () => import('./views/main/content/categories/Category.vue'),
                },
                /* causes */
                {
                  path: 'causes',
                  redirect: 'causes/all',
                },
                {
                  path: 'causes/all',
                  component: () => import('./views/main/content/causes/Causes.vue'),
                },
                {
                  path: 'causes/create',
                  props: true,
                  name: 'main-content-causes-create',
                  component: () => import('./views/main/content/causes/Cause.vue'),
                },
                {
                  path: 'causes/edit/:causeId',
                  name: 'main-content-causes-edit',
                  props: true,
                  component: () => import('./views/main/content/causes/Cause.vue'),
                },
                /* Symptoms */
                {
                  path: 'symptoms',
                  redirect: 'symptoms/all',
                },
                {
                  path: 'symptoms/all',
                  component: () => import('./views/main/content/symptoms/Symptoms.vue'),
                },
                {
                  path: 'symptoms/create',
                  props: true,
                  name: 'main-content-symptoms-create',
                  component: () => import('./views/main/content/symptoms/Symptom.vue'),
                },
                {
                  path: 'symptoms/edit/:symptomId',
                  name: 'main-content-symptoms-edit',
                  props: true,
                  component: () => import('./views/main/content/symptoms/Symptom.vue'),
                },
                /* Self Care */
                {
                  path: 'selfcare',
                  redirect: 'selfcare/all',
                },
                {
                  path: 'selfcare/all',
                  component: () => import('./views/main/content/selfcare/SelfCares.vue'),
                },
                {
                  path: 'selfcare/create',
                  props: true,
                  name: 'main-content-selfcare-create',
                  component: () => import('./views/main/content/selfcare/SelfCare.vue'),
                },
                {
                  path: 'selfcare/edit/:selfCareId',
                  name: 'main-content-selfcare-edit',
                  props: true,
                  component: () => import('./views/main/content/selfcare/SelfCare.vue'),
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: '/*',
      redirect: '/',
    },
  ],
});

export default router;
