import gql from 'graphql-tag';

export const GET_REQUISITIONS_FOR_TABLE_VIEW_QUERY = gql`
query requisitions($skip: Int, $limit: Int) {
    requisitions(skip: $skip, limit: $limit){
        id
        patientId
        requisitionState
        readyForConfirmation
        confirmation
        reviewed
        patient{
            id
            patientFirebaseId
            patientIdentifier
            fname
            lname
            patientContactInfo{
                email
            }
        }
        visits{
            id
        }
    }
}
`;
export const GET_REQUISITIONS_FOR_LIST_QUERY = gql`
query requisitions($skip: Int, $limit: Int) {
    requisitions(skip: $skip, limit: $limit){
        id
        patient{
            id
            fname
            lname
        }
        referringPhysician{
            email
            fname
            lname
        }
        reviewingPhysician{
            id
            email
            fname
            lname
        }
        visits{
            id
            visitDate
            streetAddress
            city
            state
        }
    }
}
`;
export const GET_REQUISITIONS_QUERY = gql`
query requisitions($skip: Int, $limit: Int) {
    requisitions(skip: $skip, limit: $limit){
        id
        patientId
        requisitionState
        confirmation
        physicianNotes
        patientNotes
        referringPhysicianId
        reviewingPhysicianId
        reviewed
        readyForConfirmation
        dateTimeConfirmed
        dateTimeReviewed
        allResultsAvailable
        patient{
            id
            patientIdentifier
            fname
            lname
            preferredName
            biologicalSex
            dob
            ssn
            pronouns
            ethnicOrigin
            shirtSize
            createdAt
            updatedAt
            motivational{
                id
                patientId
                tenYearHealthGoals
                purposeForJoiningFunction
                howDidTheyHearAboutFunction
                commentsBeforeBooking
                createdAt
                updatedAt
            }
            covid{
                id
                patientId
                hadCovid
                covidDate
                covidSeverity
                hasCovidResidualSymptoms
                covidResidualSymptoms
                vaccinatedForCovid
                covidVaccineName
                covidVaccineDateLastDosage
                createdAt
                updatedAt
            }
            femaleReproductiveHealth{
                id
                patientId
                currentlyOnBirthControl
                birthControlType
                timeOnBirthControl
                dateOfLastPeriod
                currentlyPregnant
                tryingToConceive
                currentMenstrualStatus
                priorPregnancies
                priorPregnancy{
                    id
                    femaleReproductiveHealthId
                    count
                    numberOfChildren
                    numberOfMiscarriages
                    numberOfAbortions
                    createdAt
                    updatedAt
                }
                createdAt
                updatedAt
            }
            medicalHx{
                id
                patientId
                hasChronicCondition
                chronicConditions
                chronicConditionDiagnosisDates
                takingMedications
                currentMedications
                hadSurgeries
                surgeries
                beenHospitalized
                hospitalizations
                hadIssuesWithBloodDraw
                bloodDrawIssues
                createdAt
                updatedAt
            }
            patientBehaviorInfo{
                id
                patientId
                diet
                physicallyActive
                smoker
                smokingFrequency
                alcohol
                alcoholFrequency
                createdAt
                updatedAt
            }
            patientContactInfo{
                id
                patientId
                email
                phoneNumber
                streetAddress
                city
                state
                zip
                createdAt
                updatedAt
            }
        }
        referringPhysician{
            id
            email
            phoneNumber
            isActive
            isSuperuser
            isPhysician
            fname
            lname
            npi
            licenseState
            isNursePracticioner
            isReviewer
        }
        reviewingPhysician{
            id
            email
            phoneNumber
            isActive
            isSuperuser
            isPhysician
            fname
            lname
            npi
            licenseState
            isNursePracticioner
            isReviewer
        }
        visits{
            id
            patientId
            visitDate
            streetAddress
            city
            state
            zip
            resultsAvailable
            confirmationCode
            documents
            patient{
                id
                patientIdentifier
                fname
                lname
                preferredName
                biologicalSex
                dob
                ssn
                pronouns
                ethnicOrigin
                shirtSize
                createdAt
                updatedAt
                motivational{
                    id
                    patientId
                    tenYearHealthGoals
                    purposeForJoiningFunction
                    howDidTheyHearAboutFunction
                    commentsBeforeBooking
                    createdAt
                    updatedAt
                }
                covid{
                    id
                    patientId
                    hadCovid
                    covidDate
                    covidSeverity
                    hasCovidResidualSymptoms
                    covidResidualSymptoms
                    vaccinatedForCovid
                    covidVaccineName
                    covidVaccineDateLastDosage
                    createdAt
                    updatedAt
                }
                femaleReproductiveHealth{
                    id
                    patientId
                    currentlyOnBirthControl
                    birthControlType
                    timeOnBirthControl
                    dateOfLastPeriod
                    currentlyPregnant
                    tryingToConceive
                    currentMenstrualStatus
                    priorPregnancies
                    priorPregnancy{
                        id
                        femaleReproductiveHealthId
                        count
                        numberOfChildren
                        numberOfMiscarriages
                        numberOfAbortions
                        createdAt
                        updatedAt
                    }
                    createdAt
                    updatedAt
                }
                medicalHx{
                    id
                    patientId
                    hasChronicCondition
                    chronicConditions
                    chronicConditionDiagnosisDates
                    takingMedications
                    currentMedications
                    hadSurgeries
                    surgeries
                    beenHospitalized
                    hospitalizations
                    hadIssuesWithBloodDraw
                    bloodDrawIssues
                    createdAt
                    updatedAt
                }
                patientBehaviorInfo{
                    id
                    patientId
                    diet
                    physicallyActive
                    smoker
                    smokingFrequency
                    alcohol
                    alcoholFrequency
                    createdAt
                    updatedAt
                }
                patientContactInfo{
                    id
                    patientId
                    email
                    phoneNumber
                    streetAddress
                    city
                    state
                    zip
                    createdAt
                    updatedAt
                }
            }
            packages{
                id
                name
                version
                description
                panels{
                    id
                    name
                    description
                    questTestCode
                    frequencyPerYear
                    modeOfAcquisition
                    questPrice
                    sex
                    biomarkers{
                        id
                        categories{
                            id
                        }
                        name
                        questBiomarkerCode
                        modeOfAcquisition
                        measurementUnits
                        resultDataType
                        sexDetails{
                            id
                            biomarkerId
                            sex
                            whyItMatters
                            oneLineDescription
                            questRefRangeLow
                            questRefRangeHigh
                            optimalRangeLow
                            optimalRangeHigh
                            crossReferenceBiomarkers
                            notes
                            resourcesCited
                            recommendations{
                                id
                                name
                                biomarkerId
                                sex
                                inRange
                                outOfRangeType
                                summaryDescription
                                causesDescription
                                symptomsDescription
                                foodsToEatDescription
                                foodsToAvoidDescription
                                supplementsDescription
                                selfCareDescription
                                additionalTestsDescription
                                followUpsDescription
                            }
                            critical{
                                id
                                sexSpecificId
                                isPriority1
                                priority1Range
                                isPriority2
                                priority2Range
                            }
                        }
                    }
                }
            }
            biomarkerResults{
                id
                biomarker{
                    id
                    categories{
                        id
                    }
                    name
                    questBiomarkerCode
                    modeOfAcquisition
                    measurementUnits
                    resultDataType
                    sexDetails{
                        id
                        biomarkerId
                        sex
                        whyItMatters
                        oneLineDescription
                        questRefRangeLow
                        questRefRangeHigh
                        optimalRangeLow
                        optimalRangeHigh
                        crossReferenceBiomarkers
                        notes
                        resourcesCited
                        recommendations{
                            id
                            name
                            biomarkerId
                            sex
                            inRange
                            outOfRangeType
                            summaryDescription
                            causesDescription
                            symptomsDescription
                            foodsToEatDescription
                            foodsToAvoidDescription
                            supplementsDescription
                            selfCareDescription
                            additionalTestsDescription
                            followUpsDescription
                        }
                        critical{
                            id
                            sexSpecificId
                            isPriority1
                            priority1Range
                            isPriority2
                            priority2Range
                        }
                    }
                }
                questBiomarkerId
                biomarkerName
                orderingPhysician{
                    id
                    email
                    phoneNumber
                    isActive
                    isSuperuser
                    isPhysician
                    fname
                    lname
                    npi
                    licenseState
                    isNursePracticioner
                }
                dateOfService
                collectionSite
                testResult
                measurementUnits
                testResultOutOfRange
                questReferenceRange
            }
        }
    }
}
`;
export const GET_REQUISITION_BY_ID_QUERY = gql`
query requisitionById($id: UUID!) {
    requisitionById(id: $id){
        id
        patientId
        requisitionState
        confirmation
        physicianNotes
        patientNotes
        referringPhysicianId
        reviewingPhysicianId
        reviewed
        dateTimeConfirmed
        dateTimeReviewed
        readyForConfirmation
        allResultsAvailable
        patient{
            id
            fname
            lname
            patientContactInfo{
                email
            }
        }
        referringPhysician{
            id
            email
            isActive
            isPhysician
            fname
            lname
            licenseState
        }
        reviewingPhysician{
            id
            email
            isActive
            isPhysician
            fname
            lname
            licenseState
        }
        visits{
            id
            patientId
            visitDate
            streetAddress
            confirmationCode
            documents
            city
            state
            zip
            resultsAvailable
            patient{
              fname
              lname
            }
            biomarkerResults{
              id
              biomarker {
                name
              }
            }
            criticalResult {
                id
                criticalReviewed
            }
        }
        requisitionComments {
            id
            comment
            updatedAt
            createdAt
            creatorId
        }
        listOfPhysicianNotes{
          id
          content
          category {
            id
            categoryName
          }
          user {
            id
            fname
            lname
          }
          createdAt
          updatedAt
        }
    }
}
`;

export const GET_AVAILABLE_REQUISITIONS_TO_REVIEW_QUERY = gql`
query requisitionByStateIsConfirmed($skip: Int, $limit: Int) {
    requisitionByStateIsConfirmed(skip: $skip, limit: $limit){
        id
        patient{
            fname
            lname
            patientIdentifier
            preferredName
        }
        visits {
            visitDate
            criticalResult {
                criticalReviewed
                level
            }
        }
    }
}
`;

// TODO- allResultsAvailable
//         reviewed
// allResultsAvailable
// reviewed


export const GET_REQUISITION_TO_REVIEW_BY_ID_QUERY = gql`
query requisitionByStateIsConfirmedById($id: UUID!) {
    requisitionByStateIsConfirmedById(id: $id){
        id
        reviewed
        patient{
            fname
            lname
            patientIdentifier
            preferredName
            biologicalSex
            dob
            femaleReproductiveHealth{
                currentlyOnBirthControl
                birthControlType
                timeOnBirthControl
                dateOfLastPeriod
                currentlyPregnant
                tryingToConceive
                currentMenstrualStatus
                priorPregnancies
                priorPregnancy{
                    femaleReproductiveHealthId
                    count
                    numberOfChildren
                    numberOfMiscarriages
                    numberOfAbortions
                }
            }
            medicalHx{
                hasChronicCondition
                chronicConditions
                chronicConditionDiagnosisDates
                takingMedications
                currentMedications
                hadSurgeries
                surgeries
                beenHospitalized
                hospitalizations
                hadIssuesWithBloodDraw
                bloodDrawIssues
            }
            patientBehaviorInfo{
                diet
                physicallyActive
                smoker
                smokingFrequency
                alcohol
                alcoholFrequency
            }
            patientContactInfo{
                email
                phoneNumber
                streetAddress
                city
                state
                zip
            }
        }
        physicianNotes
        patientNotes
        visits{
            visitDate
            biomarkerResults{
                id
                biomarker{
                    id
                    name
                    questBiomarkerCode
                    measurementUnits
                    sexDetails{
                        id
                        biomarkerId
                        sex
                        questRefRangeLow
                        questRefRangeHigh
                        optimalRangeLow
                        optimalRangeHigh
                        critical{
                            id
                            sexSpecificId
                            isPriority1
                            priority1Range
                            isPriority2
                            priority2Range
                        }
                    }
                }
                questBiomarkerId
                biomarkerName
                dateOfService
                collectionSite
                testResult
                measurementUnits
                testResultOutOfRange
                questReferenceRange
            }
            criticalResult {
                id
                criticalReviewed
            }
        }
        requisitionComments {
            id
            comment
            updatedAt
            createdAt
            creatorId
        }
    }
}
`;

export const GET_REQUISITION_RESULTS_QUERY = gql`
query requisitionByStateIsConfirmed($skip: Int, $limit: Int) {
    requisitionByStateIsConfirmed(skip: $skip, limit: $limit){
        id
        physicianNotes
        patientNotes
        patient{
            patientIdentifier
            fname
            lname
            preferredName
            biologicalSex
            dob
            femaleReproductiveHealth{
                currentlyOnBirthControl
                birthControlType
                timeOnBirthControl
                dateOfLastPeriod
                currentlyPregnant
                tryingToConceive
                currentMenstrualStatus
                priorPregnancies
                priorPregnancy{
                    femaleReproductiveHealthId
                    count
                    numberOfChildren
                    numberOfMiscarriages
                    numberOfAbortions
                }
            }
            medicalHx{
                hasChronicCondition
                chronicConditions
                chronicConditionDiagnosisDates
                takingMedications
                currentMedications
                hadSurgeries
                surgeries
                beenHospitalized
                hospitalizations
                hadIssuesWithBloodDraw
                bloodDrawIssues
            }
            patientBehaviorInfo{
                diet
                physicallyActive
                smoker
                smokingFrequency
                alcohol
                alcoholFrequency
            }
            patientContactInfo{
                email
                phoneNumber
                streetAddress
                city
                state
                zip
            }
        }
        visits{
            biomarkerResults{
                id
                biomarker{
                    id
                    categories{
                        id
                    }
                    name
                    questBiomarkerCode
                    measurementUnits
                    sexDetails{
                        id
                        biomarkerId
                        sex
                        questRefRangeLow
                        questRefRangeHigh
                        optimalRangeLow
                        optimalRangeHigh
                        recommendations{
                            id
                            name
                            biomarkerId
                            sex
                            inRange
                            outOfRangeType
                        }
                        critical{
                            id
                            sexSpecificId
                            isPriority1
                            priority1Range
                            isPriority2
                            priority2Range
                        }
                    }
                }
                questBiomarkerId
                biomarkerName
                dateOfService
                collectionSite
                testResult
                measurementUnits
                testResultOutOfRange
                questReferenceRange
            }
        }
    }
}
`;

export const GET_REQUISITIONS_BY_STATE_QUERY = gql`
query requisitionByState($skip: Int, $limit: Int) {
    requisitionByState(skip: $skip, limit: $limit){
        id
        patient{
            patientIdentifier
            fname
            lname
            preferredName
            biologicalSex
            dob
            pronouns
            ethnicOrigin
            shirtSize
            femaleReproductiveHealth{
                currentlyOnBirthControl
                birthControlType
                timeOnBirthControl
                dateOfLastPeriod
                currentlyPregnant
                tryingToConceive
                currentMenstrualStatus
                priorPregnancies
                priorPregnancy{
                    femaleReproductiveHealthId
                    count
                    numberOfChildren
                    numberOfMiscarriages
                    numberOfAbortions
                }
            }
            medicalHx{
                hasChronicCondition
                chronicConditions
                chronicConditionDiagnosisDates
                takingMedications
                currentMedications
                hadSurgeries
                surgeries
                beenHospitalized
                hospitalizations
                hadIssuesWithBloodDraw
                bloodDrawIssues
            }
            patientBehaviorInfo{
                diet
                physicallyActive
                smoker
                smokingFrequency
                alcohol
                alcoholFrequency
            }
            patientContactInfo{
                email
                phoneNumber
                streetAddress
                city
                state
                zip
            }
        }
        visits{
            packages{
                panels{
                    name
                }
            }
        }
    }
}
`;

export const GET_REQUISITION_BY_PATIENT_ID_QUERY = gql`
query requisitionByPatientId($patientId: UUID!, $skip: Int, $limit: Int) {
    requisitionByPatientId(patientId: $patientId, skip: $skip, limit: $limit){
        id
        patientId
        requisitionState
        confirmation
        physicianNotes
        patientNotes
        referringPhysicianId
        reviewingPhysicianId
        reviewed
        dateTimeConfirmed
        dateTimeReviewed
        readyForConfirmation
        allResultsAvailable
        patient{
            id
            patientIdentifier
            fname
            lname
            preferredName
            biologicalSex
            dob
            ssn
            pronouns
            ethnicOrigin
            shirtSize
            createdAt
            updatedAt
            motivational{
                id
                patientId
                tenYearHealthGoals
                purposeForJoiningFunction
                howDidTheyHearAboutFunction
                commentsBeforeBooking
                createdAt
                updatedAt
            }
            covid{
                id
                patientId
                hadCovid
                covidDate
                covidSeverity
                hasCovidResidualSymptoms
                covidResidualSymptoms
                vaccinatedForCovid
                covidVaccineName
                covidVaccineDateLastDosage
                createdAt
                updatedAt
            }
            femaleReproductiveHealth{
                id
                patientId
                currentlyOnBirthControl
                birthControlType
                timeOnBirthControl
                dateOfLastPeriod
                currentlyPregnant
                tryingToConceive
                currentMenstrualStatus
                priorPregnancies
                priorPregnancy{
                    id
                    femaleReproductiveHealthId
                    count
                    numberOfChildren
                    numberOfMiscarriages
                    numberOfAbortions
                    createdAt
                    updatedAt
                }
                createdAt
                updatedAt
            }
            medicalHx{
                id
                patientId
                hasChronicCondition
                chronicConditions
                chronicConditionDiagnosisDates
                takingMedications
                currentMedications
                hadSurgeries
                surgeries
                beenHospitalized
                hospitalizations
                hadIssuesWithBloodDraw
                bloodDrawIssues
                createdAt
                updatedAt
            }
            patientBehaviorInfo{
                id
                patientId
                diet
                physicallyActive
                smoker
                smokingFrequency
                alcohol
                alcoholFrequency
                createdAt
                updatedAt
            }
            patientContactInfo{
                id
                patientId
                email
                phoneNumber
                streetAddress
                city
                state
                zip
                createdAt
                updatedAt
            }
        }
        referringPhysician{
            id
            email
            phoneNumber
            isActive
            isSuperuser
            isPhysician
            fname
            lname
            npi
            licenseState
            isNursePracticioner
            isReviewer
        }
        reviewingPhysician{
            id
            email
            phoneNumber
            isActive
            isSuperuser
            isPhysician
            fname
            lname
            npi
            licenseState
            isNursePracticioner
            isReviewer
        }
        visits{
            id
            patientId
            visitDate
            streetAddress
            city
            state
            zip
            resultsAvailable
            confirmationCode
            documents
            patient{
                id
                patientIdentifier
                fname
                lname
                preferredName
                biologicalSex
                dob
                ssn
                pronouns
                ethnicOrigin
                shirtSize
                createdAt
                updatedAt
                motivational{
                    id
                    patientId
                    tenYearHealthGoals
                    purposeForJoiningFunction
                    howDidTheyHearAboutFunction
                    commentsBeforeBooking
                    createdAt
                    updatedAt
                }
                covid{
                    id
                    patientId
                    hadCovid
                    covidDate
                    covidSeverity
                    hasCovidResidualSymptoms
                    covidResidualSymptoms
                    vaccinatedForCovid
                    covidVaccineName
                    covidVaccineDateLastDosage
                    createdAt
                    updatedAt
                }
                femaleReproductiveHealth{
                    id
                    patientId
                    currentlyOnBirthControl
                    birthControlType
                    timeOnBirthControl
                    dateOfLastPeriod
                    currentlyPregnant
                    tryingToConceive
                    currentMenstrualStatus
                    priorPregnancies
                    priorPregnancy{
                        id
                        femaleReproductiveHealthId
                        count
                        numberOfChildren
                        numberOfMiscarriages
                        numberOfAbortions
                        createdAt
                        updatedAt
                    }
                    createdAt
                    updatedAt
                }
                medicalHx{
                    id
                    patientId
                    hasChronicCondition
                    chronicConditions
                    chronicConditionDiagnosisDates
                    takingMedications
                    currentMedications
                    hadSurgeries
                    surgeries
                    beenHospitalized
                    hospitalizations
                    hadIssuesWithBloodDraw
                    bloodDrawIssues
                    createdAt
                    updatedAt
                }
                patientBehaviorInfo{
                    id
                    patientId
                    diet
                    physicallyActive
                    smoker
                    smokingFrequency
                    alcohol
                    alcoholFrequency
                    createdAt
                    updatedAt
                }
                patientContactInfo{
                    id
                    patientId
                    email
                    phoneNumber
                    streetAddress
                    city
                    state
                    zip
                    createdAt
                    updatedAt
                }
            }
            packages{
                id
                name
                version
                description
                panels{
                    id
                    name
                    description
                    questTestCode
                    frequencyPerYear
                    modeOfAcquisition
                    questPrice
                    sex
                    biomarkers{
                        id
                        categories{
                            id
                        }
                        name
                        questBiomarkerCode
                        modeOfAcquisition
                        measurementUnits
                        resultDataType
                        sexDetails{
                            id
                            biomarkerId
                            sex
                            whyItMatters
                            oneLineDescription
                            questRefRangeLow
                            questRefRangeHigh
                            optimalRangeLow
                            optimalRangeHigh
                            crossReferenceBiomarkers
                            notes
                            resourcesCited
                            recommendations{
                                id
                                name
                                biomarkerId
                                sex
                                inRange
                                outOfRangeType
                                summaryDescription
                                causesDescription
                                symptomsDescription
                                foodsToEatDescription
                                foodsToAvoidDescription
                                supplementsDescription
                                selfCareDescription
                                additionalTestsDescription
                                followUpsDescription
                            }
                            critical{
                                id
                                sexSpecificId
                                isPriority1
                                priority1Range
                                isPriority2
                                priority2Range
                            }
                        }
                    }
                }
            }
            biomarkerResults{
                id
                biomarker{
                    id
                    categories{
                        id
                    }
                    name
                    questBiomarkerCode
                    modeOfAcquisition
                    measurementUnits
                    resultDataType
                    sexDetails{
                        id
                        biomarkerId
                        sex
                        whyItMatters
                        oneLineDescription
                        questRefRangeLow
                        questRefRangeHigh
                        optimalRangeLow
                        optimalRangeHigh
                        crossReferenceBiomarkers
                        notes
                        resourcesCited
                        recommendations{
                            id
                            name
                            biomarkerId
                            sex
                            inRange
                            outOfRangeType
                            summaryDescription
                            causesDescription
                            symptomsDescription
                            foodsToEatDescription
                            foodsToAvoidDescription
                            supplementsDescription
                            selfCareDescription
                            additionalTestsDescription
                            followUpsDescription
                        }
                        critical{
                            id
                            sexSpecificId
                            isPriority1
                            priority1Range
                            isPriority2
                            priority2Range
                        }
                    }
                }
                questBiomarkerId
                biomarkerName
                orderingPhysician{
                    id
                    email
                    phoneNumber
                    isActive
                    isSuperuser
                    isPhysician
                    fname
                    lname
                    npi
                    licenseState
                    isNursePracticioner
                }
                dateOfService
                collectionSite
                testResult
                measurementUnits
                testResultOutOfRange
                questReferenceRange
            }
        }
    }
}
`;

export const REVIEWER_REQUISITIONS_QUERY = gql`
query requisitionReviewer($skip: Int!, $limit: Int!) {
    requisitionReviewer(skip: $skip, limit: $limit){
        id
        patient{
            dob
            biologicalSex
        }
    }
}
`;

export const REVIEWER_REQUISITIONS_BY_ID_QUERY = gql`
query requisitionReviewer($skip: Int!, $limit: Int!, $filters: CamelCaseJSON) {
    requisitionReviewer(skip: $skip, limit: $limit, filters: $filters){
        id
        physicianNotes
        patient{
            dob
            biologicalSex
            pronouns
            motivational{
                tenYearHealthGoals
                purposeForJoiningFunction
                howDidTheyHearAboutFunction
                commentsBeforeBooking
            }
            femaleReproductiveHealth{
                currentlyOnBirthControl
                birthControlType
                timeOnBirthControl
                dateOfLastPeriod
                currentlyPregnant
                tryingToConceive
                currentMenstrualStatus
                priorPregnancies
                priorPregnancy{
                    femaleReproductiveHealthId
                    count
                    numberOfChildren
                    numberOfMiscarriages
                    numberOfAbortions
                    createdAt
                    updatedAt
                }
            }
            medicalHx{
                hasChronicCondition
                chronicConditions
                chronicConditionDiagnosisDates
                takingMedications
                currentMedications
                hadSurgeries
                surgeries
                beenHospitalized
                hospitalizations
                hadIssuesWithBloodDraw
                bloodDrawIssues
            }
            patientBehaviorInfo{
                diet
                physicallyActive
                smoker
                smokingFrequency
                alcohol
                alcoholFrequency
            }
        }
        visits{
            biomarkerResults{
                id
                biomarker{
                    id
                    categories{
                        id
                    }
                    name
                    questBiomarkerCode
                    measurementUnits
                    sexDetails{
                        id
                        sex
                        questRefRangeLow
                        questRefRangeHigh
                        optimalRangeLow
                        optimalRangeHigh
                        critical{
                            id
                            sexSpecificId
                            isPriority1
                            priority1Range
                            isPriority2
                            priority2Range
                        }
                    }
                }
                questBiomarkerId
                biomarkerName
                dateOfService
                collectionSite
                testResult
                measurementUnits
                testResultOutOfRange
                questReferenceRange
            }
        }
    }
}
`;

