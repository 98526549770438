import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';

Vue.config.productionTip = false;

const opts = {
    theme: {
      themes: {
        old: {
          primary: '#6E90FF',
          secondary: '#586FB9',
          tertiary: '#B1BBF5',
          accent: '#CD2553',
          darkPrimary: '#23315C',
          background: '#FEF9EF',
        },
        light: {
          primary: '#C07858',
          secondary: '#A9B4AC',
          tertiary: '#3C3D42',
          accent: '#887677',
          background: '#FFFFFF',
          card: '#FFFFFF',
          nav: '#FEF9EF',
          scrollbar: '#FEF9EF',
          sidebar: '#FFFFFF',
          header: '#A9B4AC',
          subHeader: '#3C3D42',
          links: '#887677',
          button: '#C07858',
          text: '#3C3D42',
          error: '#ff4469',

          // #E0DCD3, #838C8B, #FFFFFF, #FEF9EF, #3C3D42, #C07858, #A9B4AC, #887677, #616867, #DBC486
        },
      },
    },
  };

Vue.use(Vuetify, {
  icons: {
    iconfont: 'md',
  },
});

export default new Vuetify(opts);
