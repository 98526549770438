import gql from 'graphql-tag';

export const CREATE_BIOMARKER_MUTATION = gql`
  mutation createBiomarker($biomarkerInput: BiomarkerCreate!) {
    createBiomarker(biomarkerInput: $biomarkerInput) {
      id
      categories {
        id
        categoryName
      }
      name
      questBiomarkerCode
      modeOfAcquisition
      measurementUnits
      resultDataType
      sexDetails {
        id
        biomarkerId
        sex
        whyItMatters
        oneLineDescription
        questRefRangeLow
        questRefRangeHigh
        optimalRangeLow
        optimalRangeHigh
        crossReferenceBiomarkers
        notes
        resourcesCited
        recommendations {
          id
          name
          biomarkerId
          sex
          inRange
          outOfRangeType
          summaryDescription
          causesDescription
          symptomsDescription
          foodsToEatDescription
          foodsToAvoidDescription
          supplementsDescription
          selfCareDescription
          additionalTestsDescription
          followUpsDescription
        }
        critical {
          id
          sexSpecificId
          isPriority1
          priority1Range
          isPriority2
          priority2Range
        }
      }
    }
  }
`;

export const UPDATE_BIOMARKER_MUTATION = gql`
  mutation updateBiomarker($biomarkerInput: BiomarkerUpdate!, $id: UUID!) {
    updateBiomarker(biomarkerInput: $biomarkerInput, id: $id) {
      id
      categories {
        id
        categoryName
      }
      name
      questBiomarkerCode
      modeOfAcquisition
      measurementUnits
      resultDataType
      sexDetails {
        id
        biomarkerId
        sex
        whyItMatters
        oneLineDescription
        questRefRangeLow
        questRefRangeHigh
        optimalRangeLow
        optimalRangeHigh
        crossReferenceBiomarkers
        notes
        resourcesCited
        recommendations {
          id
          name
          biomarkerId
          sex
          inRange
          outOfRangeType
          summaryDescription
          causesDescription
          symptomsDescription
          foodsToEatDescription
          foodsToAvoidDescription
          supplementsDescription
          selfCareDescription
          additionalTestsDescription
          followUpsDescription
        }
        critical {
          id
          sexSpecificId
          isPriority1
          priority1Range
          isPriority2
          priority2Range
        }
      }
    }
  }
`;

export const DELETE_BIOMARKER_MUTATION = gql`
  mutation deleteBiomarker($id: UUID!) {
    deleteBiomarker(id: $id)
  }
`;

export const CREATE_CRITICAL_MUTATION = gql`
  mutation createCritical($criticalInput: CriticalCreate!) {
    createCritical(criticalInput: $criticalInput) {
      id
      sexSpecificId
      isPriority1
      priority1Range
      isPriority2
      priority2Range
    }
  }
`;

export const UPDATE_CRITICAL_MUTATION = gql`
  mutation updateCritical($criticalInput: CriticalUpdate!, $id: UUID!) {
    updateCritical(criticalInput: $criticalInput, id: $id) {
      id
      sexSpecificId
      isPriority1
      priority1Range
      isPriority2
      priority2Range
    }
  }
`;

export const CREATE_SEX_SPECIFIC_MUTATION = gql`
  mutation createSexSpecific($sexSpecificInput: SexSpecificCreate!) {
    createSexSpecific(sexSpecificInput: $sexSpecificInput) {
      id
      biomarkerId
      sex
      whyItMatters
      oneLineDescription
      questRefRangeLow
      questRefRangeHigh
      optimalRangeLow
      optimalRangeHigh
      crossReferenceBiomarkers
      notes
      resourcesCited
      recommendations {
        id
        name
        biomarkerId
        sex
        inRange
        outOfRangeType
        summaryDescription
        causesDescription
        symptomsDescription
        foodsToEatDescription
        foodsToAvoidDescription
        supplementsDescription
        selfCareDescription
        additionalTestsDescription
        followUpsDescription
      }
      critical {
        id
        sexSpecificId
        isPriority1
        priority1Range
        isPriority2
        priority2Range
      }
    }
  }
`;

export const UPDATE_SEX_SPECIFIC_MUTATION = gql`
  mutation updateSexSpecific($id: UUID!, $sexSpecificInput: SexSpecificUpdate!) {
    updateSexSpecific(id: $id, sexSpecificInput: $sexSpecificInput) {
      id
      biomarkerId
      sex
      whyItMatters
      oneLineDescription
      questRefRangeLow
      questRefRangeHigh
      optimalRangeLow
      optimalRangeHigh
      crossReferenceBiomarkers
      notes
      resourcesCited
      recommendations {
        id
        name
        biomarkerId
        sex
        inRange
        outOfRangeType
        summaryDescription
        causesDescription
        symptomsDescription
        foodsToEatDescription
        foodsToAvoidDescription
        supplementsDescription
        selfCareDescription
        additionalTestsDescription
        followUpsDescription
      }
      critical {
        id
        sexSpecificId
        isPriority1
        priority1Range
        isPriority2
        priority2Range
      }
    }
  }
`;

export const DELETE_SEX_SPECIFIC_MUTATION = gql`
  mutation deleteSexSpecific($id: UUID!) {
    deleteSexSpecific(id: $id)
  }
`;
