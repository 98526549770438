const env = process.env.VUE_APP_ENV;

let envApiUrl = '';
let envGraphQl = '';
let envDocumentUploadServiceUrl = '';
let envTimezoneServiceUrl = '';
let membersCrmServiceUrl = '';
let accessCodeServiceUrl = '';

envApiUrl = `https://${process.env.VUE_APP_DOMAIN}`;
envGraphQl = `https://${process.env.VUE_APP_DOMAIN}/graphql`;
envDocumentUploadServiceUrl = `https://${process.env.VUE_APP_DOCUMENT_UPLOAD_SERVICE_URL}`;
envTimezoneServiceUrl = `https://${process.env.VUE_APP_TIMEZONE_SERVICE_URL}`;
membersCrmServiceUrl = `https://${process.env.VUE_APP_MEMBERS_CRM_URL}`;
accessCodeServiceUrl = `https://${process.env.VUE_APP_ACCESS_CODE_SERVICE_URL}`;

export const apiUrl = envApiUrl;
export const graphQlApiUrl = envGraphQl;
export const appName = process.env.VUE_APP_NAME;
export const appVersion = process.env.VUE_APP_ADMIN_APP_VERSION;
export const documentUploadServiceUrl = envDocumentUploadServiceUrl;
export const timezoneServiceUrl = envTimezoneServiceUrl;
export const membersCrmUrl = membersCrmServiceUrl;
export const accessCodeUrl = accessCodeServiceUrl;
export const accessCodeEnabled = process.env.VUE_APP_ACCESS_CODE_FEATURE && process.env.VUE_APP_ACCESS_CODE_FEATURE === 'true';
