import {ActionContext} from 'vuex';
import {CrudState} from '@/store/crud/state';
import {State} from '@/store/state';
import {commitSetAccessCodes} from '@/store/crud/mutations';
import {commitAddNotification} from '@/store/main/mutations';
import {api} from '@/api';
import {IAccessCode} from '@/interfaces/accessCode';
import {orderBy} from 'lodash';

type MainContext = ActionContext<CrudState, State>;
export default {
  async getAccessCodes(context: MainContext) {
    try {
      const {data} = await api.getAccessCodes();
      const codes = data.data;
      const total = data.total;
      const sortedData = orderBy(codes, ['id'], ['desc']);
      commitSetAccessCodes(context, sortedData);
      return data;
    } catch (e) {
      commitAddNotification(context, {
        content: 'Failed while fetching access codes',
        color: 'error',
      });
    }
  },
  async generateAccessCode(
    context: MainContext,
    payload: {accessCode: Partial<IAccessCode>},
  ) {
    try {
      const {data} = await api.generateCustomAccessCode(payload.accessCode);
      const response = data[0];
      if (response.status === 'Failure') {
        throw new Error(response.message);
      }
      commitAddNotification(context, {content: 'Access code was created', color: 'success'});
      return data;
    } catch (e) {
      const isAlreadyExists = e.message.includes('already exists');
      commitAddNotification(context, {
        content: isAlreadyExists ? 'Access code already exists' : 'Failed while generating access code',
        color: 'error',
      });
      return null;
    }
  },
  async getAccessCodeLogs(context: MainContext, payload: {accessCode: IAccessCode}) {
    try {
      const {data} = await api.getAccessCodeLogs(payload.accessCode.access_code);
      return data;
    } catch (e) {
      commitAddNotification(context, {
        content: 'Failed while fetching access code logs',
        color: 'error',
      });
    }
  },
};
