import { ActionContext } from 'vuex';
import { CrudState } from '@/store/crud/state';
import { State } from '@/store/state';
import { commitDeletePhysicianNote, commitSetPhysicianNote, commitSetPhysicianNotes } from '@/store/crud/mutations';
import { INote, INoteInput } from '@/interfaces/notes';
import { graphQlApi } from '@/graphql/graphqlApi';
import { commitAddNotification } from '@/store/main/mutations';

type MainContext = ActionContext<CrudState, State>;
export default {
  async getNotes(context: MainContext, requisitionId: string) {
    try {
      const { data, errors } = (await graphQlApi.getPhysicianNotes([requisitionId], 'no-cache'));
      const notes: INote[] = data.physicianNotes;
      if (errors) {
        throw new Error();
      }
      commitSetPhysicianNotes(context, notes);
      return notes;
    } catch (e) {
      commitAddNotification(context, { content: 'Failed while fetching notes', color: 'error' });
    }
  },
  async createNote(context: MainContext, { requisitionId, content, categoryId }: INoteInput) {
    try {
      const { data, errors } = (await graphQlApi.createPhysicianNote({ requisitionId, content, categoryId }));
      if (errors) {
        throw new Error();
      }
      if (data) {
        commitSetPhysicianNote(context, data);
        return data;
      }
      commitAddNotification(context, { content: 'Can`t create a new note', color: 'error' });
      return Promise.reject();
    } catch (e) {
      commitAddNotification(context, { content: 'Failed while creating a new note', color: 'error' });
      return Promise.reject();
    }
  },
  async updateNote(context: MainContext, { requisitionId, content, categoryId, id }: INoteInput) {
    try {
      if (id) {
        const { data, errors } = (await graphQlApi.updatePhysicianNote(id, {
          requisitionId,
          content,
          categoryId,
        }));
        if (errors) {
          throw new Error();
        }
        if (data) {
          commitSetPhysicianNote(context, data);
          return data;
        }
        commitAddNotification(context, { content: 'Can`t update a note', color: 'error' });
        return Promise.reject();
      }
    } catch (e) {
      commitAddNotification(context, { content: 'Failed while updating a note', color: 'error' });
      return Promise.reject();
    }
  },
  async deleteNote(context: MainContext, id: string) {
    try {
      if (id) {
        const { data, errors } = (await graphQlApi.deletePhysicianNote(id));
        if (errors) {
          throw new Error();
        }
        if (data) {
          commitDeletePhysicianNote(context, id);
          return data;
        }
        commitAddNotification(context, { content: 'Can`t delete a note', color: 'error' });
        return Promise.reject();
      }
    } catch (e) {
      commitAddNotification(context, { content: 'Failed while deleting a note', color: 'error' });
      return Promise.reject();
    }
  },
};
