import { ActionContext } from 'vuex';

import * as mutations from '@/store/crud/mutations';

import { CrudState } from '@/store/crud/state';

import { State } from '@/store/state';
import { commitAddNotification } from '@/store/main/mutations';
import { dispatchCheckApiError } from '@/store/main/actions';

import { graphQlApi } from '@/graphql/graphqlApi';
import { crudModules } from '@/constants/globalConstants';
import { ICause, ICreateCause, IUpdateCause } from '@/interfaces/causes';


type MainContext = ActionContext<CrudState, State>;


/**
 * Returns cached data
 * or false if it is expired
 */
const getCachedData = async (context: MainContext, needsDataUpgrade: boolean) => {

    const cachedData = context.getters.causes;
    const dispatchAction = 'causesDataExpired';

    if (cachedData && cachedData.length > 0) {
        const dataExpired = await context.dispatch(dispatchAction);
        // check data expiration
        if (dataExpired) {
            // re run this action without cached data
            return false; // data is expired so we need to fetch new data
        } else {
            if (needsDataUpgrade) {
                if (context.getters.needsDataUpgrade(crudModules.CAUSES)) {
                    return false;
                }
            }
            return cachedData;
        }
    } else {
        return false;
    }
};

/**
 * Actions sub module for Exercises actions
 */
export default {
    /**
     * Gets all available causes with minimal information to be used in lists and dropdowns
     * @param context - Vuex Action context
     * @returns - Promise causes or false if error
     */
    async actionGetCausesForList(context: MainContext) {
        try {
            // check cached data
            const cachedData = await getCachedData(context, false);
            if (cachedData) {
                return cachedData;
            }
            const response = await graphQlApi.getAllCausesForList();
            if (response) {
                mutations.commitSetCauses(context, response.data.causes);
                mutations.setModuleNeedsDataUpgrade(context, {
                    module: crudModules.CAUSES,
                    needsDataUpgrade: true,
                });
                mutations.setDataUpdated(context, crudModules.CAUSES);
                return Promise.resolve(response.data.causes);
            } else {
                throw new Error('Failed while fetching causes');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Error while fetching causes', color: 'error' });
            return Promise.resolve(false);
        }
    },
    /**
     * Gets all available causes with all available data
     * @param context - Vuex Action context
     * @returns - Promise causes or false if error
     */
    async actionGetAllCauses(context: MainContext) {
        try {
            // check cached data
            const cachedData = await getCachedData(context, true);
            if (cachedData) {
                return cachedData;
            }

            const response = await graphQlApi.getAllCauses();
            if (response) {
                mutations.commitSetCauses(context, response.data.causes);
                mutations.setModuleNeedsDataUpgrade(context, {
                    module: crudModules.CAUSES,
                    needsDataUpgrade: false,
                });
                mutations.setDataUpdated(context, crudModules.CAUSES);
                return Promise.resolve(response.data.causes);
            } else {
                throw new Error('Failed while fetching causes');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Error while fetching causes', color: 'error' });
            return Promise.resolve(false);
        }
    },
    /**
     * Gets a single cause from the database
     * @param context - Vuex Action context
     * @param payload - causeId -> cause to get
     * @returns - Promise with cause or false if error
     */
    async actionGetCauseById(context: MainContext, id: string) {
        try {
            const response = await graphQlApi.getCauseById(id);
            if (response) {
                mutations.commitSetCause(context, response.data.causeById);
                return Promise.resolve(response.data.causeById);
            } else {
                throw new Error('Failed while fetching cause');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Error while fetching cause', color: 'error' });
            return Promise.resolve(false);
        }
    },
    /**
     * Updates a single cause in the DB
     * @param context - Vuex Action context
     * @param payload -
     *  - causeId -> cause to update
     *  - cause -> cause data to update
     * @returns - Promise with updated cause or false if error
     */
    async actionUpdateCause(context: MainContext, payload: { causeId: string, cause: IUpdateCause }) {
        const loadingNotification = { content: 'saving', color: 'info' };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                graphQlApi.updateCause(payload.causeId, payload.cause),
                await new Promise<void>((resolve) => setTimeout(() => resolve(), 500)),
            ]))[0];
            if (response) {
                commitAddNotification(context, { content: 'cause successfully updated', color: 'success' });
                mutations.commitSetCause(context, response.data.updateCause);
                return Promise.resolve(response.data.updateCause);
            } else {
                throw new Error('Failed while updating cause');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Failed while updating cause', color: 'error' });
            return Promise.resolve(false);
        }
    },
    /**
     * Creates a new cause in the DB
     * @param context - Vuex Action context
     * @param payload - cause to add
     * - ICreatCause - cause data to add
     * @returns - Promise with added cause or false if error
     */
    async actionCreateCause(context: MainContext, payload: ICreateCause) {
        const loadingNotification = { content: 'saving', color: 'info' };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                graphQlApi.createCause(payload),
                await new Promise<void>((resolve) => setTimeout(() => resolve(), 500)),
            ]))[0];
            if (response) {
                commitAddNotification(context, { content: 'Cause successfully created', color: 'success' });
                mutations.commitSetCause(context, response.data.createCause);
                return Promise.resolve(response.data.createCause);
            } else {
                throw new Error('Failed while creating cause');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Failed while creating cause', color: 'error' });
            return Promise.resolve(false);
        }
    },
    /**
     * Deletes a single cause from the DB
     * @param context - Vuex Action context
     * @param payload - cause to remove
     * - causeId -> cause to remove
     * @returns - Promise with removed cause or false if error
     */
    async actionDeleteCause(context: MainContext, payload: { causeId: string }) {
        const loadingNotification = { content: 'saving', color: 'info' };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                graphQlApi.deleteCause(payload.causeId),
                await new Promise<void>((resolve) => setTimeout(() => resolve(), 500)),
            ]))[0];
            if (response) {
                commitAddNotification(context, { content: 'Cause successfully deleted', color: 'success' });
                mutations.commitDeleteCause(context, {id: payload.causeId} as ICause);
                return Promise.resolve(response.data.deleteCause);
            } else {
                throw new Error('Failed while deleting cause');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Failed while removing cause', color: 'error' });
            return Promise.resolve(false);
        }
    },
};

