import { ActionContext } from 'vuex';

import * as mutations from '@/store/crud/mutations';

import { CrudState } from '@/store/crud/state';

import { State } from '@/store/state';
import { commitAddNotification } from '@/store/main/mutations';
import { dispatchCheckApiError } from '@/store/main/actions';

import { graphQlApi } from '@/graphql/graphqlApi';
import { ICreateSupplement, ISupplement, IUpdateSupplement } from '@/interfaces/supplements';
import { crudModules } from '@/constants/globalConstants';


type MainContext = ActionContext<CrudState, State>;


/**
 * Returns cached data
 * or false if it is expired
 */
const getCachedData = async (context: MainContext, needsDataUpgrade: boolean) => {
    const cachedData = context.getters.supplements;
    const dispatchAction = 'supplementsDataExpired';

    if (cachedData && cachedData.length > 0) {
        const dataExpired = await context.dispatch(dispatchAction);
        // check data expiration
        if (dataExpired) {
            // re run this action without cached data
            return false; // data is expired so we need to fetch new data
        } else {
            if (needsDataUpgrade) {
                if (context.getters.needsDataUpgrade(crudModules.SUPPLEMENTS)) {
                    return false;
                }
            }
            return cachedData;
        }
    } else {
        return false;
    }
};

/**
 * Actions sub module for Exercises actions
 */
export default {
    /**
     * Gets all supplements with minimal data to be used in lists and dropdowns
     * @param context - Vuex Action context
     * @returns - Promise with added exercise or false if error
     */
     async actionGetSupplementsForList(context: MainContext) {
        try {
            // check cached data
            const cachedData = await getCachedData(context, false);
            if (cachedData) {
                return cachedData;
            }
            const response = await graphQlApi.getAllSupplementsForList();
            if (response) {
                mutations.commitSetSupplements(context, response.data.supplements);
                mutations.setModuleNeedsDataUpgrade(context, {
                    module: crudModules.SUPPLEMENTS,
                    needsDataUpgrade: true,
                });
                mutations.setDataUpdated(context, crudModules.SUPPLEMENTS);
                return Promise.resolve(response.data.supplements);
            } else {
                throw new Error('Failed while fetching supplements');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Error while fetching supplements', color: 'error' });
            return Promise.resolve(false);
        }
    },
    /**
     * Gets all supplements with all available data
     * @param context - Vuex Action context
     * @returns - Promise with added exercise or false if error
     */
    async actionGetSupplements(context: MainContext) {
        try {
            // check cached data
            const cachedData = await getCachedData(context, true);
            if (cachedData) {
                return cachedData;
            }
            const response = await graphQlApi.getAllSupplements();
            if (response) {
                mutations.commitSetSupplements(context, response.data.supplements);
                mutations.setModuleNeedsDataUpgrade(context, {
                    module: crudModules.SUPPLEMENTS,
                    needsDataUpgrade: false,
                });
                mutations.setDataUpdated(context, crudModules.SUPPLEMENTS);
                return Promise.resolve(response.data.supplements);
            } else {
                throw new Error('Failed while fetching supplements');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Error while fetching supplements', color: 'error' });
            return Promise.resolve(false);
        }
    },
    /**
     * Gets a single supplement from the database
     * @param context - Vuex Action context
     * @param payload - Supplement to get
     * @returns - Promise with Supplement or false if error
     */
    async actionGetSupplementById(context: MainContext, id: string) {
        try {
            const response = await graphQlApi.getSupplementById(id);
            if (response) {
                mutations.commitSetSupplement(context, response.data.supplementById);
                return Promise.resolve(response.data.supplementById);
            } else {
                throw new Error('Failed while fetching supplement');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Error while fetching supplement', color: 'error' });
            return Promise.resolve(false);
        }
    },
    /**
     * Updates a single supplement in the DB
     * @param context - Vuex Action context
     * @param payload - Supplement to add
     * @returns - Promise with added supplement or false if error
     */
    async actionUpdateSupplement(context: MainContext,
                                 payload: { supplementId: string, supplement: IUpdateSupplement }) {
        const loadingNotification = { content: 'saving', color: 'info' };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                graphQlApi.updateSupplement(payload.supplementId, payload.supplement),
                await new Promise<void>((resolve) => setTimeout(() => resolve(), 500)),
            ]))[0];
            if (response) {
                commitAddNotification(context, { content: 'Supplement successfully updated', color: 'success' });
                mutations.commitSetSupplement(context, response.data.updateSupplement);
                return Promise.resolve(response.data.updateSupplement);
            } else {
                throw new Error('Failed while updating supplement');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Failed while updating supplement', color: 'error' });
            return Promise.resolve(false);
        }
    },
    /**
     * creates a new supplement in the DB
     * @param context - Vuex Action context
     * @param payload - Supplement to add
     * @returns - Promise with added supplement or false if error
     */
    async actionCreateSupplement(context: MainContext, payload: ICreateSupplement) {
        const loadingNotification = { content: 'saving', color: 'info' };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                graphQlApi.createSupplement(payload),
                await new Promise<void>((resolve) => setTimeout(() => resolve(), 500)),
            ]))[0];
            if (response) {
                commitAddNotification(context, { content: 'Supplement successfully created', color: 'success' });
                mutations.commitSetSupplement(context, response.data.createSupplement);
                return Promise.resolve(response.data.createSupplement);
            } else {
                throw new Error('Failed while creating supplement');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Failed while creating supplement', color: 'error' });
            return Promise.resolve(false);
        }
    },
    /**
     * Deletes a single supplement from the DB
     * @param context - Vuex Action context
     * @param payload - Supplement to remove
     * @returns - Promise with removed supplement or false if error
     */
    async actionDeleteSupplement(context: MainContext, payload: { supplementId: string }) {
        const loadingNotification = { content: 'saving', color: 'info' };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                graphQlApi.deleteSupplement(payload.supplementId),
                await new Promise<void>((resolve) => setTimeout(() => resolve(), 500)),
            ]))[0];
            if (response) {
                commitAddNotification(context, { content: 'Supplement successfully deleted', color: 'success' });
                mutations.commitDeleteSupplement(context, {id: payload.supplementId} as ISupplement);
                return Promise.resolve(response.data.deleteSupplement);
            } else {
                throw new Error('Failed while deleting supplement');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Failed while removing supplement', color: 'error' });
            return Promise.resolve(false);
        }
    },
};

