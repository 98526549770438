import gql from 'graphql-tag';

export const PATIENTS_FOR_TABLE_VIEW_QUERY = gql`
query patients($skip: Int, $limit: Int) {
    patients(skip: $skip, limit: $limit){
        id
        fname
        lname
        patientIdentifier
        patientFirebaseId
        patientContactInfo {
            id
            email
            phoneNumber
            streetAddress
            city
            state
        }
    }
}
`;



export const GET_PATIENT_BY_ID_QUERY = gql`
query patientById($id: UUID!) {
    patientById(id: $id){
        id
        patientIdentifier
        patientFirebaseId
        fname
        lname
        preferredName
        biologicalSex
        dob
        ssn
        pronouns
        ethnicOrigin
        shirtSize
        createdAt
        updatedAt
        motivational{
            id
            patientId
            tenYearHealthGoals
            purposeForJoiningFunction
            howDidTheyHearAboutFunction
            commentsBeforeBooking
            createdAt
            updatedAt
        }
        covid{
            id
            patientId
            hadCovid
            covidDate
            covidSeverity
            hasCovidResidualSymptoms
            covidResidualSymptoms
            vaccinatedForCovid
            covidVaccineName
            covidVaccineDateLastDosage
            createdAt
            updatedAt
        }
        femaleReproductiveHealth{
            id
            patientId
            currentlyOnBirthControl
            birthControlType
            timeOnBirthControl
            dateOfLastPeriod
            currentlyPregnant
            tryingToConceive
            currentMenstrualStatus
            priorPregnancies
            priorPregnancy{
                id
                femaleReproductiveHealthId
                count
                numberOfChildren
                numberOfMiscarriages
                numberOfAbortions
                createdAt
                updatedAt
            }
            createdAt
            updatedAt
        }
        medicalHx{
            id
            patientId
            hasChronicCondition
            chronicConditions
            chronicConditionDiagnosisDates
            takingMedications
            currentMedications
            hadSurgeries
            surgeries
            beenHospitalized
            hospitalizations
            hadIssuesWithBloodDraw
            bloodDrawIssues
            createdAt
            updatedAt
        }
        patientBehaviorInfo{
            id
            patientId
            diet
            physicallyActive
            smoker
            smokingFrequency
            alcohol
            alcoholFrequency
            createdAt
            updatedAt
        }
        patientContactInfo{
            id
            patientId
            email
            phoneNumber
            streetAddress
            city
            state
            zip
            createdAt
            updatedAt
        }
    }
}
`;

export const GET_PATIENT_BY_FIREBASE_ID_QUERY = gql`
query patientByPatientFirebaseId($patientFirebaseId: String!) {
    patientByPatientFirebaseId(patientFirebaseId: $patientFirebaseId){
        id
        patientIdentifier
        patientFirebaseId
        fname
        lname
        preferredName
        biologicalSex
        dob
        ssn
        pronouns
        ethnicOrigin
        shirtSize
        createdAt
        updatedAt
        motivational{
            id
            patientId
            tenYearHealthGoals
            purposeForJoiningFunction
            howDidTheyHearAboutFunction
            commentsBeforeBooking
            createdAt
            updatedAt
        }
        covid{
            id
            patientId
            hadCovid
            covidDate
            covidSeverity
            hasCovidResidualSymptoms
            covidResidualSymptoms
            vaccinatedForCovid
            covidVaccineName
            covidVaccineDateLastDosage
            createdAt
            updatedAt
        }
        femaleReproductiveHealth{
            id
            patientId
            currentlyOnBirthControl
            birthControlType
            timeOnBirthControl
            dateOfLastPeriod
            currentlyPregnant
            tryingToConceive
            currentMenstrualStatus
            priorPregnancies
            priorPregnancy{
                id
                femaleReproductiveHealthId
                count
                numberOfChildren
                numberOfMiscarriages
                numberOfAbortions
                createdAt
                updatedAt
            }
            createdAt
            updatedAt
        }
        medicalHx{
            id
            patientId
            hasChronicCondition
            chronicConditions
            chronicConditionDiagnosisDates
            takingMedications
            currentMedications
            hadSurgeries
            surgeries
            beenHospitalized
            hospitalizations
            hadIssuesWithBloodDraw
            bloodDrawIssues
            createdAt
            updatedAt
        }
        patientBehaviorInfo{
            id
            patientId
            diet
            physicallyActive
            smoker
            smokingFrequency
            alcohol
            alcoholFrequency
            createdAt
            updatedAt
        }
        patientContactInfo{
            id
            patientId
            email
            phoneNumber
            streetAddress
            city
            state
            zip
            createdAt
            updatedAt
        }
    }
}
`;

export const GET_ALL_PATIENTS_FOR_LIST_QUERY = gql`
query patients($skip: Int, $limit: Int) {
    patients(skip: $skip, limit: $limit){
        id
        fname
        lname
        patientContactInfo {
            email
        }
    }
}
`;


export const GET_ALL_PATIENTS_QUERY = gql`
query patients($skip: Int, $limit: Int) {
    patients(skip: $skip, limit: $limit){
        id
        patientIdentifier
        patientFirebaseId
        fname
        lname
        preferredName
        biologicalSex
        dob
        ssn
        pronouns
        ethnicOrigin
        shirtSize
        createdAt
        updatedAt
        motivational{
            id
            patientId
            tenYearHealthGoals
            purposeForJoiningFunction
            howDidTheyHearAboutFunction
            commentsBeforeBooking
            createdAt
            updatedAt
        }
        covid{
            id
            patientId
            hadCovid
            covidDate
            covidSeverity
            hasCovidResidualSymptoms
            covidResidualSymptoms
            vaccinatedForCovid
            covidVaccineName
            covidVaccineDateLastDosage
            createdAt
            updatedAt
        }
        femaleReproductiveHealth{
            id
            patientId
            currentlyOnBirthControl
            birthControlType
            timeOnBirthControl
            dateOfLastPeriod
            currentlyPregnant
            tryingToConceive
            currentMenstrualStatus
            priorPregnancies
            priorPregnancy{
                id
                femaleReproductiveHealthId
                count
                numberOfChildren
                numberOfMiscarriages
                numberOfAbortions
                createdAt
                updatedAt
            }
            createdAt
            updatedAt
        }
        medicalHx{
            id
            patientId
            hasChronicCondition
            chronicConditions
            chronicConditionDiagnosisDates
            takingMedications
            currentMedications
            hadSurgeries
            surgeries
            beenHospitalized
            hospitalizations
            hadIssuesWithBloodDraw
            bloodDrawIssues
            createdAt
            updatedAt
        }
        patientBehaviorInfo{
            id
            patientId
            diet
            physicallyActive
            smoker
            smokingFrequency
            alcohol
            alcoholFrequency
            createdAt
            updatedAt
        }
        patientContactInfo{
            id
            patientId
            email
            phoneNumber
            streetAddress
            city
            state
            zip
            createdAt
            updatedAt
        }
    }
}
`;

export const GET_PATIENT_BY_PATIENT_IDENTIFIER_QUERY = gql`
query patientByPatientIdentifier($patientIdentifier: String!) {
    patientByPatientIdentifier(patientIdentifier: $patientIdentifier){
        id
        patientIdentifier
        patientFirebaseId
        fname
        lname
        preferredName
        biologicalSex
        dob
        ssn
        pronouns
        ethnicOrigin
        shirtSize
        createdAt
        updatedAt
        motivational{
            id
            patientId
            tenYearHealthGoals
            purposeForJoiningFunction
            howDidTheyHearAboutFunction
            commentsBeforeBooking
            createdAt
            updatedAt
        }
        covid{
            id
            patientId
            hadCovid
            covidDate
            covidSeverity
            hasCovidResidualSymptoms
            covidResidualSymptoms
            vaccinatedForCovid
            covidVaccineName
            covidVaccineDateLastDosage
            createdAt
            updatedAt
        }
        femaleReproductiveHealth{
            id
            patientId
            currentlyOnBirthControl
            birthControlType
            timeOnBirthControl
            dateOfLastPeriod
            currentlyPregnant
            tryingToConceive
            currentMenstrualStatus
            priorPregnancies
            priorPregnancy{
                id
                femaleReproductiveHealthId
                count
                numberOfChildren
                numberOfMiscarriages
                numberOfAbortions
                createdAt
                updatedAt
            }
            createdAt
            updatedAt
        }
        medicalHx{
            id
            patientId
            hasChronicCondition
            chronicConditions
            chronicConditionDiagnosisDates
            takingMedications
            currentMedications
            hadSurgeries
            surgeries
            beenHospitalized
            hospitalizations
            hadIssuesWithBloodDraw
            bloodDrawIssues
            createdAt
            updatedAt
        }
        patientBehaviorInfo{
            id
            patientId
            diet
            physicallyActive
            smoker
            smokingFrequency
            alcohol
            alcoholFrequency
            createdAt
            updatedAt
        }
        patientContactInfo{
            id
            patientId
            email
            phoneNumber
            streetAddress
            city
            state
            zip
            createdAt
            updatedAt
        }
    }
}
`;

export const GET_PATIENT_BY_EMAIL_QUERY = gql`
query patientByEmail($email: String!) {
    patientByEmail(email: $email){
        id
        patientIdentifier
        patientFirebaseId
        fname
        lname
        preferredName
        biologicalSex
        dob
        ssn
        pronouns
        ethnicOrigin
        shirtSize
        createdAt
        updatedAt
        motivational{
            id
            patientId
            tenYearHealthGoals
            purposeForJoiningFunction
            howDidTheyHearAboutFunction
            commentsBeforeBooking
            createdAt
            updatedAt
        }
        covid{
            id
            patientId
            hadCovid
            covidDate
            covidSeverity
            hasCovidResidualSymptoms
            covidResidualSymptoms
            vaccinatedForCovid
            covidVaccineName
            covidVaccineDateLastDosage
            createdAt
            updatedAt
        }
        femaleReproductiveHealth{
            id
            patientId
            currentlyOnBirthControl
            birthControlType
            timeOnBirthControl
            dateOfLastPeriod
            currentlyPregnant
            tryingToConceive
            currentMenstrualStatus
            priorPregnancies
            priorPregnancy{
                id
                femaleReproductiveHealthId
                count
                numberOfChildren
                numberOfMiscarriages
                numberOfAbortions
                createdAt
                updatedAt
            }
            createdAt
            updatedAt
        }
        medicalHx{
            id
            patientId
            hasChronicCondition
            chronicConditions
            chronicConditionDiagnosisDates
            takingMedications
            currentMedications
            hadSurgeries
            surgeries
            beenHospitalized
            hospitalizations
            hadIssuesWithBloodDraw
            bloodDrawIssues
            createdAt
            updatedAt
        }
        patientBehaviorInfo{
            id
            patientId
            diet
            physicallyActive
            smoker
            smokingFrequency
            alcohol
            alcoholFrequency
            createdAt
            updatedAt
        }
        patientContactInfo{
            id
            patientId
            email
            phoneNumber
            streetAddress
            city
            state
            zip
            createdAt
            updatedAt
        }
    }
}
`;
