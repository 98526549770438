import gql from 'graphql-tag';

export const GET_PATIENT_NOTES_BY_PATIENT_ID = gql`
query customerServiceNotesByPatientId($patientId: UUID!) {
    customerServiceNotesByPatientId(patientId: $patientId){
        id
        patientCustomerService {
            id
            assignedAt
        }
        noteCreator {
            id
            fname
            lname
        }
        createdAt
        text
    }
}
`;

export const GET_PATIENT_NOTES_BY_NOTE_ID = gql`
query customerServiceNoteById($id: UUID!) {
    customerServiceNoteById(id: $id){
        id
        patientCustomerService {
            id
            assignedAt
        }
        noteCreator {
            id
            fname
            lname
        }
        createdAt
        text
    }
}
`;

export const GET_PATIENT_CUSTOMER_SERVICES = gql`
query patientCustomerServices($skip: Int!, $limit: Int!) {
    patientCustomerServices(skip: $skip, limit: $limit){
        id
        patientId
        assignedUser {
          id
          fname
          lname
        }
        assignedAt
        updatedAt
    }
}
`;

export const GET_PATIENT_CUSTOMER_SERVICE_BY_PATIENT_ID = gql`
query patientCustomerServiceByPatientId($patientId: UUID!) {
    patientCustomerServiceByPatientId(patientId: $patientId){
        id
        assignedUser{
            id
            email
            fname
            lname
        }
    }
}
`;
