import gql from 'graphql-tag';

export const GET_EXERCISES_FOR_LIST_QUERY = gql`
query exercises($skip: Int, $limit: Int) {
    exercises(skip: $skip, limit: $limit){
        id
        name
    }
}
`;


export const GET_ALL_EXERCISES_QUERY = gql`
query exercises($skip: Int, $limit: Int) {
    exercises(skip: $skip, limit: $limit){
        id
        name
        description
        exerciseType
    }
}
`;

export const GET_EXERCISE_BY_ID_QUERY = gql`
query exerciseById($id: UUID!) {
    exerciseById(id: $id){
        id
        name
        description
        exerciseType
    }
}
`;
