import gql from 'graphql-tag';

export const GET_ALL_BIOMARKER_RESULTS_FOR_TABLE_VIEW_QUERY = gql `
query biomarkerResults($skip: Int, $limit: Int) {
    biomarkerResults(skip: $skip, limit: $limit){
        id
        questBiomarkerId
        visitId
        testResult
        measurementUnits
        dateOfService
    }
}
`;

export const GET_ALL_BIOMARKER_RESULTS_QUERY = gql `
query biomarkerResults($skip: Int, $limit: Int) {
    biomarkerResults(skip: $skip, limit: $limit){
        id
        biomarker{
            id
            categories{
                id
            }
            name
            questBiomarkerCode
            modeOfAcquisition
            measurementUnits
            resultDataType
            sexDetails{
                id
                biomarkerId
                sex
                whyItMatters
                oneLineDescription
                questRefRangeLow
                questRefRangeHigh
                optimalRangeLow
                optimalRangeHigh
                crossReferenceBiomarkers
                notes
                resourcesCited
                recommendations{
                    id
                    name
                    biomarkerId
                    sex
                    inRange
                    outOfRangeType
                    summaryDescription
                    causesDescription
                    symptomsDescription
                    foodsToEatDescription
                    foodsToAvoidDescription
                    supplementsDescription
                    selfCareDescription
                    additionalTestsDescription
                    followUpsDescription
                }
                critical{
                    id
                    sexSpecificId
                    isPriority1
                    priority1Range
                    isPriority2
                    priority2Range
                }
            }
        }
        questBiomarkerId
        biomarkerName
        orderingPhysician{
            id
            email
            phoneNumber
            isActive
            isSuperuser
            isPhysician
            fname
            lname
            npi
            licenseState
            isNursePracticioner
        }
        dateOfService
        collectionSite
        testResult
        measurementUnits
        testResultOutOfRange
        questReferenceRange
    }
}
`;

export const GET_BIOMARKER_RESULTS_BY_ID_QUERY = gql `
query biomarkerResultById($id: UUID!) {
    biomarkerResultById(id: $id){
        id
        biomarker{
            id
            name
        }
        biomarkerName
        orderingPhysician{
            id
            email
            isPhysician
            fname
            lname
        }
        dateOfService
        collectionSite
        testResult
        measurementUnits
        testResultOutOfRange
        questReferenceRange
    }
}
`;
