import gql from 'graphql-tag';

export const GET_ALL_PANELS_FOR_TABLE_VIEW_QUERY = gql`
query panels($skip: Int, $limit: Int) {
    panels(skip: $skip, limit: $limit){
        id
        name
        description
        questTestCode
        modeOfAcquisition
        questPrice
    }
}
`;

export const GET_ALL_PANELS_FOR_LIST_QUERY = gql`
query panels($skip: Int, $limit: Int) {
    panels(skip: $skip, limit: $limit){
        id
        name
    }
}
`;

export const GET_ALL_PANELS_QUERY = gql`
query panels($skip: Int, $limit: Int) {
    panels(skip: $skip, limit: $limit){
        id
        name
        description
        questTestCode
        frequencyPerYear
        modeOfAcquisition
        questPrice
        sex
        biomarkers{
            id
            categories{
                id
            }
            name
            questBiomarkerCode
            modeOfAcquisition
            measurementUnits
            resultDataType
            sexDetails{
                id
                biomarkerId
                sex
                whyItMatters
                oneLineDescription
                questRefRangeLow
                questRefRangeHigh
                optimalRangeLow
                optimalRangeHigh
                crossReferenceBiomarkers
                notes
                resourcesCited
                recommendations{
                    id
                    name
                    biomarkerId
                    sex
                    inRange
                    outOfRangeType
                    summaryDescription
                    causesDescription
                    symptomsDescription
                    foodsToEatDescription
                    foodsToAvoidDescription
                    supplementsDescription
                    selfCareDescription
                    additionalTestsDescription
                    followUpsDescription
                }
                critical{
                    id
                    sexSpecificId
                    isPriority1
                    priority1Range
                    isPriority2
                    priority2Range
                }
            }
        }
    }
}
`;

export const GET_PANEL_BY_ID_QUERY = gql`
query panelById($id: UUID!) {
    panelById(id: $id){
        id
        name
        description
        questTestCode
        frequencyPerYear
        modeOfAcquisition
        questPrice
        sex
        biomarkers{
            id
            name
        }
    }
}
`;

