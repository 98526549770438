


import { Vue, Component, Watch } from 'vue-property-decorator';
import { AppNotification } from '@/store/main/state';
import { readNotification } from '@/store/main/getters';

@Component
export default class NotificationsManager extends Vue {
    public timeout: number = 5000;

    public get notification() {
        return readNotification(this.$store);
    }

    @Watch('notification')
    public async onNotificationChange(
        newNotification: AppNotification) {
        if (newNotification) {
            Vue.$toast.open({
                message: newNotification.content,
                position: 'bottom-right',
                type: newNotification.color,
                dismissible: true,
                pauseOnHover: true,
                duration: this.timeout,
            });
        }
    }
}
