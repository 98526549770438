import {ActionContext} from 'vuex';

import {CrudState} from '@/store/crud/state';

import {State} from '@/store/state';
import {commitAddNotification} from '@/store/main/mutations';
import {dispatchCheckApiError} from '@/store/main/actions';

import {graphQlApi} from '@/graphql/graphqlApi';
import {IPatientNoteCreate, IPatientNoteUpdate} from '@/interfaces/customerService';

type MainContext = ActionContext<CrudState, State>;

/**
 * Actions sub module for patient notes actions
 */
export default {
  async actionGetPatientNotesByPatientId(context: MainContext, patientId: string) {
    try {
      const response = await graphQlApi.getPatientNoteByPatientId(patientId);
      if (response) {
        return Promise.resolve(response.data.customerServiceNotesByPatientId);
      } else {
        throw new Error('Failed while fetching patient notes');
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
      commitAddNotification(context, {
        content: 'Error while fetching patient notes',
        color: 'error',
      });
      return Promise.resolve(false);
    }
  },
  async actionGetPatientNotesByNoteId(context: MainContext, id: string) {
    try {
      const response = await graphQlApi.getPatientNoteById(id);
      if (response) {
        return Promise.resolve(response.data.customerServiceNoteById);
      } else {
        throw new Error('Failed while fetching patient notes');
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
      commitAddNotification(context, {
        content: 'Error while fetching patient notes',
        color: 'error',
      });
      return Promise.resolve(false);
    }
  },
  async actionGetPatientCustomerServices(context: MainContext) {
    try {
      const response = await graphQlApi.getPatientCustomerServices();
      if (response) {
        return Promise.resolve(response.data.patientCustomerServices);
      } else {
        throw new Error('Failed while fetching patient customer services');
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
      commitAddNotification(context, {
        content: 'Error while fetching patient customer services',
        color: 'error',
      });
      return Promise.resolve(false);
    }
  },
  async actionGetCustomerServiceByPatientId(context: MainContext, patientId: string) {
    try {
      const response = await graphQlApi.getCustomerServiceByPatientId(patientId);
      if (response) {
        return Promise.resolve(response.data.patientCustomerServiceByPatientId);
      } else {
        throw new Error('Failed while fetching patient customer services');
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
      commitAddNotification(context, {
        content: 'Error while fetching patient customer services',
        color: 'error',
      });
      return Promise.resolve(false);
    }
  },
  async actionCreatePatientNote(context: MainContext, payload: IPatientNoteCreate) {
    const loadingNotification = {content: 'saving', color: 'info'};
    try {
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          graphQlApi.createPatientNote(payload),
          await new Promise<void>((resolve) => setTimeout(() => resolve(), 500)),
        ])
      )[0];
      if (response) {
        commitAddNotification(context, {content: 'Patient note saved', color: 'success'});
        return Promise.resolve(response.data.createCustomerServiceNote);
      } else {
        throw new Error('Failed while saving patient note');
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
      commitAddNotification(context, {content: 'Error while saving patient note', color: 'error'});
      return Promise.resolve(false);
    }
  },
  async actionUpdatePatientNote(
    context: MainContext,
    payload: {id: string; note: IPatientNoteUpdate},
  ) {
    const loadingNotification = {content: 'saving', color: 'info'};
    try {
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          graphQlApi.updatePatientNote(payload.id, payload.note),
          await new Promise<void>((resolve) => setTimeout(() => resolve(), 500)),
        ])
      )[0];
      if (response) {
        commitAddNotification(context, {content: 'Patient note saved', color: 'success'});
        return Promise.resolve(response.data.updateCustomerServiceNote);
      } else {
        throw new Error('Failed while saving patient note');
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
      commitAddNotification(context, {content: 'Error while saving patient note', color: 'error'});
      return Promise.resolve(false);
    }
  },
  async actionCreatePatientCustomerService(
    context: MainContext,
    payload: {patientId: string; userId: string},
  ) {
    const loadingNotification = {content: 'saving', color: 'info'};
    try {
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          graphQlApi.createPatientCustomerService({
            assignedUserId: payload.userId,
            patientId: payload.patientId,
          }),
          await new Promise<void>((resolve) => setTimeout(() => resolve(), 500)),
        ])
      )[0];
      if (response) {
        commitAddNotification(context, {
          content: 'Patient customer service saved',
          color: 'success',
        });
        return Promise.resolve(response.data.createPatientCustomerService);
      } else {
        throw new Error('Failed while saving patient customer service');
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
      commitAddNotification(context, {
        content: 'Error while saving patient customer service',
        color: 'error',
      });
      return Promise.resolve(false);
    }
  },
  async actionUpdatePatientCustomerService(context: MainContext, payload) {
    const loadingNotification = {content: 'saving', color: 'info'};
    try {
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          graphQlApi.updatePatientCustomerService({
            id: payload.id,
            pcsInput: {
              assignedUserId: payload.assignedUserId,
            },
          }),
          await new Promise<void>((resolve) => setTimeout(() => resolve(), 500)),
        ])
      )[0];
      if (response) {
        commitAddNotification(context, {
          content: 'Patient customer service saved',
          color: 'success',
        });
        return Promise.resolve(response.data.updatePatientCustomerService);
      } else {
        throw new Error('Failed while saving patient customer service');
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
      commitAddNotification(context, {
        content: 'Error while saving patient customer service',
        color: 'error',
      });
      return Promise.resolve(false);
    }
  },
};
