import gql from 'graphql-tag';

export const CREATE_VISIT_MUTATION = gql`
  mutation createVisit($visitInput: VisitCreate!) {
    createVisit(visitInput: $visitInput) {
      id
      patientId
      visitDate
      streetAddress
      confirmationCode
      documents
      city
      state
      zip
      patient {
        id
        fname
        lname
        patientContactInfo {
          id
          email
          city
          state
        }
      }
      packages {
        id
        name
        panels {
          id
          biomarkers {
            id
            name
          }
        }
      }
      biomarkerResults {
        id
        biomarkerName
        biomarker {
          id
          name
        }
        testResult
        measurementUnits
      }
    }
  }
`;

export const UPDATE_VISIT_MUTATION = gql`
  mutation updateVisit($id: UUID!, $visitInput: VisitUpdate!) {
    updateVisit(id: $id, visitInput: $visitInput) {
      id
      patientId
      visitDate
      streetAddress
      confirmationCode
      documents
      city
      state
      zip
      patient {
        id
        fname
        lname
        patientContactInfo {
          id
          email
          city
          state
        }
      }
      packages {
        id
        name
        panels {
          id
          biomarkers {
            id
            name
          }
        }
      }
      biomarkerResults {
        id
        biomarkerName
        biomarker {
          id
          name
        }
        testResult
        measurementUnits
      }
    }
  }
`;

export const DELETE_VISIT_MUTATION = gql`
  mutation deleteVisit($id: UUID!) {
    deleteVisit(id: $id)
  }
`;

export const CREATE_VISIT_DOCUMENT_MUTATION = gql`
  mutation createVisitDocument($visitInput: VisitDocumentCreate!) {
    createVisitDocument(visitInput: $visitInput) {
        id,
      deleted
      docStatus
      docType
      url
      visible
      visitId
    }
  }
`;

export const SHOW_VISIT_DOCUMENT_MUTATION = gql`
  mutation showVisitDocument($visitDocumentId: UUID!) {
    showVisitDocument(visitDocumentId: $visitDocumentId) {
      deleted
      docStatus
      docType
      url
      visible
      visitId
    }
  }
`;

export const HIDE_VISIT_DOCUMENT_MUTATION = gql`
  mutation hideVisitDocument($visitDocumentId: UUID!) {
    hideVisitDocument(visitDocumentId: $visitDocumentId) {
      deleted
      docStatus
      docType
      url
      visible
      visitId
    }
  }
`;

export const DELETE_VISIT_DOCUMENT_MUTATION = gql`
  mutation deleteVisitDocument($visitDocumentId: UUID!) {
    deleteVisitDocument(visitDocumentId: $visitDocumentId) {
      deleted
      docStatus
      docType
      url
      visible
      visitId
    }
  }
`;

export const DELETE_HARD_VISIT_DOCUMENT_MUTATION = gql`
    mutation hardDeleteVisitDocument($visitDocumentId: UUID!) {
        hardDeleteVisitDocument(visitDocumentId: $visitDocumentId)
    }
`;
