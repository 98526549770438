import axios from 'axios';
import {
  apiUrl,
  documentUploadServiceUrl,
  timezoneServiceUrl,
  membersCrmUrl,
  accessCodeUrl,
} from '@/env';
import omitBy from 'lodash/omitBy';

// interfaces
import {IDocumentRequest, IDocumentUpload, IListDirectoriesRequest} from './interfaces/documents';
import { IAccessCode, IAccessCodeCreateResponse, IAccessCodeLog } from "./interfaces/accessCode";
import {getLocalToken} from '@/utils';

const restClient = axios;

function authHeaders(token: string) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

const accessCodeServiceInstance = axios.create({
  baseURL: accessCodeUrl,
});

export const api = {
  /*
    default api call to log in without MFA
  */
  async logInGetToken(username: string, password: string) {
    const params = new URLSearchParams();
    params.append('username', username);
    params.append('password', password);
    // The code below is working to hit the api, but the frontend isn't recognizing it as a login
    // params.append('username', "user@example.com");
    // params.append('password', "password");
    return axios.post(`${apiUrl}/api/v1/login/access-token`, params);
  },
  /*
    endpoint to check credentials, expected step 1 for MFA
  */
  async logInCheckCredentials(username: string, password: string) {
    const params = new URLSearchParams();
    params.append('username', username);
    params.append('password', password);
    // The code below is working to hit the api, but the frontend isn't recognizing it as a login
    // params.append('username', "user@example.com");
    // params.append('password', "password");
    return restClient.post(`${apiUrl}/api/v1/login/access-token`, params);
  },
  async logInValidateCode(code: string, userId: string) {
    return restClient.post(`${apiUrl}/api/v1/login/validate-code`, {code, user_id: userId});
  },
  /*
    Documents endpoints
  */
  async getDocument(token: string, request: IDocumentRequest) {
    return restClient.get(`${documentUploadServiceUrl}/get-file`, {
      headers: {
        Accept: 'application/pdf',
        bearer: `${token}`,
      },
      responseType: 'blob',
      params: {
        patient_id: request.patient_id,
        requisition_id: request.requisition_id,
        visit_id: request.visit_id,
        pdf_file_name: request.pdf_file_name,
      },
    });
  },
  async getDocumentsFromRequisition(token: string, request: IListDirectoriesRequest) {
    return restClient.get(`${documentUploadServiceUrl}/list-directory-files`, {
      headers: {
        bearer: `${token}`,
      },
      params: {
        patient_id: request.patient_id,
        visit_id: request.visit_id,
        list_only_pdf: request.list_only_pdf,
        requisition_id: request.requisition_id,
      },
    });
  },
  async getDocumentsFromVisit(token: string, request: IListDirectoriesRequest) {
    return restClient.get(`${documentUploadServiceUrl}/list-directory-files`, {
      headers: {
        bearer: `${token}`,
      },
      params: {
        patient_id: request.patient_id,
        list_only_pdf: request.list_only_pdf,
      },
    });
  },
  async uploadDocument(token: string, request: IDocumentUpload) {
    const data = new FormData();
    data.append('pdf_file', request.pdf_file_name);
    return restClient.post(`${documentUploadServiceUrl}/upload-pdf-to-file`, data, {
      headers: {
        'Content-Type': `${request.pdf_file_name.type}`,
        'Accept': 'application/json',
        'bearer': `${token}`,
      },
      params: {
        patient_id: request.patient_id,
        visit_id: request.visit_id,
        requisition_id: request.requisition_id,
        publish_if_visit_document: false,
      },
    });
  },
  async deleteDocument(token: string, request: IDocumentRequest) {
    return restClient.delete(`${documentUploadServiceUrl}/delete-file`, {
      headers: {
        bearer: `${token}`,
      },
      params: {
        patient_id: request.patient_id,
        requisition_id: request.requisition_id,
        visit_id: request.visit_id,
        pdf_file_name: request.pdf_file_name,
      },
    });
  },
  async getTimezoneForAppointment(token: string, payload: {city: string; state: string}) {
    return restClient.get(timezoneServiceUrl + '/get-timezone', {
      headers: {
        bearer: token,
      },
      params: {
        city: payload.city,
        state: payload.state,
      },
    });
  },
  async getTimezonesBatch(
    token: string,
    requestDictionary: {[key: string]: Array<{city: string; state: string}>},
  ) {
    return restClient.post(timezoneServiceUrl + '/get-list-timezones', requestDictionary, {
      headers: {
        'bearer': token,
        'Content-Type': 'application/json',
      },
    });
  },
  async getPatientsTimeline(token: string) {
    return restClient.get(membersCrmUrl + '/patients_info', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
  async getAccessCodes() {
    return accessCodeServiceInstance.get<{data: IAccessCode[], total: number}>('/access_code', {
      params: {limit: 100000},
      ...authHeaders(getLocalToken() || ''),
    });
  },
  async getAccessCodeLogs(access_code: string) {
    return accessCodeServiceInstance.get<IAccessCodeLog[]>(
      `/access_code_log/by_code/${access_code}`,
      {
        ...authHeaders(getLocalToken() || ''),
      },
    );
  },
  async generateCustomAccessCode(
    accessCode: Partial<IAccessCode>,
  ) {
    return accessCodeServiceInstance.post<IAccessCodeCreateResponse[]>('/access_code', {
      create_list: [omitBy(accessCode, (value) => !value)],
    }, {
      ...authHeaders(getLocalToken() || ''),
    });
  },
};
