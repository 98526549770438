import Vue from 'vue';
import VeeValidate from 'vee-validate';
import { Validator } from 'vee-validate';

const safeStringRegex = '^[a-zA-Z0-9_.:,\\-   ()!#$%&\\/?¡¿¡ñÑ]*$';

Vue.use(VeeValidate);

const checkSafeString = (value: string) => {
    if (value.length > 0) {
        const regex = new RegExp(safeStringRegex);
        if (!regex.test(value)) {
            return false;
        }
    }
    return true;
};

const checkRequiredValue = (value: any, params: any) => {
  if (value) {
    if (params.type === 'list') {
      return value && Array.isArray(value) && value.length > 0;
    } else if (params.type === 'object') {
      return value && Object.keys(value) && Object.keys(value).length > 0;
    } else if (params.type === 'string') {
      return value && value.length > 0;
    } else {
      return value ? true : false;
    }
  } else {
    return false;
  }
};


/**
 * custom rules
 */
Validator.extend('safe', {
  getMessage: (field) => `${field} contains invalid characters`,
  validate: (value) => checkSafeString(value),
});

/**
 * custom rules
 */
Validator.extend('allrequired', {
  getMessage: (field) => `the field ${field} is required to continue`,
  validate: (value, type) => checkRequiredValue(value, type),
  paramNames: ['type'],
});

/**
 * Dictionary of validation rules.
 */

const dict = {
  en: {
    messages: {
      required: () => 'This field is required',
      numeric: () => 'This field must be numeric',
    },
    custom: {
      email: {
        required: 'Email cannot be empty',
        email: 'Not a Valid Email',
      },
      visitDate: {
        required: 'The appointment Date could not be empty',
        date_format: 'Date format should be MM/dd/yyyy',
      },
    },
  },
};


Validator.localize('en', dict.en);


