import { ActionContext } from 'vuex';

import * as mutations from '@/store/crud/mutations';

import { CrudState } from '@/store/crud/state';

import { State } from '@/store/state';
import { commitAddNotification } from '@/store/main/mutations';
import { dispatchCheckApiError } from '@/store/main/actions';

import { graphQlApi } from '@/graphql/graphqlApi';
import { IBiomarker, ICreateBiomarker, IUpdateBiomarker } from '@/interfaces/biomarkers';
import { ICreateCritical, IUpdateCritical } from '@/interfaces/criticals';
import { crudModules } from '@/constants/globalConstants';
import { ICreateSexSpecifics, IUpdateSexSpecifics } from '@/interfaces/sexSpecifics';



type MainContext = ActionContext<CrudState, State>;


/**
 * Returns biomarkers cached data
 * or false if it is expired
 * If the view or components needs the full data, there is a flag
 * "needsDataUpgrade" to check if the existing cache is list or full data.
 * If it is list, then the data is not valid and needs to be upgraded.
 */
const getBiomarkersCachedData = async (context: MainContext, needsDataUpgrade = false) => {
    if (context.getters.biomarkers && context.getters.biomarkers.length > 0) {
        const dataExpired = await context.dispatch('biomarkersDataExpired');
        // check data expiration
        if (dataExpired) {
            // re run this action without cached data
            return false; // data is expired so we need to fetch new data
        } else {
            if (needsDataUpgrade) {
                if (context.getters.needsDataUpgrade(crudModules.BIOMARKERS)) {
                    return false;
                }
            }
            return context.getters.biomarkers;
        }
    } else {
        return false;
    }
};

/**
 * Actions sub module for user actions
 */
export default {
    /**
     * Get All biomarkers with All the available information
     * @param context - MainContext
     * @returns - Promise<IBiomarker[]> - list of biomarkers or false if error
     */
    async actionGetBiomarkers(context: MainContext) {
        try {
            // check cached data
            const biomarkersCachedData = await getBiomarkersCachedData(context, true);
            if (biomarkersCachedData) {
                return biomarkersCachedData;
            }
            const response = await graphQlApi.getAllBiomarkers();
            if (response) {
                mutations.commitSetBiomarkers(context, response.data.biomarkers);
                mutations.setModuleNeedsDataUpgrade(context, {
                    module: crudModules.BIOMARKERS,
                    needsDataUpgrade: false,
                });
                mutations.setDataUpdated(context, crudModules.BIOMARKERS);
                return Promise.resolve(response.data.biomarkers);
            } else {
                throw new Error('failed while getting biomarkers');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Error while fetching biomarkers', color: 'error' });
            return Promise.resolve(false);
        }
    },
    /**
     * Gets all the biomarkers with minimal information to be used in the biomarker selection dropdown
     * @param context - MainContext
     * @returns - Promise<IBiomarker[]> - list of biomarkers or false if error
     */
    async actionGetBiomarkersForList(context: MainContext) {
        try {
            const biomarkersCachedData = await getBiomarkersCachedData(context);
            if (biomarkersCachedData) {
                return biomarkersCachedData;
            }
            const response = await graphQlApi.getAllBiomarkersForTableView();
            if (response) {
                mutations.commitSetBiomarkers(context, response.data.biomarkers);
                mutations.setModuleNeedsDataUpgrade(context, {
                    module: crudModules.BIOMARKERS,
                    needsDataUpgrade: true,
                });
                mutations.setDataUpdated(context, crudModules.BIOMARKERS);
                return Promise.resolve(response.data.biomarkers);
            } else {
                throw new Error('failed while getting biomarkers');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Error while fetching biomarkers', color: 'error' });
            return Promise.resolve(false);
        }
    },
    /**
     * Gets a single biomarker by id
     * @param context - MainContext
     * @param id - biomarker id
     * @returns - Promise<IBiomarker> - biomarker or false if error
     */
    async actionGetBiomarkerById(context: MainContext, id: string) {
        try {
            const response = await graphQlApi.getBiomarkerById(id);
            if (response) {
                mutations.commitSetBiomarker(context, response.data.biomarkerById);
                return Promise.resolve(response.data.biomarkerById);
            } else {
                throw new Error('failed while getting biomarker');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            return Promise.resolve(false);
        }
    },
    /**
     * Gets a single biomarker by name string
     * @param context - MainContext
     * @param name - biomarker name
     * @returns - Promise<IBiomarker> - biomarker or false if error
     */
    async actionGetBiomarkerByName(context: MainContext, name: string) {
        try {
            const response = await graphQlApi.getBiomarkerByName(name);
            if (response) {
                mutations.commitSetBiomarker(context, response.data.biomarkerByName);
                return Promise.resolve(response.data.biomarkerByName);
            } else {
                throw new Error('failed while getting biomarker');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            return Promise.resolve(false);
        }
    },
    /**
     * Updates an existing biomarker
     * @param context - MainContext
     * @param payload - IUpdateBiomarker
     * @returns - Promise<IBiomarker> - biomarker or false if error
     */
    async actionUpdateBiomarker(context: MainContext, payload: { biomarkerId: string, biomarker: IUpdateBiomarker }) {
        const loadingNotification = { content: 'saving', color: 'info' };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                graphQlApi.updateBiomarker(payload.biomarkerId, payload.biomarker),
                await new Promise<void>((resolve) => setTimeout(() => resolve(), 500)),
            ]))[0];
            if (response) {
                commitAddNotification(context, { content: 'Biomarker successfully updated', color: 'success' });
                mutations.commitSetBiomarker(context, response.data.updateBiomarker);
                return Promise.resolve(response.data.updateBiomarker);
            } else {
                throw new Error('failed while updating biomarker');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Error while updating biomarker', color: 'error' });
            return Promise.resolve(false);
        }
    },
    /**
     * Creates a new Biomarker
     * @param context - MainContext
     * @param payload - ICreateBiomarker
     * @returns - Promise<IBiomarker> - biomarker or false if error
     */
    async actionCreateBiomarker(context: MainContext, payload: ICreateBiomarker) {
        const loadingNotification = { content: 'Creating Biomarker...', color: 'info' };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                graphQlApi.createBiomarker(payload),
                await new Promise<void>((resolve) => setTimeout(() => resolve(), 500)),
            ]))[0];
            if (response) {
                commitAddNotification(context, { content: 'Biomarker successfully created', color: 'success' });
                mutations.commitSetBiomarker(context, response.data.createBiomarker);
                return Promise.resolve(response.data.createBiomarker);
            } else {
                throw new Error('failed while creating biomarker');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Error while creating biomarker', color: 'error' });
            return Promise.resolve(false);
        }
    },
    async actionCreateCritical(context: MainContext, payload: ICreateCritical) {
        const loadingNotification = { content: 'Creating Critical info...', color: 'info' };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                graphQlApi.createCritical(payload),
                await new Promise<void>((resolve) => setTimeout(() => resolve(), 500)),
            ]))[0];
            if (response) {
                commitAddNotification(context, { content: 'Critical successfully created', color: 'success' });
                return Promise.resolve(response.data.createCritical);
            } else {
                throw new Error('failed while creating critical');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Error while creating critical', color: 'error' });
            return Promise.resolve(false);
        }
    },
    async actionUpdateCritical(context: MainContext, payload: { id: string, critical: IUpdateCritical }) {
        const loadingNotification = { content: 'saving', color: 'info' };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                graphQlApi.updateCritical(payload.id, payload.critical),
                await new Promise<void>((resolve) => setTimeout(() => resolve(), 500)),
            ]))[0];
            if (response) {
                commitAddNotification(context, { content: 'Critical successfully updated', color: 'success' });
                return Promise.resolve(response.data.updateCritical);
            } else {
                throw new Error('failed while updating critical');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Error while updating critical', color: 'error' });
            return Promise.resolve(false);
        }
    },
    /**
     * Creates a Biomarrker Sex detail record
     * @param context - MainContext
     * @param payload - ICreateSexSpecifics
     * @returns - Promise<ISexSpecific> - biomarker sex specific detail or false if error
     */
    async actionCreateBiomarkerSexSpecific(context: MainContext, payload: ICreateSexSpecifics) {
        const loadingNotification = { content: 'Creating biomarker details...', color: 'info' };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                graphQlApi.createSexSpecific(payload),
                await new Promise<void>((resolve) => setTimeout(() => resolve(), 500)),
            ]))[0];
            if (response) {
                commitAddNotification(context, { content: 'Biomarker details created', color: 'success' });
                return Promise.resolve(response.data.createSexSpecific);
            } else {
                throw new Error('failed while creating biomarker details');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Error while creating biomarker details', color: 'error' });
            return Promise.resolve(false);
        }
    },
    async actionUpdateBiomarkerSexSpecific(
        context: MainContext, payload: { id: string, sexSpecific: IUpdateSexSpecifics }) {
        const loadingNotification = { content: 'saving', color: 'info' };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                graphQlApi.updateSexSpecific(payload.id, payload.sexSpecific),
                await new Promise<void>((resolve) => setTimeout(() => resolve(), 500)),
            ]))[0];
            if (response) {
                commitAddNotification(context, { content: 'Biomarker details updated', color: 'success' });
                return Promise.resolve(response.data.updateSexSpecific);
            } else {
                throw new Error('failed while updating biomarker details');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Error while updating biomarker details', color: 'error' });
            return Promise.resolve(false);
        }
    },
    async actionDeleteBiomarkerSexSpecific(context: MainContext, id: string) {
        const loadingNotification = { content: 'saving', color: 'info' };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                graphQlApi.deleteSexSpecific(id),
                await new Promise<void>((resolve) => setTimeout(() => resolve(), 500)),
            ]))[0];
            if (response) {
                commitAddNotification(context, { content: 'Biomarker details deleted', color: 'success' });
                return Promise.resolve(true);
            } else {
                throw new Error('failed while deleting biomarker details');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Error while deleting biomarker details', color: 'error' });
            return Promise.resolve(false);
        }
    },
    /**
     * Deletes a biomarker
     * @param context - MainContext
     * @param payload - id of the biomarker to delete
     * @returns - Promise<boolean> - true if success, false if error
     */
    async actionDeleteBiomarker(context: MainContext, payload: { biomarkerId: string }) {
        const loadingNotification = { content: 'saving', color: 'info' };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                graphQlApi.deleteBiomarker(payload.biomarkerId),
                await new Promise<void>((resolve) => setTimeout(() => resolve(), 500)),
            ]))[0];
            if (response) {
                commitAddNotification(context, { content: 'Biomarker successfully deleted', color: 'success' });
                mutations.commitDeleteBiomarker(context, {id: payload.biomarkerId} as IBiomarker);
                return Promise.resolve(true);
            } else {
                throw new Error('failed while deleting biomarker');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Failed while removing biomarker', color: 'error' });
            return Promise.resolve(false);
        }
    },
};
