import gql from 'graphql-tag';

export const CREATE_REQUISITION_MUTATION = gql`
  mutation createRequisition($requisitionInput: RequisitionCreate!) {
    createRequisition(requisitionInput: $requisitionInput) {
      id
      patientId
      requisitionState
      confirmation
      physicianNotes
      patientNotes
      referringPhysicianId
      reviewingPhysicianId
      reviewed
      dateTimeConfirmed
      dateTimeReviewed
      readyForConfirmation
      allResultsAvailable
      patient {
        id
        fname
        lname
        patientContactInfo {
          email
        }
      }
      referringPhysician {
        id
        email
        isActive
        isPhysician
        fname
        lname
        licenseState
      }
      reviewingPhysician {
        id
        email
        isActive
        isPhysician
        fname
        lname
        licenseState
      }
      visits {
        id
        patientId
        visitDate
        streetAddress
        confirmationCode
        documents
        city
        state
        zip
        patient {
          fname
          lname
        }
      }
    }
  }
`;

export const UPDATE_REQUISITION_MUTATION = gql`
  mutation updateRequisition($id: UUID!, $requisitionInput: RequisitionUpdate!) {
    updateRequisition(id: $id, requisitionInput: $requisitionInput) {
      id
      patientId
      requisitionState
      confirmation
      physicianNotes
      patientNotes
      referringPhysicianId
      reviewingPhysicianId
      reviewed
      dateTimeConfirmed
      dateTimeReviewed
      readyForConfirmation
      allResultsAvailable
      patient {
        id
        fname
        lname
        patientContactInfo {
          email
        }
      }
      referringPhysician {
        id
        email
        isActive
        isPhysician
        fname
        lname
        licenseState
      }
      reviewingPhysician {
        id
        email
        isActive
        isPhysician
        fname
        lname
        licenseState
      }
      visits {
        id
        patientId
        visitDate
        streetAddress
        confirmationCode
        documents
        city
        state
        zip
        patient {
          fname
          lname
        }
      }
      listOfPhysicianNotes{
        id
        content
        category {
          id
          categoryName
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const UPDATE_REQUISITION_NURSE = gql`
  mutation updateRequisitionNurse($id: UUID!, $requisitionInput: RequisitionUpdateNurse!) {
    updateRequisitionNurse(id: $id, requisitionInput: $requisitionInput) {
      id
    }
  }
`;

export const DELETE_REQUISITION_MUTATION = gql`
  mutation deleteRequisition($id: UUID!) {
    deleteRequisition(id: $id)
  }
`;
