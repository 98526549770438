import { ActionContext } from 'vuex';

import * as mutations from '@/store/crud/mutations';

import { CrudState } from '@/store/crud/state';

import { State } from '@/store/state';
import { commitAddNotification } from '@/store/main/mutations';
import { dispatchCheckApiError } from '@/store/main/actions';

import { graphQlApi } from '@/graphql/graphqlApi';
import { crudModules } from '@/constants/globalConstants';
import { ICategory, ICreateCategory, IUpdateCategory } from '@/interfaces/categories';


type MainContext = ActionContext<CrudState, State>;


/**
 * Returns cached data
 * or false if it is expired
 */
const getCachedData = async (context: MainContext, needsDataUpgrade: boolean) => {

    const cachedData = context.getters.categories;
    const dispatchAction = 'categoriesDataExpired';

    if (cachedData && cachedData.length > 0) {
        const dataExpired = await context.dispatch(dispatchAction);
        // check data expiration
        if (dataExpired) {
            // re run this action without cached data
            return false; // data is expired so we need to fetch new data
        } else {
            if (needsDataUpgrade) {
                if (context.getters.needsDataUpgrade(crudModules.CATEGORIES)) {
                    return false;
                }
            }
            return cachedData;
        }
    } else {
        return false;
    }
};

/**
 * Actions sub module for Exercises actions
 */
export default {
    /**
     * Gets all categories with minimal information to be used in lists and dropdowns
     * @param context - Vuex Action context
     * @returns - Promise categories or false if error
     */
    async actionGetCategoriesForList(context: MainContext) {
        try {
            // check cached data
            const cachedData = await getCachedData(context, false);
            if (cachedData) {
                return cachedData;
            }

            const response = await graphQlApi.getAllCategoriesForList();
            if (response) {
                mutations.commitSetCategories(context, response.data.categories);
                mutations.setModuleNeedsDataUpgrade(context, {
                    module: crudModules.CATEGORIES,
                    needsDataUpgrade: true,
                });
                mutations.setDataUpdated(context, crudModules.CATEGORIES);
                return Promise.resolve(response.data.categories);
            } else {
                throw new Error('Failed while fetching categories');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Error while fetching categories', color: 'error' });
            return Promise.resolve(false);
        }
    },
    /**
     * Gets all categories with all available data
     * @param context - Vuex Action context
     * @returns - Promise categories or false if error
     */
    async actionGetCategories(context: MainContext) {
        try {
            // check cached data
            const cachedData = await getCachedData(context, true);

            if (cachedData) {
                return cachedData;
            }

            const response = await graphQlApi.getAllCategories();
            if (response) {
                mutations.commitSetCategories(context, response.data.categories);
                mutations.setModuleNeedsDataUpgrade(context, {
                    module: crudModules.CATEGORIES,
                    needsDataUpgrade: false,
                });
                mutations.setDataUpdated(context, crudModules.CATEGORIES);
                return Promise.resolve(response.data.categories);
            } else {
                throw new Error('Failed while fetching categories');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Error while fetching categories', color: 'error' });
            return Promise.resolve(false);
        }
    },
    /**
     * Gets a single category from the database
     * @param context - Vuex Action context
     * @param payload - category to get
     * @returns - Promise with category or false if error
     */
    async actionGetCategoryById(context: MainContext, id: string) {
        try {
            const response = await graphQlApi.getCategoryById(id);
            if (response) {
                mutations.commitSetCategory(context, response.data.categoryById);
                return Promise.resolve(response.data.categoryById);
            } else {
                throw new Error('Failed while fetching category');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Error while fetching category', color: 'error' });
            return Promise.resolve(false);
        }
    },
    /**
     * Updates a single category in the DB
     * @param context - Vuex Action context
     * @param payload - category to add
     * @returns - Promise with added category or false if error
     */
    async actionUpdateCategory(context: MainContext, payload: { categoryId: string, category: IUpdateCategory }) {
        const loadingNotification = { content: 'saving', color: 'info' };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                graphQlApi.updateCategory(payload.categoryId, payload.category),
                await new Promise<void>((resolve) => setTimeout(() => resolve(), 500)),
            ]))[0];
            if (response) {
                commitAddNotification(context, { content: 'category successfully updated', color: 'success' });
                mutations.commitSetCategory(context, response.data.updateCategory);
                return Promise.resolve(response.data.updateCategory);
            } else {
                throw new Error('Failed while updating category');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Failed while updating category', color: 'error' });
            return Promise.resolve(false);
        }
    },
    /**
     * Creates a new category in the DB
     * @param context - Vuex Action context
     * @param payload - Food to add
     * @returns - Promise with added category or false if error
     */
    async actionCreateCategory(context: MainContext, payload: ICreateCategory) {
        const loadingNotification = { content: 'saving', color: 'info' };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                graphQlApi.createCategory(payload),
                await new Promise<void>((resolve) => setTimeout(() => resolve(), 500)),
            ]))[0];
            if (response) {
                commitAddNotification(context, { content: 'Category successfully created', color: 'success' });
                mutations.commitSetCategory(context, response.data.createCategory);
                return Promise.resolve(response.data.createCategory);
            } else {
                throw new Error('Failed while creating category');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Failed while creating category', color: 'error' });
            return Promise.resolve(false);
        }
    },
    /**
     * Deletes a single category from the DB
     * @param context - Vuex Action context
     * @param payload - category to remove
     * @returns - Promise with removed category or false if error
     */
    async actionDeleteCategory(context: MainContext, payload: { categoryId: string }) {
        const loadingNotification = { content: 'saving', color: 'info' };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                graphQlApi.deleteCategory(payload.categoryId),
                await new Promise<void>((resolve) => setTimeout(() => resolve(), 500)),
            ]))[0];
            if (response) {
                commitAddNotification(context, { content: 'Category successfully deleted', color: 'success' });
                mutations.commitDeleteCategory(context, {id: payload.categoryId} as ICategory);
                return Promise.resolve(response.data.deleteCategory);
            } else {
                throw new Error('Failed while deleting category');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Failed while removing category', color: 'error' });
            return Promise.resolve(false);
        }
    },
};

