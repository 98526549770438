import gql from 'graphql-tag';

export const CREATE_REQUISITION_COMMENT_MUTATION = gql`
  mutation createRequisitionComment($requisitionCommentInput: RequisitionCommentCreate!) {
    createRequisitionComment(requisitionCommentInput: $requisitionCommentInput) {
      id
      comment
      createdAt
      updatedAt
      deleted
      requisitionId
      creatorId
    }
  }
`;

export const UPDATE_REQUISITION_COMMENT_MUTATION = gql`
  mutation updateRequisitionComment(
    $id: UUID!
    $requisitionCommentInput: RequisitionCommentUpdate!
  ) {
    updateRequisitionComment(id: $id, requisitionCommentInput: $requisitionCommentInput) {
      id
      comment
      createdAt
      updatedAt
      deleted
      requisitionId
      creatorId
    }
  }
`;

export const DELETE_REQUISITION_COMMENT_MUTATION = gql`
  mutation softDeleteRequisitionComment($id: UUID!) {
    softDeleteRequisitionComment(id: $id)
  }
`;
