import gql from 'graphql-tag';

export const CREATE_PACKAGE_MUTATION = gql`
  mutation createPackage($packageInput: PackageCreate!) {
    createPackage(packageInput: $packageInput) {
      id
      name
      version
      description
      panels {
        id
        name
      }
    }
  }
`;

export const DELETE_PACKAGE_MUTATION = gql`
  mutation deletePackage($id: UUID!) {
    deletePackage(id: $id)
  }
`;

export const UPDATE_PACKAGE_MUTATION = gql`
  mutation updatePackage($id: UUID!, $packageInput: PackageUpdate!) {
    updatePackage(id: $id, packageInput: $packageInput) {
      id
      name
      version
      description
      panels {
        id
        name
      }
    }
  }
`;
