














import { Component, Vue } from 'vue-property-decorator';
import NotificationsManager from '@/components/NotificationsManager.vue';

import { readAppLoading, readIsLoggedIn } from '@/store/main/getters';
import { dispatchCheckLoggedIn } from '@/store/main/actions';
import Loading from './components/Loading.vue';

@Component({
  components: {
    NotificationsManager,
    Loading,
  },
  computed: {
    theme() {
      return (this.$vuetify.theme.dark) ? 'dark' : 'light';
    },
  },
  methods: {
    cssVariables() {
      const theme = this.$vuetify.theme.dark
      ? this.$vuetify.theme.themes.dark
      : this.$vuetify.theme.themes.light;
      return `--background: ${theme.background};` +
        `--primary: ${theme.primary};` +
        `--secondary: ${theme.secondary};` +
        `--tertiary: ${theme.tertiary};` +
        `--accent: ${theme.accent};` +
        `--card: ${theme.card};` +
        `--nav: ${theme.nav};` +
        `--scrollbar: ${theme.scrollbar};` +
        `--sidebar: ${theme.sidebar};` +
        `--header: ${theme.header};` +
        `--sub-header: ${theme.subheader};` +
        `--text: ${theme.text};` +
        `--link: ${theme.link};` +
        `--buttons: ${theme.buttons};`;
    },
  },
})
export default class App extends Vue {

  get loggedIn() {
    return readIsLoggedIn(this.$store);
  }

  public async created() {
    await dispatchCheckLoggedIn(this.$store);
  }

    public get isAppLoading() {
    return readAppLoading(this.$store);
  }
}
