import { CrudState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';
import { ITableRequisitionDocuments } from '@/interfaces/documents';


export const getters = {
    users: (state: CrudState) => state.users,
    oneUserById: (state: CrudState) => (userId: string) => {
        const filteredUsers = state.users.filter((user) => user.id === userId);
        if (filteredUsers.length > 0) {
            return { ...filteredUsers[0] };
        }
    },
    oneUserByEmail: (state: CrudState) => (email: string) => {
        const filteredUsers = state.users.filter((user) => user.email === email);
        if (filteredUsers.length > 0) {
            return { ...filteredUsers[0] };
        }
    },
    patients: (state: CrudState) => state.patients,
    onePatientById: (state: CrudState) => (patient_id: string) => {
        const filteredPatients = state.patients.filter((patient) => patient.id === patient_id);
        if (filteredPatients.length > 0) {
            return { ...filteredPatients[0] };
        }
    },
    onePatientByEmail: (state: CrudState) => (email: string) => {
        const filteredPatients = state.patients.filter((patient) => patient.patientContactInfo?.email === email);
        if (filteredPatients.length > 0) {
            return { ...filteredPatients[0] };
        }
    },
    visits: (state: CrudState) => state.visits,
    oneVisit: (state: CrudState) => (visitId: string) => {
        const filteredVisits = state.visits.filter((visit) => visit.id === visitId);
        if (filteredVisits.length > 0) {
            return { ...filteredVisits[0] };
        }
    },
    requisitions: (state: CrudState) => state.requisitions,
    oneRequisition: (state: CrudState) => (requisitionId: string) => {
        const filteredRequisitions = state.requisitions.filter((requisition) => requisition.id === requisitionId);
        if (filteredRequisitions.length > 0) {
            return { ...filteredRequisitions[0] };
        }
    },
    packages: (state: CrudState) => state.packages,
    onePackage: (state: CrudState) => (packageId: string) => {
        const filteredPackages = state.packages.filter((packageItem) => packageItem.id === packageId);
        if (filteredPackages.length > 0) {
            return { ...filteredPackages[0] };
        }
    },
    panels: (state: CrudState) => state.panels,
    onePanel: (state: CrudState) => (panelId: string) => {
        const filteredPanels = state.panels.filter((panel) => panel.id === panelId);
        if (filteredPanels.length > 0) {
            return { ...filteredPanels[0] };
        }
    },
    biomarkers: (state: CrudState) => state.biomarkers,
    oneBiomarker: (state: CrudState) => (biomarkerId: string) => {
        const filteredBiomarkers = state.biomarkers.filter((biomarker) => biomarker.id === biomarkerId);
        if (filteredBiomarkers.length > 0) {
            return { ...filteredBiomarkers[0] };
        }
    },
    biomarkerResults: (state: CrudState) => state.biomarkerResults,
    oneBiomarkerResult: (state: CrudState) => (biomarkerResultId: string) => {
        const filteredBiomarkerResults = state.biomarkerResults.filter((biomarkerResult) =>
        biomarkerResult.id === biomarkerResultId);
        if (filteredBiomarkerResults.length > 0) {
            return { ...filteredBiomarkerResults[0] };
        }
    },
    foods: (state: CrudState) => state.foods,
    oneFood: (state: CrudState) => (foodId: string) => {
        const filteredFoods = state.foods.filter((food) => food.id === foodId);
        if (filteredFoods.length > 0) {
            return { ...filteredFoods[0] };
        }
    },
    recommendations: (state: CrudState) => state.recommendations,
    oneRecommendation: (state: CrudState) => (recommendationId: string) => {
        const filteredRecommendations = state.recommendations.filter((recommendation) =>
        recommendation.id === recommendationId);
        if (filteredRecommendations.length > 0) {
            return { ...filteredRecommendations[0] };
        }
    },
    supplements: (state: CrudState) => state.supplements,
    oneSupplement: (state: CrudState) => (supplementId: string) => {
        const filteredSupplements = state.supplements.filter((supplement) => supplement.id === supplementId);
        if (filteredSupplements.length > 0) {
            return { ...filteredSupplements[0] };
        }
    },
    exercises: (state: CrudState) => state.exercises,
    oneExercise: (state: CrudState) => (exerciseId: string) => {
        const filteredExercises = state.exercises.filter((exercise) => exercise.id === exerciseId);
        if (filteredExercises.length > 0) {
            return { ...filteredExercises[0] };
        }
    },
    categories: (state: CrudState) => state.categories,
    oneCategory: (state: CrudState) => (categoryId: string) => {
        const filteredCategories = state.categories.filter((category) => category.id === categoryId);
        if (filteredCategories.length > 0) {
            return { ...filteredCategories[0] };
        }
    },
    causes: (state: CrudState) => state.causes,
    oneCause: (state: CrudState) => (causeId: string) => {
        const filteredCauses = state.causes.filter((cause) => cause.id === causeId);
        if (filteredCauses.length > 0) {
            return { ...filteredCauses[0] };
        }
    },
    symptoms: (state: CrudState) => state.symptoms,
    oneSymptom: (state: CrudState) => (symptomId: string) => {
        const filteredSymptoms = state.symptoms.filter((symptom) => symptom.id === symptomId);
        if (filteredSymptoms.length > 0) {
            return { ...filteredSymptoms[0] };
        }
    },
    selfCares: (state: CrudState) => state.selfCares,
    oneSelfCare: (state: CrudState) => (selfCareId: string) => {
        const filteredSelfCares = state.selfCares.filter((selfCare) => selfCare.id === selfCareId);
        if (filteredSelfCares.length > 0) {
            return { ...filteredSelfCares[0] };
        }
    },
    needsDataUpgrade: (state: CrudState) => (module: string) => {
        return state.needsDataUpgrade[module];
    },
    documents: (state: CrudState) => state.requisitionDocuments,
    requisitionDocuments: (state: CrudState) => (requisitionDocument: ITableRequisitionDocuments) => {
        const requisitionId = requisitionDocument.requisitionId;
        const filteredRequisitionDocuments: ITableRequisitionDocuments []
            = state.requisitionDocuments.filter((currentRequisitionDocument) => {
                return currentRequisitionDocument.requisitionId === requisitionId
                && currentRequisitionDocument.documentName === requisitionDocument.documentName;
            });
        if (filteredRequisitionDocuments && filteredRequisitionDocuments.length > 0) {
            return filteredRequisitionDocuments;
        }

        return null;
    },
    physicianNotes: (state: CrudState) => state.physicianNotes,
};

const { read } = getStoreAccessors<CrudState, State>('');

export const readOneUserById = read(getters.oneUserById);
export const readOneUserByEmail = read(getters.oneUserByEmail);
export const readUsers = read(getters.users);
export const readOnePatientById = read(getters.onePatientById);
export const readOnePatientByEmail = read(getters.onePatientByEmail);
export const readPatients = read(getters.patients);
export const readOneVisit = read(getters.oneVisit);
export const readVisits = read(getters.visits);

export const readOneRequisition = read(getters.oneRequisition);
export const readRequisitions = read(getters.requisitions);

export const readPackages = read(getters.packages);
export const readOnePackage = read(getters.onePackage);

export const readPanels = read(getters.panels);
export const readOnePanel = read(getters.onePanel);

export const readBiomarkers = read(getters.biomarkers);
export const readOneBiomarker = read(getters.oneBiomarker);

export const readBiomarkerResults = read(getters.biomarkerResults);
export const readOneBiomarkerResult = read(getters.oneBiomarkerResult);
export const readRequisitionDocument = read(getters.requisitionDocuments);
export const readRequisitionDocuments = read(getters.documents);


export const readExercises = read(getters.exercises);
export const readOneExercise = read(getters.oneExercise);

export const readFoods = read(getters.foods);
export const readOneFood = read(getters.oneFood);

export const readSupplements = read(getters.supplements);
export const readOneSupplement = read(getters.oneSupplement);

export const readOneRecommendation = read(getters.oneRecommendation);
export const readRecommendations = read(getters.recommendations);

export const readCategories = read(getters.categories);
export const readOneCategory = read(getters.oneCategory);

export const readCauses = read(getters.causes);
export const readOneCause = read(getters.oneCause);

export const readSymptoms = read(getters.symptoms);
export const readOneSymptom = read(getters.oneSymptom);

export const readSelfCares = read(getters.selfCares);
export const readOneSelfCare = read(getters.oneSelfCare);

export const readNeedsDataUpgrade = read(getters.needsDataUpgrade);
export const readPhysicianNotes = read(getters.physicianNotes);
