import gql from 'graphql-tag';

export * from './biomarkersMutations';
export * from './packagesMutations';
export * from './panelsMutations';

export const CREATE_SUPPLEMENT_MUTATION = gql`
  mutation createSupplement($supplementInput: SupplementCreate!) {
    createSupplement(supplementInput: $supplementInput) {
      id
      name
      supplementGroup
      description
    }
  }
`;

export const CREATE_RECOMMENDATION_MUTATION = gql`
  mutation createRecommendation($recommendationInput: RecommendationCreate!) {
    createRecommendation(recommendationInput: $recommendationInput) {
      id
      name
      biomarkerId
      sex
      inRange
      outOfRangeType
      summaryDescription
      causesDescription
      symptomsDescription
      foodsToEatDescription
      foodsToAvoidDescription
      supplementsDescription
      selfCareDescription
      additionalTestsDescription
      followUpsDescription
      foodsToAvoid {
        id
        name
        foodGroup
        description
      }
      foodsToEat {
        id
        name
        foodGroup
        description
      }
      supplements {
        id
        name
        supplementGroup
        description
      }
      exercises {
        id
        name
        description
        exerciseType
      }
      additionalTests {
        id
        name
        description
        questTestCode
        frequencyPerYear
        modeOfAcquisition
        questPrice
        sex
        biomarkers {
          id
          categories {
            id
          }
          name
          questBiomarkerCode
          modeOfAcquisition
          measurementUnits
          resultDataType
          sexDetails {
            id
            biomarkerId
            sex
            whyItMatters
            oneLineDescription
            questRefRangeLow
            questRefRangeHigh
            optimalRangeLow
            optimalRangeHigh
            crossReferenceBiomarkers
            notes
            resourcesCited
            recommendations {
              id
              name
              biomarkerId
              sex
              inRange
              outOfRangeType
              summaryDescription
              causesDescription
              symptomsDescription
              foodsToEatDescription
              foodsToAvoidDescription
              supplementsDescription
              selfCareDescription
              additionalTestsDescription
              followUpsDescription
            }
            critical {
              id
              sexSpecificId
              isPriority1
              priority1Range
              isPriority2
              priority2Range
            }
          }
        }
      }
      causes {
        id
        name
        description
      }
      symptoms {
        id
        name
        description
        symptomType
      }
      selfcares {
        id
        name
        description
      }
    }
  }
`;

export const CREATE_SELF_CARE_MUTATION = gql`
  mutation createSelfcare($selfcareInput: SelfcareCreate!) {
    createSelfcare(selfcareInput: $selfcareInput) {
      id
      name
      description
    }
  }
`;

export const CREATE_EXERCISE_MUTATION = gql`
  mutation createExercise($exerciseInput: ExerciseCreate!) {
    createExercise(exerciseInput: $exerciseInput) {
      id
      name
      description
      exerciseType
    }
  }
`;

export const CREATE_FOOD_MUTATION = gql`
  mutation createFood($foodInput: FoodCreate!) {
    createFood(foodInput: $foodInput) {
      id
      name
      foodGroup
      description
    }
  }
`;

export const CREATE_CATEGORY_MUTATION = gql`
  mutation createCategory($categoryInput: CategoryCreate!) {
    createCategory(categoryInput: $categoryInput) {
      id
      categoryName
      description
      biomarkers {
        id
        name
        questBiomarkerCode
      }
    }
  }
`;

export const CREATE_SYMPTOM_MUTATION = gql`
  mutation createSymptom($symptomInput: SymptomCreate!) {
    createSymptom(symptomInput: $symptomInput) {
      id
      name
      description
      symptomType
    }
  }
`;

export const CREATE_CAUSE_MUTATION = gql`
  mutation createCause($causeInput: CauseCreate!) {
    createCause(causeInput: $causeInput) {
      id
      name
      description
    }
  }
`;

export const UPDATE_SUPPLEMENT_MUTATION = gql`
  mutation updateSupplement($id: UUID!, $supplementInput: SupplementUpdate!) {
    updateSupplement(id: $id, supplementInput: $supplementInput) {
      id
      name
      supplementGroup
      description
    }
  }
`;

export const UPDATE_RECOMMENDATION_MUTATION = gql`
  mutation updateRecommendation($id: UUID!, $recommendationInput: RecommendationUpdate!) {
    updateRecommendation(id: $id, recommendationInput: $recommendationInput) {
      id
      name
      biomarkerId
      sex
      inRange
      outOfRangeType
      summaryDescription
      causesDescription
      symptomsDescription
      foodsToEatDescription
      foodsToAvoidDescription
      supplementsDescription
      selfCareDescription
      additionalTestsDescription
      followUpsDescription
      foodsToAvoid {
        id
        name
        foodGroup
        description
      }
      foodsToEat {
        id
        name
        foodGroup
        description
      }
      supplements {
        id
        name
        supplementGroup
        description
      }
      exercises {
        id
        name
        description
        exerciseType
      }
      additionalTests {
        id
        name
        description
        questTestCode
        frequencyPerYear
        modeOfAcquisition
        questPrice
        sex
        biomarkers {
          id
          categories {
            id
          }
          name
          questBiomarkerCode
          modeOfAcquisition
          measurementUnits
          resultDataType
          sexDetails {
            id
            biomarkerId
            sex
            whyItMatters
            oneLineDescription
            questRefRangeLow
            questRefRangeHigh
            optimalRangeLow
            optimalRangeHigh
            crossReferenceBiomarkers
            notes
            resourcesCited
            recommendations {
              id
              name
              biomarkerId
              sex
              inRange
              outOfRangeType
              summaryDescription
              causesDescription
              symptomsDescription
              foodsToEatDescription
              foodsToAvoidDescription
              supplementsDescription
              selfCareDescription
              additionalTestsDescription
              followUpsDescription
            }
            critical {
              id
              sexSpecificId
              isPriority1
              priority1Range
              isPriority2
              priority2Range
            }
          }
        }
      }
      causes {
        id
        name
        description
      }
      symptoms {
        id
        name
        description
        symptomType
      }
      selfcares {
        id
        name
        description
      }
    }
  }
`;

export const UPDATE_EXERCISE_MUTATION = gql`
  mutation updateExercise($exerciseInput: ExerciseUpdate!, $id: UUID!) {
    updateExercise(exerciseInput: $exerciseInput, id: $id) {
      id
      name
      description
      exerciseType
    }
  }
`;

export const UPDATE_FOOD_MUTATION = gql`
  mutation updateFood($foodInput: FoodUpdate!, $id: UUID!) {
    updateFood(foodInput: $foodInput, id: $id) {
      id
      name
      foodGroup
      description
    }
  }
`;

export const UPDATE_CATEGORY_MUTATION = gql`
  mutation updateCategory($categoryInput: CategoryUpdate!, $id: UUID!) {
    updateCategory(categoryInput: $categoryInput, id: $id) {
      id
      categoryName
      description
      biomarkers {
        id
        name
        questBiomarkerCode
      }
    }
  }
`;

export const UPDATE_SYMPTOM_MUTATION = gql`
  mutation updateSymptom($id: UUID!, $symptomInput: SymptomUpdate!) {
    updateSymptom(id: $id, symptomInput: $symptomInput) {
      id
      name
      description
      symptomType
    }
  }
`;

export const UPDATE_CAUSE_MUTATION = gql`
  mutation updateCause($causeInput: CauseUpdate!, $id: UUID!) {
    updateCause(causeInput: $causeInput, id: $id) {
      id
      name
      description
    }
  }
`;

export const UPDATE_SELF_CARE_MUTATION = gql`
  mutation updateSelfcare($id: UUID!, $selfcareInput: SelfcareUpdate!) {
    updateSelfcare(id: $id, selfcareInput: $selfcareInput) {
      id
      name
      description
    }
  }
`;

export const DELETE_FOOD_MUTATION = gql`
  mutation deleteFood($id: UUID!) {
    deleteFood(id: $id)
  }
`;

export const DELETE_EXERCISE_MUTATION = gql`
  mutation deleteExercises($id: UUID!) {
    deleteExercises(id: $id)
  }
`;

export const DELETE_SUPPLEMENT_MUTATION = gql`
  mutation deleteSupplement($id: UUID!) {
    deleteSupplement(id: $id)
  }
`;

export const DELETE_RECOMMENDATION_MUTATION = gql`
  mutation deleteRecommendation($id: UUID!) {
    deleteRecommendation(id: $id)
  }
`;

export const DELETE_CATEGORY_MUTATION = gql`
  mutation deleteCategory($id: UUID!) {
    deleteCategory(id: $id)
  }
`;

export const DELETE_SYMPTOM_MUTATION = gql`
  mutation deleteSymptom($id: UUID!) {
    deleteSymptom(id: $id)
  }
`;

export const DELETE_CAUSE_MUTATION = gql`
  mutation deleteCause($id: UUID!) {
    deleteCause(id: $id)
  }
`;

export const DELETE_SELF_CARE_MUTATION = gql`
  mutation deleteSelfcare($id: UUID!) {
    deleteSelfcare(id: $id)
  }
`;
