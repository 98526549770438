import gql from 'graphql-tag';

export const GET_SCHEMA_QUERY = gql`
query IntrospectionQuery {
  __schema {
    types {
      name
      fields {
        name
        description
      }
    }
  }
}
`;
