import { graphQlApi } from '@/graphql/graphqlApi';
import { api } from '@/api';
import router from '@/router';
import { getLocalToken, removeLocalToken, saveLocalToken } from '@/utils';
import { getStoreAccessors } from 'typesafe-vuex';
import { ActionContext } from 'vuex';
import { State } from '../state';
import {
    commitAddNotification,
    commitSetLoggedIn,
    commitSetLogInError,
    commitSetToken,
    commitSetUserProfile,
    commitSetAppLoading,
} from './mutations';
import { MainState } from './state';

type MainContext = ActionContext<MainState, State>;

export const actions = {

    async actionLogIn(context: MainContext, payload: { username: string; password: string }) {
        try {
            commitSetAppLoading(context, true);
            const response = await graphQlApi.logIn(payload.username, payload.password);
            const token = response.createToken;
            if (token && token.accessToken
                && token.accessToken.length > 0) { // this mean that the login in was successful
                saveLocalToken(token.accessToken);
                commitSetToken(context, token);
                commitSetLoggedIn(context, true);
                commitSetLogInError(context, false);
                await dispatchGetUserProfile(context);
                await dispatchRouteLoggedIn(context);
                commitAddNotification(context, { content: 'Logged in', color: 'success' });
            } else {
                await dispatchLogOut(context);
            }
        } catch (err) {
            commitSetLogInError(context, true);
            await dispatchLogOut(context);
        } finally {
            commitSetAppLoading(context, false);
        }
    },
    async actionCheckCredentials(context: MainContext, payload: { username: string; password: string }) {
        try {
            commitSetAppLoading(context, true);
            const response = await api.logInCheckCredentials(payload.username, payload.password);
            const userId = response.data.user_id;
            if (userId) {
                dispatchRouteValidationCode(context, {user_id: userId});
            } else {
                await dispatchLogOut(context);
            }
        } catch (err) {
            commitSetLogInError(context, true);
            await dispatchLogOut(context);
        } finally {
            commitSetAppLoading(context, false);
        }
    },
    async actionValidateSmsCode(context: MainContext, payload: { code: string; user_id: string }) {
        try {
            commitSetAppLoading(context, true);
            const response = await api.logInValidateCode(payload.code, payload.user_id);
            const token = response.data.access_token;
            if (token) {
                saveLocalToken(token);
                commitSetToken(context, token);
                commitSetLoggedIn(context, true);
                commitSetLogInError(context, false);
                await dispatchGetUserProfile(context);
                await dispatchRouteLoggedIn(context);
                commitAddNotification(context, { content: 'Logged in', color: 'success' });
            } else {
                await dispatchLogOut(context);
            }
        } catch (err) {
            commitSetLogInError(context, true);
            await dispatchLogOut(context);
        } finally {
            commitSetAppLoading(context, false);
        }
    },
    async actionGetUserProfile(context: MainContext) {
        try {
            commitSetAppLoading(context, true);
            const response = await graphQlApi.getUserMe();
            if (response.data) {
                commitSetUserProfile(context, response.data.userMe);
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Error while getting user Data', color: 'error' });
        } finally {
            commitSetAppLoading(context, false);
        }
    },
    async actionUpdateUserProfile(context: MainContext, payload) {
        const loadingNotification = { content: 'saving', color: 'info' };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                graphQlApi.updateMe(payload),
                await new Promise<void>((resolve) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetUserProfile(context, response.data.updateUserMe);
            commitAddNotification(context, { content: 'Profile successfully updated', color: 'success' });
        } catch (error) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Error while updating profile', color: 'error' });
        }
    },
    async actionCheckLoggedIn(context: MainContext) {
        if (!context.state.isLoggedIn) {
            let token = context.state.token;
            if (!token) {
                const localToken = getLocalToken();
                if (localToken) {
                    commitSetToken(context, localToken);
                    token = localToken;
                }
            }
            if (token) {
                try {
                    const response = await graphQlApi.getUserMe();
                    commitSetLoggedIn(context, true);
                    commitSetUserProfile(context, response.data.userMe);
                } catch (error) {
                    await dispatchRemoveLogIn(context);
                }
            } else {
                await dispatchRemoveLogIn(context);
            }
        }
    },
    async actionRemoveLogIn(context: MainContext) {
        removeLocalToken();
        commitSetToken(context, '');
        commitSetLoggedIn(context, false);
    },
    async actionResetStore(context: MainContext) {
        await context.dispatch('actionResetCrudState');
    },
    async actionLogOut(context: MainContext) {
        await dispatchRemoveLogIn(context);
        await dispatchRouteLogOut(context);
        await dispatchResetStore(context);
    },
    async actionUserLogOut(context: MainContext) {
        await dispatchLogOut(context);
        commitAddNotification(context, { content: 'Logged out', color: 'success' });
    },
    actionRouteLogOut(context: MainContext) {
        if (router.currentRoute.path !== '/login') {
            router.push('/login');
        }
    },
    async actionCheckApiError(context: MainContext, payload: any) {
        if (payload && payload.response && payload.response!.status === 401) {
            await dispatchLogOut(context);
        }
    },
    actionRouteLoggedIn(context: MainContext) {
        if (router.currentRoute.path === '/login' || router.currentRoute.path === '/'
                || router.currentRoute.name === 'validate-code') {
            router.push('/main/profile');
        }
    },
    actionRouteValidateCode(context: MainContext, payload) {
        if (router.currentRoute.path === '/login' || router.currentRoute.path === '/') {
            router.push({name: 'validate-code', params: {user_id: payload.user_id}});
        }
    },
    addNotification(context: MainContext, payload: any) {
        commitAddNotification(context, { content: payload.message, color: payload.color });
    },
    /**
     * Password recovery disabled for the moment
     * this code block is just for future reference, it targets the old api
     */
    /*
    async passwordRecovery(context: MainContext, payload: { username: string }) {
        const loadingNotification = { content: 'Sending password recovery email', color: 'info' };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.passwordRecovery(payload.username),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitAddNotification(context, { content: 'Password recovery email sent', color: 'success' });
            await dispatchLogOut(context);
        } catch (error) {
            commitAddNotification(context, { color: 'error', content: 'Incorrect username' });
        }
    },
    */
   /**
    * Reset password disabled for the moment
    * this code block is just for future reference, it targets the old api
    */
   /*
    async resetPassword(context: MainContext, payload: { password: string, token: string }) {
        const loadingNotification = { content: 'Resetting password', color: 'info' };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.resetPassword(payload.password, payload.token),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitAddNotification(context, { content: 'Password successfully reset', color: 'success' });
            await dispatchLogOut(context);
        } catch (error) {
            commitAddNotification(context, { color: 'error', content: 'Error resetting password' });
        }
    },
    */
    actionSetAppLoading(context: MainContext, payload: boolean) {
        commitSetAppLoading(context, payload);
    },
};

const { dispatch } = getStoreAccessors<MainState | any, State>('');

export const dispatchCheckApiError = dispatch(actions.actionCheckApiError);
export const dispatchCheckLoggedIn = dispatch(actions.actionCheckLoggedIn);
export const dispatchGetUserProfile = dispatch(actions.actionGetUserProfile);
export const dispatchLogIn = dispatch(actions.actionLogIn);

export const dispatchValidateSmsCode = dispatch(actions.actionValidateSmsCode);

export const dispatchLogOut = dispatch(actions.actionLogOut);
export const dispatchResetStore = dispatch(actions.actionResetStore);
export const dispatchUserLogOut = dispatch(actions.actionUserLogOut);
export const dispatchRemoveLogIn = dispatch(actions.actionRemoveLogIn);
export const dispatchRouteLoggedIn = dispatch(actions.actionRouteLoggedIn);
export const dispatchRouteValidationCode = dispatch(actions.actionRouteValidateCode);

export const dispatchRouteLogOut = dispatch(actions.actionRouteLogOut);
export const dispatchUpdateUserProfile = dispatch(actions.actionUpdateUserProfile);
export const dispatchAddNotification = dispatch(actions.addNotification);
// export const dispatchPasswordRecovery = dispatch(actions.passwordRecovery);
// export const dispatchResetPassword = dispatch(actions.resetPassword);

export const dispatchSetAppLoading = dispatch(actions.actionSetAppLoading);
