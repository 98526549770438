import { MainState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const getters = {
    hasAdminAccess: (state: MainState) => {
        return (
            state.userProfile &&
            state.userProfile.isSuperuser && state.userProfile.isActive);
    },
    hasPhysicianAccess: (state: MainState) => {
        return (
            state.userProfile &&
            state.userProfile.isPhysician && state.userProfile.isActive);
    },
    hasNurseAccess: (state: MainState) => {
        return (
            state.userProfile &&
            state.userProfile.isNursePracticioner  && state.userProfile.isActive);
    },
    hasReviewerAccess: (state: MainState) => {
        return (
            state.userProfile &&
            state.userProfile.isReviewer && state.userProfile.isActive);
    },
    /**
     * has creator access if user is creator or superuser
     */
    hasCreatorAccess: (state: MainState) => {
        return (
            state.userProfile &&
            (state.userProfile.isCreator || state.userProfile?.isSuperuser) && state.userProfile.isActive);
    },
    loginError: (state: MainState) => state.logInError,
    dashboardShowDrawer: (state: MainState) => state.dashboardShowDrawer,
    dashboardMiniDrawer: (state: MainState) => state.dashboardMiniDrawer,
    userProfile: (state: MainState) => state.userProfile,
    userId: (state: MainState) => state.userProfile && state.userProfile.id,
    token: (state: MainState) => state.token,
    isLoggedIn: (state: MainState) => state.isLoggedIn,
    isAppLoading: (state: MainState) => state.appLoading,
    notification: (state: MainState) => state.notification,
    readRequestIndex: (state: MainState) => state.requestIndex,
};

const {read} = getStoreAccessors<MainState, State>('');

export const readDashboardMiniDrawer = read(getters.dashboardMiniDrawer);
export const readDashboardShowDrawer = read(getters.dashboardShowDrawer);
export const readHasAdminAccess = read(getters.hasAdminAccess);
export const readHasPhysicianAccess = read(getters.hasPhysicianAccess);
export const readHasNurseAccess = read(getters.hasNurseAccess);
export const readHasReviewerAcccess = read(getters.hasReviewerAccess);
export const readHasCreatorAccess = read(getters.hasCreatorAccess);
export const readCurrentUserId = read(getters.userId);


export const readIsLoggedIn = read(getters.isLoggedIn);
export const readLoginError = read(getters.loginError);
export const readToken = read(getters.token);
export const readUserProfile = read(getters.userProfile);
export const readNotification = read(getters.notification);
export const readAppLoading = read(getters.isAppLoading);
export const readRequestIndex = read(getters.readRequestIndex);
