import { ActionContext } from 'vuex';

import * as mutations from '@/store/crud/mutations';

import { CrudState } from '@/store/crud/state';

import { State } from '@/store/state';
import { commitAddNotification } from '@/store/main/mutations';
import { dispatchCheckApiError } from '@/store/main/actions';

import { graphQlApi } from '@/graphql/graphqlApi';
import { ICreateExercise, IExercise, IUpdateExercise } from '@/interfaces/exercises';
import { crudModules } from '@/constants/globalConstants';


type MainContext = ActionContext<CrudState, State>;


/**
 * Returns cached data
 * or false if it is expired
 */
const getCachedData = async (context: MainContext, needsDataUpgrade: boolean) => {
    const cachedData = context.getters.exercises;
    const dispatchAction = 'exercisesDataExpired';

    if (cachedData && cachedData.length > 0) {
        const dataExpired = await context.dispatch(dispatchAction);
        // check data expiration
        if (dataExpired) {
            // re run this action without cached data
            return false; // data is expired so we need to fetch new data
        } else {
            if (needsDataUpgrade) {
                if (context.getters.needsDataUpgrade(crudModules.EXERCISES)) {
                    return false;
                }
            }
            return cachedData;
        }
    } else {
        return false;
    }
};

/**
 * Actions sub module for Exercises actions
 */
export default {
    /**
     * Gets all exercises with minimal information to be used in dropdwons and lists
     * @param context - Vuex Action context
     * @returns - Promise with exercises or false if error
     */
    async actionGetExercisesForList(context: MainContext) {
            try {
                // check cached data
                const cachedData = await getCachedData(context, false);
                if (cachedData) {
                    return cachedData;
                }
                const response = await graphQlApi.getAllExercisesForList();
                if (response) {
                    mutations.commitSetExercises(context, response.data.exercises);
                    mutations.setModuleNeedsDataUpgrade(context, {
                        module: crudModules.EXERCISES,
                        needsDataUpgrade: true,
                    });
                    mutations.setDataUpdated(context, crudModules.EXERCISES);
                    return Promise.resolve(response.data.exercises);
                } else {
                    throw new Error('Failed while fetching exercises');
                }
            } catch (error) {
                await dispatchCheckApiError(context, error);
                commitAddNotification(context, { content: 'Error while fetching exercises', color: 'error' });
                return Promise.resolve(false);
            }
        },
    /**
     * Gets all exercises with all available data
     * @param context - Vuex Action context
     * @returns - Promise with exercises or false if error
     */
    async actionGetExercises(context: MainContext) {
        try {
            // check cached data
            const cachedData = await getCachedData(context, true);
            if (cachedData) {
                return cachedData;
            }
            const response = await graphQlApi.getAllExercises();
            if (response) {
                mutations.commitSetExercises(context, response.data.exercises);
                mutations.setModuleNeedsDataUpgrade(context, {
                    module: crudModules.EXERCISES,
                    needsDataUpgrade: false,
                });
                mutations.setDataUpdated(context, crudModules.EXERCISES);
                return Promise.resolve(response.data.exercises);
            } else {
                throw new Error('Failed while fetching exercises');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Error while fetching exercises', color: 'error' });
            return Promise.resolve(false);
        }
    },
    /**
     * Gets a single exercise with all available data from the DB
     * @param context - Vuex Action context
     * @param id - Exercise ID
     * @returns - Promise with exercise or false if error
     */
    async actionGetExerciseById(context: MainContext, id: string) {
        try {
            const response = await graphQlApi.getExerciseById(id);
            if (response) {
                mutations.commitSetExercise(context, response.data.exerciseById);
                return Promise.resolve(response.data.exerciseById);
            } else {
                throw new Error('Failed while fetching exercise');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Error while fetching exercise', color: 'error' });
            return Promise.resolve(false);
        }
    },
    /**
     * updates a single exercise in the DB
     * @param context - Vuex Action context
     * @param payload - Exercise to add
     * @returns - Promise with added exercise or false if error
     */
    async actionUpdateExercise(context: MainContext, payload: { exerciseId: string, exercise: IUpdateExercise }) {
        const loadingNotification = { content: 'saving', color: 'info' };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                graphQlApi.updateExercise(payload.exerciseId, payload.exercise),
                await new Promise<void>((resolve) => setTimeout(() => resolve(), 500)),
            ]))[0];
            if (response) {
                commitAddNotification(context, { content: 'Exercise successfully updated', color: 'success' });
                mutations.commitSetExercise(context, response.data.updateExercise);
                return Promise.resolve(response.data.updateExercise);
            } else {
                throw new Error('Failed while updating exercise');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Failed while updating exercise', color: 'error' });
            return Promise.resolve(false);
        }
    },
    /**
     * Creates a new exercise in the DB
     * @param context - Vuex Action context
     * @param payload - Exercise to add
     * @returns - Promise with added exercise or false if error
     */
    async actionCreateExercise(context: MainContext, payload: ICreateExercise) {
        const loadingNotification = { content: 'saving', color: 'info' };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                graphQlApi.createExcercise(payload),
                await new Promise<void>((resolve) => setTimeout(() => resolve(), 500)),
            ]))[0];
            if (response) {
                commitAddNotification(context, { content: 'Exercise successfully created', color: 'success' });
                mutations.commitSetExercise(context, response.data.createExercise);
                return Promise.resolve(response.data.createExercise);
            } else {
                throw new Error('Failed while creating exercise');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Failed while creating exercise', color: 'error' });
            return Promise.resolve(false);
        }
    },
    /**
     * deletes a single exercise from the DB
     * @param context - Vuex Action context
     * @param payload - Exercise to add
     * @returns - Promise with added exercise or false if error
     */
    async actionDeleteExercise(context: MainContext, payload: { exerciseId: string }) {
        const loadingNotification = { content: 'saving', color: 'info' };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                graphQlApi.deleteExercise(payload.exerciseId),
                await new Promise<void>((resolve) => setTimeout(() => resolve(), 500)),
            ]))[0];
            if (response) {
                commitAddNotification(context, { content: 'Exercise successfully deleted', color: 'success' });
                mutations.commitDeleteExercise(context, {id: payload.exerciseId} as IExercise);
                return Promise.resolve(response.data.deleteExercises);
            } else {
                throw new Error('Failed while deleting exercise');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Failed while removing exercise', color: 'error' });
            return Promise.resolve(false);
        }
    },
};

