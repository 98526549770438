import { ActionContext } from 'vuex';

import * as mutations from '@/store/crud/mutations';

import { CrudState } from '@/store/crud/state';

import { State } from '@/store/state';
import { commitAddNotification } from '@/store/main/mutations';
import { dispatchCheckApiError } from '@/store/main/actions';

import { graphQlApi } from '@/graphql/graphqlApi';
import { IUserProfile, IUserProfileCreate, IUserProfileUpdate } from '@/interfaces/users';
import { crudModules } from '@/constants/globalConstants';


type MainContext = ActionContext<CrudState, State>;


/**
 * Returns user cached data
 * or false if it is expired
 * If the view or components needs the full data, there is a flag
 * "needsDataUpgrade" to check if the existing cache is list or full data.
 * If it is list, then the data is not valid and needs to be upgraded.
 */
const getUsersCachedData = async (context: MainContext, needsDataUpgrade = false) => {
    if (context.getters.users && context.getters.users.length > 0) {
        const dataExpired = await context.dispatch('usersDataExpired');
        // check data expiration
        if (dataExpired) {
            // re run this action without cached data
            return false; // data is expired so we need to fetch new data
        } else {
            if (needsDataUpgrade) {
                if (context.getters.needsDataUpgrade(crudModules.USERS)) {
                    return false;
                }
            }
            return context.getters.users;
        }
    } else {
        return false;
    }
};

/**
 * Actions sub module for user actions
 */
export default {
    /**
     * get all users from the database with all possible fields
     * @param context - vuex action context
     * @returns - returns a promise resolved with the users if successful or false if not
     */
    async actionGetUsers(context: MainContext) {
        try {
            // check cached data
            const cachedUsers = await getUsersCachedData(context, true);
            if (cachedUsers) {
                return cachedUsers;
            }

            const response = await graphQlApi.getAllUsers();
            if (response) {
                mutations.commitSetUsers(context, response.data.users);
                mutations.setModuleNeedsDataUpgrade(context, {
                    module: crudModules.USERS,
                    needsDataUpgrade: false,
                });
                mutations.setDataUpdated(context, crudModules.USERS);
                return Promise.resolve(response.data.users);
            } else {
                throw new Error('Failed while getting users');
            }
        } catch (error) {
            commitAddNotification(context, { content: 'Failed while fetching Users', color: 'error' });
            await dispatchCheckApiError(context, error);
            return Promise.resolve(false);
        }
    },
    /**
     * Gets all users with minimal fields to show in the user list
     * @param context - vuex action context
     * @returns - returns a promise resolved with the users if successful or false if not
     */
    async actionGetUsersForList(context: MainContext) {
        try {
            // check cached data
            const cachedUsers = await getUsersCachedData(context);
            if (cachedUsers) {
                return cachedUsers;
            }
            const response = await graphQlApi.getAllUsersForTableView();
            if (response) {
                mutations.commitSetUsers(context, response.data.users);
                mutations.setModuleNeedsDataUpgrade(context, {
                    module: crudModules.USERS,
                    needsDataUpgrade: true,
                });
                mutations.setDataUpdated(context, crudModules.USERS);
                return Promise.resolve(response.data.users);
            } else {
                throw new Error('Failed while getting users');
            }
        } catch (error) {
            commitAddNotification(context, { content: 'Failed while fetching Users', color: 'error' });
            await dispatchCheckApiError(context, error);
            return Promise.resolve(false);
        }
    },
    /**
     * gets a single user by id
     * @param context - vuex action context
     * @param id - the id of the user to get
     * @returns - returns a promise resolved with the user if successful or false if not
     */
    async actionGetUserById(context: MainContext, id: string) {
        try {
            const response = await graphQlApi.getUserById(id);
            if (response) {
                mutations.commitSetUser(context, response.data.userById);
                return Promise.resolve(response.data.userById);
            } else {
                throw new Error('Failed while getting user');
            }
        } catch (error) {
            commitAddNotification(context, { content: 'Failed while fetching Users', color: 'error' });
            await dispatchCheckApiError(context, error);
            return Promise.resolve(false);
        }
    },
    /**
     * Gets a single user by email
     * @param context - vuex action context
     * @param email - the email of the user to get
     * @returns - returns a promise resolved with the user if successful or false if not
     */
    async actionGetUserByEmail(context: MainContext, payload: { email: string; silent?: boolean }) {
        try {
            const response = await graphQlApi.getUserByEmail(payload.email);
            if (response) {
                return Promise.resolve(response.data.userByEmail);
            } else {
                if (!payload.silent) {
                    throw new Error('Failed while getting user');
                }
            }
        } catch (error) {
            commitAddNotification(context, { content: 'Failed while fetching Users', color: 'error' });
            await dispatchCheckApiError(context, error);
            return Promise.resolve(false);
        }
    },
    /**
     * Gets an user by user group
     * @param context - vuex action context
     * @param userGroup - the user group to get
     * @returns - returns a promise resolved with the user if successful or false if not
     */
    async actionGetUserByUserGroupId(context: MainContext, userGroup: string) {
        try {
            const response = await graphQlApi.getUserByUserGroup(userGroup);
            if (response) {
                mutations.commitSetUser(context, response.data.userByUserGroup);
                return Promise.resolve(response.data.userByUserGroup);
            } else {
                throw new Error('Failed while getting user');
            }
        } catch (error) {
            commitAddNotification(context, { content: 'Failed while fetching Users', color: 'error' });
            await dispatchCheckApiError(context, error);
            return Promise.resolve(false);
        }
    },
    /**
     * Updates an user in the database
     * @param context - vuex action context
     * @param payload - the user to update
     * @returns - returns a promise resolved with the user if successful or false if not
     */
    async actionUpdateUser(context: MainContext, payload: { id: string, user: IUserProfileUpdate }) {
        const loadingNotification = { content: 'saving', color: 'info' };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                graphQlApi.updateUser(payload.id, payload.user),
                await new Promise<void>((resolve) => setTimeout(() => resolve(), 500)),
            ]))[0];
            if (response) {
                commitAddNotification(context, { content: 'User successfully updated', color: 'success' });
                mutations.commitSetUser(context, response.data.updateUsers);
                return Promise.resolve(response.data.updateUsers);
            } else {
                throw new Error('Failed while updating user');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Failed while updating User', color: 'error' });
            return Promise.resolve(false);
        }
    },
    /**
     * Creates a new user in the database
     * @param context - vuex action context
     * @param payload - the user to create
     * @returns - returns a promise resolved with the user if successful or false if not
     */
    async actionCreateUser(context: MainContext, payload: IUserProfileCreate) {
        const loadingNotification = { content: 'saving', color: 'info' };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                graphQlApi.createUser(payload),
                await new Promise<void>((resolve) => setTimeout(() => resolve(), 500)),
            ]))[0];
            if (response) {
                mutations.commitSetUser(context, response.data.createUsers);
                return Promise.resolve(response.data.createUsers);
            } else {
                throw new Error('Failed while creating user');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            return Promise.resolve(false);
        }
    },
    /**
     * Deletes an user from the database
     * @param context - vuex action context
     * @param payload - the user to delete
     * @returns - returns a promise resolved with "true"" if successful or false if not
     */
    async actionDeleteUser(context: MainContext, payload: { id: string }) {
        const loadingNotification = { content: 'saving', color: 'info' };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                graphQlApi.deleteUser(payload.id),
                await new Promise<void>((resolve) => setTimeout(() => resolve(), 500)),
            ]))[0];
            if (response) {
                commitAddNotification(context, { content: 'User successfully deleted', color: 'success' });
                mutations.commitDeleteUser(context, payload as IUserProfile);
                return Promise.resolve(true);
            } else {
                throw new Error('Failed while deleting user');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Failed while deleting user', color: 'error' });
            return Promise.resolve(false);
        }
    },
};
