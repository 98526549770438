import gql from 'graphql-tag';

export const GET_RECOMMENDATIONS_FOR_LIST_QUERY = gql`
query recommendations($skip: Int, $limit: Int) {
    recommendations(skip: $skip, limit: $limit){
        id
        name
    }
}
`;

export const GET_ALL_RECOMMENDATIONS_FOR_TABLE_QUERY = gql`
query recommendations($skip: Int, $limit: Int) {
    recommendations(skip: $skip, limit: $limit){
        id
        name
        inRange
        sex
        outOfRangeType
        biomarkerId
    }
}
`;

export const GET_ALL_RECOMMENDATIONS_QUERY = gql`
query recommendations($skip: Int, $limit: Int) {
    recommendations(skip: $skip, limit: $limit){
        id
        name
        biomarkerId
        sex
        inRange
        outOfRangeType
        summaryDescription
        causesDescription
        symptomsDescription
        foodsToEatDescription
        foodsToAvoidDescription
        supplementsDescription
        selfCareDescription
        additionalTestsDescription
        followUpsDescription
        foodsToAvoid{
            id
            name
            foodGroup
            description
        }
        foodsToEat{
            id
            name
            foodGroup
            description
        }
        supplements{
            id
            name
            supplementGroup
            description
        }
        exercises{
            id
            name
            description
            exerciseType
        }
        additionalTests{
            id
            name
            description
            questTestCode
            frequencyPerYear
            modeOfAcquisition
            questPrice
            sex
            biomarkers{
                id
                categories{
                    id
                }
                name
                questBiomarkerCode
                modeOfAcquisition
                measurementUnits
                resultDataType
                sexDetails{
                    id
                    biomarkerId
                    sex
                    whyItMatters
                    oneLineDescription
                    questRefRangeLow
                    questRefRangeHigh
                    optimalRangeLow
                    optimalRangeHigh
                    crossReferenceBiomarkers
                    notes
                    resourcesCited
                    recommendations{
                        id
                        name
                        biomarkerId
                        sex
                        inRange
                        outOfRangeType
                        summaryDescription
                        causesDescription
                        symptomsDescription
                        foodsToEatDescription
                        foodsToAvoidDescription
                        supplementsDescription
                        selfCareDescription
                        additionalTestsDescription
                        followUpsDescription
                    }
                    critical{
                        id
                        sexSpecificId
                        isPriority1
                        priority1Range
                        isPriority2
                        priority2Range
                    }
                }
            }
        }
        causes{
            id
            name
            description
        }
        symptoms{
            id
            name
            description
            symptomType
        }
        selfcares{
            id
            name
            description
        }
    }
}
`;

export const GET_RECOMMENDATION_BY_ID_QUERY = gql`
query recommendationById($id: UUID!) {
    recommendationById(id: $id){
        id
        name
        biomarkerId
        sex
        inRange
        outOfRangeType
        summaryDescription
        causesDescription
        symptomsDescription
        foodsToEatDescription
        foodsToAvoidDescription
        supplementsDescription
        selfCareDescription
        additionalTestsDescription
        followUpsDescription
        foodsToAvoid{
            id
            name
            foodGroup
            description
        }
        foodsToEat{
            id
            name
            foodGroup
            description
        }
        supplements{
            id
            name
            supplementGroup
            description
        }
        exercises{
            id
            name
            description
            exerciseType
        }
        additionalTests{
            id
            name
            description
            questTestCode
            frequencyPerYear
            modeOfAcquisition
            questPrice
            sex
            biomarkers{
                id
                categories{
                    id
                }
                name
                questBiomarkerCode
                modeOfAcquisition
                measurementUnits
                resultDataType
                sexDetails{
                    id
                    biomarkerId
                    sex
                    whyItMatters
                    oneLineDescription
                    questRefRangeLow
                    questRefRangeHigh
                    optimalRangeLow
                    optimalRangeHigh
                    crossReferenceBiomarkers
                    notes
                    resourcesCited
                    recommendations{
                        id
                        name
                        biomarkerId
                        sex
                        inRange
                        outOfRangeType
                        summaryDescription
                        causesDescription
                        symptomsDescription
                        foodsToEatDescription
                        foodsToAvoidDescription
                        supplementsDescription
                        selfCareDescription
                        additionalTestsDescription
                        followUpsDescription
                    }
                    critical{
                        id
                        sexSpecificId
                        isPriority1
                        priority1Range
                        isPriority2
                        priority2Range
                    }
                }
            }
        }
        causes{
            id
            name
            description
        }
        symptoms{
            id
            name
            description
            symptomType
        }
        selfcares{
            id
            name
            description
        }
    }
}
`;
