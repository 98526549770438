import gql from 'graphql-tag';

export const GET_CAUSES_FOR_LIST_QUERY = gql`
query causes($skip: Int, $limit: Int) {
    causes(skip: $skip, limit: $limit){
        id
        name
    }
}
`;

export const GET_ALL_CAUSES_QUERY = gql`
query causes($skip: Int, $limit: Int) {
    causes(skip: $skip, limit: $limit){
        id
        name
        description
    }
}
`;

export const GET_CAUSE_BY_ID_QUERY = gql`
query causeById($id: UUID!) {
    causeById(id: $id){
        id
        name
        description
    }
}
`;
