import {ActionContext} from 'vuex';
import {CrudState} from '@/store/crud/state';
import {State} from '@/store/state';
import {commitAddNotification} from '@/store/main/mutations';

import {graphQlApi} from '@/graphql/graphqlApi';
import {IRequisitionCommentInput} from '@/interfaces/requisitionComments';

type MainContext = ActionContext<CrudState, State>;

/**
 * Actions sub module for critical-results-comments actions
 */
export default {
  /**
   * Updates a single Critical result comment in database
   * @param context - MainContext
   * @param payload - IRequisitionComment, the Critical result comment to update
   * @returns - returns a Promise with the updated Critical result comment or false if error
   */
  async actionUpdateRequisitionComment(
    context: MainContext,
    payload: {id: string; comment: string},
  ) {
    const {id, ...restPayload} = payload;
    try {
      const {data, errors} = await graphQlApi.updateRequisitionComment(id, {
        ...restPayload,
      });
      if (errors) {
        throw new Error();
      }
      if (data) return data;
      commitAddNotification(context, {
        content: 'Can`t update a requisition comment',
        color: 'error',
      });
      return Promise.reject();
    } catch (e) {
      commitAddNotification(context, {
        content: 'Failed while updating a requisition comment',
        color: 'error',
      });
      return Promise.reject();
    }
  },
  /**
   * Creates a new Biomarker result in the databasee
   * This particular function does not alert the user of success or failure
   * it leaves that to the calling function since this is used during a bigger process (creating result)
   * @param context - MainContext
   * @param payload - ICreateBiomarkerResult, the biomarker result to create
   * @returns - returns a Promise with the created biomarker result or false if error
   */ async actionCreateRequisitionComment(
    context: MainContext,
    payload: IRequisitionCommentInput,
  ) {
    try {
      const {data, errors} = await graphQlApi.createRequisitionComment(payload);
      if (errors) {
        throw new Error();
      }
      if (data) return data;

      commitAddNotification(context, {
        content: 'Can`t create a new  requisition comment',
        color: 'error',
      });
      return Promise.reject();
    } catch (e) {
      commitAddNotification(context, {
        content: 'Failed while creating a new  requisition comment',
        color: 'error',
      });
      return Promise.reject();
    }
  },
  /**
   * deletes a biomarker result from the database
   * @param context - MainContext
   * @param payload - the biomarker result to delete
   * @returns - returns a promise that resolves in "True" if successful or false if error
   */ async actionDeleteRequisitionComment(context: MainContext, payload: {id: string}) {
    try {
      const {data, errors} = await graphQlApi.deleteRequisitionComment(payload.id);
      if (errors) {
        throw new Error();
      }
      if (data) return data;
      commitAddNotification(context, {
        content: 'Can`t delete a  requisition comment',
        color: 'error',
      });
      return Promise.reject();
    } catch (e) {
      commitAddNotification(context, {
        content: 'Failed while deleting a  requisition comment',
        color: 'error',
      });
      return Promise.reject();
    }
  },
};
