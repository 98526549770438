import { ActionContext } from 'vuex';

import * as mutations from '@/store/crud/mutations';

import { CrudState } from '@/store/crud/state';

import { State } from '@/store/state';
import { commitAddNotification } from '@/store/main/mutations';
import { dispatchCheckApiError } from '@/store/main/actions';



import { graphQlApi } from '@/graphql/graphqlApi';
import { ICreatePackage, IPackage, IUpdatePackage } from '@/interfaces/packages';
import { crudModules } from '@/constants/globalConstants';


type MainContext = ActionContext<CrudState, State>;

/**
 * Returns packages cached data
 * or false if it is expired
 * If the view or components needs the full data, there is a flag
 * "needsDataUpgrade" to check if the existing cache is list or full data.
 * If it is list, then the data is not valid and needs to be upgraded.
 */
const getPackagesCachedData = async (context: MainContext, needsDataUpgrade = false) => {
    if (context.getters.packages && context.getters.packages.length > 0) {
        const dataExpired = await context.dispatch('packagesDataExpired');
        // check data expiration
        if (dataExpired) {
            // re run this action without cached data
            return false; // data is expired so we need to fetch new data
        } else {
            if (needsDataUpgrade) {
                if (context.getters.needsDataUpgrade(crudModules.PACKAGES)) {
                    return false;
                }
            }
            return context.getters.packages;
        }
    } else {
        return false;
    }
};

/**
 * Actions sub module for packages actions
 */
export default {
    /**
     * Gets all packages with all available information
     * @param context - vuex action context
     * @returns - Promise with packages or false if error
     */
    async actionGetPackages(context: MainContext) {
        try {
            // check cached data
            const cachedPackages = await getPackagesCachedData(context, true);
            if (cachedPackages) {
                return cachedPackages;
            }
            const response = await graphQlApi.getAllPackages();
            if (response) {
                mutations.commitSetPackages(context, response.data.packages);
                mutations.setModuleNeedsDataUpgrade(context, {
                    module: crudModules.PACKAGES,
                    needsDataUpgrade: false,
                });
                mutations.setDataUpdated(context, crudModules.PACKAGES);
                return Promise.resolve(response.data.packages);
            } else {
                throw new Error('Failed while fetching packages');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Error while fetching packages', color: 'error' });
            return Promise.resolve(false);
        }
    },
    /**
     * returns a reduced list of packages with only the information needed for a list
     * @param context - MainContext
     * @returns - Promise<IPackage[]> - list of packages or false if error
     */
    async actionGetPackagesForList(context: MainContext) {
        try {
            const cachedPackages = await getPackagesCachedData(context);
            if (cachedPackages) {
                return cachedPackages;
            }
            const response = await graphQlApi.getAllPackagesForTableView();
            if (response) {
                mutations.commitSetPackages(context, response.data.packages);
                mutations.setModuleNeedsDataUpgrade(context, {
                    module: crudModules.PACKAGES,
                    needsDataUpgrade: true,
                });
                mutations.setDataUpdated(context, crudModules.PACKAGES);
                return Promise.resolve(response.data.packages);
            } else {
                throw new Error('Failed while fetching packages');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Error while fetching packages', color: 'error' });
            return Promise.resolve(false);
        }
    },
    /**
     * Gets a single package from the database
     * @param context - vuex action context
     * @param id - id of the package to get
     * @returns - Promise with package or false if error
     */
    async actionGetPackageById(context: MainContext, id: string) {
        try {
            const response = await graphQlApi.getPackageById(id);
            if (response) {
                mutations.commitSetPackage(context, response.data.packageById);
                return Promise.resolve(response.data.packageById);
            } else {
                throw new Error('Failed while fetching package');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Error while fetching package', color: 'error' });
            return Promise.resolve(false);
        }
    },
    /**
     * Updates a single package in the database
     * @param context - vuex action context
     * @param payload - package to update
     * @returns - Promise with package or false if error
     */
    async actionUpdatePackage(context: MainContext, payload: { packageId: string, package: IUpdatePackage }) {
        const loadingNotification = { content: 'saving', color: 'info' };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                graphQlApi.updatePackage(payload.packageId, payload.package),
                await new Promise<void>((resolve) => setTimeout(() => resolve(), 500)),
            ]))[0];
            if (response) {
                commitAddNotification(context, { content: 'Package successfully updated', color: 'success' });
                mutations.commitSetPackage(context, response.data.updatePackage);
                return Promise.resolve(response.data.updatePackage);
            } else {
                throw new Error('Failed while updating package');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Error while updating package', color: 'error' });
            return Promise.resolve(false);
        }
    },
    /**
     * Creates a new package in the database
     * @param context - vuex action context
     * @param payload - package to create
     * @returns - Promise with package or false if error
     */
    async actionCreatePackage(context: MainContext, payload: ICreatePackage) {
        const loadingNotification = { content: 'saving', color: 'info' };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                graphQlApi.createPackage(payload),
                await new Promise<void>((resolve) => setTimeout(() => resolve(), 500)),
            ]))[0];
            if (response) {
                commitAddNotification(context, { content: 'Package successfully created', color: 'success' });
                mutations.commitSetPackage(context, response.data.createPackage);
                return Promise.resolve(response.data.createPackage);
            } else {
                throw new Error('Failed while creating package');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Error while creating package', color: 'error' });
            return Promise.resolve(false);
        }
    },
    /**
     * Deletes a package from the database
     * @param context - vuex action context
     * @param payload - package to delete
     * @returns - Promise with package or false if error
     */
    async actionDeletePackage(context: MainContext, payload: { packageId: string }) {
        const loadingNotification = { content: 'saving', color: 'info' };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                graphQlApi.deletePackage(payload.packageId),
                await new Promise<void>((resolve) => setTimeout(() => resolve(), 500)),
            ]))[0];
            if (response) {
                commitAddNotification(context, { content: 'Package successfully deleted', color: 'success' });
                mutations.commitDeletePackage(context, {id: payload.packageId} as IPackage);
                return Promise.resolve(true);
            } else {
                throw new Error('Failed while deleting package');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Failed while removing package', color: 'error' });
            return Promise.resolve(false);
        }
    },
};
