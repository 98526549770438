import gql from 'graphql-tag';

export const GET_VISITS_FOR_TABLE_VIEW_QUERY = gql`
query visits($skip: Int, $limit: Int) {
    visits(skip: $skip, limit: $limit){
        id
        patient{
            id
            fname
            lname
            patientIdentifier
            patientFirebaseId
            patientContactInfo{
                email
            }
        }
        visitDate
        state
        requisition {
            id
        }
    }
}
`;

export const GET_VISITS_FOR_LIST_QUERY = gql`
query visits($skip: Int, $limit: Int) {
    visits(skip: $skip, limit: $limit){
        id
        confirmation
        visitDate
        streetAddress
        city
        state
        resultsAvailable
    }
}
`;

export const GET_VISIT_BY_ID_QUERY = gql`
query visitById($id: UUID!) {
    visitById(id: $id){
        id
        patientId
        visitDate
        streetAddress
        confirmationCode
        documents
        city
        state
        zip
        resultsAvailable
        criticalResult {
            id
            criticalReviewed
            level
        }
        visitDocuments {
            id
            docStatus
            docType
            url
            visible
        }
        patient{
            id
            fname
            lname
            patientContactInfo{
                id
                email
                city
                state
            }
        }
        packages{
            id
            name
            panels{
                id
                biomarkers{
                    id
                    name
                }
            }
        }
        biomarkerResults{
            id
            biomarkerName
            biomarker{
                id
                name
            }
            testResult
            measurementUnits
        }
    }
}
`;

export const GET_VISIT_BY_PATIENT_EMAIL_QUERY = gql`
query visitByPatientEmail($email: String!) {
    visitByPatientEmail(email: $email){
        id
        patientId
        visitDate
        streetAddress
        city
        state
        zip
        resultsAvailable
        confirmationCode
        patient{
            id
            patientIdentifier
            fname
            lname
            preferredName
            biologicalSex
            dob
            ssn
            pronouns
            ethnicOrigin
            shirtSize
            createdAt
            updatedAt
            motivational{
                id
                patientId
                tenYearHealthGoals
                purposeForJoiningFunction
                howDidTheyHearAboutFunction
                commentsBeforeBooking
                createdAt
                updatedAt
            }
            covid{
                id
                patientId
                hadCovid
                covidDate
                covidSeverity
                hasCovidResidualSymptoms
                covidResidualSymptoms
                vaccinatedForCovid
                covidVaccineName
                covidVaccineDateLastDosage
                createdAt
                updatedAt
            }
            femaleReproductiveHealth{
                id
                patientId
                currentlyOnBirthControl
                birthControlType
                timeOnBirthControl
                dateOfLastPeriod
                currentlyPregnant
                tryingToConceive
                currentMenstrualStatus
                priorPregnancies
                priorPregnancy{
                    id
                    femaleReproductiveHealthId
                    count
                    numberOfChildren
                    numberOfMiscarriages
                    numberOfAbortions
                    createdAt
                    updatedAt
                }
                createdAt
                updatedAt
            }
            medicalHx{
                id
                patientId
                hasChronicCondition
                chronicConditions
                chronicConditionDiagnosisDates
                takingMedications
                currentMedications
                hadSurgeries
                surgeries
                beenHospitalized
                hospitalizations
                hadIssuesWithBloodDraw
                bloodDrawIssues
                createdAt
                updatedAt
            }
            patientBehaviorInfo{
                id
                patientId
                diet
                physicallyActive
                smoker
                smokingFrequency
                alcohol
                alcoholFrequency
                createdAt
                updatedAt
            }
            patientContactInfo{
                id
                patientId
                email
                phoneNumber
                streetAddress
                city
                state
                zip
                createdAt
                updatedAt
            }
        }
        packages{
            id
            name
            version
            description
            panels{
                id
                name
                description
                questTestCode
                frequencyPerYear
                modeOfAcquisition
                questPrice
                sex
                biomarkers{
                    id
                    categories{
                        id
                    }
                    name
                    questBiomarkerCode
                    modeOfAcquisition
                    measurementUnits
                    resultDataType
                    sexDetails{
                        id
                        biomarkerId
                        sex
                        whyItMatters
                        oneLineDescription
                        questRefRangeLow
                        questRefRangeHigh
                        optimalRangeLow
                        optimalRangeHigh
                        crossReferenceBiomarkers
                        notes
                        resourcesCited
                        recommendations{
                            id
                            name
                            biomarkerId
                            sex
                            inRange
                            outOfRangeType
                            summaryDescription
                            causesDescription
                            symptomsDescription
                            foodsToEatDescription
                            foodsToAvoidDescription
                            supplementsDescription
                            selfCareDescription
                            additionalTestsDescription
                            followUpsDescription
                        }
                        critical{
                            id
                            sexSpecificId
                            isPriority1
                            priority1Range
                            isPriority2
                            priority2Range
                        }
                    }
                }
            }
        }
        biomarkerResults{
            id
            biomarker{
                id
                categories{
                    id
                }
                name
                questBiomarkerCode
                modeOfAcquisition
                measurementUnits
                resultDataType
                sexDetails{
                    id
                    biomarkerId
                    sex
                    whyItMatters
                    oneLineDescription
                    questRefRangeLow
                    questRefRangeHigh
                    optimalRangeLow
                    optimalRangeHigh
                    crossReferenceBiomarkers
                    notes
                    resourcesCited
                    recommendations{
                        id
                        name
                        biomarkerId
                        sex
                        inRange
                        outOfRangeType
                        summaryDescription
                        causesDescription
                        symptomsDescription
                        foodsToEatDescription
                        foodsToAvoidDescription
                        supplementsDescription
                        selfCareDescription
                        additionalTestsDescription
                        followUpsDescription
                    }
                    critical{
                        id
                        sexSpecificId
                        isPriority1
                        priority1Range
                        isPriority2
                        priority2Range
                    }
                }
            }
            questBiomarkerId
            biomarkerName
            orderingPhysician{
                id
                email
                phoneNumber
                isActive
                isSuperuser
                isPhysician
                fname
                lname
                npi
                licenseState
                isNursePracticioner
            }
            dateOfService
            collectionSite
            testResult
            measurementUnits
            testResultOutOfRange
            questReferenceRange
        }
    }
}
`;

export const GET_VISIT_BY_PATIENT_ID_QUERY = gql`
query visitByPatientId($patientId: UUID!) {
    visitByPatientId(patientId: $patientId){
        id
        patientId
        visitDate
        streetAddress
        city
        state
        zip
        resultsAvailable
        confirmationCode
        documents
        patient{
            id
            patientIdentifier
            fname
            lname
            preferredName
            biologicalSex
            dob
            ssn
            pronouns
            ethnicOrigin
            shirtSize
            createdAt
            updatedAt
            motivational{
                id
                patientId
                tenYearHealthGoals
                purposeForJoiningFunction
                howDidTheyHearAboutFunction
                commentsBeforeBooking
                createdAt
                updatedAt
            }
            covid{
                id
                patientId
                hadCovid
                covidDate
                covidSeverity
                hasCovidResidualSymptoms
                covidResidualSymptoms
                vaccinatedForCovid
                covidVaccineName
                covidVaccineDateLastDosage
                createdAt
                updatedAt
            }
            femaleReproductiveHealth{
                id
                patientId
                currentlyOnBirthControl
                birthControlType
                timeOnBirthControl
                dateOfLastPeriod
                currentlyPregnant
                tryingToConceive
                currentMenstrualStatus
                priorPregnancies
                priorPregnancy{
                    id
                    femaleReproductiveHealthId
                    count
                    numberOfChildren
                    numberOfMiscarriages
                    numberOfAbortions
                    createdAt
                    updatedAt
                }
                createdAt
                updatedAt
            }
            medicalHx{
                id
                patientId
                hasChronicCondition
                chronicConditions
                chronicConditionDiagnosisDates
                takingMedications
                currentMedications
                hadSurgeries
                surgeries
                beenHospitalized
                hospitalizations
                hadIssuesWithBloodDraw
                bloodDrawIssues
                createdAt
                updatedAt
            }
            patientBehaviorInfo{
                id
                patientId
                diet
                physicallyActive
                smoker
                smokingFrequency
                alcohol
                alcoholFrequency
                createdAt
                updatedAt
            }
            patientContactInfo{
                id
                patientId
                email
                phoneNumber
                streetAddress
                city
                state
                zip
                createdAt
                updatedAt
            }
        }
        packages{
            id
            name
            version
            description
            panels{
                id
                name
                description
                questTestCode
                frequencyPerYear
                modeOfAcquisition
                questPrice
                sex
                biomarkers{
                    id
                    categories{
                        id
                    }
                    name
                    questBiomarkerCode
                    modeOfAcquisition
                    measurementUnits
                    resultDataType
                    sexDetails{
                        id
                        biomarkerId
                        sex
                        whyItMatters
                        oneLineDescription
                        questRefRangeLow
                        questRefRangeHigh
                        optimalRangeLow
                        optimalRangeHigh
                        crossReferenceBiomarkers
                        notes
                        resourcesCited
                        recommendations{
                            id
                            name
                            biomarkerId
                            sex
                            inRange
                            outOfRangeType
                            summaryDescription
                            causesDescription
                            symptomsDescription
                            foodsToEatDescription
                            foodsToAvoidDescription
                            supplementsDescription
                            selfCareDescription
                            additionalTestsDescription
                            followUpsDescription
                        }
                        critical{
                            id
                            sexSpecificId
                            isPriority1
                            priority1Range
                            isPriority2
                            priority2Range
                        }
                    }
                }
            }
        }
        biomarkerResults{
            id
            biomarker{
                id
                categories{
                    id
                }
                name
                questBiomarkerCode
                modeOfAcquisition
                measurementUnits
                resultDataType
                sexDetails{
                    id
                    biomarkerId
                    sex
                    whyItMatters
                    oneLineDescription
                    questRefRangeLow
                    questRefRangeHigh
                    optimalRangeLow
                    optimalRangeHigh
                    crossReferenceBiomarkers
                    notes
                    resourcesCited
                    recommendations{
                        id
                        name
                        biomarkerId
                        sex
                        inRange
                        outOfRangeType
                        summaryDescription
                        causesDescription
                        symptomsDescription
                        foodsToEatDescription
                        foodsToAvoidDescription
                        supplementsDescription
                        selfCareDescription
                        additionalTestsDescription
                        followUpsDescription
                    }
                    critical{
                        id
                        sexSpecificId
                        isPriority1
                        priority1Range
                        isPriority2
                        priority2Range
                    }
                }
            }
            questBiomarkerId
            biomarkerName
            orderingPhysician{
                id
                email
                phoneNumber
                isActive
                isSuperuser
                isPhysician
                fname
                lname
                npi
                licenseState
                isNursePracticioner
            }
            dateOfService
            collectionSite
            testResult
            measurementUnits
            testResultOutOfRange
            questReferenceRange
        }
    }
}
`;

export const GET_ALL_VISITS_QUERY = gql`
query visits($skip: Int, $limit: Int) {
    visits(skip: $skip, limit: $limit){
        id
        patientId
        visitDate
        streetAddress
        city
        state
        zip
        resultsAvailable
        confirmationCode
        documents
        patient{
            id
            patientIdentifier
            fname
            lname
            preferredName
            biologicalSex
            dob
            ssn
            pronouns
            ethnicOrigin
            shirtSize
            createdAt
            updatedAt
            motivational{
                id
                patientId
                tenYearHealthGoals
                purposeForJoiningFunction
                howDidTheyHearAboutFunction
                commentsBeforeBooking
                createdAt
                updatedAt
            }
            covid{
                id
                patientId
                hadCovid
                covidDate
                covidSeverity
                hasCovidResidualSymptoms
                covidResidualSymptoms
                vaccinatedForCovid
                covidVaccineName
                covidVaccineDateLastDosage
                createdAt
                updatedAt
            }
            femaleReproductiveHealth{
                id
                patientId
                currentlyOnBirthControl
                birthControlType
                timeOnBirthControl
                dateOfLastPeriod
                currentlyPregnant
                tryingToConceive
                currentMenstrualStatus
                priorPregnancies
                priorPregnancy{
                    id
                    femaleReproductiveHealthId
                    count
                    numberOfChildren
                    numberOfMiscarriages
                    numberOfAbortions
                    createdAt
                    updatedAt
                }
                createdAt
                updatedAt
            }
            medicalHx{
                id
                patientId
                hasChronicCondition
                chronicConditions
                chronicConditionDiagnosisDates
                takingMedications
                currentMedications
                hadSurgeries
                surgeries
                beenHospitalized
                hospitalizations
                hadIssuesWithBloodDraw
                bloodDrawIssues
                createdAt
                updatedAt
            }
            patientBehaviorInfo{
                id
                patientId
                diet
                physicallyActive
                smoker
                smokingFrequency
                alcohol
                alcoholFrequency
                createdAt
                updatedAt
            }
            patientContactInfo{
                id
                patientId
                email
                phoneNumber
                streetAddress
                city
                state
                zip
                createdAt
                updatedAt
            }
        }
        packages{
            id
            name
            version
            description
            panels{
                id
                name
                description
                questTestCode
                frequencyPerYear
                modeOfAcquisition
                questPrice
                sex
                biomarkers{
                    id
                    categories{
                        id
                    }
                    name
                    questBiomarkerCode
                    modeOfAcquisition
                    measurementUnits
                    resultDataType
                    sexDetails{
                        id
                        biomarkerId
                        sex
                        whyItMatters
                        oneLineDescription
                        questRefRangeLow
                        questRefRangeHigh
                        optimalRangeLow
                        optimalRangeHigh
                        crossReferenceBiomarkers
                        notes
                        resourcesCited
                        recommendations{
                            id
                            name
                            biomarkerId
                            sex
                            inRange
                            outOfRangeType
                            summaryDescription
                            causesDescription
                            symptomsDescription
                            foodsToEatDescription
                            foodsToAvoidDescription
                            supplementsDescription
                            selfCareDescription
                            additionalTestsDescription
                            followUpsDescription
                        }
                        critical{
                            id
                            sexSpecificId
                            isPriority1
                            priority1Range
                            isPriority2
                            priority2Range
                        }
                    }
                }
            }
        }
        biomarkerResults{
            id
            biomarker{
                id
                categories{
                    id
                }
                name
                questBiomarkerCode
                modeOfAcquisition
                measurementUnits
                resultDataType
                sexDetails{
                    id
                    biomarkerId
                    sex
                    whyItMatters
                    oneLineDescription
                    questRefRangeLow
                    questRefRangeHigh
                    optimalRangeLow
                    optimalRangeHigh
                    crossReferenceBiomarkers
                    notes
                    resourcesCited
                    recommendations{
                        id
                        name
                        biomarkerId
                        sex
                        inRange
                        outOfRangeType
                        summaryDescription
                        causesDescription
                        symptomsDescription
                        foodsToEatDescription
                        foodsToAvoidDescription
                        supplementsDescription
                        selfCareDescription
                        additionalTestsDescription
                        followUpsDescription
                    }
                    critical{
                        id
                        sexSpecificId
                        isPriority1
                        priority1Range
                        isPriority2
                        priority2Range
                    }
                }
            }
            questBiomarkerId
            biomarkerName
            orderingPhysician{
                id
                email
                phoneNumber
                isActive
                isSuperuser
                isPhysician
                fname
                lname
                npi
                licenseState
                isNursePracticioner
            }
            dateOfService
            collectionSite
            testResult
            measurementUnits
            testResultOutOfRange
            questReferenceRange
        }
    }
}
`;
