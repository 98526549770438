import { ActionContext } from 'vuex';

import * as mutations from '@/store/crud/mutations';
import { CrudState } from '@/store/crud/state';
import { State } from '@/store/state';
import { commitAddNotification } from '@/store/main/mutations';
import { dispatchCheckApiError } from '@/store/main/actions';

import { graphQlApi } from '@/graphql/graphqlApi';
import { ICreateBiomarkerResult, IUpdateBiomarkerResult } from '@/interfaces/biomarkerResults';
import { crudModules } from '@/constants/globalConstants';
import { IBiomarker } from '@/interfaces/biomarkers';

type MainContext = ActionContext<CrudState, State>;


/**
 * Returns biomarkers result cached data
 * or false if it is expired
 * If the view or components needs the full data, there is a flag
 * "needsDataUpgrade" to check if the existing cache is list or full data.
 * If it is list, then the data is not valid and needs to be upgraded.
 */
const getBiomarkerResultsCachedData = async (context: MainContext, needsDataUpgrade = false) => {
    if (context.getters.biomarkerResults && context.getters.biomarkerResults.length > 0) {
        const dataExpired = await context.dispatch('biomarkerResultsDataExpired');
        // check data expiration
        if (dataExpired) {
            // re run this action without cached data
            return false; // data is expired so we need to fetch new data
        } else {
            if (needsDataUpgrade) {
                if (context.getters.needsDataUpgrade(crudModules.BIOMARKER_RESULTS)) {
                    return false;
                }
            }
            return context.getters.biomarkerResults;
        }
    } else {
        return false;
    }
};


/**
 * Actions sub module for biomarkers-results actions
 */
export default {
/**
 * Gets all the biomarker results with all available data
 * @param context - MainContext
 * @returns - Promise<IResult[]> - list of biomarkers or false if error
 */
async actionGetBiomarkerResults(context: MainContext) {
    try {
        // check cached data
        const biomarkerResultsCachedData = await getBiomarkerResultsCachedData(context, true);
        if (biomarkerResultsCachedData) {
            return biomarkerResultsCachedData;
        }
        const response = await graphQlApi.getAllBiomarkerResults();
        if (response) {
            mutations.commitSetBiomarkerResults(context, response.data.biomarkerResults);
            mutations.setModuleNeedsDataUpgrade(context, {
                module: crudModules.BIOMARKER_RESULTS,
                needsDataUpgrade: false,
            });
            mutations.setDataUpdated(context, crudModules.BIOMARKER_RESULTS);
            return Promise.resolve(response.data.biomarkerResults);
        } else {
            throw new Error('failed while getting biomarker results');
        }
    } catch (error) {
        await dispatchCheckApiError(context, error);
        commitAddNotification(context, { content: 'Error while fetching biomarker results' + error, color: 'error' });
        return Promise.resolve(false);
    }
},
/**
 * Get all the biomarker results with reduced information for table view
 * @param context - MainContext
 * @returns - Promise<IResult[]> - list of biomarkers or false if error
 */
 async actionGetBiomarkerResultsForList(context: MainContext) {
    try {
        const biomarkerResultsCachedData = await getBiomarkerResultsCachedData(context);
        if (biomarkerResultsCachedData) {
            return biomarkerResultsCachedData;
        }
        const response = await graphQlApi.getAllBiomarkerResultsForTableView();
        if (response) {
            mutations.commitSetBiomarkerResults(context, response.data.biomarkerResults);
            mutations.setModuleNeedsDataUpgrade(context, {
                module: crudModules.BIOMARKER_RESULTS,
                needsDataUpgrade: true,
            });
            mutations.setDataUpdated(context, crudModules.BIOMARKER_RESULTS);
            return Promise.resolve(response.data.biomarkerResults);
        } else {
            throw new Error('failed while getting biomarker results');
        }
    } catch (error) {
        await dispatchCheckApiError(context, error);
        commitAddNotification(context, { content: 'Error while fetching biomarker results', color: 'error' });
        return Promise.resolve(false);
    }
},
/**
 * Gets a single biomarker result with all available data
 * @param context - MainContext
 * @param id - biomarker id
 * @returns - Promise<IResult[]> - list of biomarkers or false if error
 */
async actionGetBiomarkerResultById(context: MainContext, id: string) {
    try {
        const response = await graphQlApi.getBiomarkerResultById(id);
        if (response) {
            mutations.commitSetBiomarkerResult(context, response.data.biomarkerResultById);
            return Promise.resolve(response.data.biomarkerResultById);
        } else {
            throw new Error('failed while getting biomarker result');
        }
    } catch (error) {
        await dispatchCheckApiError(context, error);
        commitAddNotification(context, { content: 'Error while fetching biomarker result', color: 'error' });
        return Promise.resolve(false);
    }
},
/**
 * Updates a single biomarker result in the database
 * @param context - MainContext
 * @param payload - IUpdateBiomarkerResult, the biomarker result to update
 * @returns - returns a Promise with the updated biomarker result or false if error
 */
async actionUpdateBiomarkerResult(
    context: MainContext, payload: { biomarkerResultId: string, biomarkerResult: IUpdateBiomarkerResult }) {
    const loadingNotification = { content: 'saving..', color: 'info' };
    try {
        commitAddNotification(context, loadingNotification);
        const response = (await Promise.all([
            graphQlApi.updateBiomarkerResult(payload.biomarkerResultId, payload.biomarkerResult),
            await new Promise<void>((resolve) => setTimeout(() => resolve(), 500)),
        ]))[0];
        if (response) {
            commitAddNotification(context, { content: 'Biomarker result successfully updated', color: 'success' });
            mutations.commitSetBiomarkerResult(context, response.data.updateBiomarkerResults);
            return Promise.resolve(response.data.updateBiomarkerResults);
        } else {
            throw new Error('failed while updating biomarker result');
        }
    } catch (error) {
        await dispatchCheckApiError(context, error);
        commitAddNotification(context, { content: 'Error while updating biomarker result', color: 'error' });
        return Promise.resolve(false);
    }
},
/**
 * Creates a new Biomarker result in the databasee
 * This particular function does not alert the user of success or failure
 * it leaves that to the calling function since this is used during a bigger process (creating result)
 * @param context - MainContext
 * @param payload - ICreateBiomarkerResult, the biomarker result to create
 * @returns - returns a Promise with the created biomarker result or false if error
 */
async actionCreateBiomarkerResult(
    context: MainContext, payload: ICreateBiomarkerResult) {
    try {
        const response = (await Promise.all([
            graphQlApi.createBiomarkerResult(payload),
            await new Promise<void>((resolve) => setTimeout(() => resolve(), 500)),
        ]))[0];
        if (response) {
            mutations.commitSetBiomarkerResult(context, response.data.createBiomarkerResults);
            return Promise.resolve(response.data.createBiomarkerResults);
        } else {
            throw new Error('failed while creating biomarker result');
        }
    } catch (error) {
        await dispatchCheckApiError(context, error);
        return Promise.resolve(false);
    }
},
/**
 * deletes a biomarker result from the database
 * @param context - MainContext
 * @param payload - the biomarker result to delete
 * @returns - returns a promise that resolves in "True" if successful or false if error
 */
async actionDeleteBiomarkerResult(context: MainContext, payload: { biomarkerResultId: string }) {
    const loadingNotification = { content: 'Deleting Biomarker result..', color: 'info' };
    try {
        commitAddNotification(context, loadingNotification);
        const response = (await Promise.all([
            graphQlApi.deleteBiomarkerResult(payload.biomarkerResultId),
            await new Promise<void>((resolve) => setTimeout(() => resolve(), 500)),
        ]))[0];
        if (response) {
            commitAddNotification(context, { content: 'Biomarker result successfully deleted', color: 'success' });
            mutations.commitDeleteBiomarkerResult(context, {id: payload.biomarkerResultId} as IBiomarker);
            return Promise.resolve(true);
        } else {
            throw new Error('failed while deleting biomarker result');
        }
    } catch (error) {
        await dispatchCheckApiError(context, error);
        commitAddNotification(context, { content: 'Failed while removing biomarker result', color: 'error' });
        return Promise.resolve(false);
    }
},
};

