export const getLocalToken = () => localStorage.getItem('token');

export const saveLocalToken = (token: string) => localStorage.setItem('token', token);
// export const saveLocalToken = (token: string) => localStorage.setItem('token'
// , 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.
// eyJleHAiOjE2Mjc1MDYwNzcsInN1YiI6InVzZXJAZXhhbXBsZS5jb20ifQ.0ms2IJuOT5PgchItvoID7m51y5y2NLxyv53G0nPIqu8');
export const removeLocalToken = () => localStorage.removeItem('token');

export const stringSanitization = {
    SanitizeAlphaNumSpaceSymbols: (val: string) => {
        const pattern = '1234567890 ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz,.;-_@°'.split('');
        return  val.split('').every( (item) => pattern.indexOf(item) >= 0);
    },
};

