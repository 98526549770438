import { ActionContext } from 'vuex';

import * as mutations from '@/store/crud/mutations';

import { CrudState } from '@/store/crud/state';

import { State } from '@/store/state';
import { commitAddNotification } from '@/store/main/mutations';
import { dispatchCheckApiError } from '@/store/main/actions';

import { graphQlApi } from '@/graphql/graphqlApi';
import { crudModules } from '@/constants/globalConstants';
import { ICreateSymptom, ISymptom, IUpdateSymptom } from '@/interfaces/symptoms';


type MainContext = ActionContext<CrudState, State>;


/**
 * Returns cached data
 * or false if it is expired
 */
const getCachedData = async (context: MainContext, needsDataUpgrade: boolean) => {

    const cachedData = context.getters.symptoms;
    const dispatchAction = 'symptomsDataExpired';

    if (cachedData && cachedData.length > 0) {
        const dataExpired = await context.dispatch(dispatchAction);
        // check data expiration
        if (dataExpired) {
            // re run this action without cached data
            return false; // data is expired so we need to fetch new data
        } else {
            if (needsDataUpgrade) {
                if (context.getters.needsDataUpgrade(crudModules.SYMPTOMS)) {
                    return false;
                }
            }
            return cachedData;
        }
    } else {
        return false;
    }
};

/**
 * Actions sub module for Exercises actions
 */
export default {
    /**
     * Gets all symptoms with all available data from the DB
     * @param context - Vuex Action context
     * @returns - Promise with symptoms or false if error
     */
     async actionGetSymptomsForList(context: MainContext) {
        try {
            // check cached data
            const cachedData = await getCachedData(context, false);
            if (cachedData) {
                return cachedData;
            }

            const response = await graphQlApi.getAllSymptomsForList();
            if (response) {
                mutations.commitSetSymptoms(context, response.data.symptoms);
                mutations.setModuleNeedsDataUpgrade(context, {
                    module: crudModules.SYMPTOMS,
                    needsDataUpgrade: true,
                });
                mutations.setDataUpdated(context, crudModules.SYMPTOMS);
                return Promise.resolve(response.data.symptoms);
            } else {
                throw new Error('Failed while fetching symptoms');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Error while fetching symptoms', color: 'error' });
            return Promise.resolve(false);
        }
    },
    /**
     * Gets all symptoms with all available data from the DB
     * @param context - Vuex Action context
     * @returns - Promise with symptoms or false if error
     */
    async actionGetAllSymptoms(context: MainContext) {
        try {
            // check cached data
            const cachedData = await getCachedData(context, true);
            if (cachedData) {
                return cachedData;
            }

            const response = await graphQlApi.getAllSymptoms();
            if (response) {
                mutations.commitSetSymptoms(context, response.data.symptoms);
                mutations.setModuleNeedsDataUpgrade(context, {
                    module: crudModules.SYMPTOMS,
                    needsDataUpgrade: false,
                });
                mutations.setDataUpdated(context, crudModules.SYMPTOMS);
                return Promise.resolve(response.data.symptoms);
            } else {
                throw new Error('Failed while fetching symptoms');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Error while fetching symptoms', color: 'error' });
            return Promise.resolve(false);
        }
    },
    /**
     * Gets a single Symptom from the DB
     * @param context - Vuex Action context
     * @param payload - symptomId -> symptom to get
     * @returns - Promise with symptom or false if error
     */
    async actionGetSymptomById(context: MainContext, id: string) {
        try {
            const response = await graphQlApi.getSymptomById(id);
            if (response) {
                mutations.commitSetSymptom(context, response.data.symptomById);
                return Promise.resolve(response.data.symptomById);
            } else {
                throw new Error('Failed while fetching symptom');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Error while fetching symptom', color: 'error' });
            return Promise.resolve(false);
        }
    },
    /**
     * Updates a single symptom in the DB
     * @param context - Vuex Action context
     * @param payload -
     * - symptomId -> symptom to update
     * - symptom -> symptom data to update
     * @returns - Promise with updated symptom or false if error
     */
    async actionUpdateSymptom(context: MainContext, payload: { symptomId: string, symptom: IUpdateSymptom }) {
        const loadingNotification = { content: 'saving', color: 'info' };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                graphQlApi.updateSymptom(payload.symptomId, payload.symptom),
                await new Promise<void>((resolve) => setTimeout(() => resolve(), 500)),
            ]))[0];
            if (response) {
                commitAddNotification(context, { content: 'symptom successfully updated', color: 'success' });
                mutations.commitSetSymptom(context, response.data.updateSymptom);
                return Promise.resolve(response.data.updateSymptom);
            } else {
                throw new Error('Failed while updating symptom');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Failed while updating symptom', color: 'error' });
            return Promise.resolve(false);
        }
    },
    /**
     * Creates a new symptom in the DB
     * @param context - Vuex Action context
     * @param payload -
     * - ICreateSymptom ->  symptom data to add
     * @returns - Promise with added symptom or false if error
     */
    async actionCreateSymptom(context: MainContext, payload: ICreateSymptom) {
        const loadingNotification = { content: 'saving', color: 'info' };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                graphQlApi.createSymptom(payload),
                await new Promise<void>((resolve) => setTimeout(() => resolve(), 500)),
            ]))[0];
            if (response) {
                commitAddNotification(context, { content: 'Symptom successfully created', color: 'success' });
                mutations.commitSetSymptom(context, response.data.createSymptom);
                return Promise.resolve(response.data.createSymptom);
            } else {
                throw new Error('Failed while creating symptom');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Failed while creating symptom', color: 'error' });
            return Promise.resolve(false);
        }
    },
    /**
     * Deletes a single symptom from the DB
     * @param context - Vuex Action context
     * @param payload -> symptomId -  symptom to remove
     * @returns - Promise with removed symptom or false if error
     */
    async actionDeleteSymptom(context: MainContext, payload: { symptomId: string }) {
        const loadingNotification = { content: 'saving', color: 'info' };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                graphQlApi.deleteSymptom(payload.symptomId),
                await new Promise<void>((resolve) => setTimeout(() => resolve(), 500)),
            ]))[0];
            if (response) {
                commitAddNotification(context, { content: 'Symptom successfully deleted', color: 'success' });
                mutations.commitDeleteSymptom(context, {id: payload.symptomId} as ISymptom);
                return Promise.resolve(response.data.deleteSymptom);
            } else {
                throw new Error('Failed while deleting symptom');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Failed while removing symptom', color: 'error' });
            return Promise.resolve(false);
        }
    },

};

