import gql from 'graphql-tag';

export const GET_ALL_BIOMARKERS_QUERY = gql `
query biomarkers($skip: Int, $limit: Int) {
    biomarkers(skip: $skip, limit: $limit){
        id
        categories{
            id
            categoryName
        }
        name
        questBiomarkerCode
        modeOfAcquisition
        measurementUnits
        resultDataType
        sexDetails{
            id
            biomarkerId
            sex
            whyItMatters
            oneLineDescription
            questRefRangeLow
            questRefRangeHigh
            optimalRangeLow
            optimalRangeHigh
            crossReferenceBiomarkers
            notes
            resourcesCited
            recommendations{
                id
                name
                biomarkerId
                sex
                inRange
                outOfRangeType
                summaryDescription
                causesDescription
                symptomsDescription
                foodsToEatDescription
                foodsToAvoidDescription
                supplementsDescription
                selfCareDescription
                additionalTestsDescription
                followUpsDescription
            }
            critical{
                id
                sexSpecificId
                isPriority1
                priority1Range
                isPriority2
                priority2Range
            }
        }
    }
}
`;

export const GET_ALL_BIOMARKERS_FOR_TABLE_VIEW_QUERY = gql `
query biomarkers($skip: Int, $limit: Int) {
    biomarkers(skip: $skip, limit: $limit){
        id
        name
        categories{
            id
            categoryName
        }
        questBiomarkerCode
    }
}
`;

export const GET_ALL_BIOMARKERS_FOR_LIST_QUERY = gql `
query biomarkers($skip: Int, $limit: Int) {
    biomarkers(skip: $skip, limit: $limit){
        id
        name
    }
}
`;

export const GET_BIOMARKER_BY_QUEST_BIOMARKER_CODE = gql `
query biomarkerByQuestBiomarkerCode($questBiomarkerCode: String!) {
    biomarkerByQuestBiomarkerCode(questBiomarkerCode: $questBiomarkerCode){
        id
        categories{
            id
            categoryName
            description
        }
        name
        questBiomarkerCode
        modeOfAcquisition
        measurementUnits
        resultDataType
        sexDetails{
            id
            biomarkerId
            sex
            whyItMatters
            oneLineDescription
            questRefRangeLow
            questRefRangeHigh
            optimalRangeLow
            optimalRangeHigh
            crossReferenceBiomarkers
            notes
            resourcesCited
            recommendations{
                id
                name
                biomarkerId
                sex
                inRange
                outOfRangeType
                summaryDescription
                causesDescription
                symptomsDescription
                foodsToEatDescription
                foodsToAvoidDescription
                supplementsDescription
                selfCareDescription
                additionalTestsDescription
                followUpsDescription
            }
            critical{
                id
                sexSpecificId
                isPriority1
                priority1Range
                isPriority2
                priority2Range
            }
        }
    }
}
`;

export const GET_BIOMARKER_BY_NAME_QUERY = gql `
query biomarkerByName($name: String!) {
    biomarkerByName(name: $name){
        id
        categories{
            id
            categoryName
            description
        }
        name
        questBiomarkerCode
        modeOfAcquisition
        measurementUnits
        resultDataType
        sexDetails{
            id
            biomarkerId
            sex
            whyItMatters
            oneLineDescription
            questRefRangeLow
            questRefRangeHigh
            optimalRangeLow
            optimalRangeHigh
            crossReferenceBiomarkers
            notes
            resourcesCited
            recommendations{
                id
                name
                biomarkerId
                sex
                inRange
                outOfRangeType
                summaryDescription
                causesDescription
                symptomsDescription
                foodsToEatDescription
                foodsToAvoidDescription
                supplementsDescription
                selfCareDescription
                additionalTestsDescription
                followUpsDescription
            }
            critical{
                id
                sexSpecificId
                isPriority1
                priority1Range
                isPriority2
                priority2Range
            }
        }
    }
}
`;

export const GET_BIOMARKER_BY_ID_QUERY = gql `
query biomarkerById($id: UUID!) {
    biomarkerById(id: $id){
        id
        categories{
            id
            categoryName
        }
        name
        questBiomarkerCode
        modeOfAcquisition
        measurementUnits
        resultDataType
        sexDetails{
            id
            biomarkerId
            sex
            whyItMatters
            oneLineDescription
            questRefRangeLow
            questRefRangeHigh
            optimalRangeLow
            optimalRangeHigh
            crossReferenceBiomarkers
            notes
            resourcesCited
            recommendations{
                id
                name
                biomarkerId
                sex
                inRange
                outOfRangeType
                summaryDescription
                causesDescription
                symptomsDescription
                foodsToEatDescription
                foodsToAvoidDescription
                supplementsDescription
                selfCareDescription
                additionalTestsDescription
                followUpsDescription
            }
            critical{
                id
                sexSpecificId
                isPriority1
                priority1Range
                isPriority2
                priority2Range
            }
        }
    }
}
`;
