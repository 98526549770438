
// mixins.js
import { dispatchAddNotification, dispatchSetAppLoading } from '@/store/main/actions';
import { readAppLoading, readRequestIndex } from '@/store/main/getters';
import { Component, Vue } from 'vue-property-decorator';

// You can declare mixins as the same style as components.
@Component
export default class LoaderMixin extends Vue {
  private requestId = 0;

  public setAppLoading(isLoading: boolean) {
    dispatchSetAppLoading(this.$store, isLoading);
    if (isLoading) {
      this.requestId = readRequestIndex(this.$store);
      setTimeout((id) => {
        const currentRequestId = readRequestIndex(this.$store);
        if (this.isAppLoading && currentRequestId === id) {
          dispatchSetAppLoading(this.$store, false);
          dispatchAddNotification(this.$store, {
            message: 'Loading Timed out',
            color: 'error',
          });
        }
      }, 30000, this.requestId);
    }
  }

  public get isAppLoading() {
    return readAppLoading(this.$store);
  }
}
