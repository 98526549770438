import gql from 'graphql-tag';


export const GET_SYMPTOMS_FOR_LIST_QUERY = gql`
query symptoms($skip: Int, $limit: Int) {
    symptoms(skip: $skip, limit: $limit){
        id
        name
    }
}
`;

export const GET_ALL_SYMPTOMS_QUERY = gql`
query symptoms($skip: Int, $limit: Int) {
    symptoms(skip: $skip, limit: $limit){
        id
        name
        description
        symptomType
    }
}
`;

export const GET_SYMPTOM_BY_ID_QUERY = gql`
query symptomById($id: UUID!) {
    symptomById(id: $id){
        id
        name
        description
        symptomType
    }
}
`;
