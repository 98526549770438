import { ActionContext } from 'vuex';

import * as mutations from '@/store/crud/mutations';

import { CrudState } from '@/store/crud/state';

import { State } from '@/store/state';
import { commitAddNotification } from '@/store/main/mutations';
import { dispatchCheckApiError } from '@/store/main/actions';

import { graphQlApi } from '@/graphql/graphqlApi';
import { ICreatePanel, IPanel, IUpdatePanel } from '@/interfaces/panels';
import { crudModules } from '@/constants/globalConstants';


type MainContext = ActionContext<CrudState, State>;

/**
 * Returns panels cached data
 * or false if it is expired
 * If the view or components needs the full data, there is a flag
 * "needsDataUpgrade" to check if the existing cache is list or full data.
 * If it is list, then the data is not valid and needs to be upgraded.
 */
const getPanelsCachedData = async (context: MainContext, needsDataUpgrade = false) => {
    if (context.getters.panels && context.getters.panels.length > 0) {
        const dataExpired = await context.dispatch('panelsDataExpired');
        // check data expiration
        if (dataExpired) {
            // re run this action without cached data
            return false; // data is expired so we need to fetch new data
        } else {
            if (needsDataUpgrade) {
                if (context.getters.needsDataUpgrade(crudModules.PANELS)) {
                    return false;
                }
            }
            return context.getters.panels;
        }
    } else {
        return false;
    }
};

/**
 * Actions sub module for Panels actions
 */
export default {
    /**
     * gets all panels with all available data
     * @param context - MainContext
     * @returns - Promise<IPanel[]> - list of panels or false if error
     */
    async actionGetPanels(context: MainContext) {
        try {
            const panelsCachedData = await getPanelsCachedData(context, true);
            if (panelsCachedData) {
                return panelsCachedData;
            }
            const response = await graphQlApi.getAllPanels();
            if (response) {
                mutations.commitSetPanels(context, response.data.panels);
                mutations.setModuleNeedsDataUpgrade(context, {
                    module: crudModules.PANELS,
                    needsDataUpgrade: false,
                });
                mutations.setDataUpdated(context, crudModules.PANELS);
                return Promise.resolve(response.data.panels);
            } else {
                throw new Error('failed while getting panels');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Error while fetching panels', color: 'error' });
            return Promise.resolve(false);
        }
    },
    /**
     * Gets all panels with the needed information for the Panels view
     * @param context - MainContext
     * @returns - Promise<IPanel[]> - list of panels or false if error
     */
     async actionGetPanelsForList(context: MainContext) {
        try {
            const panelsCachedData = await getPanelsCachedData(context);
            if (panelsCachedData) {
                return panelsCachedData;
            }
            const response = await graphQlApi.getAllPanelsForTableView();
            if (response) {
                mutations.commitSetPanels(context, response.data.panels);
                mutations.setModuleNeedsDataUpgrade(context, {
                    module: crudModules.PANELS,
                    needsDataUpgrade: true,
                });
                mutations.setDataUpdated(context, crudModules.PANELS);
                return Promise.resolve(response.data.panels);
            } else {
                throw new Error('failed while getting panels');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Error while fetching panels', color: 'error' });
            return Promise.resolve(false);
        }
    },
    /**
     * Gets a single panel from the database
     * @param context - MainContext
     * @param id - the id of the panel to get
     * @returns - Promise<IPanel> - the panel or false if error
     */
    async actionGetPanelById(context: MainContext, id: string) {
        try {
            const response = await graphQlApi.getPanelById(id);
            if (response) {
                mutations.commitSetPanel(context, response.data.panelById);
                return Promise.resolve(response.data.panelById);
            } else {
                throw new Error('failed while getting panels');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Error while fetching panels', color: 'error' });
            return Promise.resolve(false);
        }
    },
    /**
     * Updaes a panel in the database
     * @param context - MainContext
     * @param payload - IPanelUpdate
     * @returns - Promise<IPanel> - updated panel or false if error
     */
    async actionUpdatePanel(context: MainContext, payload: { panelId: string, panel: IUpdatePanel }) {
        const loadingNotification = { content: 'saving', color: 'info' };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                graphQlApi.updatePanel(payload.panelId, payload.panel),
                await new Promise<void>((resolve) => setTimeout(() => resolve(), 500)),
            ]))[0];
            if (response) {
                commitAddNotification(context, { content: 'Panel successfully updated', color: 'success' });
                mutations.commitSetPanel(context, response.data.updatePanel);
                return Promise.resolve(response.data.updatePanel);
            } else {
                throw new Error('failed while updating panel');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Error while updating panel', color: 'error' });
            return Promise.resolve(false);
        }
    },
    /**
     * Creates a Panel in the database
     * @param context - MainContext
     * @param payload - ICreatePanel
     * @returns - Promise<IPanel> - created panel or false if error
     */
    async actionCreatePanel(context: MainContext, payload: ICreatePanel) {
        const loadingNotification = { content: 'saving', color: 'info' };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                graphQlApi.createPanel(payload),
                await new Promise<void>((resolve) => setTimeout(() => resolve(), 500)),
            ]))[0];
            if (response) {
                commitAddNotification(context, { content: 'Panel successfully created', color: 'success' });
                mutations.commitSetPanel(context, response.data.createPanel);
                return Promise.resolve(response.data.createPanel);
            } else {
                throw new Error('failed while creating panel');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Error while creating panel', color: 'error' });
            return Promise.resolve(false);
        }
    },
    /**
     * Deletes a Panel from the database
     * @param context - MainContext
     * @param payload - panel Id for deletion
     * @returns - Promise<boolean> - true if success or false if error
     */
    async actionDeletePanel(context: MainContext, payload: { panelId: string }) {
        const loadingNotification = { content: 'saving', color: 'info' };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                graphQlApi.deletePanel(payload.panelId),
                await new Promise<void>((resolve) => setTimeout(() => resolve(), 500)),
            ]))[0];
            if (response) {
                commitAddNotification(context, { content: 'Panel successfully deleted', color: 'success' });
                mutations.commitDeletePanel(context, {id: payload.panelId} as IPanel);
                return Promise.resolve(true);
            } else {
                throw new Error('failed while deleting panel');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Failed while removing panel', color: 'error' });
            return Promise.resolve(false);
        }
    },
};
