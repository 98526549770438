// graphQlClient
import {graphQlApiUrl} from '@/env';
import {ApolloClient} from 'apollo-client';
import {createHttpLink} from 'apollo-link-http';
import {InMemoryCache} from 'apollo-cache-inmemory';

/* import queries */
import * as userQueries from '@/graphql/queries/usersQueries';
import * as patientQueries from '@/graphql/queries/patientsQueries';
import * as biomarkerQueries from '@/graphql/queries/biomarkerQueries';
import * as packagesQueries from '@/graphql/queries/packagesQueries';
import * as visitsQueries from '@/graphql/queries/visitsQueries';
import * as panelsQueries from '@/graphql/queries/panelsQueries';
import * as miscQueries from '@/graphql/queries/miscQueries';
import * as symptomsQueries from '@/graphql/queries/symptomsQueries';
import * as BiomarkerResultsQueries from '@/graphql/queries/biomarkerResultsQueries';
import * as requisitionsQueries from '@/graphql/queries/requisitionsQueries';
import * as categoriesQueries from '@/graphql/queries/categoriesQueries';
import * as causesQueries from '@/graphql/queries/causesQueries';
import * as supplementsQueries from '@/graphql/queries/supplementsQueries';
import * as exercisesQueries from '@/graphql/queries/exercisesQueries';
import * as foodsQueries from '@/graphql/queries/foodsQueries';
import * as recommendationsQueries from '@/graphql/queries/recommendationsQueries';
import * as selfCareQueries from '@/graphql/queries/selfCareQueries';
import * as customerServiceQueries from '@/graphql/queries/customerServiceQueries';

/* import mutations */
import * as userMutations from '@/graphql/mutations/usersMutations';
import * as mainMutations from '@/graphql/mutations/mainMutations';
import * as visitMutations from '@/graphql/mutations/visitsMutations';
import * as patientsMutations from '@/graphql/mutations/patientsMutations';
import * as contentMutations from '@/graphql/mutations/contentMutations';
import * as biomarkerResultsMutations from '@/graphql/mutations/biomarkerResultsMutations';
import * as requisitionsMutations from '@/graphql/mutations/requisitionsMutations';
import * as customerServiceMutations from '@/graphql/mutations/customerServiceMutations';

import {getLocalToken} from '@/utils';
import {ApolloLink} from 'apollo-boost';
import {IUserProfile, IUserProfileCreate, IUserProfileUpdate} from '@/interfaces/users';
import {ICreateVisit, IUpdateVisit, IVisitDocument} from '@/interfaces/visits';
import {
  ICovidInfo,
  IFemaleReproductiveHealth,
  IMotivationalInfo,
  IPatientBehaviorInfo,
  IPatientContactInfo,
  IPatientCreate,
  IPatientMedicalHx,
  IPatientUpdate,
  IPriorPregnancies,
} from '@/interfaces/patients';
import {ICreateBiomarker, IUpdateBiomarker} from '@/interfaces/biomarkers';
import {ICreateBiomarkerResult, IUpdateBiomarkerResult} from '@/interfaces/biomarkerResults';
import {ICreateExercise, IUpdateExercise} from '@/interfaces/exercises';
import {ICreateFood, IUpdateFood} from '@/interfaces/foods';
import {ICreateSupplement, IUpdateSupplement} from '@/interfaces/supplements';
import {ICreateRecommendation, IUpdateRecommendation} from '@/interfaces/recommendations';
import {ICreatePackage, IUpdatePackage} from '@/interfaces/packages';
import {ICreatePanel, IUpdatePanel} from '@/interfaces/panels';
import {ICreateCritical, ICriticalResultCreate, ICriticalResultUpdate, IUpdateCritical} from '@/interfaces/criticals';
import {ICreateRequisition, IUpdateRequisition} from '@/interfaces/requisitions';
import {ICreateCategory, IUpdateCategory} from '@/interfaces/categories';
import {ICreateCause, IUpdateCause} from '@/interfaces/causes';
import {ICreateSymptom, IUpdateSymptom} from '@/interfaces/symptoms';
import {ICreateSelfCare, IUpdateSelfCare} from '@/interfaces/selfCare';
import {ICreateSexSpecifics, IUpdateSexSpecifics} from '@/interfaces/sexSpecifics';
import {ICreatePatientCustomerService, IPatientNoteCreate, IPatientNoteUpdate} from '@/interfaces/customerService';
import {
  CREATE_PHYSICIAN_NOTE_MUTATION,
  DELETE_PHYSICIAN_NOTE_MUTATION,
  UPDATE_PHYSICIAN_NOTE_MUTATION,
} from '@/graphql/mutations/physicianNotesMutations';
import {INote, INoteInput} from '@/interfaces/notes';
import {GET_PHYSICIAN_NOTE_BY_ID_QUERY, GET_PHYSICIAN_NOTES_QUERY} from '@/graphql/queries/physicianNotesQueries';
import {
  CREATE_REQUISITION_COMMENT_MUTATION,
  DELETE_REQUISITION_COMMENT_MUTATION,
  UPDATE_REQUISITION_COMMENT_MUTATION,
} from '@/graphql/mutations/requisitionCommentMutations';
import {CREATE_CRITICAL_RESULT_MUTATION, UPDATE_CRITICAL_RESULT_MUTATION} from '@/graphql/mutations/criticalResultMutations';
import {
  IRequisitionComment,
  IRequisitionCommentInput,
  IRequisitionCommentUpdate,
} from '@/interfaces/requisitionComments';
import {
  CREATE_VISIT_DOCUMENT_MUTATION, DELETE_HARD_VISIT_DOCUMENT_MUTATION,
  DELETE_VISIT_DOCUMENT_MUTATION, HIDE_VISIT_DOCUMENT_MUTATION,
  SHOW_VISIT_DOCUMENT_MUTATION,
} from '@/graphql/mutations/visitsMutations';
import {ITimeTrackerCreate} from '@/interfaces/timeTracker';
import {CREATE_REVIEWING_TIME_MUTATION} from '@/graphql/mutations/mainMutations';

/*
Apollo Client instance
*/
// HTTP connection to the API
const httpLink = createHttpLink({uri: graphQlApiUrl});

// Cache implementation
const cache = new InMemoryCache();

// Auth logic
const authLink = new ApolloLink((operation, forward) => {
  const token = getLocalToken();

  operation.setContext(authHeaders(token || ''));
  return forward(operation);
});

// Create the apollo client
export const graphQlClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache,
});

function authHeaders(token: string) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
      'Accept-Encoding': 'gzip',
    },
  };
}

export const graphQlApi = {
  /* Main */
  getSchema: async () => {
    return await graphQlClient.query({query: miscQueries.GET_SCHEMA_QUERY});
  },
  logIn: async (email: string, password: string) => {
    const response = await graphQlClient.mutate({
      mutation: mainMutations.CREATE_TOKEN_MUTATION,
      variables: {
        email,
        password,
      },
    });
    return response.data;
  } /* Users */,
  getAllUsers: async () => {
    return await graphQlClient.query({
      query: userQueries.GET_ALL_USERS_QUERY,
      variables: {skip: 0, limit: 99999999},
      fetchPolicy: 'no-cache',
    });
  },
  getAllUsersForTableView: async () => {
    return await graphQlClient.query({
      query: userQueries.GET_USERS_FOR_TABLE_VIEW_QUERY,
      variables: {skip: 0, limit: 99999999},
      fetchPolicy: 'no-cache',
    });
  },
  getAllUsersForList: async () => {
    return await graphQlClient.query({
      query: userQueries.GET_USERS_FOR_LIST_QUERY,
      variables: {skip: 0, limit: 99999999},
      fetchPolicy: 'no-cache',
    });
  },
  getUserMe: async () => {
    return await graphQlClient.query({
      query: userQueries.GET_USER_ME_QUERY,
      variables: {skip: 0, limit: 99999999},
      fetchPolicy: 'no-cache',
    });
  },
  getUserById: async (id: string) => {
    return await graphQlClient.query({
      query: userQueries.GET_USER_BY_ID_QUERY,
      variables: {id},
      fetchPolicy: 'no-cache',
    });
  },
  getUserByEmail: async (email: string) => {
    return await graphQlClient.query({
      query: userQueries.GET_USER_BY_EMAIL_QUERY,
      variables: {email},
      fetchPolicy: 'no-cache',
    });
  },
  getUserByUserGroup: async (userGroup: string) => {
    return await graphQlClient.query({
      query: userQueries.GET_USER_BY_USER_GROUP_QUERY,
      variables: {userGroup},
      fetchPolicy: 'no-cache',
    });
  },
  createUser: async (userInput: IUserProfileCreate) => {
    return await graphQlClient.mutate({
      mutation: userMutations.CREATE_USER_MUTATION,
      variables: {userInput},
    });
  },
  updateUser: async (id: string, userInput: IUserProfileUpdate) => {
    return await graphQlClient.mutate({
      mutation: userMutations.UPDATE_USER_MUTATION,
      variables: {id, userInput},
    });
  },
  updateMe: async (userInput: IUserProfile) => {
    return await graphQlClient.mutate({
      mutation: userMutations.UPDATE_ME_MUTATION,
      variables: {userInput},
    });
  },
  deleteUser: async (id: string) => {
    return await graphQlClient.mutate({
      mutation: userMutations.DELETE_USER_MUTATION,
      variables: {id},
    });
  } /* patients */,
  getPatientsForTableView: async () => {
    return await graphQlClient.query({
      query: patientQueries.PATIENTS_FOR_TABLE_VIEW_QUERY,
      variables: {skip: 0, limit: 99999999},
      fetchPolicy: 'no-cache',
    });
  },
  getAllPatients: async () => {
    return await graphQlClient.query({
      query: patientQueries.GET_ALL_PATIENTS_QUERY,
      variables: {skip: 0, limit: 99999999},
      fetchPolicy: 'no-cache',
    });
  },
  getAllPatientsForList: async () => {
    return await graphQlClient.query({
      query: patientQueries.GET_ALL_PATIENTS_FOR_LIST_QUERY,
      variables: {skip: 0, limit: 99999999},
      fetchPolicy: 'no-cache',
    });
  },
  getPatientById: async (id: string) => {
    return await graphQlClient.query({
      query: patientQueries.GET_PATIENT_BY_ID_QUERY,
      variables: {id},
      fetchPolicy: 'no-cache',
    });
  },
  getPatientByFirebaseId: async (patientFirebaseId: string) => {
    return await graphQlClient.query({
      query: patientQueries.GET_PATIENT_BY_FIREBASE_ID_QUERY,
      variables: {patientFirebaseId},
      fetchPolicy: 'no-cache',
    });
  },
  getPatientByPatientIdentifier: async (patientIdentifier: string) => {
    return await graphQlClient.query({
      query: patientQueries.GET_PATIENT_BY_PATIENT_IDENTIFIER_QUERY,
      variables: {patientIdentifier},
      fetchPolicy: 'no-cache',
    });
  },
  getPatientByEmail: async (email: string) => {
    return await graphQlClient.query({
      query: patientQueries.GET_PATIENT_BY_EMAIL_QUERY,
      variables: {email},
      fetchPolicy: 'no-cache',
    });
  },
  createPatient: async (patientInput: IPatientCreate) => {
    return await graphQlClient.mutate({
      mutation: patientsMutations.CREATE_PATIENT_MUTATION,
      variables: {patientInput},
    });
  },
  updatePatient: async (id: string, patientInput: IPatientUpdate) => {
    return await graphQlClient.mutate({
      mutation: patientsMutations.UPDATE_PATIENT_MUTATION,
      variables: {id, patientInput},
    });
  },
  deletePatient: async (id: string) => {
    return await graphQlClient.mutate({
      mutation: patientsMutations.DELETE_PATIENT_MUTATION,
      variables: {id},
    });
  },
  createPatientCovid: async (covidInput: ICovidInfo) => {
    return await graphQlClient.mutate({
      mutation: patientsMutations.CREATE_PATIENT_COVID_MUTATION,
      variables: {covidInput},
    });
  },
  updatePatientCovid: async (id: string, covidInput: ICovidInfo) => {
    return await graphQlClient.mutate({
      mutation: patientsMutations.UPDATE_PATIENT_COVID_MUTATION,
      variables: {id, covidInput},
    });
  },
  createPatientMedicalHx: async (medicalHxInput: IPatientMedicalHx) => {
    return await graphQlClient.mutate({
      mutation: patientsMutations.CREATE_PATIENT_MEDICAL_HX_MUTATION,
      variables: {medicalHxInput},
    });
  },
  updatePatientMedicalHx: async (id: string, medicalHxInput: IPatientMedicalHx) => {
    return await graphQlClient.mutate({
      mutation: patientsMutations.UPDATE_PATIENT_MEDICAL_HX_MUTATION,
      variables: {id, medicalHxInput},
    });
  },
  createPatientMotionalHx: async (motivationalInput: IMotivationalInfo) => {
    return await graphQlClient.mutate({
      mutation: patientsMutations.CREATE_PATIENT_MOTIVATIONAL_MUTATION,
      variables: {motivationalInput},
    });
  },
  updatePatientMotionalHx: async (id: string, motivationalInput: IMotivationalInfo) => {
    return await graphQlClient.mutate({
      mutation: patientsMutations.UPDATE_PATIENT_MOTIVATIONAL_MUTATION,
      variables: {id, motivationalInput},
    });
  },
  createPatientBehaviorInfo: async (patientBehaviorInfoInput: IPatientBehaviorInfo) => {
    return await graphQlClient.mutate({
      mutation: patientsMutations.CREATE_PATIENT_BEHAVIOR_INFO_MUTATION,
      variables: {patientBehaviorInfoInput},
    });
  },
  updatePatientBehaviorInfo: async (id: string, patientBehaviorInfoInput: IPatientBehaviorInfo) => {
    return await graphQlClient.mutate({
      mutation: patientsMutations.UPDATE_PATIENT_BEHAVIOR_INFO_MUTATION,
      variables: {id, patientBehaviorInfoInput},
    });
  },
  createPatientContactInfo: async (patientContactInfoInput: IPatientContactInfo) => {
    return await graphQlClient.mutate({
      mutation: patientsMutations.CREATE_PATIENT_CONTACT_INFO_MUTATION,
      variables: {patientContactInfoInput},
    });
  },
  updatePatientContactInfo: async (id: string, patientContactInfoInput: IPatientContactInfo) => {
    return await graphQlClient.mutate({
      mutation: patientsMutations.UPDATE_PATIENT_CONTACT_INFO_MUTATION,
      variables: {id, patientContactInfoInput},
    });
  },
  createPatientFemaleInfo: async (femaleReproductiveHealthInput: IFemaleReproductiveHealth) => {
    return await graphQlClient.mutate({
      mutation: patientsMutations.CREATE_PATIENT_FEMALE_REPRODUCTIVE_HEALTH_MUTATION,
      variables: {femaleReproductiveHealthInput},
    });
  },
  updatePatientFemaleInfo: async (
    id: string,
    femaleReproductiveHealthInput: IFemaleReproductiveHealth,
  ) => {
    return await graphQlClient.mutate({
      mutation: patientsMutations.UPDATE_PATIENT_FEMALE_REPRODUCTIVE_HEALTH_MUTATION,
      variables: {id, femaleReproductiveHealthInput},
    });
  },
  createPriorPregnancies: async (priorPregnancyInput: IPriorPregnancies) => {
    return await graphQlClient.mutate({
      mutation: patientsMutations.CREATE_PATIENT_PRIOR_PREGNANCY,
      variables: {priorPregnancyInput},
    });
  },
  updatePriorPregnancies: async (id: string, priorPregnancyInput: IPriorPregnancies) => {
    return await graphQlClient.mutate({
      mutation: patientsMutations.UPDATE_PATIENT_PRIOR_PREGNANCY,
      variables: {id, priorPregnancyInput},
    });
  } /* patient notes */,
  createPatientNote: async (patientNoteInput: IPatientNoteCreate) => {
    return await graphQlClient.mutate({
      mutation: customerServiceMutations.CREATE_PATIENT_NOTE_MUTATION,
      variables: {customerServiceNoteInput: patientNoteInput},
    });
  },
  updatePatientNote: async (id: string, patientNoteInput: IPatientNoteUpdate) => {
    return await graphQlClient.mutate({
      mutation: customerServiceMutations.UPDATE_PATIENT_NOTE_MUTATION,
      variables: {id, customerServiceNoteInput: patientNoteInput},
    });
  },
  getPatientNoteByPatientId: async (patientId: string) => {
    return await graphQlClient.query({
      query: customerServiceQueries.GET_PATIENT_NOTES_BY_PATIENT_ID,
      variables: {patientId},
      fetchPolicy: 'no-cache',
    });
  },
  getPatientNoteById: async (id: string) => {
    return await graphQlClient.query({
      query: customerServiceQueries.GET_PATIENT_NOTES_BY_NOTE_ID,
      variables: {id},
      fetchPolicy: 'no-cache',
    });
  },
  getPatientCustomerServices: async () => {
    return await graphQlClient.query({
      query: customerServiceQueries.GET_PATIENT_CUSTOMER_SERVICES,
      variables: {skip: 0, limit: 99999999},
      fetchPolicy: 'no-cache',
    });
  },
  getCustomerServiceByPatientId: async (patientId: string) => {
    return await graphQlClient.query({
      query: customerServiceQueries.GET_PATIENT_CUSTOMER_SERVICE_BY_PATIENT_ID,
      variables: {patientId},
      fetchPolicy: 'no-cache',
    });
  },
  createPatientCustomerService: async (customerServiceInput: ICreatePatientCustomerService) => {
    return await graphQlClient.mutate({
      mutation: customerServiceMutations.CREATE_PATIENT_CUSTOMER_SERVICE_MUTATION,
      variables: {
        pcsInput: {
          ...customerServiceInput,
        },
      },
    });
  },
  updatePatientCustomerService: async (customerServiceInput: any) => {
    return await graphQlClient.mutate({
      mutation: customerServiceMutations.UPDATE_PATIENT_CUSTOMER_SERVICE_MUTATION,
      variables: {...customerServiceInput},
    });
  } /* requisitions */,
  getAllRequisitions: async () => {
    return await graphQlClient.query({
      query: requisitionsQueries.GET_REQUISITIONS_QUERY,
      variables: {skip: 0, limit: 99999999},
      fetchPolicy: 'no-cache',
    });
  },
  getRequisitionsForTableView: async () => {
    return await graphQlClient.query({
      query: requisitionsQueries.GET_REQUISITIONS_FOR_TABLE_VIEW_QUERY,
      variables: {skip: 0, limit: 99999999},
      fetchPolicy: 'no-cache',
    });
  },
  getRequisitionById: async (id: string) => {
    return await graphQlClient.query({
      query: requisitionsQueries.GET_REQUISITION_BY_ID_QUERY,
      variables: {id},
      fetchPolicy: 'no-cache',
    });
  },
  getRequisitionByPatientId: async (id: string) => {
    return await graphQlClient.query({
      query: requisitionsQueries.GET_REQUISITION_BY_PATIENT_ID_QUERY,
      variables: {id},
      fetchPolicy: 'no-cache',
    });
  },
  getRequisitionsForListView: async () => {
    return await graphQlClient.query({
      query: requisitionsQueries.GET_REQUISITIONS_FOR_LIST_QUERY,
      variables: {skip: 0, limit: 99999999},
      fetchPolicy: 'no-cache',
    });
  },
  getRequisitionsByState: async () => {
    return await graphQlClient.query({
      query: requisitionsQueries.GET_REQUISITIONS_BY_STATE_QUERY,
      variables: {skip: 0, limit: 99999999},
      fetchPolicy: 'no-cache',
    });
  },
  getRequisitionResults: async () => {
    return await graphQlClient.query({
      query: requisitionsQueries.GET_AVAILABLE_REQUISITIONS_TO_REVIEW_QUERY,
      variables: {skip: 0, limit: 99999999},
      fetchPolicy: 'no-cache',
    });
  },
  getRequisitionResultById: async (id: string) => {
    return await graphQlClient.query({
      query: requisitionsQueries.GET_REQUISITION_TO_REVIEW_BY_ID_QUERY,
      variables: {id},
      fetchPolicy: 'no-cache',
    });
  },
  getRequisitionsForReviewer: async () => {
    return await graphQlClient.query({
      query: requisitionsQueries.REVIEWER_REQUISITIONS_QUERY,
      variables: {skip: 0, limit: 99999999},
      fetchPolicy: 'no-cache',
    });
  },
  getRequisitionsForReviewerById: async (id: string) => {
    return await graphQlClient.query({
      query: requisitionsQueries.REVIEWER_REQUISITIONS_BY_ID_QUERY,
      variables: {skip: 0, limit: 99999999, filters: {id}},
      fetchPolicy: 'no-cache',
    });
  },
  createRequisition: async (requisitionInput: ICreateRequisition) => {
    return await graphQlClient.mutate({
      mutation: requisitionsMutations.CREATE_REQUISITION_MUTATION,
      variables: {requisitionInput},
    });
  },
  updateRequisition: async (id: string, requisitionInput: IUpdateRequisition) => {
    return await graphQlClient.mutate({
      mutation: requisitionsMutations.UPDATE_REQUISITION_MUTATION,
      variables: {id, requisitionInput},
      fetchPolicy: 'no-cache',
    });
  },
  updateRequisitionNurse: async (id: string, requisitionInput: IUpdateRequisition) => {
    return await graphQlClient.mutate({
      mutation: requisitionsMutations.UPDATE_REQUISITION_NURSE,
      variables: {id, requisitionInput},
    });
  },
  deleteRequisition: async (id: string) => {
    return await graphQlClient.mutate({
      mutation: requisitionsMutations.DELETE_REQUISITION_MUTATION,
      variables: {id},
    });
  } /* Visits */,
  getAllVisits: async () => {
    return await graphQlClient.query({
      query: visitsQueries.GET_ALL_VISITS_QUERY,
      variables: {skip: 0, limit: 99999999},
      fetchPolicy: 'no-cache',
    });
  },
  getVisitsForTableView: async () => {
    return await graphQlClient.query({
      query: visitsQueries.GET_VISITS_FOR_TABLE_VIEW_QUERY,
      variables: {skip: 0, limit: 99999999},
      fetchPolicy: 'no-cache',
    });
  },
  getVisitsForListView: async () => {
    return await graphQlClient.query({
      query: visitsQueries.GET_VISITS_FOR_LIST_QUERY,
      variables: {skip: 0, limit: 99999999},
      fetchPolicy: 'no-cache',
    });
  },
  getVisitById: async (id: string) => {
    return await graphQlClient.query({
      query: visitsQueries.GET_VISIT_BY_ID_QUERY,
      variables: {id},
      fetchPolicy: 'no-cache',
    });
  },
  getVisitByPatientEmail: async (patientEmail: string) => {
    return await graphQlClient.query({
      query: visitsQueries.GET_VISIT_BY_PATIENT_EMAIL_QUERY,
      variables: {patientEmail},
      fetchPolicy: 'no-cache',
    });
  },
  getVisitByPatientId: async (patientId: string) => {
    return await graphQlClient.query({
      query: visitsQueries.GET_VISIT_BY_PATIENT_ID_QUERY,
      variables: {patientId},
      fetchPolicy: 'no-cache',
    });
  },
  createVisit: async (visitInput: ICreateVisit) => {
    return await graphQlClient.mutate({
      mutation: visitMutations.CREATE_VISIT_MUTATION,
      variables: {visitInput},
    });
  },
  updateVisit: async (id: string, visitInput: IUpdateVisit) => {
    return await graphQlClient.mutate({
      mutation: visitMutations.UPDATE_VISIT_MUTATION,
      variables: {id, visitInput},
    });
  },
  deleteVisit: async (id: string) => {
    return await graphQlClient.mutate({
      mutation: visitMutations.DELETE_VISIT_MUTATION,
      variables: {id},
    });
  },
  createVisitDocument: async (visitInput: IVisitDocument) => {
    return await graphQlClient.mutate({
      mutation: visitMutations.CREATE_VISIT_DOCUMENT_MUTATION,
      variables: {visitInput},
    });
  },
  deleteVisitDocument: async (visitDocumentId: string) => {
    return await graphQlClient.mutate({
      mutation: visitMutations.DELETE_HARD_VISIT_DOCUMENT_MUTATION,
      variables: {visitDocumentId},
    });
  },
  showVisitDocument: async (visitDocumentId: string) => {
    return await graphQlClient.mutate({
      mutation: visitMutations.SHOW_VISIT_DOCUMENT_MUTATION,
      variables: {visitDocumentId},
    });
  },
  hideVisitDocument: async (visitDocumentId: string) => {
    return await graphQlClient.mutate({
      mutation: visitMutations.HIDE_VISIT_DOCUMENT_MUTATION,
      variables: {visitDocumentId},
    });
  }
  /* biomarkers */,
  getAllBiomarkers: async () => {
    return await graphQlClient.query({
      query: biomarkerQueries.GET_ALL_BIOMARKERS_QUERY,
      variables: {skip: 0, limit: 999999},
      fetchPolicy: 'no-cache',
    });
  },
  getAllBiomarkersForTableView: async () => {
    return await graphQlClient.query({
      query: biomarkerQueries.GET_ALL_BIOMARKERS_FOR_TABLE_VIEW_QUERY,
      variables: {skip: 0, limit: 999999},
      fetchPolicy: 'no-cache',
    });
  },
  getAllBiomarkersForList: async () => {
    return await graphQlClient.query({
      query: biomarkerQueries.GET_ALL_BIOMARKERS_FOR_LIST_QUERY,
      variables: {skip: 0, limit: 999999},
      fetchPolicy: 'no-cache',
    });
  },
  getBiomarkerById: async (id: string) => {
    return await graphQlClient.query({
      query: biomarkerQueries.GET_BIOMARKER_BY_ID_QUERY,
      variables: {id},
      fetchPolicy: 'no-cache',
    });
  },
  getBiomarkerByName: async (name: string) => {
    return await graphQlClient.query({
      query: biomarkerQueries.GET_BIOMARKER_BY_NAME_QUERY,
      variables: {name},
      fetchPolicy: 'no-cache',
    });
  },
  createBiomarker: async (biomarkerInput: ICreateBiomarker) => {
    return await graphQlClient.mutate({
      mutation: contentMutations.CREATE_BIOMARKER_MUTATION,
      variables: {biomarkerInput},
    });
  },
  updateBiomarker: async (id: string, biomarkerInput: IUpdateBiomarker) => {
    return await graphQlClient.mutate({
      mutation: contentMutations.UPDATE_BIOMARKER_MUTATION,
      variables: {id, biomarkerInput},
    });
  },
  deleteBiomarker: async (id: string) => {
    return await graphQlClient.mutate({
      mutation: contentMutations.DELETE_BIOMARKER_MUTATION,
      variables: {id},
    });
  },
  createCritical: async (criticalInput: ICreateCritical) => {
    return await graphQlClient.mutate({
      mutation: contentMutations.CREATE_CRITICAL_MUTATION,
      variables: {criticalInput},
    });
  },
  updateCritical: async (id: string, criticalInput: IUpdateCritical) => {
    return await graphQlClient.mutate({
      mutation: contentMutations.UPDATE_CRITICAL_MUTATION,
      variables: {criticalInput, id},
    });
  },
  createSexSpecific: async (sexSpecificInput: ICreateSexSpecifics) => {
    return await graphQlClient.mutate({
      mutation: contentMutations.CREATE_SEX_SPECIFIC_MUTATION,
      variables: {sexSpecificInput},
    });
  },
  updateSexSpecific: async (id: string, sexSpecificInput: IUpdateSexSpecifics) => {
    return await graphQlClient.mutate({
      mutation: contentMutations.UPDATE_SEX_SPECIFIC_MUTATION,
      variables: {sexSpecificInput, id},
    });
  },
  deleteSexSpecific: async (id: string) => {
    return await graphQlClient.mutate({
      mutation: contentMutations.DELETE_SEX_SPECIFIC_MUTATION,
      variables: {id},
    });
  } /* packages */,
  getAllPackages: async () => {
    return await graphQlClient.query({
      query: packagesQueries.GET_ALL_PACKAGES_QUERY,
      variables: {skip: 0, limit: 999999},
      fetchPolicy: 'no-cache',
    });
  },
  getAllPackagesForTableView: async () => {
    return await graphQlClient.query({
      query: packagesQueries.GET_ALL_PACKAGES_FOR_TABLE_VIEW_QUERY,
      variables: {skip: 0, limit: 999999},
      fetchPolicy: 'no-cache',
    });
  },
  getAllPackagesForList: async () => {
    return await graphQlClient.query({
      query: packagesQueries.GET_ALL_PACKAGES_FOR_LIST_QUERY,
      variables: {skip: 0, limit: 999999},
      fetchPolicy: 'no-cache',
    });
  },
  getPackageById: async (id: string) => {
    return await graphQlClient.query({
      query: packagesQueries.GET_PACKAGE_BY_ID_QUERY,
      variables: {id},
      fetchPolicy: 'no-cache',
    });
  },
  deletePackage: async (id: string) => {
    return await graphQlClient.mutate({
      mutation: contentMutations.DELETE_PACKAGE_MUTATION,
      variables: {id},
    });
  },
  createPackage: async (packageInput: ICreatePackage) => {
    return await graphQlClient.mutate({
      mutation: contentMutations.CREATE_PACKAGE_MUTATION,
      variables: {packageInput},
    });
  },
  updatePackage: async (id: string, packageInput: IUpdatePackage) => {
    return await graphQlClient.mutate({
      mutation: contentMutations.UPDATE_PACKAGE_MUTATION,
      variables: {id, packageInput},
    });
  } /* panels */,
  getAllPanels: async () => {
    return await graphQlClient.query({
      query: panelsQueries.GET_ALL_PANELS_QUERY,
      variables: {skip: 0, limit: 999999},
      fetchPolicy: 'no-cache',
    });
  },
  getAllPanelsForTableView: async () => {
    return await graphQlClient.query({
      query: panelsQueries.GET_ALL_PANELS_FOR_TABLE_VIEW_QUERY,
      variables: {skip: 0, limit: 999999},
      fetchPolicy: 'no-cache',
    });
  },
  getAllPanelsForList: async () => {
    return await graphQlClient.query({
      query: panelsQueries.GET_ALL_PANELS_FOR_LIST_QUERY,
      variables: {skip: 0, limit: 999999},
      fetchPolicy: 'no-cache',
    });
  },
  getPanelById: async (id: string) => {
    return await graphQlClient.query({
      query: panelsQueries.GET_PANEL_BY_ID_QUERY,
      variables: {id},
      fetchPolicy: 'no-cache',
    });
  },
  createPanel: async (panelInput: ICreatePanel) => {
    return await graphQlClient.mutate({
      mutation: contentMutations.CREATE_PANEL_MUTATION,
      variables: {panelInput},
    });
  },
  updatePanel: async (id: string, panelInput: IUpdatePanel) => {
    return await graphQlClient.mutate({
      mutation: contentMutations.UPDATE_PANEL_MUTATION,
      variables: {id, panelInput},
    });
  },
  deletePanel: async (id: string) => {
    return await graphQlClient.mutate({
      mutation: contentMutations.DELETE_PANEL_MUTATION,
      variables: {id},
    });
  } /* biomarkers results */,
  getAllBiomarkerResults: async () => {
    return await graphQlClient.query({
      query: BiomarkerResultsQueries.GET_ALL_BIOMARKER_RESULTS_QUERY,
      variables: {skip: 0, limit: 999999},
      fetchPolicy: 'no-cache',
    });
  },
  getAllBiomarkerResultsForTableView: async () => {
    return await graphQlClient.query({
      query: BiomarkerResultsQueries.GET_ALL_BIOMARKER_RESULTS_FOR_TABLE_VIEW_QUERY,
      variables: {skip: 0, limit: 999999},
      fetchPolicy: 'no-cache',
    });
  },
  getBiomarkerResultById: async (id: string) => {
    return await graphQlClient.query({
      query: BiomarkerResultsQueries.GET_BIOMARKER_RESULTS_BY_ID_QUERY,
      variables: {id},
    });
  },
  createBiomarkerResult: async (biomarkerResultInput: ICreateBiomarkerResult) => {
    return await graphQlClient.mutate({
      mutation: biomarkerResultsMutations.CREATE_BIOMARKER_RESULT_MUTATION,
      variables: {biomarkerResultInput},
    });
  },
  updateBiomarkerResult: async (id: string, biomarkerResultInput: IUpdateBiomarkerResult) => {
    return await graphQlClient.mutate({
      mutation: biomarkerResultsMutations.UPDATE_BIOMARKER_RESULT_MUTATION,
      variables: {id, biomarkerResultInput},
    });
  },
  deleteBiomarkerResult: async (id: string) => {
    return await graphQlClient.mutate({
      mutation: biomarkerResultsMutations.DELETE_BIOMARKER_RESULT_MUTATION,
      variables: {id},
    });
  } /* exercises */,
  getAllExercisesForList: async () => {
    return await graphQlClient.query({
      query: exercisesQueries.GET_EXERCISES_FOR_LIST_QUERY,
      variables: {skip: 0, limit: 999999},
      fetchPolicy: 'no-cache',
    });
  },
  getAllExercises: async () => {
    return await graphQlClient.query({
      query: exercisesQueries.GET_ALL_EXERCISES_QUERY,
      variables: {skip: 0, limit: 999999},
      fetchPolicy: 'no-cache',
    });
  },
  getExerciseById: async (id: string) => {
    return await graphQlClient.query({
      query: exercisesQueries.GET_EXERCISE_BY_ID_QUERY,
      variables: {id},
      fetchPolicy: 'no-cache',
    });
  },
  updateExercise: async (id: string, exerciseInput: IUpdateExercise) => {
    return await graphQlClient.mutate({
      mutation: contentMutations.UPDATE_EXERCISE_MUTATION,
      variables: {id, exerciseInput},
    });
  },
  createExcercise: async (exerciseInput: ICreateExercise) => {
    return await graphQlClient.mutate({
      mutation: contentMutations.CREATE_EXERCISE_MUTATION,
      variables: {exerciseInput},
    });
  },
  deleteExercise: async (id: string) => {
    return await graphQlClient.mutate({
      mutation: contentMutations.DELETE_EXERCISE_MUTATION,
      variables: {id},
    });
  } /* foods */,
  getAllFoodsForList: async () => {
    return await graphQlClient.query({
      query: foodsQueries.GET_FOODS_FOR_LIST_QUERY,
      variables: {skip: 0, limit: 999999},
      fetchPolicy: 'no-cache',
    });
  },
  getAllFoods: async () => {
    return await graphQlClient.query({
      query: foodsQueries.GET_ALL_FOODS_QUERY,
      variables: {skip: 0, limit: 999999},
      fetchPolicy: 'no-cache',
    });
  },
  getFoodById: async (id: string) => {
    return await graphQlClient.query({
      query: foodsQueries.GET_FOOD_BY_ID_QUERY,
      variables: {id},
      fetchPolicy: 'no-cache',
    });
  },
  updateFood: async (id: string, foodInput: IUpdateFood) => {
    return await graphQlClient.mutate({
      mutation: contentMutations.UPDATE_FOOD_MUTATION,
      variables: {id, foodInput},
      fetchPolicy: 'no-cache',
    });
  },
  createFood: async (foodInput: ICreateFood) => {
    return await graphQlClient.mutate({
      mutation: contentMutations.CREATE_FOOD_MUTATION,
      variables: {foodInput},
    });
  },
  deleteFood: async (id: string) => {
    return await graphQlClient.mutate({
      mutation: contentMutations.DELETE_FOOD_MUTATION,
      variables: {id},
    });
  } /* supplements */,
  getAllSupplementsForList: async () => {
    return await graphQlClient.query({
      query: supplementsQueries.GET_SUPPLEMENTS_FOR_LIST_QUERY,
      variables: {skip: 0, limit: 999999},
      fetchPolicy: 'no-cache',
    });
  },
  getAllSupplements: async () => {
    return await graphQlClient.query({
      query: supplementsQueries.GET_ALL_SUPPLEMENTS_QUERY,
      variables: {skip: 0, limit: 999999},
      fetchPolicy: 'no-cache',
    });
  },
  getSupplementById: async (id: string) => {
    return await graphQlClient.query({
      query: supplementsQueries.GET_SUPPLEMENTS_BY_ID_QUERY,
      variables: {id},
      fetchPolicy: 'no-cache',
    });
  },
  getSupplementByName: async (name: string) => {
    return await graphQlClient.query({
      query: supplementsQueries.GET_SUPPLEMENTS_BY_NAME_QUERY,
      variables: {name},
      fetchPolicy: 'no-cache',
    });
  },
  getSupplementsByGroup: async (group: string) => {
    return await graphQlClient.query({
      query: supplementsQueries.GET_SUPPLEMENTS_BY_GROUP_QUERY,
      variables: {group},
      fetchPolicy: 'no-cache',
    });
  },
  updateSupplement: async (id: string, supplementInput: IUpdateSupplement) => {
    return await graphQlClient.mutate({
      mutation: contentMutations.UPDATE_SUPPLEMENT_MUTATION,
      variables: {id, supplementInput},
    });
  },
  createSupplement: async (supplementInput: ICreateSupplement) => {
    return await graphQlClient.mutate({
      mutation: contentMutations.CREATE_SUPPLEMENT_MUTATION,
      variables: {supplementInput},
    });
  },
  deleteSupplement: async (id: string) => {
    return await graphQlClient.mutate({
      mutation: contentMutations.DELETE_SUPPLEMENT_MUTATION,
      variables: {id},
    });
  } /* Recommendations */,
  getRecommendationsForList: async () => {
    return await graphQlClient.query({
      query: recommendationsQueries.GET_RECOMMENDATIONS_FOR_LIST_QUERY,
      variables: {skip: 0, limit: 999999},
      fetchPolicy: 'no-cache',
    });
  },
  getRecommendationsForTable: async () => {
    return await graphQlClient.query({
      query: recommendationsQueries.GET_ALL_RECOMMENDATIONS_FOR_TABLE_QUERY,
      variables: {skip: 0, limit: 999999},
      fetchPolicy: 'no-cache',
    });
  },
  getAllRecommendations: async () => {
    return await graphQlClient.query({
      query: recommendationsQueries.GET_ALL_RECOMMENDATIONS_QUERY,
      variables: {skip: 0, limit: 999999},
      fetchPolicy: 'no-cache',
    });
  },
  getRecommendationById: async (id: string) => {
    return await graphQlClient.query({
      query: recommendationsQueries.GET_RECOMMENDATION_BY_ID_QUERY,
      variables: {id},
      fetchPolicy: 'no-cache',
    });
  },
  updateRecommendation: async (id: string, recommendationInput: IUpdateRecommendation) => {
    return await graphQlClient.mutate({
      mutation: contentMutations.UPDATE_RECOMMENDATION_MUTATION,
      variables: {id, recommendationInput},
    });
  },
  createRecommendation: async (recommendationInput: ICreateRecommendation) => {
    return await graphQlClient.mutate({
      mutation: contentMutations.CREATE_RECOMMENDATION_MUTATION,
      variables: {recommendationInput},
    });
  },
  deleteRecommendation: async (id: string) => {
    return await graphQlClient.mutate({
      mutation: contentMutations.DELETE_RECOMMENDATION_MUTATION,
      variables: {id},
    });
  } /* categories */,
  getAllCategoriesForList: async () => {
    return await graphQlClient.query({
      query: categoriesQueries.GET_CATEGORIES_FOR_LIST_QUERY,
      variables: {skip: 0, limit: 999999},
      fetchPolicy: 'no-cache',
    });
  },
  getAllCategories: async () => {
    return await graphQlClient.query({
      query: categoriesQueries.GET_ALL_CATEGORIES_QUERY,
      variables: {skip: 0, limit: 999999},
      fetchPolicy: 'no-cache',
    });
  },
  getCategoryById: async (id: string) => {
    return await graphQlClient.query({
      query: categoriesQueries.GET_CATEGORY_BY_ID_QUERY,
      variables: {id},
      fetchPolicy: 'no-cache',
    });
  },
  updateCategory: async (id: string, categoryInput: IUpdateCategory) => {
    return await graphQlClient.mutate({
      mutation: contentMutations.UPDATE_CATEGORY_MUTATION,
      variables: {id, categoryInput},
    });
  },
  createCategory: async (categoryInput: ICreateCategory) => {
    return await graphQlClient.mutate({
      mutation: contentMutations.CREATE_CATEGORY_MUTATION,
      variables: {categoryInput},
    });
  },
  deleteCategory: async (id: string) => {
    return await graphQlClient.mutate({
      mutation: contentMutations.DELETE_CATEGORY_MUTATION,
      variables: {id},
    });
  } /* causes */,
  getAllCausesForList: async () => {
    return await graphQlClient.query({
      query: causesQueries.GET_CAUSES_FOR_LIST_QUERY,
      variables: {skip: 0, limit: 999999},
      fetchPolicy: 'no-cache',
    });
  },
  getAllCauses: async () => {
    return await graphQlClient.query({
      query: causesQueries.GET_ALL_CAUSES_QUERY,
      variables: {skip: 0, limit: 999999},
      fetchPolicy: 'no-cache',
    });
  },
  getCauseById: async (id: string) => {
    return await graphQlClient.query({
      query: causesQueries.GET_CAUSE_BY_ID_QUERY,
      variables: {id},
      fetchPolicy: 'no-cache',
    });
  },
  updateCause: async (id: string, causeInput: IUpdateCause) => {
    return await graphQlClient.mutate({
      mutation: contentMutations.UPDATE_CAUSE_MUTATION,
      variables: {id, causeInput},
    });
  },
  createCause: async (causeInput: ICreateCause) => {
    return await graphQlClient.mutate({
      mutation: contentMutations.CREATE_CAUSE_MUTATION,
      variables: {causeInput},
    });
  },
  deleteCause: async (id: string) => {
    return await graphQlClient.mutate({
      mutation: contentMutations.DELETE_CAUSE_MUTATION,
      variables: {id},
    });
  } /* symptoms */,
  getAllSymptomsForList: async () => {
    return await graphQlClient.query({
      query: symptomsQueries.GET_SYMPTOMS_FOR_LIST_QUERY,
      variables: {skip: 0, limit: 999999},
      fetchPolicy: 'no-cache',
    });
  },
  getAllSymptoms: async () => {
    return await graphQlClient.query({
      query: symptomsQueries.GET_ALL_SYMPTOMS_QUERY,
      variables: {skip: 0, limit: 999999},
      fetchPolicy: 'no-cache',
    });
  },
  getSymptomById: async (id: string) => {
    return await graphQlClient.query({
      query: symptomsQueries.GET_SYMPTOM_BY_ID_QUERY,
      variables: {id},
      fetchPolicy: 'no-cache',
    });
  },
  updateSymptom: async (id: string, symptomInput: IUpdateSymptom) => {
    return await graphQlClient.mutate({
      mutation: contentMutations.UPDATE_SYMPTOM_MUTATION,
      variables: {id, symptomInput},
    });
  },
  createSymptom: async (symptomInput: ICreateSymptom) => {
    return await graphQlClient.mutate({
      mutation: contentMutations.CREATE_SYMPTOM_MUTATION,
      variables: {symptomInput},
    });
  },
  deleteSymptom: async (id: string) => {
    return await graphQlClient.mutate({
      mutation: contentMutations.DELETE_SYMPTOM_MUTATION,
      variables: {id},
    });
  } /* Self care */,
  getAllSelfCaresForList: async () => {
    return await graphQlClient.query({
      query: selfCareQueries.GET_SELF_CARE_FOR_LIST_QUERY,
      variables: {skip: 0, limit: 999999},
      fetchPolicy: 'no-cache',
    });
  },
  getAllSelfCares: async () => {
    return await graphQlClient.query({
      query: selfCareQueries.GET_ALL_SELF_CARE_QUERY,
      variables: {skip: 0, limit: 999999},
      fetchPolicy: 'no-cache',
    });
  },
  getSelfCareById: async (id: string) => {
    return await graphQlClient.query({
      query: selfCareQueries.GET_SYMPTOM_BY_ID_QUERY,
      variables: {id},
      fetchPolicy: 'no-cache',
    });
  },
  updateSelfCare: async (id: string, selfcareInput: IUpdateSelfCare) => {
    return await graphQlClient.mutate({
      mutation: contentMutations.UPDATE_SELF_CARE_MUTATION,
      variables: {id, selfcareInput},
    });
  },
  createSelfCare: async (selfcareInput: ICreateSelfCare) => {
    return await graphQlClient.mutate({
      mutation: contentMutations.CREATE_SELF_CARE_MUTATION,
      variables: {selfcareInput},
    });
  },
  deleteSelfCare: async (id: string) => {
    return await graphQlClient.mutate({
      mutation: contentMutations.DELETE_SELF_CARE_MUTATION,
      variables: {id},
    });
  },
  createPhysicianNote: async (physicianNoteInput: INoteInput) => {
    return await graphQlClient.mutate<INote>({
      mutation: CREATE_PHYSICIAN_NOTE_MUTATION,
      variables: {physicianNoteInput},
    });
  },
  updatePhysicianNote: async (id: string, physicianNoteInput: INoteInput) => {
    return await graphQlClient.mutate({
      mutation: UPDATE_PHYSICIAN_NOTE_MUTATION,
      variables: {physicianNoteInput, id},
    });
  },
  deletePhysicianNote: async (id: string) => {
    return await graphQlClient.mutate({
      mutation: DELETE_PHYSICIAN_NOTE_MUTATION,
      variables: {id},
    });
  },
  getPhysicianNotes: async (
    requisitionIds: string[],
    fetchPolicy?: 'cache-first' | 'network-only' | 'cache-only' | 'no-cache' | 'standby',
  ) => {
    return await graphQlClient.query<{physicianNotes: INote[]}>({
      query: GET_PHYSICIAN_NOTES_QUERY,
      variables: {skip: 0, limit: 999999, requisitionIds},
      fetchPolicy,
    });
  },
  getPhysicianNoteById: async (id: string) => {
    return await graphQlClient.query({
      query: GET_PHYSICIAN_NOTE_BY_ID_QUERY,
      variables: {id},
      fetchPolicy: 'no-cache',
    });
  },
  createRequisitionComment: async (requisitionCommentInput: IRequisitionCommentInput) => {
    return await graphQlClient.mutate<{createRequisitionComment: IRequisitionComment}>({
      mutation: CREATE_REQUISITION_COMMENT_MUTATION,
      variables: {requisitionCommentInput},
    });
  },
  updateRequisitionComment: async (
    id: string,
    requisitionCommentInput: IRequisitionCommentUpdate,
  ) => {
    return await graphQlClient.mutate({
      mutation: UPDATE_REQUISITION_COMMENT_MUTATION,
      variables: {requisitionCommentInput, id},
    });
  },
  deleteRequisitionComment: async (id: string) => {
    return await graphQlClient.mutate({
      mutation: DELETE_REQUISITION_COMMENT_MUTATION,
      variables: {id},
    });
  },
  createCriticalResult: async (payload: ICriticalResultCreate) => {
    return await graphQlClient.mutate({
      mutation: CREATE_CRITICAL_RESULT_MUTATION,
      variables: {criticalResultInput: payload},
    });
  },
  updateCriticalResult: async (id: string, criticalResultInput: ICriticalResultUpdate) => {
    return await graphQlClient.mutate({
      mutation: UPDATE_CRITICAL_RESULT_MUTATION,
      variables: {criticalResultInput, id},
    });
  },
  createReviewingTime: async (reviewingTimeInput: ITimeTrackerCreate) => {
    return await graphQlClient.mutate({
      mutation: mainMutations.CREATE_REVIEWING_TIME_MUTATION,
      variables: {reviewingTimeInput},
    });
  },
};
