import gql from 'graphql-tag';


export const GET_SELF_CARE_FOR_LIST_QUERY = gql`
query selfcares($skip: Int, $limit: Int) {
    selfcares(skip: $skip, limit: $limit){
        id
        name
    }
}
`;

export const GET_ALL_SELF_CARE_QUERY = gql`
query selfcares($skip: Int, $limit: Int) {
    selfcares(skip: $skip, limit: $limit){
        id
        name
        description
    }
}
`;

export const GET_SYMPTOM_BY_ID_QUERY = gql`
query selfcareById($id: UUID!) {
    selfcareById(id: $id){
        id
        name
        description
    }
}
`;
