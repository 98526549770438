import gql from 'graphql-tag';

export const GET_FOODS_FOR_LIST_QUERY = gql`
query foods($skip: Int, $limit: Int) {
    foods(skip: $skip, limit: $limit){
        id
        name
    }
}
`;

export const GET_ALL_FOODS_QUERY = gql`
query foods($skip: Int, $limit: Int) {
    foods(skip: $skip, limit: $limit){
        id
        name
        foodGroup
        description
    }
}
`;

export const GET_FOOD_BY_ID_QUERY = gql`
query foodById($id: UUID!) {
    foodById(id: $id){
        id
        name
        foodGroup
        description
    }
}
`;
