import gql from 'graphql-tag';

export const CREATE_PATIENT_NOTE_MUTATION = gql`
  mutation createCustomerServiceNote($customerServiceNoteInput: CustomerServiceNoteCreate!) {
    createCustomerServiceNote(customerServiceNoteInput: $customerServiceNoteInput) {
      id
      patientCustomerService {
          id
          assignedAt
      }
      noteCreator {
          id
          fname
          lname
      }
      createdAt
      text
    }
  }
`;

export const UPDATE_PATIENT_NOTE_MUTATION = gql`
  mutation updateCustomerServiceNote(
    $id: UUID!
    $customerServiceNoteInput: CustomerServiceNoteUpdate!
  ) {
    updateCustomerServiceNote(id: $id, customerServiceNoteInput: $customerServiceNoteInput) {
      id
      patientCustomerService {
          id
          assignedAt
      }
      noteCreator {
          id
          fname
          lname
      }
      createdAt
      text
    }
  }
`;

export const CREATE_PATIENT_CUSTOMER_SERVICE_MUTATION = gql`
  mutation createPatientCustomerService($pcsInput: PatientCustomerServiceCreate!) {
    createPatientCustomerService(pcsInput: $pcsInput) {
      id
      assignedUserId
    }
  }
`;

export const UPDATE_PATIENT_CUSTOMER_SERVICE_MUTATION = gql`
  mutation updatePatientCustomerService($id: UUID!, $pcsInput: PatientCustomerServiceUpdate!) {
    updatePatientCustomerService(id: $id, pcsInput: $pcsInput) {
      id
      assignedUserId
    }
  }
`;
