import { State } from '../state';
import { CrudState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { ActionContext } from 'vuex';
type MainContext = ActionContext<CrudState, State>;



/* Actions */

import userActions from '@/store/crud/actions-modules/users-actions';
import patientActions from './actions-modules/patients-actions';
import visitActions from './actions-modules/visits-actions';
import packagesActions from './actions-modules/packages-actions';
import panelsActions from './actions-modules/panels-actions';
import biomarkersActions from './actions-modules/biomarkers-actions';
import requisitionsActions from './actions-modules/requisitions-actions';

import biomarkerResultsActions from './actions-modules/biomarker-results-actions';
import dataExpirationActions from './actions-modules/data-expiration-actions';
import otherActions from './actions-modules/other-actions';
import symptomsActions from './actions-modules/symptoms-actions';
import causesActions from './actions-modules/causes-actions';
import supplementsActions from './actions-modules/supplements-actions';
import foodsActions from './actions-modules/foods-actions';
import recommendationsActions from './actions-modules/recommendations-actions';
import exercisesActions from './actions-modules/exercises-actions';
import categoriesActions from './actions-modules/categories-actions';
import selfCareActions from './actions-modules/self-care-actions';
import customerServiceActions from './actions-modules/customer-service-actions';
import physicianNotesActions from './actions-modules/physicianNotes-actions';
import accessCodesActions from './actions-modules/access-codes-actions';
import requisitionCommentsActions from './actions-modules/requisition-comments-actions';
import criticalResultsActions from '@/store/crud/actions-modules/critical-results-actions';

export const actions = {
    ...userActions,
    ...patientActions,
    ...visitActions,
    ...packagesActions,
    ...panelsActions,
    ...biomarkersActions,
    ...biomarkerResultsActions,
    ...requisitionsActions,
    ...dataExpirationActions,
    ...otherActions,
    ...symptomsActions,
    ...causesActions,
    ...supplementsActions,
    ...foodsActions,
    ...recommendationsActions,
    ...exercisesActions,
    ...symptomsActions,
    ...categoriesActions,
    ...supplementsActions,
    ...selfCareActions,
    ...customerServiceActions,
    ...physicianNotesActions,
    ...accessCodesActions,
    ...requisitionCommentsActions,
    ...criticalResultsActions,
    async actionResetCrudState(context: MainContext) {
        context.commit('resetCrudState');
    },
};

const { dispatch } = getStoreAccessors<CrudState, State>('');

// Users
export const dispatchCreateUser = dispatch(actions.actionCreateUser);
export const dispatchGetUsers = dispatch(actions.actionGetUsers);
export const dispatchGetUsersForList = dispatch(actions.actionGetUsersForList);
export const dispatchGetUserById = dispatch(actions.actionGetUserById);
export const dispatchGetUserByEmail = dispatch(actions.actionGetUserByEmail);
export const dispatchGetUserByUserGroup = dispatch(actions.actionGetUserByUserGroupId);
export const dispatchUpdateUser = dispatch(actions.actionUpdateUser);
export const dispatchDeleteUser = dispatch(actions.actionDeleteUser);
// Patients
export const dispatchCreatePatient = dispatch(actions.actionCreatePatient);
export const dispatchGetPatients = dispatch(actions.actionGetPatients);
export const dispatchGetPatientsForList = dispatch(actions.actionGetPatientsForList);
export const dispatchGetPatientsForTable = dispatch(actions.actionGetPatientsForTable);

export const dispatchGetPatientByEmail = dispatch(actions.actionGetPatientByEmail);
export const dispatchGetPatientById = dispatch(actions.actionGetPatientById);
export const dispatchGetPatientByFirebaseId = dispatch(actions.actionGetPatientByFirebaseId);
export const dispatchGetPatientByPatientIdentifier = dispatch(actions.actionGetPatientByPatientIdentifier);
export const dispatchUpdatePatient = dispatch(actions.actionUpdatePatient);
export const dispatchDeletePatient = dispatch(actions.actionDeletePatient);
export const dispatchCreatePatientCovid = dispatch(actions.actionCreatePatientCovidInfo);
export const dispatchUpdatePatientCovid = dispatch(actions.actionUpdatePatientCovidInfo);
export const dispatchCreatePatientFemale = dispatch(actions.actionCreatePatientFemaleInfo);
export const dispatchUpdatePatientFemale = dispatch(actions.actionUpdatePatientFemaleInfo);
export const dispatchCreatePriorPregnancies = dispatch(actions.actionCreatePriorPregnancies);
export const dispatchUpdatePriorPregnancies = dispatch(actions.actionUpdatePriorPregnancies);

// customer service
export const dispatchCreatePatientNote = dispatch(actions.actionCreatePatientNote);
export const dispatchUpdatePatientNote = dispatch(actions.actionUpdatePatientNote);
export const dispatchGetPatientNotes = dispatch(actions.actionGetPatientNotesByPatientId);
export const dispatchGetPatientNote = dispatch(actions.actionGetPatientNotesByNoteId);
export const dispatchGetPatientCustomerServices = dispatch(actions.actionGetPatientCustomerServices);
export const dispatchGetCustomerServiceByPatientId = dispatch(actions.actionGetCustomerServiceByPatientId);
export const dispatchCreatePatientCustomerService = dispatch(actions.actionCreatePatientCustomerService);
export const dispatchUpdatePatientCustomerService = dispatch(actions.actionUpdatePatientCustomerService);

/* Requisitions */
export const dispatchCreateRequisition = dispatch(actions.actionCreateRequisition);
export const dispatchGetRequisitions = dispatch(actions.actionGetRequisitions);
export const dispatchGetRequisitionResultsById = dispatch(actions.actionGetRequisitionResultById);
export const dispatchGetRequisitionsForList = dispatch(actions.actionGetRequisitionsForList);
export const dispatchGetRequisitionsByState = dispatch(actions.actionGetRequisitionsByState);
export const dispatchGetRequisitionResults = dispatch(actions.actionGetRequisitionResults);
export const dispatchGetRequisitionById = dispatch(actions.actionGetRequisitionById);
export const dispatchGetRequisitionsForReviewer = dispatch(actions.actionGetRequisitionsForReviewer);
export const dispatchGetRequisitionsForReviewerById = dispatch(actions.actionGetRequisitionForReviewerById);
export const dispatchUpdateRequisition = dispatch(actions.actionUpdateRequisition);
export const dispatchUpdateRequisitionNurse = dispatch(actions.actionUpdateRequisitionNurse);
export const dispatchDeleteRequisition = dispatch(actions.actionDeleteRequisition);

export const dispatchCreatePatientBehavior = dispatch(actions.actionCreatePatientBehaviorInfo);
export const dispatchUpdatePatientBehavior = dispatch(actions.actionUpdatePatientBehaviorInfo);
export const dispatchCreatePatientContact = dispatch(actions.actionCreatePatientContactInfo);
export const dispatchUpdatePatientContact = dispatch(actions.actionUpdatePatientContactInfo);
export const dispatchCreatePatientMedicalHx = dispatch(actions.actionCreateMedicalHx);
export const dispatchUpdatePatientMedicalHx = dispatch(actions.actionUpdateMedicalHx);
export const dispatchCreatePatientMotivational = dispatch(actions.actionCreatePatientMotivational);
export const dispatchUpdatePatientMotivational = dispatch(actions.actionUpdatePatientMotivational);

// Visits
export const dispatchGetVisitById = dispatch(actions.actionGetVisitById);
export const dispatchGetVisitByPatientEmail = dispatch(actions.actionGetVisitByPatientEmail);
export const dispatchGetVisitByPatientId = dispatch(actions.actionGetVisitByPatientId);
export const dispatchCreateVisit = dispatch(actions.actionCreateVisit);
export const dispatchGetVisits = dispatch(actions.actionGetVisits);
export const dispatchGetVisitsForList = dispatch(actions.actionGetVisitsForList);
export const dispatchUpdateVisit = dispatch(actions.actionUpdateVisit);
export const dispatchDeleteVisit = dispatch(actions.actionDeleteVisit);
// packages
export const dispatchGetPackages = dispatch(actions.actionGetPackages);
export const dispatchGetPackagesForList = dispatch(actions.actionGetPackagesForList);
export const dispatchGetPackageById = dispatch(actions.actionGetPackageById);
export const dispatchCreatePackage = dispatch(actions.actionCreatePackage);
export const dispatchUpdatePackage = dispatch(actions.actionUpdatePackage);
export const dispatchDeletePackage = dispatch(actions.actionDeletePackage);
// panels
export const dispatchGetPanels = dispatch(actions.actionGetPanels);
export const dispatchGetPanelsForList = dispatch(actions.actionGetPanelsForList);
export const dispatchCreatePanel = dispatch(actions.actionCreatePanel);
export const dispatchUpdatePanel = dispatch(actions.actionUpdatePanel);
export const dispatchGetPanelById = dispatch(actions.actionGetPanelById);
export const dispatchDeletePanel = dispatch(actions.actionDeletePanel);
// biomarkers
export const dispatchGetBiomarkers = dispatch(actions.actionGetBiomarkers);
export const dispatchGetBiomarkersForList = dispatch(actions.actionGetBiomarkersForList);
export const dispatchGetBiomarkerById = dispatch(actions.actionGetBiomarkerById);
export const dispatchGetBiomarkerByName = dispatch(actions.actionGetBiomarkerByName);
export const dispatchCreateBiomarker = dispatch(actions.actionCreateBiomarker);
export const dispatchUpdateBiomarker = dispatch(actions.actionUpdateBiomarker);
export const dispatchDeleteBiomarker = dispatch(actions.actionDeleteBiomarker);
export const dispatchCreateCritical = dispatch(actions.actionCreateCritical);
export const dispatchUpdateCritical = dispatch(actions.actionUpdateCritical);
export const dispatchCreateSexSpecific = dispatch(actions.actionCreateBiomarkerSexSpecific);
export const dispatchUpdateSexSpecific = dispatch(actions.actionUpdateBiomarkerSexSpecific);
export const dispatchDeleteSexSpecific = dispatch(actions.actionDeleteBiomarkerSexSpecific);
// biomarker results
export const dispatchGetBiomarkerResults = dispatch(actions.actionGetBiomarkerResults);
export const dispatchGetBiomarkerResultsForList = dispatch(actions.actionGetBiomarkerResultsForList);
export const dispatchGetBiomarkerResultById = dispatch(actions.actionGetBiomarkerResultById);
export const dispatchCreateBiomarkerResult = dispatch(actions.actionCreateBiomarkerResult);
export const dispatchUpdateBiomarkerResult = dispatch(actions.actionUpdateBiomarkerResult);
export const dispatchDeleteBiomarkerResult = dispatch(actions.actionDeleteBiomarkerResult);

// Supplements
export const dispatchGetSupplements = dispatch(actions.actionGetSupplements);
export const dispatchGetSupplementsById = dispatch(actions.actionGetSupplementById);
export const dispatchCreateSupplement = dispatch(actions.actionCreateSupplement);
export const dispatchUpdateSupplement = dispatch(actions.actionUpdateSupplement);
export const dispatchDeleteSupplement = dispatch(actions.actionDeleteSupplement);
export const dispatchGetSupplementsForList = dispatch(actions.actionGetSupplementsForList);
// Exercises
export const dispatchGetExercises = dispatch(actions.actionGetExercises);
export const dispatchCreateExercise = dispatch(actions.actionCreateExercise);
export const dispatchGetExerciseById = dispatch(actions.actionGetExerciseById);
export const dispatchUpdateExercise = dispatch(actions.actionUpdateExercise);
export const dispatchDeleteExercise = dispatch(actions.actionDeleteExercise);
export const dispatchGetExercisesForList = dispatch(actions.actionGetExercisesForList);
// foods
export const dispatchGetFoods = dispatch(actions.actionGetFoods);
export const dispatchGetFoodsById = dispatch(actions.actionGetFoodById);
export const dispatchCreateFood = dispatch(actions.actionCreateFood);
export const dispatchUpdateFood = dispatch(actions.actionUpdateFood);
export const dispatchDeleteFood = dispatch(actions.actionDeleteFood);
export const dispatchGetFoodsForList = dispatch(actions.actionGetFoodsForList);

// recommendations
export const dispatchGetRecommendations = dispatch(actions.actionGetRecommendations);
export const dispatchGetRecommendationsForTable = dispatch(actions.actionGetRecommendationsForTable);
export const dispatchGetRecommendationById = dispatch(actions.actionGetRecommendationById);
export const dispatchCreateRecommendation = dispatch(actions.actionCreateRecommendation);
export const dispatchUpdateRecommendation = dispatch(actions.actionUpdateRecommendation);
export const dispatchDeleteRecommendation = dispatch(actions.actionDeleteRecommendation);
export const dispatchGetRecommendationsForList = dispatch(actions.actionGetRecommendationsForList);
// causes
export const dispatchGetCauses = dispatch(actions.actionGetAllCauses);
export const dispatchGetCauseById = dispatch(actions.actionGetCauseById);
export const dispatchCreateCause = dispatch(actions.actionCreateCause);
export const dispatchUpdateCause = dispatch(actions.actionUpdateCause);
export const dispatchDeleteCause = dispatch(actions.actionDeleteCause);
export const dispatchGetCausesForList = dispatch(actions.actionGetCausesForList);
// symptoms
export const dispatchGetSymptoms = dispatch(actions.actionGetAllSymptoms);
export const dispatchGetSymptomById = dispatch(actions.actionGetSymptomById);
export const dispatchCreateSymptom = dispatch(actions.actionCreateSymptom);
export const dispatchUpdateSymptom = dispatch(actions.actionUpdateSymptom);
export const dispatchDeleteSymptom = dispatch(actions.actionDeleteSymptom);
export const dispatchGetSymptomsForList = dispatch(actions.actionGetSymptomsForList);
// categories
export const dispatchGetCategories = dispatch(actions.actionGetCategories);
export const dispatchGetCategoryById = dispatch(actions.actionGetCategoryById);
export const dispatchCreateCategory = dispatch(actions.actionCreateCategory);
export const dispatchUpdateCategory = dispatch(actions.actionUpdateCategory);
export const dispatchDeleteCategory = dispatch(actions.actionDeleteCategory);
export const dispatchGetCategoriesForList = dispatch(actions.actionGetCategoriesForList);
// self cares
export const dispatchGetSelfCares = dispatch(actions.actionGetAllSelfCares);
export const dispatchGetSelfCareById = dispatch(actions.actionGetSelfCareById);
export const dispatchCreateSelfCare = dispatch(actions.actionCreateSelfCare);
export const dispatchUpdateSelfCare = dispatch(actions.actionUpdateSelfCare);
export const dispatchDeleteSelfCare = dispatch(actions.actionDeleteSelfCare);
export const dispatchGetSelfCaresForList = dispatch(actions.actionGetSelfCaresForList);

// data expiration checker
export const dispatchIsUserDataExpired = dispatch(actions.usersDataExpired);
export const dispatchIsPatientDataExpired = dispatch(actions.patientsDataExpired);
export const dispatchIsRequisitionDataExpired = dispatch(actions.requisitionsDataExpired);
export const dispatchIsVisitDataExpired = dispatch(actions.visitsDataExpired);
export const dispatchIsPackageDataExpired = dispatch(actions.packagesDataExpired);
export const dispatchIsPanelDataExpired = dispatch(actions.panelsDataExpired);
export const dispatchIsBiomarkerDataExpired = dispatch(actions.biomarkersDataExpired);
export const dispatchIsBiomarkerResultDataExpired = dispatch(actions.biomarkerResultsDataExpired);
export const dispatchIsSupplementDataExpired = dispatch(actions.supplementsDataExpired);
export const dispatchIsExerciseDataExpired = dispatch(actions.exercisesDataExpired);
export const dispatchIsFoodDataExpired = dispatch(actions.foodsDataExpired);
export const dispatchIsRecommendationDataExpired = dispatch(actions.recommendationsDataExpired);
export const dispatchExpireDataByModuleName = dispatch(actions.expireDataByModuleName);

export const dispatchResetCrudState = dispatch(actions.actionResetCrudState);


/* Documents actions */

export const dispatchGetFile = dispatch(actions.actionGetFile);
export const dispatchGetDocumentsFromRequisition = dispatch(actions.actionGetDocumentsFromRequisition);
export const dispatchDeleteDocument = dispatch(actions.actionDeleteDocument);
export const dispatchUploadDocument = dispatch(actions.actionUploadDocument);
export const dispatchGetAllDocuments = dispatch(actions.actionGetAllDocuments);
export const dispatchDownloadDocument = dispatch(actions.actionDownloadDocument);
export const dispatchGetVisitDocuments = dispatch(actions.actionGetVisitDocuments);

/* Member CRM actions */
export const dispatchGetPatientsTimeline = dispatch(actions.actionGetPatientsTimeline);

// timezone service

export const dispatchGetTimezone = dispatch(actions.actionGetTimezoneForLocation);
export const dispatchGetTimezones = dispatch(actions.actionGetTimezonesBatchAction);

// PhysicianNotes
export const dispatchGetNotes = dispatch(actions.getNotes);
export const dispatchCreateNote = dispatch(actions.createNote);
export const dispatchUpdateNote = dispatch(actions.updateNote);
export const dispatchDeleteNote = dispatch(actions.deleteNote);

// Access codes
export const dispatchGetAccessCodes = dispatch(actions.getAccessCodes);
export const dispatchGenerateCustomAccessCode = dispatch(actions.generateAccessCode);
export const dispatchGetAccessCodeLogs = dispatch(actions.getAccessCodeLogs);

// Requisition comments
export const dispatchCreateRequisitionComment = dispatch(actions.actionCreateRequisitionComment);
export const dispatchUpdateRequisitionComment = dispatch(actions.actionUpdateRequisitionComment);
export const dispatchDeleteRequisitionComment = dispatch(actions.actionDeleteRequisitionComment);

// Critical results
export const dispatchUpdateCriticalResult = dispatch(actions.actionUpdateCriticalResult);
export const dispatchCreateCriticalResult = dispatch(actions.actionCreateCriticalResult);

// Visit documents

export const dispatchCreateVisitDocuments = dispatch(actions.actionCreateVisitDocument);
export const dispatchDeleteVisitDocuments = dispatch(actions.actionDeleteVisitDocument);
export const dispatchShowVisitDocuments = dispatch(actions.actionShowVisitDocument);
export const dispatchHideVisitDocuments = dispatch(actions.actionHideVisitDocument);
export const dispatchCreateReviewingTime = dispatch(actions.actionCreateReviewingTime);

