import gql from 'graphql-tag';

export const DELETE_USER_MUTATION = gql`
  mutation deleteUsers($id: UUID!) {
    deleteUsers(id: $id)
  }
`;

export const CREATE_USER_MUTATION = gql`
  mutation createUsers($userInput: UserCreate!) {
    createUsers(userInput: $userInput) {
      id
      healthGorillaId
      email
      phoneNumber
      isActive
      isSuperuser
      isPhysician
      isCreator
      fname
      lname
      npi
      licenseState
      isNursePracticioner
      isReviewer
      requisition {
        id
      }
    }
  }
`;

export const UPDATE_USER_MUTATION = gql`
  mutation updateUsers($id: UUID!, $userInput: UserUpdate!) {
    updateUsers(id: $id, userInput: $userInput) {
      id
      healthGorillaId
      email
      phoneNumber
      isActive
      isSuperuser
      isPhysician
      isCreator
      fname
      lname
      npi
      licenseState
      isNursePracticioner
      isReviewer
      requisition {
        id
      }
    }
  }
`;

export const UPDATE_ME_MUTATION = gql`
  mutation updateUserMe($userInput: UserUpdate!) {
    updateUserMe(userInput: $userInput) {
      id
      healthGorillaId
      email
      phoneNumber
      isActive
      isSuperuser
      isPhysician
      fname
      lname
      npi
      licenseState
      isNursePracticioner
      isReviewer
      isCreator
    }
  }
`;
