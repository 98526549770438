











import { Component, Mixins } from 'vue-property-decorator';
import LoaderMixin from '@/mixins/LoaderMixin.vue';
import { readDashboardShowDrawer } from '@/store/main/getters';

/**
 * Loader Class component that whill show a loading spinner
 * it uses a simple variable from store that is read by the mixin
 * @see LoaderMixin
 */
@Component({})
export default class Loading extends Mixins(LoaderMixin) {
    get showDrawer() {
    return readDashboardShowDrawer(this.$store);
  }
}
