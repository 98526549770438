import { ActionContext } from 'vuex';

import * as mutations from '@/store/crud/mutations';

import { CrudState } from '@/store/crud/state';

import { State } from '@/store/state';
import { commitAddNotification } from '@/store/main/mutations';
import { dispatchCheckApiError } from '@/store/main/actions';

import { graphQlApi } from '@/graphql/graphqlApi';
import { crudModules } from '@/constants/globalConstants';
import { ICreateSelfCare, ISelfCare, IUpdateSelfCare } from '@/interfaces/selfCare';


type MainContext = ActionContext<CrudState, State>;

/**
 * Returns cached data
 * or false if it is expired
 */
const getCachedData = async (context: MainContext, needsDataUpgrade: boolean) => {

    const cachedData = context.getters.SelfCares;
    const dispatchAction = 'SelfCaresDataExpired';

    if (cachedData && cachedData.length > 0) {
        const dataExpired = await context.dispatch(dispatchAction);
        // check data expiration
        if (dataExpired) {
            // re run this action without cached data
            return false; // data is expired so we need to fetch new data
        } else {
            if (needsDataUpgrade) {
                if (context.getters.needsDataUpgrade(crudModules.SELF_CARE)) {
                    return false;
                }
            }
            return cachedData;
        }
    } else {
        return false;
    }
};

/**
 * Actions sub module for Exercises actions
 */
export default {
    /**
     * Gets all SelfCares with all available data from the DB
     * @param context - Vuex Action context
     * @returns - Promise with SelfCares or false if error
     */
     async actionGetSelfCaresForList(context: MainContext) {
        try {
            // check cached data
            const cachedData = await getCachedData(context, false);
            if (cachedData) {
                return cachedData;
            }

            const response = await graphQlApi.getAllSelfCaresForList();
            if (response) {
                mutations.commitSetSelfCares(context, response.data.selfcares);
                mutations.setModuleNeedsDataUpgrade(context, {
                    module: crudModules.SELF_CARE,
                    needsDataUpgrade: true,
                });
                mutations.setDataUpdated(context, crudModules.SELF_CARE);
                return Promise.resolve(response.data.selfcares);
            } else {
                throw new Error('Failed while fetching SelfCares');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Error while fetching SelfCares', color: 'error' });
            return Promise.resolve(false);
        }
    },
    /**
     * Gets all SelfCares with all available data from the DB
     * @param context - Vuex Action context
     * @returns - Promise with SelfCares or false if error
     */
    async actionGetAllSelfCares(context: MainContext) {
        try {
            // check cached data
            const cachedData = await getCachedData(context, true);
            if (cachedData) {
                return cachedData;
            }

            const response = await graphQlApi.getAllSelfCares();
            if (response) {
                mutations.commitSetSelfCares(context, response.data.selfcares);
                mutations.setModuleNeedsDataUpgrade(context, {
                    module: crudModules.SELF_CARE,
                    needsDataUpgrade: false,
                });
                mutations.setDataUpdated(context, crudModules.SELF_CARE);
                return Promise.resolve(response.data.selfcares);
            } else {
                throw new Error('Failed while fetching SelfCares');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Error while fetching SelfCares', color: 'error' });
            return Promise.resolve(false);
        }
    },
    /**
     * Gets a single SelfCare from the DB
     * @param context - Vuex Action context
     * @param payload - selfCareId -> SelfCare to get
     * @returns - Promise with SelfCare or false if error
     */
    async actionGetSelfCareById(context: MainContext, id: string) {
        try {
            const response = await graphQlApi.getSelfCareById(id);
            if (response) {
                mutations.commitSetSelfCare(context, response.data.selfcareById);
                return Promise.resolve(response.data.selfcareById);
            } else {
                throw new Error('Failed while fetching SelfCare');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Error while fetching SelfCare', color: 'error' });
            return Promise.resolve(false);
        }
    },
    /**
     * Updates a single SelfCare in the DB
     * @param context - Vuex Action context
     * @param payload -
     * - SelfCareId -> SelfCare to update
     * - SelfCare -> SelfCare data to update
     * @returns - Promise with updated SelfCare or false if error
     */
    async actionUpdateSelfCare(context: MainContext, payload: { selfCareId: string, selfCare: IUpdateSelfCare }) {
        const loadingNotification = { content: 'saving', color: 'info' };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                graphQlApi.updateSelfCare(payload.selfCareId, payload.selfCare),
                await new Promise<void>((resolve) => setTimeout(() => resolve(), 500)),
            ]))[0];
            if (response) {
                commitAddNotification(context, { content: 'SelfCare successfully updated', color: 'success' });
                mutations.commitSetSelfCare(context, response.data.updateSelfcare);
                return Promise.resolve(response.data.updateSelfcare);
            } else {
                throw new Error('Failed while updating SelfCare');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Failed while updating SelfCare', color: 'error' });
            return Promise.resolve(false);
        }
    },
    /**
     * Creates a new SelfCare in the DB
     * @param context - Vuex Action context
     * @param payload -
     * - ICreateSelfCare ->  SelfCare data to add
     * @returns - Promise with added SelfCare or false if error
     */
    async actionCreateSelfCare(context: MainContext, payload: ICreateSelfCare) {
        const loadingNotification = { content: 'saving', color: 'info' };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                graphQlApi.createSelfCare(payload),
                await new Promise<void>((resolve) => setTimeout(() => resolve(), 500)),
            ]))[0];
            if (response) {
                commitAddNotification(context, { content: 'SelfCare successfully created', color: 'success' });
                mutations.commitSetSelfCare(context, response.data.createSelfcare);
                return Promise.resolve(response.data.createSelfcare);
            } else {
                throw new Error('Failed while creating SelfCare');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Failed while creating SelfCare', color: 'error' });
            return Promise.resolve(false);
        }
    },
    /**
     * Deletes a single SelfCare from the DB
     * @param context - Vuex Action context
     * @param payload -> SelfCareId -  SelfCare to remove
     * @returns - Promise with removed SelfCare or false if error
     */
    async actionDeleteSelfCare(context: MainContext, payload: { selfCareId: string }) {
        const loadingNotification = { content: 'saving', color: 'info' };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                graphQlApi.deleteSelfCare(payload.selfCareId),
                await new Promise<void>((resolve) => setTimeout(() => resolve(), 500)),
            ]))[0];
            if (response) {
                commitAddNotification(context, { content: 'SelfCare successfully deleted', color: 'success' });
                mutations.commitDeleteSelfCare(context, {id: payload.selfCareId} as ISelfCare);
                return Promise.resolve(response.data.deleteSelfcare);
            } else {
                throw new Error('Failed while deleting SelfCare');
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
            commitAddNotification(context, { content: 'Failed while removing SelfCare', color: 'error' });
            return Promise.resolve(false);
        }
    },

};

